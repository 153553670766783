import { useMutation } from "@apollo/client";
import * as Sentry from "@sentry/react";
import { loader } from "graphql.macro";
import React, { useContext } from "react";
import styled from "styled-components";
import FormInstruction from "../../components/FormTemplates/Instruction/FormInstruction";
import { PageWrapper } from "../../components/Layout/PageWrapper/PageWrapper";
import { LANGUAGES } from "../../constants/languages_types";
import ApplicationContext from "../../utils/context";
import { displayToastNotification } from "../../utils/toastNotification";

const addInstructionQuery = loader("../../graphql/addInstruction.graphql");
const addInstructionI18nQuery = loader(
  "../../graphql/addInstructionI18n.graphql"
);

interface FormValues {
  id?: string;
  type: string;
  surgery_category_id: string;
  active: boolean;
  value_fr: string;
  value_en: string;
}

interface InstructionsCreateProps {}

const InstructionsCreate: React.FC<InstructionsCreateProps> = () => {
  const { languagesCxt } = useContext<any>(ApplicationContext);

  const [addInstruction] = useMutation(addInstructionQuery);
  const [addInstructionI18n] = useMutation(addInstructionI18nQuery);

  const initialValues = {
    type: "",
    surgery_category_id: "",
    active: false,
    value_fr: "",
    value_en: "",
  };

  const addInstructionAsync = async (values: FormValues) => {
    return await addInstruction({
      variables: values,
    })
      .then((...response) => {
        const [
          {
            data: {
              insert_instruction: { returning: instruction },
            },
          },
        ] = response;
        console.info("Instruction created", instruction[0]);
        return instruction[0];
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const createInstruction = async (values: FormValues) => {
    try {
      const newInstruction = await addInstructionAsync(values);

      let language_fr_id = languagesCxt.languagesToEdit.filter(
        (e: { id: string; is_default: boolean; language_code: string }) =>
          e.language_code === LANGUAGES.FR_FR
      )?.[0]?.id;

      let language_en_id = languagesCxt.languagesToEdit.filter(
        (e: { id: string; is_default: boolean; language_code: string }) =>
          e.language_code === LANGUAGES.EN_UK
      )?.[0]?.id;
      await addInstructionI18n({
        variables: {
          instruction_id: newInstruction.id,
          value_fr: values.value_fr,
          value_en: values.value_en,
          language_fr_id,
          language_en_id,
        },
      });
      displayToastNotification(
        "success",
        "La nouvelle instruction est bien enregistrée"
      );
    } catch (err) {
      console.error("Invalid input:", values);
      console.error(err);
      Sentry.captureException(err);
      displayToastNotification("error", "Une erreur est survenue");
    }
  };

  const handleSubmit = async (values: FormValues) => {
    await createInstruction(values);
  };

  return (
    <PageWrapper>
      <Container>
        <FormInstruction
          title="Créer une instruction"
          initialValues={initialValues}
          mode="creation"
          handleSubmit={(values: FormValues) => handleSubmit(values)}
        />
      </Container>
    </PageWrapper>
  );
};

export default InstructionsCreate;

const Container = styled.div`
  height: 100%;
  margin: 0 auto;
  max-width: 1030px;
`;
