import React from "react";
import styled from "styled-components";
import { LinearGradientText } from "../../../theme/components/ThemeComponents";
import { BodySmall, Heading4 } from "../../../theme/fonts";
import CustomButton from "../../CustomButton/CustomButton";

interface AuthCardButtonProps {
  content: string;
  disabled?: boolean;
  type?: "button" | "submit" | "reset";
  subtitleContent?: string;
  handleOnClick: () => void;
  handleOnClickSubtitle?: () => void;
}

export const AuthCardButton: React.FC<AuthCardButtonProps> = ({
  content,
  disabled,
  type,
  subtitleContent,
  handleOnClick,
  handleOnClickSubtitle,
}) => {
  return (
    <ButtonContainer>
      <CustomButton
        type={type}
        disabled={disabled}
        content={content}
        handleOnClick={handleOnClick}
      />
      {subtitleContent && (
        <ButtonSubtitle
          onClick={() =>
            handleOnClickSubtitle ? handleOnClickSubtitle() : null
          }
        >
          {subtitleContent}
        </ButtonSubtitle>
      )}
    </ButtonContainer>
  );
};

export default AuthCardButton;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 20px;

  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.laptop13Max}) {
    margin-top: 10px;
  }
`;

const ButtonSubtitle = styled.div`
  ${Heading4}
  text-decoration: underline !important;
  border-bottom: 1px solid #095c66;
  cursor: pointer;
  margin-top: 5px;
  ${LinearGradientText}

  @media screen and (max-width: ${(props) =>
    props.theme.breakpoints.laptop13Max}) {
    ${BodySmall}
  }
`;
