import { useMutation } from "@apollo/client";
import { pdf } from "@react-pdf/renderer";
import { loader } from "graphql.macro";
import cookie from "js-cookie";
import JsBarcode from "jsbarcode";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Modal, ModalBody } from "reactstrap";
import styled, { css } from "styled-components";
import { COOKIES } from "../../../constants/cookies_types";
import { APPLICATION_ROLE } from "../../../constants/db_types";
import { BodyMain, BodySmall, Heading3, Heading4 } from "../../../theme/fonts";
import { ReactComponent as IconClose } from "../../../theme/icons/close.svg";
import { getFirebaseToken } from "../../../utils/auth";
import {
  sendPrescriptionEmail,
  uploadPrescription,
} from "../../../utils/prescription";
import { displayToastNotification } from "../../../utils/toastNotification";
import CustomButton from "../../CustomButton/CustomButton";
import CustomModal from "../../CustomModal/CustomModal";
import SeparatorSectionForm from "../../Form/SeparatorSectionForm/SeparatorSectionForm";
import TitleSectionForm from "../../Form/TitleSectionForm/TitleSectionForm";
import CustomContour from "../../Layout/CustomContour/CustomContour";
import PatientListTemplates from "../../ListTemplates/Patient/PatientListTemplates";
import PdfViewer from "../../PdfViewer";
import PrescriptionPdfLayout from "../../PrescriptionPdfLayout/PrescriptionPdfLayout";
import SearchBar from "../../SearchBar/SearchBar";
import * as Sentry from "@sentry/react";
import { base64ToBlob } from "../../../utils/global";

const addPrescriptionQuery = loader("../../../graphql/addPrescription.graphql");

type Medications = { name: string; text: string }[];
type Practitioner = {
  name: string;
  surname: string;
  phone: string;
  rpps: string;
  signature: string;
  specialization: string;
};
type Organization = {
  id: string;
  address: { city: string; postalCode: string; street: string; number: string };
  am: string | null;
  finess: string | null;
};
type Patient = {
  id: string;
  name: string;
  surname: string;
  birthDate: string;
  surgeryId: string;
};

interface FormValues {
  medications: Medications;
  practitioner: Practitioner;
  organization: Organization;
  patient: Patient;
}

interface FormPrescpriptionProps {
  title: string;
  handleSubmit: (values: FormValues) => void;
  organization?: Organization;
  practitioner?: Practitioner;
  patient?: Patient;
}

async function blobToBase64(blob: Blob | null) {
  if (!blob) return;
  return new Promise((resolve, _) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.readAsDataURL(blob);
  });
}

const FormPrescription: React.FC<FormPrescpriptionProps> = ({
  title,
  handleSubmit,
  organization,
  practitioner,
  patient,
}) => {
  const history = useHistory();

  const [addPrescription] = useMutation(addPrescriptionQuery);

  const [pdfBase64, setPdfBase64] = useState<any>(null);
  const [searchBarValue, setSearchBarValue] = useState("");
  const [medicationsProposed, setMedicationsProposed] = useState<string[]>([]);
  const [medicationsSelected, setMedicationsSelected] = useState<
    { name: string; text: string }[]
  >([]);

  const [currentPatient, setCurrentPatient] = useState<Patient | null>();

  const [patientsList, setPatientsList] = useState({
    show: false,
  });

  useEffect(() => {
    if (patient) {
      setCurrentPatient({ ...patient });
    }
  }, [patient]);

  const handleSearchMedication = async (value: string) => {
    if (!value) {
      setMedicationsProposed([]);
      return;
    }
    try {
      //TODO utils function
      const token = await getFirebaseToken();
      const res = await fetch(
        `${process.env.REACT_APP_AUTH_SERVER_URL}/search-medications`,
        {
          headers: {
            "Authorization": `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          method: "POST",
          body: JSON.stringify({ value }),
        }
      );
      const resJson = await res.json();
      if (resJson.medications) {
        setMedicationsProposed(resJson.medications);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleClickMedicationProposed = (medication: string) => {
    const medicationsSelectedTmp = [
      ...medicationsSelected,
      { name: medication, text: "" },
    ];
    setMedicationsSelected(medicationsSelectedTmp);
    setMedicationsProposed([]);
    setSearchBarValue("");
  };

  const handleClickDeleteMedicationSelected = (index: number) => {
    const medicationsSelectedTmp = [...medicationsSelected];
    medicationsSelectedTmp.splice(index, 1);
    setMedicationsSelected(medicationsSelectedTmp);
  };

  const handleChangeMedicationSelectedInput = (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const medicationsSelectedTmp = medicationsSelected.map((e, i) => {
      if (index === i) {
        return { ...e, text: event.target.value };
      } else {
        return { ...e };
      }
    });
    setMedicationsSelected(medicationsSelectedTmp);
  };

  const handleClickPatient = (data: any) => {
    setCurrentPatient({
      id: data.id,
      name: data.name,
      surname: data.surname,
      birthDate: data.birth_date,
      surgeryId: data.surgeries?.[0]?.id,
    });
  };

  const generateDocument = async () => {
    const role = cookie.get(COOKIES.ROLE);
    if (role === APPLICATION_ROLE.ADMIN) {
      displayToastNotification(
        "error",
        "[KOALOU-ADMIN] Veuillez tester avec le rôle de practitioner"
      );
      return;
    }

    // Generate RPPS Barcode
    if (practitioner?.rpps) {
      JsBarcode("#barcode-rpps", practitioner.rpps, {
        displayValue: false,
      });
    } else {
      displayToastNotification("error", "RPPS non valide");
      return;
    }

    // Generate AM / FINESS Barcode
    if (organization?.am) {
      JsBarcode("#barcode-am-finess", organization.am, {
        displayValue: false,
      });
    } else if (organization?.finess) {
      JsBarcode("#barcode-am-finess", organization.finess, {
        displayValue: false,
      });
    } else {
      displayToastNotification("error", "AM/FINESS non valide");
      return;
    }

    if (!practitioner || !organization || !currentPatient) {
      displayToastNotification("error", "Une erreur est survenue");
      return;
    }

    try {
      const blob = await pdf(
        <PrescriptionPdfLayout
          medications={medicationsSelected}
          patient={currentPatient!}
          practitioner={practitioner!}
          organization={organization!}
        />
      ).toBlob();
      try {
        const b64 = await blobToBase64(blob);
        setPdfBase64(b64);
      } catch (err) {
        console.error(err);
        displayToastNotification("error", "Une erreur est survenue");
      }
    } catch (err) {
      console.error(err);
      displayToastNotification("error", "Une erreur est survenue");
    }
  };

  const saveAndsendDocument = async () => {
    if (!practitioner || !organization || !currentPatient) {
      displayToastNotification("error", "Une erreur est survenue");
      return;
    }
    try {
      //convert base64 to file format
      const file = base64ToBlob(pdfBase64, "pdf");
      if (!file) {
        displayToastNotification("error", "Une erreur est survenue");
        return;
      }
      const data = new FormData();
      data.append(
        "file",
        file,
        `${practitioner.name} ${practitioner.surname}-${currentPatient.name} ${
          currentPatient.surname
        }-${moment(new Date()).format("DD-MM-YYYY")}`
      );
      const { fileName, error } = await uploadPrescription(data);
      if (error) {
        displayToastNotification("error", "Une erreur est survenue");
        return;
      } else if (fileName) {
        await addPrescription({
          variables: {
            surgeryId: currentPatient.surgeryId,
            organizationId: organization.id,
            filePath: fileName,
          },
        });
        data.append("patientId", currentPatient.id);
        const { error } = await sendPrescriptionEmail(data);
        if (error) {
          Sentry.captureException(error);
        }
        displayToastNotification(
          "success",
          "La prescription a bien été envoyée"
        );
        setCurrentPatient(null);
        setMedicationsSelected([]);
        setPdfBase64(null);
      }
    } catch (err) {
      console.log(err);
      Sentry.captureException(err);
      displayToastNotification("error", "Une erreur est survenue");
    }
  };

  return (
    <FormContainer>
      <CustomContour title={title}>
        <Section>
          <TitleSectionForm title="Séléctionnez le patient" />
          <FieldSection>
            <FieldContainer style={{ display: "flex", alignItems: "center" }}>
              <CustomButton
                content="Choisir le patient"
                disabled={!!currentPatient}
                handleOnClick={() =>
                  setPatientsList({
                    show: true,
                  })
                }
              />
              {currentPatient && (
                <>
                  <PatientName>
                    {currentPatient?.name} {currentPatient?.surname}
                  </PatientName>
                  <PatientDelete onClick={() => setCurrentPatient(null)}>
                    <IconClose />
                    <span>Supprimer</span>
                  </PatientDelete>
                </>
              )}
            </FieldContainer>
          </FieldSection>
        </Section>
        <SeparatorContainer>
          <SeparatorSectionForm />
        </SeparatorContainer>
        <Section>
          <TitleSectionForm title="Séléctionnez les médicaments" />
          <FieldSection>
            <FieldContainer>
              <SearchBar
                searchBarValue={searchBarValue}
                sendQuery={(value: string) => handleSearchMedication(value)}
                setSearchBarValue={setSearchBarValue}
              />
              <MedicationsProposedContainer>
                {medicationsProposed.map((e, i) => (
                  <MedicationsProposedItem
                    key={i}
                    onClick={() => handleClickMedicationProposed(e)}
                  >
                    <b>{e.substring(0, searchBarValue.length)}</b>
                    {e.substring(searchBarValue.length)}
                  </MedicationsProposedItem>
                ))}
              </MedicationsProposedContainer>
              <MedicationsSelectedContainer>
                {medicationsSelected.map((e, i) => (
                  <MedicationsSelectedRow key={i}>
                    <MedicationsSelectedLabel>
                      {e.name}
                    </MedicationsSelectedLabel>
                    <MedicationInputContainer>
                      <InputContainer
                        placeholder={"Détails de la posologie"}
                        onChange={(e) =>
                          handleChangeMedicationSelectedInput(e, i)
                        }
                      />
                      <MedicationsSelectedDelete
                        onClick={() => handleClickDeleteMedicationSelected(i)}
                      >
                        <IconClose />
                        <span>Supprimer</span>
                      </MedicationsSelectedDelete>
                    </MedicationInputContainer>
                  </MedicationsSelectedRow>
                ))}
              </MedicationsSelectedContainer>
            </FieldContainer>
          </FieldSection>
        </Section>
      </CustomContour>
      <ButtonContainer>
        <CustomButton
          content="Prévisualiser"
          disabled={!currentPatient}
          handleOnClick={() => generateDocument()}
        />
      </ButtonContainer>
      <CustomModal
        showModal={patientsList.show}
        shouldCloseOnOverlayClick={true}
        component={
          <PatientListContainer>
            <>
              <PatientListTemplates
                customHandleClick={(data: any) => handleClickPatient(data)}
                activeRowStyle={true}
              />

              <CustomButtonContainer>
                <CustomButton
                  content="Créer un patient"
                  handleOnClick={() => {
                    history.push("/patients/create?origin=create-prescription");
                  }}
                />
                <VerticalSeparator />
                <CustomButton
                  content="Annuler"
                  handleOnClick={() => {
                    setCurrentPatient(null);
                    setPatientsList({ show: false });
                  }}
                />
                <CustomButton
                  content="Valider"
                  handleOnClick={() => setPatientsList({ show: false })}
                  disabled={!currentPatient}
                />
              </CustomButtonContainer>
            </>
          </PatientListContainer>
        }
      />
      <ModalContainer
        isOpen={!!pdfBase64}
        className="reactstrap-modal-full-size"
      >
        <ModalBody>
          <PdfViewer
            fileBase64={pdfBase64}
            toggle={() => setPdfBase64(null)}
            documentName="ordonnance"
            secondCustomButtonText="Enregistrer et envoyer"
            handleClickSecondCustomButton={() => {
              saveAndsendDocument();
            }}
          />
        </ModalBody>
      </ModalContainer>
      <div>
        <canvas id="barcode-am-finess" style={{ display: "none" }} />
        <canvas id="barcode-rpps" style={{ display: "none" }} />
      </div>
    </FormContainer>
  );
};

export default FormPrescription;

const deleteBtnStyle = css`
  ${BodySmall}
  color: ${({ theme }) => theme.colors.redL4};
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  & > svg > path {
    stroke: ${({ theme }) => theme.colors.redL4};
  }

  & > span {
    @media screen and (max-width: ${(props) =>
        props.theme.breakpoints.mobilePortraitMax}) {
      display: none;
    }
    margin-left: 5px;
  }
`;

const FormContainer = styled.div``;

const Section = styled.div`
  width: 100%;
`;

const FieldSection = styled.div`
  width: 100%;
  display: flex;
  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.tabletMax}) {
    flex-direction: column;
  }
`;

const FieldContainer = styled.div`
  width: 100%;
  margin: 16px 8px;

  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.tabletMax}) {
    width: 100%;
    margin: 8px 0;
  }
`;

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 30px;
`;

const PatientName = styled.div`
  text-transform: capitalize;
  ${Heading3}
  color: ${({ theme }) => theme.colors.darkGreenMain};
  text-decoration: underline;
  margin-left: 20px;
`;

const PatientDelete = styled.div`
  ${deleteBtnStyle}
  margin-left: 10px;
`;

const SeparatorContainer = styled.div`
  margin: 40px 0;
`;

const MedicationsProposedContainer = styled.div`
  max-height: 169px;
  overflow-y: auto;
  box-shadow: -6px -6px 12px 0.5px rgba(197, 197, 197, 0.21),
    6px 6px 12px 0.5px rgba(197, 197, 197, 0.21);
  border-radius: 6px;
  margin-top: 20px;
`;

const MedicationsProposedItem = styled.div`
  cursor: pointer;
  margin: 9px 0;
`;

const MedicationsSelectedContainer = styled.div`
  margin-top: 38px;
`;

const MedicationsSelectedRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 20px 0;
`;

const MedicationsSelectedLabel = styled.div`
  width: 40%;
  ${Heading4}
  color: ${({ theme }) => theme.colors.darkGreenMain};
  padding-right: 5px;
`;

const MedicationInputContainer = styled.div`
  width: 60%;
  display: flex;

  & > input {
    width: 75%;
  }
`;

const MedicationsSelectedDelete = styled.div`
  width: 25%;
  ${deleteBtnStyle}
`;

const InputContainer = styled.input`
  background: #d5e0e2;
  border: 0;
  border-radius: 6px;
  padding: 5px 20px;
  ${BodyMain}
  color: ${({ theme }) => theme.colors.darkGreenMain};
  ::placeholder {
    color: ${({ theme }) => theme.colors.darkGreenMain};
  }
`;

const PatientListContainer = styled.div`
  min-width: 1036px;

  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.laptop13Max}) {
    min-width: 800px;
  }
  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.tabletMax}) {
    min-width: 566px;
  }
`;

const CustomButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-evenly;
`;

const ModalContainer = styled(Modal)``;

const VerticalSeparator = styled.div`
  width: 2px;
  background-color: ${({ theme }) => theme.colors.darkGreenMain};
`;
