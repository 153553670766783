import { useQuery } from "@apollo/client";
import { loader } from "graphql.macro";
import cookie from "js-cookie";
import React, { useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import styled, { css } from "styled-components";
import { COOKIES } from "../../../constants/cookies_types";
import {
  APPLICATION_ROLE,
  ORGANIZATION_FEATURE,
  ORGANIZATION_ROLE,
} from "../../../constants/db_types";
import { Mixpanel } from "../../../lib/mixpanel";
import { BodyMain, Heading4 } from "../../../theme/fonts";
import { ReactComponent as IconDocument } from "../../../theme/icons/document.svg";
import { ReactComponent as IconDocumentSelected } from "../../../theme/icons/documentSelected.svg";
import { ReactComponent as IconEsign } from "../../../theme/icons/esign.svg";
import { ReactComponent as IconEsignSelected } from "../../../theme/icons/esignSelected.svg";
import { ReactComponent as IconEstablishment } from "../../../theme/icons/establishment.svg";
import { ReactComponent as IconEstablishmentSelected } from "../../../theme/icons/establishmentSelected.svg";
import { ReactComponent as IconFeedback } from "../../../theme/icons/feedback.svg";
import { ReactComponent as IconGuideline } from "../../../theme/icons/guideline.svg";
import { ReactComponent as IconGuidelineSelected } from "../../../theme/icons/guidelineSelected.svg";
import { ReactComponent as IconHelp } from "../../../theme/icons/help.svg";
import { ReactComponent as IconHome } from "../../../theme/icons/home.svg";
import { ReactComponent as IconHomeSelected } from "../../../theme/icons/homeSelected.svg";
import { ReactComponent as IconInstruction } from "../../../theme/icons/instruction.svg";
import { ReactComponent as IconInstructionSelected } from "../../../theme/icons/instructionSelected.svg";
import { ReactComponent as IconPrescription } from "../../../theme/icons/prescription.svg";
import { ReactComponent as IconPrescriptionSelected } from "../../../theme/icons/prescriptionSelected.svg";
import { ReactComponent as IconLogout } from "../../../theme/icons/logout.svg";
import { ReactComponent as IconPatient } from "../../../theme/icons/patient.svg";
import { ReactComponent as IconPatientSelected } from "../../../theme/icons/patientSelected.svg";
import { ReactComponent as IconPractitioner } from "../../../theme/icons/practitioner.svg";
import { ReactComponent as IconPractitionerSelected } from "../../../theme/icons/practitionerSelected.svg";
import { ReactComponent as IconAdminPanel } from "../../../theme/icons/adminPanel.svg";
import { ReactComponent as IconAdminPanelSelected } from "../../../theme/icons/adminPanelSelected.svg";
import { ReactComponent as IconReminder } from "../../../theme/icons/reminder.svg";
import { ReactComponent as IconReminderSelected } from "../../../theme/icons/reminderSelected.svg";
import { ReactComponent as KoalouLogo } from "../../../theme/images/koalouLogoColLightWithoutK.svg";
import { logout } from "../../../utils/auth";
import ApplicationContext from "../../../utils/context";
import Dropdown from "../../Dropdown/Dropdown";
import Modal from "react-modal";
import { checkIfOrganizationCanAccessFeature } from "../../../utils/authorization";
import { ROUTES_AUTH } from "../../../constants/routes";

interface SidebarProps {}

const getOrganizationsQuery = loader(
  "../../../graphql/getOrganizationsSidebar.graphql"
);

const getSidebarList = (
  currentPath: string,
  organizationFeatures: string[]
) => {
  return [
    {
      text: "Accueil",
      roles: [APPLICATION_ROLE.ADMIN, APPLICATION_ROLE.PRACTITIONER],
      featureLocked: null,
      icon: <IconHome style={{ width: "24px", height: "20px" }} />,
      iconIsActive: (
        <IconHomeSelected style={{ width: "24px", height: "20px" }} />
      ),
      link: ROUTES_AUTH.HOME,
      isActive: currentPath.startsWith(ROUTES_AUTH.HOME),
    },
    {
      text: "Patients",
      roles: [APPLICATION_ROLE.ADMIN, APPLICATION_ROLE.PRACTITIONER],
      featureLocked: null,
      icon: <IconPatient style={{ width: "24px", height: "20px" }} />,
      iconIsActive: (
        <IconPatientSelected style={{ width: "24px", height: "20px" }} />
      ),
      link: ROUTES_AUTH.PATIENTS_LIST,
      subMenu: [
        {
          text: "Liste des patients",
          link: ROUTES_AUTH.PATIENTS_LIST,
          isActive:
            currentPath.startsWith(ROUTES_AUTH.PATIENTS_LIST) ||
            currentPath.startsWith(ROUTES_AUTH.PATIENTS_UPDATE) ||
            currentPath.startsWith(ROUTES_AUTH.PATIENTS_PROFILE),
        },
        {
          text: "Créer un patient",
          link: ROUTES_AUTH.PATIENTS_CREATE,
          isActive: currentPath.startsWith(ROUTES_AUTH.PATIENTS_CREATE),
        },
      ],
    },
    {
      text: "Praticiens",
      roles: [
        APPLICATION_ROLE.ADMIN,
        ORGANIZATION_ROLE.ORG_OWNER,
        ORGANIZATION_ROLE.ORG_ADMIN,
      ],
      featureLocked: null,
      icon: <IconPractitioner style={{ width: "24px", height: "20px" }} />,
      iconIsActive: (
        <IconPractitionerSelected style={{ width: "24px", height: "20px" }} />
      ),
      link: ROUTES_AUTH.PRACTITIONERS_LIST,
      subMenu: [
        {
          text: "Liste des praticiens",
          link: ROUTES_AUTH.PRACTITIONERS_LIST,
          isActive:
            currentPath.startsWith(ROUTES_AUTH.PRACTITIONERS_LIST) ||
            currentPath.startsWith(ROUTES_AUTH.PRACTITIONERS_UPDATE),
        },
        {
          text: "Créer un praticien",
          link: ROUTES_AUTH.PRACTITIONERS_CREATE,
          isActive: currentPath.startsWith(ROUTES_AUTH.PRACTITIONERS_CREATE),
        },
      ],
    },
    {
      text: "Documents utiles",
      roles: [
        APPLICATION_ROLE.ADMIN,
        ORGANIZATION_ROLE.ORG_OWNER,
        ORGANIZATION_ROLE.ORG_ADMIN,
      ],
      featureLocked: !organizationFeatures.includes(
        ORGANIZATION_FEATURE.ORGANIZATION_DOCUMENTS
      ),
      icon: <IconDocument style={{ width: "24px", height: "20px" }} />,
      iconIsActive: (
        <IconDocumentSelected style={{ width: "24px", height: "20px" }} />
      ),
      link: ROUTES_AUTH.ORGANIZATION_DOCUMENTS_LIST,
      subMenu: [
        {
          text: "Liste des documents",
          link: ROUTES_AUTH.ORGANIZATION_DOCUMENTS_LIST,
          isActive:
            currentPath.startsWith(ROUTES_AUTH.ORGANIZATION_DOCUMENTS_LIST) ||
            currentPath.startsWith(ROUTES_AUTH.ORGANIZATION_DOCUMENTS_UPDATE),
        },
        {
          text: "Créer un document",
          link: ROUTES_AUTH.ORGANIZATION_DOCUMENTS_CREATE,
          isActive: currentPath.startsWith(
            ROUTES_AUTH.ORGANIZATION_DOCUMENTS_CREATE
          ),
        },
      ],
    },
    {
      text: "Consignes médicales",
      roles: [
        APPLICATION_ROLE.ADMIN,
        ORGANIZATION_ROLE.ORG_OWNER,
        ORGANIZATION_ROLE.ORG_ADMIN,
      ],
      featureLocked: !organizationFeatures.includes(
        ORGANIZATION_FEATURE.ORGANIZATION_GUIDELINES
      ),
      icon: <IconGuideline style={{ width: "24px", height: "20px" }} />,
      iconIsActive: (
        <IconGuidelineSelected style={{ width: "24px", height: "20px" }} />
      ),
      link: ROUTES_AUTH.ORGANIZATION_GUIDELINES_LIST,
      subMenu: [
        {
          text: "Liste des consignes",
          link: ROUTES_AUTH.ORGANIZATION_GUIDELINES_LIST,
          isActive:
            currentPath.startsWith(ROUTES_AUTH.ORGANIZATION_GUIDELINES_LIST) ||
            currentPath.startsWith(ROUTES_AUTH.ORGANIZATION_GUIDELINES_UPDATE),
        },
        {
          text: "Créer une consigne",
          link: ROUTES_AUTH.ORGANIZATION_GUIDELINES_CREATE,
          isActive: currentPath.startsWith(
            ROUTES_AUTH.ORGANIZATION_GUIDELINES_CREATE
          ),
        },
      ],
    },
    {
      text: "Établissements",
      roles: [APPLICATION_ROLE.ADMIN],
      featureLocked: null,
      icon: <IconEstablishment style={{ width: "24px", height: "20px" }} />,
      iconIsActive: (
        <IconEstablishmentSelected style={{ width: "24px", height: "20px" }} />
      ),
      link: ROUTES_AUTH.ESTABLISHMENTS_LIST,
      subMenu: [
        {
          text: "Liste des établissements",
          link: ROUTES_AUTH.ESTABLISHMENTS_LIST,
          isActive:
            currentPath.startsWith(ROUTES_AUTH.ESTABLISHMENTS_LIST) ||
            currentPath.startsWith(ROUTES_AUTH.ESTABLISHMENTS_UPDATE),
        },
        {
          text: "Créer un établissement",
          link: ROUTES_AUTH.ESTABLISHMENTS_CREATE,
          isActive: currentPath.startsWith(ROUTES_AUTH.ESTABLISHMENTS_CREATE),
        },
      ],
    },
    {
      text: "Signature Éléctronique",
      roles: [APPLICATION_ROLE.ADMIN, APPLICATION_ROLE.PRACTITIONER],
      featureLocked: !organizationFeatures.includes(ORGANIZATION_FEATURE.ESIGN),
      icon: <IconEsign style={{ width: "24px", height: "20px" }} />,
      iconIsActive: (
        <IconEsignSelected style={{ width: "24px", height: "20px" }} />
      ),
      link: ROUTES_AUTH.ESIGNATURE_STATUS,
      subMenu: [
        {
          text: "Suivi des signatures",
          link: ROUTES_AUTH.ESIGNATURE_STATUS,
          isActive: currentPath.startsWith(ROUTES_AUTH.ESIGNATURE_STATUS),
        },
        {
          text: "Créer un modèle",
          link: ROUTES_AUTH.ESIGNATURE_TEMPLATES_CREATE,
          isActive: currentPath.startsWith(
            ROUTES_AUTH.ESIGNATURE_TEMPLATES_CREATE
          ),
        },
        {
          text: "Liste des modèles",
          link: ROUTES_AUTH.ESIGNATURE_TEMPLATES_LIST,
          isActive: currentPath.startsWith(
            ROUTES_AUTH.ESIGNATURE_TEMPLATES_LIST
          ),
        },
      ],
    },
    {
      text: "Rappel des patients",
      roles: [APPLICATION_ROLE.ADMIN, APPLICATION_ROLE.PRACTITIONER],
      featureLocked: !organizationFeatures.includes(
        ORGANIZATION_FEATURE.CALL_DAY_BEFORE
      ),
      icon: <IconReminder style={{ width: "24px", height: "20px" }} />,
      iconIsActive: (
        <IconReminderSelected style={{ width: "24px", height: "20px" }} />
      ),
      link: ROUTES_AUTH.REMINDER_AVAILABILITY,
      subMenu: [
        {
          text: "Liste des disponibilités",
          link: ROUTES_AUTH.REMINDER_AVAILABILITY,
          isActive: currentPath.startsWith(ROUTES_AUTH.REMINDER_AVAILABILITY),
        },
        {
          text: "Configurer",
          link: ROUTES_AUTH.REMINDER_CONFIGURE,
          isActive: currentPath.startsWith(ROUTES_AUTH.REMINDER_CONFIGURE),
        },
      ],
    },
    {
      text: "Conseils",
      roles: [APPLICATION_ROLE.ADMIN],
      featureLocked: null,
      icon: <IconInstruction style={{ width: "24px", height: "20px" }} />,
      iconIsActive: (
        <IconInstructionSelected style={{ width: "24px", height: "20px" }} />
      ),
      link: ROUTES_AUTH.INSTRUCTIONS_LIST,
      subMenu: [
        {
          text: "Liste des instructions",
          link: ROUTES_AUTH.INSTRUCTIONS_LIST,
          isActive:
            currentPath.startsWith(ROUTES_AUTH.INSTRUCTIONS_LIST) ||
            currentPath.startsWith(ROUTES_AUTH.INSTRUCTIONS_UPDATE),
        },
        {
          text: "Créer une instruction",
          link: ROUTES_AUTH.INSTRUCTIONS_CREATE,
          isActive: currentPath.startsWith(ROUTES_AUTH.INSTRUCTIONS_CREATE),
        },
      ],
    },
    {
      text: "Prescriptions",
      roles: [APPLICATION_ROLE.ADMIN, APPLICATION_ROLE.PRACTITIONER],
      featureLocked: !organizationFeatures.includes(
        ORGANIZATION_FEATURE.PRESCRIPTIONS
      ),
      icon: <IconPrescription style={{ width: "24px", height: "20px" }} />,
      iconIsActive: (
        <IconPrescriptionSelected style={{ width: "24px", height: "20px" }} />
      ),
      link: ROUTES_AUTH.PRESCRIPTIONS_LIST,
      subMenu: [
        {
          text: "Liste des prescriptions",
          link: ROUTES_AUTH.PRESCRIPTIONS_LIST,
          isActive: currentPath.startsWith(ROUTES_AUTH.PRESCRIPTIONS_LIST),
        },
        {
          text: "Créer une prescritpion",
          link: ROUTES_AUTH.PRESCRIPTIONS_CREATE,
          isActive: currentPath.startsWith(ROUTES_AUTH.PRESCRIPTIONS_CREATE),
        },

        {
          text: "Configuration",
          link: ROUTES_AUTH.PRESCRIPTIONS_CONFIGURATION,
          isActive: currentPath.startsWith(
            ROUTES_AUTH.PRESCRIPTIONS_CONFIGURATION
          ),
        },
      ],
    },
    {
      text: "Admin panel",
      roles: [APPLICATION_ROLE.ADMIN],
      featureLocked: null,
      icon: <IconAdminPanel style={{ width: "24px", height: "20px" }} />,
      iconIsActive: (
        <IconAdminPanelSelected style={{ width: "24px", height: "20px" }} />
      ),
      link: ROUTES_AUTH.ADMIN,
      isActive: currentPath.startsWith(ROUTES_AUTH.ADMIN),
    },
  ];
};

const Sidebar: React.FC<SidebarProps> = () => {
  const history = useHistory();
  const location = useLocation();
  const { currentOrganizationCxt, meCxt } = useContext<any>(ApplicationContext);

  const [role, setRole] = useState("");
  const [organizationSelected, setOrganizationSelected] = useState<any>(null);
  const [dropdownValues, setDropdownValues] = useState<Array<{
    value: string;
    label: string;
  }> | null>(null);

  const [organizationFeatures, setOrganizationFeatures] = useState([]);

  /* Retreive organizations */
  const { data: dataOrganizations } = useQuery(getOrganizationsQuery, {
    variables: {
      userId: cookie.get(COOKIES.ID),
    },
  });

  useEffect(() => {
    const role = cookie.get(COOKIES.ROLE);
    if (role) {
      setRole(role);
    }
  }, []);

  useEffect(() => {
    /* Here we check if organization is allowed to access a specific feature */
    if (organizationSelected) {
      const features =
        organizationSelected.organization_subscription?.organization_plan?.organization_plan_features?.map(
          (e: any) => {
            return e.organization_feature.name;
          }
        );
      setOrganizationFeatures(features ?? []);
      if (
        !checkIfOrganizationCanAccessFeature(features ?? [], location.pathname)
      ) {
        history.push(ROUTES_AUTH.HOME);
        return;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, organizationSelected]);

  useEffect(() => {
    if (dataOrganizations?.organization) {
      const organizations = dataOrganizations.organization;
      const currentOrganizationLocalStorage = localStorage.getItem(
        "currentOrganization"
      );

      if (organizations && organizations.length > 0) {
        let organizationFirstItem = { ...organizations[0] };
        if (currentOrganizationLocalStorage) {
          organizationFirstItem = JSON.parse(currentOrganizationLocalStorage);
        }
        const organizationTmp = {
          ...organizationFirstItem,
          organization_role:
            organizationFirstItem.organization_role ||
            organizationFirstItem.organization_memberships?.[0]
              ?.organization_membership_roles?.[0]?.organization_role?.name ||
            ORGANIZATION_ROLE.ORG_MEMBER,
        };

        delete organizationTmp?.organization_memberships;
        setOrganizationSelected(organizationTmp);
        currentOrganizationCxt.setCurrentOrganizationToEdit(organizationTmp);
        localStorage.setItem(
          "currentOrganization",
          JSON.stringify(organizationTmp)
        );
        /* Set the dropdown Options (format => {value: "", label: ""}) */
        const dropdownValuesTmp = organizations.map((e: any) => {
          return {
            value: e.id,
            label: getOrganizationName(e),
          };
        });
        setDropdownValues(dropdownValuesTmp);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataOrganizations, role]);

  const getOrganizationName = (organization: any) => {
    let name = organization.name;
    if (
      organization.hospital_service &&
      organization.hospital_service.hospital &&
      organization.hospital_service.hospital.name
    ) {
      name += ` - ${organization.hospital_service.hospital.name}`;
    }
    return name;
  };

  const handleChangeOrganization = (data: { value: string; label: string }) => {
    //TODO refacto this part. Same as above
    const organization = dataOrganizations.organization.filter(
      (e: any) => data.value === e.id
    )[0];
    const organizationTmp = {
      ...organization,
      organization_role:
        organization.organization_memberships?.[0]
          ?.organization_membership_roles?.[0]?.organization_role?.name,
    };
    delete organizationTmp?.organization_memberships;
    setOrganizationSelected(organizationTmp);
    currentOrganizationCxt.setCurrentOrganizationToEdit(organizationTmp);
    localStorage.setItem(
      "currentOrganization",
      JSON.stringify(organizationTmp)
    );
    history.push(ROUTES_AUTH.HOME);
  };

  const renderOrganizationsList = () => {
    return (
      <OrganizationsContainer>
        <DropdownContainer>
          {dropdownValues && (
            <Dropdown
              options={dropdownValues}
              defaultValue={
                dropdownValues.filter(
                  (e) => organizationSelected.id === e.value
                )[0]
              }
              handleOnChange={(data) => handleChangeOrganization(data)}
              dropdownIndicatorColor="#FFF"
              singleValueColor="#FFF"
            />
          )}
        </DropdownContainer>
      </OrganizationsContainer>
    );
  };

  const [modal, setModal] = useState(false);

  useEffect(() => {
    window.$crisp?.push([
      "on",
      "chat:opened",
      () => window.$crisp.push(["do", "chat:show"]),
    ]);
    window.$crisp?.push([
      "on",
      "chat:closed",
      () => window.$crisp.push(["do", "chat:hide"]),
    ]);
    window.$crisp.push(["do", "chat:hide"]);
  }, []);

  return (
    <Container>
      <LogoContainer onClick={() => history.push("/home")}>
        <KoalouLogo />
      </LogoContainer>
      <ItemsContainer>
        {renderOrganizationsList()}
        <ItemsMiddleContainer>
          {getSidebarList(location.pathname, organizationFeatures).map(
            (item: any, i: number) => {
              if (item.featureLocked) {
                return null;
              }
              if (
                item.roles.includes(role) ||
                item.roles.includes(organizationSelected?.organization_role)
              ) {
                return (
                  <div
                    key={i}
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <ItemLink
                      onClick={() => history.push(item.link)}
                      subMenu={!!item.subMenu}
                      key={i}
                    >
                      <ItemLinkIcon>
                        {item.isActive ? item.iconIsActive : item.icon}
                      </ItemLinkIcon>
                      <ItemLinkText
                        isActive={item.isActive}
                        subMenu={!!item.subMenu}
                      >
                        {item.text}
                      </ItemLinkText>
                      <ItemLinkBackground isActive={item.isActive} />
                    </ItemLink>
                    {item.subMenu && (
                      <ItemLinkSubMenuMain
                        isActive={
                          item.subMenu.filter((e: any) => e.isActive).length > 0
                        }
                      >
                        {item.subMenu.map((e: any, j: number) => (
                          <ItemLinkSubMenuContainer
                            key={j}
                            onClick={() => history.push(e.link)}
                          >
                            <ItemLinkSubMenuText isActive={e.isActive}>
                              {e.text}
                              <ItemLinkSubMenuBackground
                                isActive={e.isActive}
                              />
                            </ItemLinkSubMenuText>
                          </ItemLinkSubMenuContainer>
                        ))}
                      </ItemLinkSubMenuMain>
                    )}
                  </div>
                );
              } else {
                return null;
              }
            }
          )}
        </ItemsMiddleContainer>
        <ItemsEndContainer>
          <ItemEndLink
            onClick={() => {
              Mixpanel?.openFeedbackSurvey(
                meCxt.meToEdit?.id,
                meCxt.meToEdit?.name,
                meCxt.meToEdit?.surname
              );
              setModal(true);
            }}
          >
            <ItemEndLinkIcon>
              <IconFeedback style={{ width: "24px", height: "19px" }} />
            </ItemEndLinkIcon>
            <ItemEndText>Feedback</ItemEndText>
          </ItemEndLink>
          <ItemEndLink
            onClick={() => {
              window.$crisp.push(["do", "chat:show"]);
              window.$crisp.push(["do", "chat:toggle"]);
            }}
          >
            <ItemEndLinkIcon>
              <IconHelp style={{ width: "24px", height: "19px" }} />
            </ItemEndLinkIcon>
            <ItemEndText>Aide</ItemEndText>
          </ItemEndLink>
          <ItemEndLink onClick={() => logout()}>
            <ItemEndLinkIcon>
              <IconLogout style={{ width: "24px", height: "19px" }} />
            </ItemEndLinkIcon>
            <ItemEndText>Déconnexion</ItemEndText>
          </ItemEndLink>
        </ItemsEndContainer>
      </ItemsContainer>
      <Modal
        isOpen={modal}
        onRequestClose={() => setModal(false)}
        style={{
          overlay: {
            zIndex: 99999999,
            background: `rgba(0, 0, 0, 0.6)`,
          },
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            padding: "0px",
            border: "0px",
          },
        }}
      >
        <div style={{ backgroundColor: "#12737b" }}>
          <div
            onClick={() => setModal(false)}
            style={{
              backgroundColor: "rgb(174, 233, 233)",
              color: "rgb(22, 143, 143)",
              borderRadius: "4px",
              padding: "4px",
              border: "1px solid transparent",
              fontWeight: 700,
              marginLeft: "97%",
              textAlign: "center",
            }}
            role="button"
          >
            X
          </div>
          <div
            dangerouslySetInnerHTML={{
              __html: `<iframe scrolling="no" frameBorder="0" width="${
                window.innerWidth / 1.5
              }" height="${
                window.innerHeight / 1.2
              }" src="https://koalou.typeform.com/to/M7TmkwFX" />`,
            }}
          />
        </div>
      </Modal>
    </Container>
  );
};

export default Sidebar;

const Container = styled.div``;

const LogoContainer = styled.div`
  margin: 0 20px;
  height: 120px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  cursor: pointer;
  border-bottom: 2px solid ${(props) => props.theme.colors._white};

  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.mobileLandscapeMax}) {
    display: none;
  }
`;

const OrganizationsContainer = styled.div`
  color: ${({ theme }) => theme.colors._white};
  user-select: none;
  position: relative;
  height: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 25px;
  cursor: pointer;
  width: 100%;
  margin-bottom: 20px;
`;

const ItemsContainer = styled.div`
  width: 100%;
  /* height: calc(100% - 200px - 2px); */
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.mobileLandscapeMax}) {
    margin-top: 50px;
  }
  font-weight: 500 !important;
`;

const ItemsMiddleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-height: calc(
    100vh - 120px - 220px - 45px
  ); /* Full screen height -  Logo container - end container - some margin bottom for space with end items*/
  overflow-y: auto;
  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.mobileLandscapeMax}) {
    max-height: calc(100vh - 50px - 220px);
  }
`;

const ItemLink = styled.div<{ subMenu: boolean }>`
  width: calc(100% - 32px);
  height: 42px;
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;

  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.mobileLandscapeMax}) {
    width: 100%;
    height: auto;
    align-items: initial;
  }
`;

const ItemLinkIcon = styled.div`
  z-index: 10;
  margin: 0 5px;
  display: flex;
  width: 30px;
  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.mobileLandscapeMax}) {
    display: none;
    margin: 0;
    width: 0;
  }

  & > svg > * {
    stroke: ${({ theme }) => theme.colors._white};
  }
`;

const ItemLinkText = styled.div<{ isActive: boolean; subMenu: boolean }>`
  width: calc(100% - 30px);
  z-index: 10;
  color: ${({ theme }) => theme.colors._white};
  margin-left: 5px;
  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.mobileLandscapeMax}) {
    padding-left: 10px;
    text-align: left;
  }
  ${BodyMain}

  font-weight: ${({ isActive }) => (isActive ? "bold" : "")};

  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.mobileLandscapeMax}) {
    width: 100%;
    padding: 20px 10px;
  }
`;

const activeItemLink = css`
  background: rgba(189, 210, 213, 0.69);
  opacity: 0.5;
  backdrop-filter: blur(30px);
  /* Note: backdrop-filter has minimal browser support */
  border-radius: 6px;

  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.mobileLandscapeMax}) {
    border-radius: 0px;
  }
`;

const ItemLinkBackground = styled.div<{ isActive: boolean }>`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
  ${(props) => (props.isActive ? activeItemLink : null)}
`;

const ItemsEndContainer = styled.div`
  width: 100%;
  position: absolute;
  bottom: 20px;
  padding: 0 15px;
  color: ${({ theme }) => theme.colors._white};
`;

const ItemEndLink = styled.div`
  margin-top: 15px;
  display: flex;
  cursor: pointer;
  align-items: center;
`;

const ItemEndText = styled.div`
  margin-left: 5px;
  ${Heading4}
`;

const ItemEndLinkIcon = styled.div`
  z-index: 10;
  margin: 0 5px;
  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.mobileLandscapeMax}) {
    display: none;
    margin: 0;
  }

  & > svg > * {
    fill: ${({ theme }) => theme.colors._white};
  }
`;
const ItemLinkSubMenuMain = styled.div<{ isActive: boolean }>`
  display: ${({ isActive }) => (isActive ? "flex" : "none")};
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: calc(100% - 32px);
`;

const ItemLinkSubMenuContainer = styled.div`
  width: 100%;
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
`;

const ItemLinkSubMenuBackground = styled.div<{ isActive: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  /* width: calc(100% - 75px); */
  width: calc(100%);
  height: 100%;
  z-index: -1;
  ${(props) => (props.isActive ? activeItemLink : null)}
`;

const ItemLinkSubMenuText = styled.div<{ isActive: boolean }>`
  z-index: 10;
  color: ${({ theme }) => theme.colors._white};
  padding: 10px 5px;
  width: calc(100% - 30px - 10px);
  position: relative;
  ${BodyMain}
  font-weight: ${({ isActive }) => (isActive ? "bold" : "")};
`;

const DropdownContainer = styled.div`
  width: 100%;
  z-index: 100;
`;
