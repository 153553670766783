import { useQuery } from "@apollo/client";
import { Field, Form, Formik } from "formik";
import { loader } from "graphql.macro";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import * as Yup from "yup";
import { validatePhoneNumber } from "../../../utils/validatePhoneNumber";
import CustomButton from "../../CustomButton/CustomButton";
import { DropdownForm } from "../../Form/DropdownForm/DropdownForm";
import PhoneInputForm from "../../Form/PhoneInputForm/PhoneInputForm";
import SeparatorSectionForm from "../../Form/SeparatorSectionForm/SeparatorSectionForm";
import { TextInputForm } from "../../Form/TextInputForm/TextInputForm";
import TitleSectionForm from "../../Form/TitleSectionForm/TitleSectionForm";
import CustomContour from "../../Layout/CustomContour/CustomContour";
import cookie from "js-cookie";
import { COOKIES } from "../../../constants/cookies_types";
import { APPLICATION_ROLE } from "../../../constants/db_types";
import { checkUserEmail } from "../../../utils/auth";

const getPractitionerSpecializationQuery = loader(
  "../../../graphql/getPractitionerSpecialization.graphql"
);

const getOrganizationRoleQuery = loader(
  "../../../graphql/getOrganizationRole.graphql"
);

interface FormValues {
  title: string;
  specialty: string;
  surname: string;
  name: string;
  email: string;
  phone: string;
  rpps: string;
  organization_role_id: string;
}

type FormMode = "creation" | "update";

interface FormPractitionerProps {
  title: string;
  mode: FormMode;
  handleSubmit: (values: FormValues) => void;
  initialValues: FormValues;
  organizationMembership?: { [key: string]: string };
  organizationMembershipLength?: number;
}

const validationSchema = (isAdmin: boolean, mode: FormMode) =>
  Yup.object().shape({
    title: Yup.string().required("Ce champ est requis"),
    specialty: Yup.string().required("Ce champ est requis"),
    name: Yup.string()
      .required("Ce champ est requis")
      .min(2, "2 lettres minimum"),
    surname: Yup.string()
      .required("Ce champ est requis")
      .min(2, "2 lettres minimum"),
    email: Yup.string().required("Ce champ est requis").email("Email invalide"),
    phone: Yup.string().required("Ce champ est requis"),
    organization_role_id:
      isAdmin && mode === "creation"
        ? Yup.string().required("Ce champ est requis")
        : Yup.string(),
  });

const FormPractitioner: React.FunctionComponent<FormPractitionerProps> = ({
  title,
  mode,
  handleSubmit,
  initialValues,
  organizationMembership,
  organizationMembershipLength,
}) => {
  const isAdmin = cookie.get(COOKIES.ROLE) === APPLICATION_ROLE.ADMIN;

  const { data: practitionerSpecializationData } = useQuery(
    getPractitionerSpecializationQuery
  );

  const [practitionerSpecialization, setPractitionerSpecialization] = useState(
    []
  );

  const { data: organizationRoleData } = useQuery(getOrganizationRoleQuery);

  const [organizationRole, setOrganizationRole] = useState([]);

  useEffect(() => {
    if (practitionerSpecializationData?.specialization) {
      const practitionerSpecializationTmp =
        practitionerSpecializationData.specialization.map(
          (e: { id: string; name: string }) => {
            return { label: e.name, value: e.id };
          }
        );
      setPractitionerSpecialization(practitionerSpecializationTmp);
    }
  }, [practitionerSpecializationData]);

  useEffect(() => {
    if (organizationRoleData) {
      const organizationRoleTmp = organizationRoleData.organization_role.map(
        (e: { id: string; name: string }) => {
          return { label: e.name, value: e.id };
        }
      );
      setOrganizationRole(organizationRoleTmp);
    }
  }, [organizationRoleData]);

  const validateEmail = async (value: string) => {
    let error = "";
    if (value && mode === "creation") {
      const res = await checkUserEmail(value);
      if (res.error) {
        error = res.error;
      } else if (
        res.role === APPLICATION_ROLE.PARENT ||
        res.role === APPLICATION_ROLE.PRACTITIONER
      ) {
        error = "L'email est déjà utilisé";
      }
    }
    return error;
  };

  const validateMembershipRole = (value: string) => {
    let error = "";
    if (!value) error = "Ce champs est requis";
    return error;
  };

  return (
    <Formik
      initialValues={{ ...initialValues, ...organizationMembership }}
      onSubmit={async (values: FormValues, { resetForm }) => {
        await handleSubmit(values);
        if (mode === "creation") {
          resetForm();
        }
      }}
      validationSchema={() => validationSchema(isAdmin, mode)}
      validateOnChange={false}
    >
      {(props) => {
        return (
          <FormContainer>
            <CustomContour title={title}>
              <Section>
                <TitleSectionForm title="Informations concernant le praticien" />
                <FieldSection>
                  <FieldContainer>
                    <Field
                      name={"title"}
                      label={"Titre"}
                      placeholder={"Prof | Dr"}
                      component={TextInputForm}
                    />
                  </FieldContainer>
                  <FieldContainer>
                    <Field
                      name={"specialty"}
                      label={"Spécialité"}
                      placeholder={"Spécialité"}
                      component={DropdownForm}
                      data={practitionerSpecialization}
                    />
                  </FieldContainer>
                </FieldSection>
                <SeparatorContainer></SeparatorContainer>
                <FieldSection>
                  <FieldContainer>
                    <Field
                      name={"name"}
                      label={"Prénom"}
                      placeholder={"Prénom"}
                      component={TextInputForm}
                    />
                  </FieldContainer>
                  <FieldContainer>
                    <Field
                      name={"surname"}
                      label={"Nom"}
                      placeholder={"Nom"}
                      component={TextInputForm}
                    />
                  </FieldContainer>
                </FieldSection>

                <SeparatorContainer></SeparatorContainer>
                <FieldSection>
                  <FieldContainer>
                    <Field
                      name={"email"}
                      label={"Email"}
                      placeholder={"Email"}
                      component={TextInputForm}
                      disabled={mode === "update"}
                      validate={validateEmail}
                    />
                  </FieldContainer>
                  <FieldContainer>
                    <Field
                      name={"phone"}
                      label={"Téléphone"}
                      placeholder={"01 XX XX XX XX"}
                      component={PhoneInputForm}
                      validate={validatePhoneNumber}
                    />
                  </FieldContainer>
                </FieldSection>
                <SeparatorContainer></SeparatorContainer>
                <FieldSection>
                  <FieldContainer>
                    <Field
                      name={"rpps"}
                      label={"Numéro RPPS"}
                      placeholder={"RPPS"}
                      component={TextInputForm}
                    />
                  </FieldContainer>
                </FieldSection>
              </Section>

              {isAdmin && mode === "creation" && (
                <>
                  <SeparatorContainer>
                    <SeparatorSectionForm />
                  </SeparatorContainer>
                  <Section>
                    <TitleSectionForm title="Espace Admin" />
                    <FieldSection>
                      <FieldContainer>
                        <Field
                          name={"organization_role_id"}
                          label={"Role"}
                          placeholder={"Role"}
                          component={DropdownForm}
                          data={organizationRole}
                        />
                      </FieldContainer>
                    </FieldSection>
                  </Section>
                </>
              )}

              {isAdmin && mode === "update" && (
                <>
                  <SeparatorContainer>
                    <SeparatorSectionForm />
                  </SeparatorContainer>

                  <Section>
                    <TitleSectionForm title="Espace Admin" />

                    {Array(organizationMembershipLength)
                      .fill(null)
                      .map((_, i) => (
                        <FieldSection key={i}>
                          {/* <Field
                            name={`organization_id_${i}`}
                            label={"Établissement"}
                            placeholder={"Établissement"}
                            component={DropdownForm}
                            data={organizations}
                            disabled={true}
                          /> */}
                          <FieldContainer>
                            <Field
                              name={"organization_role_id_" + i}
                              label={"Role"}
                              placeholder={"Role"}
                              component={DropdownForm}
                              data={organizationRole}
                              validate={validateMembershipRole}
                            />
                          </FieldContainer>
                        </FieldSection>
                      ))}
                  </Section>
                </>
              )}
              {/* 
                  {true &&
                    mode === "update" && ( //TODO is admin check  + test query
                      {Array(organizationMembershipLength)
                        .fill(null)
                        .map((_, i) => (
                          <FieldRow key={i}>
                          <FieldCol xs={12} sm={6}>
                      <Field
                        name={`organization_id_${i}`}
                        label={"Établissement"}
                        placeholder={"Établissement"}
                        component={DropdownForm}
                        data={organizations}
                        disabled={true}
                      />
                    </FieldCol>
                    <FieldCol xs={12} sm={6}>
                      <Field
                        name={"organization_role_id_" + i}
                        label={"Role"}
                        placeholder={"Role"}
                        component={DropdownForm}
                        data={organizationRole}
                        validate={validateMembershipRole}
                      />
                    </FieldCol>
                  </FieldRow>
                ))}
                  )} */}
            </CustomContour>
            <ButtonContainer>
              <CustomButton
                type="submit"
                content={
                  mode === "creation"
                    ? "Enregistrer le praticien"
                    : "Sauvegarder les modifications"
                }
                disabled={props.isSubmitting}
              />
            </ButtonContainer>
          </FormContainer>
        );
      }}
    </Formik>
  );
};

export default FormPractitioner;

const FormContainer = styled(Form)``;

const Section = styled.div`
  width: 100%;
`;

const FieldSection = styled.div`
  width: 100%;
  display: flex;
  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.tabletMax}) {
    flex-direction: column;
  }
`;

const SeparatorContainer = styled.div`
  margin: 40px 0;
`;

const FieldContainer = styled.div`
  width: calc(100% / 2);
  margin: 0 8px;

  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.tabletMax}) {
    width: 100%;
    margin: 8px 0;
  }
`;

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 30px;
`;
