import { useMutation } from "@apollo/client";
import * as Sentry from "@sentry/react";
import { loader } from "graphql.macro";
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { v4 as uuidv4 } from "uuid";
import FormInstruction from "../../components/FormTemplates/Instruction/FormInstruction";
import { PageWrapper } from "../../components/Layout/PageWrapper/PageWrapper";
import { Loader } from "../../components/Loader/Loader";
import { LANGUAGES } from "../../constants/languages_types";
import ApplicationContext from "../../utils/context";
import { displayToastNotification } from "../../utils/toastNotification";

const updateInstructionQuery = loader(
  "../../graphql/updateInstruction.graphql"
);

const updateInstructionI18nQuery = loader(
  "../../graphql/updateInstructionI18n.graphql"
);

interface FormValues {
  id?: string;
  type: string;
  surgery_category_id: string;
  active: boolean;
  value_fr: string;
  value_en: string;
  instructioni18n_id_fr?: string;
  instructioni18n_id_en?: string;
}

interface InstructionsUpdateProps {}

const InstructionsUpdate: React.FC<InstructionsUpdateProps> = () => {
  const history = useHistory();
  const { instructionCxt, languagesCxt } = useContext<any>(ApplicationContext);
  const [isLoading, setIsLoading] = useState(true);
  const [initialValues, setInitialValues] = useState(null);

  const [updateInstruction] = useMutation(updateInstructionQuery);
  const [updateInstructionI18n] = useMutation(updateInstructionI18nQuery);

  //TODO refacto this part ...
  useEffect(() => {
    let curatedInstruction;
    if (instructionCxt.instructionToEdit.id) {
      curatedInstruction = Object.assign({}, instructionCxt.instructionToEdit);
    } else if (localStorage.getItem("currentInstruction")) {
      curatedInstruction = Object.assign(
        {},
        JSON.parse(localStorage.getItem("currentInstruction")!)
      );
    } else {
      history.push("/instructions/list");
    }
    delete curatedInstruction.instruction_type;
    delete curatedInstruction.__typename;
    let translation_fr = curatedInstruction.instruction_i18ns.filter(
      (e: {
        id: string;
        language: { id: string; language_code: string };
        value: string;
      }) => e.language.language_code === LANGUAGES.FR_FR
    );
    let translation_en = curatedInstruction.instruction_i18ns.filter(
      (e: {
        id: string;
        language: { id: string; language_code: string };
        value: string;
      }) => e.language.language_code === LANGUAGES.EN_UK
    );
    curatedInstruction.value_fr = translation_fr?.[0]?.value;
    curatedInstruction.instructioni18n_id_fr =
      translation_fr?.[0]?.id ?? uuidv4(); //Id id is NULL, it means the translation in this language is not in DB
    curatedInstruction.value_en = translation_en?.[0]?.value;
    curatedInstruction.instructioni18n_id_en =
      translation_en?.[0]?.id ?? uuidv4();
    setInitialValues({ ...curatedInstruction });
  }, [instructionCxt, history]);

  useEffect(() => {
    if (initialValues) {
      setIsLoading(false);
    }
  }, [initialValues]);

  function modifyInstruction(values: FormValues) {
    updateInstruction({
      variables: values,
    })
      .then((...args) => {
        const [
          {
            data: {
              update_instruction: { returning: instruction },
            },
          },
        ] = args;
        console.info("Instruction updated", instruction);
      })
      .catch((e) => {
        console.error("Invalid input:", values);
        console.error(e);
        Sentry.captureException(e);
      });
  }

  const handleSubmit = async (values: FormValues) => {
    await modifyInstruction(values);
    let language_fr_id = languagesCxt.languagesToEdit.filter(
      (e: { id: string; is_default: boolean; language_code: string }) =>
        e.language_code === LANGUAGES.FR_FR
    )?.[0]?.id;
    let language_en_id = languagesCxt.languagesToEdit.filter(
      (e: { id: string; is_default: boolean; language_code: string }) =>
        e.language_code === LANGUAGES.EN_UK
    )?.[0]?.id;
    try {
      await updateInstructionI18n({
        variables: {
          instruction_id: values.id,
          value_fr: values.value_fr,
          value_en: values.value_en,
          instructioni18n_id_fr: values.instructioni18n_id_fr,
          instructioni18n_id_en: values.instructioni18n_id_en,
          language_fr_id,
          language_en_id,
        },
      });
      displayToastNotification(
        "success",
        "Les modifications ont bien été enregistrées"
      );
    } catch (err) {
      displayToastNotification("error", "Une erreur est survenue");
    }
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <PageWrapper
      previousPageIconOn={true}
      previousPageText={"Retour à la liste des instructions"}
      handleOnClickPreviousPage={() => history.push("/instructions/list")}
    >
      <Container>
        <FormInstruction
          title="Modifier l'instruction"
          initialValues={initialValues!}
          mode="update"
          handleSubmit={(values) => handleSubmit(values)}
        />
      </Container>
    </PageWrapper>
  );
};

export default InstructionsUpdate;

const Container = styled.div`
  height: 100%;
  margin: 0 auto;
  max-width: 1030px;
`;
