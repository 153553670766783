import { useLazyQuery } from "@apollo/client";
import { loader } from "graphql.macro";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import CustomList from "../../../components/List/CustomList/CustomList";
import ApplicationContext from "../../../utils/context";

const getSurgeriesCountQuery = loader(
  "../../../graphql/getSurgeriesCountByOrganizationId.graphql"
);
const getSurgeriesWithLimitOffsetQuery = loader(
  "../../../graphql/getSurgeriesWithLimitOffset.graphql"
);

interface PatientListTemplatesProps {
  customHandleClick?: (data: any) => void;
  activeRowStyle?: boolean;
}

const columns = [
  { value: "Nom", ratio: "20%", type: "string" },
  { value: "Prénom", ratio: "20%", type: "string" },
  { value: "Date de naissance", ratio: "20%", type: "string" },
  {
    value: "Intervention",
    ratio: "20%",
    type: "string",
    sortable: true,
    sortId: "surgery_type",
  },
  {
    value: "Date de l'intervention",
    ratio: "20%",
    type: "string",
    sortable: true,
    sortId: "date",
  },
];

const limit = 10;

const PatientListTemplates: React.FC<PatientListTemplatesProps> = ({
  customHandleClick,
  activeRowStyle,
}) => {
  const history = useHistory();
  const [pagesNumber, setPagesNumber] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("%%");
  const [searchBarValue, setSearchBarValue] = useState("");
  const [orderBy, setOrderBy] = useState<any>({
    date: "desc",
  });
  const { currentOrganizationCxt, patientCxt } =
    useContext<any>(ApplicationContext);
  const [surgeries, setSurgeries] = useState<Array<{
    customData: any;
    rowValues: Array<string>;
  }> | null>(null);
  const [getSurgeriesCount, { data: dataSurgeriesCount }] = useLazyQuery(
    getSurgeriesCountQuery,
    { fetchPolicy: "network-only" }
  );
  const [getSurgeriesWithLimitOffset, { data: dataSurgeries }] = useLazyQuery(
    getSurgeriesWithLimitOffsetQuery,
    { fetchPolicy: "network-only" }
  );

  useEffect(() => {
    if (currentOrganizationCxt?.currentOrganizationToEdit?.id) {
      getSurgeriesCount({
        variables: {
          organizationId: currentOrganizationCxt.currentOrganizationToEdit.id,
          search,
        },
      });
    }
  }, [
    currentOrganizationCxt?.currentOrganizationToEdit,
    getSurgeriesCount,
    search,
  ]);

  useEffect(() => {
    if (currentOrganizationCxt?.currentOrganizationToEdit?.id) {
      getSurgeriesWithLimitOffset({
        variables: {
          limit,
          offset: currentPage * limit - limit,
          orderBy,
          search,
          organizationId: currentOrganizationCxt?.currentOrganizationToEdit?.id,
        },
      });
    }
  }, [
    currentPage,
    currentOrganizationCxt?.currentOrganizationToEdit,
    getSurgeriesWithLimitOffset,
    search,
    orderBy,
  ]);

  useEffect(() => {
    if (dataSurgeries) {
      const surgeriesTmp = dataSurgeries.surgery.map((e: any) => {
        //Here we convert surgery object to patient object (because of sort feature (2 march 2021) on surgery value and date)
        return {
          customData: {
            id: e.patient?.id,
            birth_date: e.patient?.birth_date,
            name: e.patient?.name,
            surname: e.patient?.surname,
            parent_patients: [
              {
                parent: e.patient?.parent_patients?.[0]?.parent,
              },
            ],
            surgeries: [
              {
                id: e.id,
                date: e.date,
                type: e.type,
                organization_id: e.organization_id,
                organization: e.organization,
                surgery_type: e.surgery_type,
              },
            ],
            prescriptions: e.prescriptions.map((p: any) => {
              return {
                filePath: p.file_path,
                date: p.created_at,
                practitionerId: p.practitioner_id,
              };
            }),
          },
          rowValues: [
            e.patient?.surname,
            e.patient?.name,
            moment(e.patient?.birth_date).format("DD/MM/YYYY"),
            e.surgery_type?.value,
            moment(e.date).format("DD/MM/YYYY"),
          ],
        };
      });
      setSurgeries(surgeriesTmp);
    }
  }, [dataSurgeries]);

  /* Calculate number of pages */
  useEffect(() => {
    if (dataSurgeriesCount) {
      const surgeriesCount = dataSurgeriesCount.surgery.length;
      let pagesNumberTmp = Math.ceil(surgeriesCount / limit);
      setPagesNumber(pagesNumberTmp);
    }
  }, [dataSurgeriesCount]);

  const handleChangeCurrentPage = (value: number) => {
    if (value > 0 && value <= pagesNumber && value !== currentPage) {
      setCurrentPage(value);
    }
  };

  const handleFilter = (value: string) => {
    setSearch("%" + value + "%");
    setCurrentPage(1);
  };

  const handleClickPatient = (index: number) => {
    localStorage.setItem(
      "currentPatient",
      JSON.stringify(surgeries?.[index].customData)
    );
    patientCxt.setPatientToEdit(surgeries?.[index].customData);
    history.push("/patients/profile");
  };

  const handleClickSort = (sortId: string) => {
    let direction;
    if (sortId === "surgery_type") {
      direction = orderBy.surgery_type?.value === "asc" ? "desc" : "asc";
      setOrderBy({ surgery_type: { value: direction } });
    } else if (sortId === "date") {
      direction = orderBy.date === "asc" ? "desc" : "asc";
      setOrderBy({ date: direction });
    }
  };

  return (
    <CustomList
      title="Liste des patients"
      columns={columns}
      data={surgeries}
      pagesNumber={pagesNumber}
      currentPage={currentPage}
      handleChangeCurrentPage={handleChangeCurrentPage}
      displaySearchBar={true}
      searchBarValue={searchBarValue}
      setSearchBarValue={(v: string) => setSearchBarValue(v)}
      handleSearch={(v: string) => handleFilter(v)}
      handleClick={(i: number) =>
        customHandleClick
          ? customHandleClick(surgeries?.[i].customData)
          : handleClickPatient(i)
      }
      handleClickSort={(sortId: string) => handleClickSort(sortId)}
      activeRowStyle={activeRowStyle}
    />
  );
};

export default PatientListTemplates;
