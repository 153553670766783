import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Burger from "../../Burger/Burger";
import Sidebar from "../Sidebar/Sidebar";

const LayoutWithSidebar: React.FunctionComponent<{}> = ({ children }) => {
  const [sidebar, setSidebar] = useState({ isOpen: false });

  useEffect(() => {
    if (window.innerWidth > 767) {
      setSidebar({ isOpen: true });
    }
    window.addEventListener("resize", handleResize);

    window.addEventListener("click", handleOutsideClick);
    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  const handleOutsideClick = (e: any) => {
    const elementSidebar = document?.getElementById("sidebar");
    const elementBurgerIcon = document?.getElementById("burger-icon");
    if (elementSidebar && elementBurgerIcon) {
      if (
        window.innerWidth <= 767 &&
        !elementSidebar.contains(e.target) &&
        !elementBurgerIcon.contains(e.target)
      ) {
        // Clicked outside of the sidebar
        setSidebar({ isOpen: false });
      }
    }
  };

  const handleResize = () => {
    if (window.innerWidth <= 767) {
      setSidebar({ isOpen: false });
    } else {
      setSidebar({ isOpen: true });
    }
  };

  const handleDisplaySidebar = () => {
    setSidebar({ isOpen: !sidebar.isOpen });
  };

  return (
    <Root>
      <BurgerContainer
        onClick={() => handleDisplaySidebar()}
        sidebarIsOpen={sidebar.isOpen}
        id="burger-icon"
      >
        <Burger color={sidebar.isOpen ? "#FFF" : undefined} />
      </BurgerContainer>
      {sidebar.isOpen && (
        <SideBarContainer
          mobileView={window.innerWidth <= 767}
          sidebarIsOpen={sidebar.isOpen}
          id="sidebar"
        >
          <Sidebar />
        </SideBarContainer>
      )}
      <MainContainer>{children}</MainContainer>
    </Root>
  );
};

const Root = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
`;

const SideBarContainer = styled.div<{
  mobileView: boolean;
  sidebarIsOpen: boolean;
}>`
  z-index: 1000;
  width: 270px; /* Sidebar total width */
  height: 100vh;
  transition: left 1s linear;
  background: linear-gradient(135.02deg, #095c66 0%, #209aa2 100%);
  /* background: rgba(0, 0, 0, 0.54); */
  /* filter: blur(12px);
  -webkit-filter: blur(12px); */
  position: ${(props) => (props.mobileView ? "absolute" : "relative")};
  transform: ${(props) =>
    props.sidebarIsOpen ? "translateX(0)" : "translateX(100)"};
`;

const MainContainer = styled.div`
  overflow-y: auto;
  width: calc(100% - 270px); /* Minus Sidebar width */
  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.mobileLandscapeMax}) {
    width: 100%;
  }
`;

const BurgerContainer = styled.div<{ sidebarIsOpen: boolean }>`
  position: absolute;
  z-index: 10001;
  top: 15px;
  left: ${(props) => (props.sidebarIsOpen ? "-20px" : "-15px")};
  display: none;
  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.mobileLandscapeMax}) {
    display: block;
  }
`;

export default LayoutWithSidebar;
