import React from "react";
import styled from "styled-components";

interface AuthCardImageProps {
  imageComponent: React.ReactNode;
}

export const AuthCardImage: React.FC<AuthCardImageProps> = ({
  imageComponent,
}) => {
  return (
    <ImageContainer>
      <LoginImageContainer component={imageComponent} />
    </ImageContainer>
  );
};

const ImageContainer = styled.div`
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.mobilePortraitMax}) {
    display: none;
  }
`;

const LoginImageContainer = styled(({ component, ...props }) =>
  React.cloneElement(component, props)
)`
  margin-top: 80px;
  width: 303px;
  height: 258px;
  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.laptop13Max}) {
    width: 240px;
    height: 204px;
  }
`;
