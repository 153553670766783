import React from "react";
import styled from "styled-components";
import { Heading2, Heading3, Heading4 } from "../../../theme/fonts";

interface TitleSectionFormProps {
  title: string;
  subtitle?: string;
}

const TitleSectionForm: React.FC<TitleSectionFormProps> = ({
  title,
  subtitle,
}) => {
  return (
    <Container>
      <Title>{title}</Title>
      {subtitle && <Subtitle>{subtitle}</Subtitle>}
    </Container>
  );
};

export default TitleSectionForm;

const Container = styled.div`
  margin-bottom: 20px;
`;

const Title = styled.div`
  color: ${({ theme }) => theme.colors.darkGreenMain};
  ${Heading2}
  @media screen and (max-width: ${(props) =>
    props.theme.breakpoints.laptop13Max}) {
    ${Heading3}
  }
`;

const Subtitle = styled.div`
  color: ${({ theme }) => theme.colors.blackMain};
  margin-top: 10px;
  ${Heading3}
  @media screen and (max-width: ${(props) =>
    props.theme.breakpoints.laptop13Max}) {
    ${Heading4}
  }
`;
