import { useLazyQuery, useMutation } from "@apollo/client";
import { loader } from "graphql.macro";
import cookie from "js-cookie";
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { Calendar } from "../../components/Calendar/Calendar";
import BasicCard from "../../components/Card/BasicCard/BasicCard";
import { PageWrapper } from "../../components/Layout/PageWrapper/PageWrapper";
import { Loader } from "../../components/Loader/Loader";
import { COOKIES } from "../../constants/cookies_types";
import {
  BodyMain,
  BodySmall,
  Heading1,
  Heading2,
  Heading3,
  Heading4,
  Heading5,
} from "../../theme/fonts";
import ApplicationContext from "../../utils/context";
import { getSurveyTypeValueFormat } from "../../utils/patient-profile";
import { client } from "../../lib/apolloClient";
import {
  ORGANIZATION_FEATURE,
  SURVEY_TYPE_VALUE,
} from "../../constants/db_types";

const dateFormat = new Intl.DateTimeFormat("fr", {
  year: "numeric",
  month: "short",
  day: "2-digit",
});

const getNotificationsQuery = loader("../../graphql/getNotifications.graphql");

const getLastSurveysByOrganizationIdQuery = loader(
  "../../graphql/getLastSurveysByOrganizationId.graphql"
);

const updateNotificationUserReadQuery = loader(
  "../../graphql/updateNotificationUserRead.graphql"
);
type NotificationType = {
  notificationUserId: string;
  patientSurname?: string;
  patientName?: string;
  value: string;
  read: boolean;
};

interface HomeProps {}

const Home: React.FC<HomeProps> = () => {
  const history = useHistory();

  const { currentOrganizationCxt, patientCxt } =
    useContext<any>(ApplicationContext);

  const [selectedDay, setSelectedDay] = useState(new Date());
  const [surveys, setSurveys] = useState<Array<{
    name: string;
    surname: string;
    formatValue: string;
    date: string;
  }> | null>(null);
  const [notifications, setNotifications] = useState<
    Array<NotificationType> | undefined
  >(undefined);

  const [dataNotificationsRaw, setDataNotificationRaw] = useState<any>();

  /* Bug ! Use lazy query method does not trigger useEffect. So we call directly client.query */
  // const [getNotifications, { data: dataNotifications }] = useLazyQuery(
  //   getNotificationsQuery,
  //   { fetchPolicy: "network-only" }
  // );

  const [getLastSurveys, { data: dataLastSurveys }] = useLazyQuery(
    getLastSurveysByOrganizationIdQuery,
    { fetchPolicy: "network-only" }
  );

  const [updateNotificationUserRead] = useMutation(
    updateNotificationUserReadQuery
  );

  useEffect(() => {
    if (currentOrganizationCxt.currentOrganizationToEdit.id) {
      /* Contruct features constraints array regarding organization features */
      let featuresConstraints = [];
      if (
        !currentOrganizationCxt.currentOrganizationToEdit?.organization_subscription?.organization_plan?.organization_plan_features?.find(
          (e: any) =>
            e.organization_feature.name === ORGANIZATION_FEATURE.COVID_CHECKER
        )
      ) {
        featuresConstraints.push(SURVEY_TYPE_VALUE.COVID_CHECKER);
      }
      getLastSurveys({
        variables: {
          organizationId: currentOrganizationCxt.currentOrganizationToEdit.id,
          featuresConstraints,
        },
      });
      const getNotificationsAsync = async () => {
        let dataNotifications = await client.query({
          query: getNotificationsQuery,
          variables: {
            userId: cookie.get(COOKIES.ID),
          },
          fetchPolicy: "network-only",
        });
        setDataNotificationRaw(dataNotifications.data);
        const notificationsTmp = dataNotifications.data.notification_user.map(
          (e: any) => {
            if (
              e.notification.surgery?.organization.id ===
              currentOrganizationCxt.currentOrganizationToEdit.id
            )
              return {
                notificationUserId: e.id,
                patientSurname: e.notification.surgery?.patient.surname,
                patientName: e.notification.surgery?.patient.name,
                value:
                  e.notification.notification_type.notification_message_i18ns[0]
                    .value,
                read: false,
              };
            else {
              return undefined;
            }
          }
        );
        setNotifications(
          notificationsTmp.filter((e: Array<NotificationType>) => e)
        );
      };
      getNotificationsAsync();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentOrganizationCxt.currentOrganizationToEdit]);

  useEffect(() => {
    if (dataLastSurveys) {
      const surveysTmp = dataLastSurveys.survey_result_linked_to_surgery.map(
        (e: any) => {
          return {
            name: e.surgery.patient.name,
            surname: e.surgery.patient.surname,
            formatValue: getSurveyTypeValueFormat(e.survey_type.value),
            date: dateFormat.format(new Date(e.created_at)),
          };
        }
      );
      setSurveys(surveysTmp);
    }
  }, [dataLastSurveys]);

  const handleClickLastSurvey = (i: number) => {
    const survey = dataLastSurveys.survey_result_linked_to_surgery[i];
    const patient = {
      id: survey.surgery.patient?.id,
      birth_date: survey.surgery.patient?.birth_date,
      name: survey.surgery.patient?.name,
      surname: survey.surgery.patient?.surname,
      parent_patients: [
        {
          parent: survey.surgery.patient?.parent_patients?.[0]?.parent,
        },
      ],
      surgeries: [
        {
          id: survey.surgery.id,
          date: survey.surgery.date,
          type: survey.surgery.type,
          organization_id: survey.surgery.organization_id,
          organization: survey.surgery.organization,
          surgery_type: survey.surgery.surgery_type,
        },
      ],
      prescriptions: survey.surgery.prescriptions.map((p: any) => {
        return {
          filePath: p.file_path,
          date: p.created_at,
          practitionerId: p.practitioner_id,
        };
      }),
    };
    patientCxt.setPatientToEdit(patient);
    localStorage.setItem("currentPatient", JSON.stringify(patient));
    history.push("/patients/profile");
  };

  const handleClickNotification = async (notificationUserId: string) => {
    await updateNotificationUserRead({ variables: { id: notificationUserId } });
    const notificationUser = dataNotificationsRaw.notification_user.filter(
      (e: any) => e.id === notificationUserId
    )?.[0];
    if (!notificationUser) return;
    const patient = {
      id: notificationUser.notification.surgery.patient?.id,
      birth_date: notificationUser.notification.surgery.patient?.birth_date,
      name: notificationUser.notification.surgery.patient?.name,
      surname: notificationUser.notification.surgery.patient?.surname,
      parent_patients: [
        {
          parent:
            notificationUser.notification.surgery.patient?.parent_patients?.[0]
              ?.parent,
        },
      ],
      surgeries: [
        {
          id: notificationUser.notification.surgery.id,
          date: notificationUser.notification.surgery.date,
          type: notificationUser.notification.surgery.type,
          organization_id:
            notificationUser.notification.surgery.organization_id,
          organization: notificationUser.notification.surgery.organization,
          surgery_type: notificationUser.notification.surgery.surgery_type,
        },
      ],
    };
    patientCxt.setPatientToEdit(patient);
    localStorage.setItem("currentPatient", JSON.stringify(patient));
    history.push("/patients/profile");
  };

  const handleClickMakedAllAsRead = async (
    notificationUserIds: Array<string>
  ) => {
    for (let i = 0; i < notificationUserIds.length; i++) {
      await updateNotificationUserRead({
        variables: { id: notificationUserIds[i] },
      });
    }
  };

  //TODO notification data directly in context or page wrapper if global. For now the notification bell is only on the Home page
  return (
    <PageWrapper
      notificationIcon
      notificationData={notifications}
      handleClickNotification={(notificationUserId) =>
        handleClickNotification(notificationUserId)
      }
      handleClickMakedAllAsRead={(notificationUserIds) =>
        handleClickMakedAllAsRead(notificationUserIds)
      }
    >
      <Title>Bienvenue sur Koalou</Title>
      <Subtitle>Accueil</Subtitle>
      <Container>
        <CalendarCardContainer>
          <BasicCard
            title="Votre calendrier"
            titleSeparator={true}
            borderRadius="24px"
          >
            <Calendar
              selectedDay={selectedDay}
              setSelectedDay={(day) => setSelectedDay(day)}
            />
          </BasicCard>
        </CalendarCardContainer>
        <LastSurveysCardContainer>
          <BasicCard
            borderRadius="24px"
            title="Dernières évaluations remplies"
            titleSeparator={true}
          >
            {surveys ? (
              <LastSurveysContainer>
                {surveys!.map((e, i) => {
                  return (
                    <LastSurveysRow
                      key={i}
                      onClick={() => handleClickLastSurvey(i)}
                    >
                      <LastSurveyName>
                        {e.name} {e.surname}
                      </LastSurveyName>
                      <LastSurveyTypeValue even={i % 2 === 0}>
                        {e.formatValue}
                      </LastSurveyTypeValue>
                      <LastSurveyDate>{e.date}</LastSurveyDate>
                    </LastSurveysRow>
                  );
                })}
              </LastSurveysContainer>
            ) : (
              <Loader />
            )}
          </BasicCard>
        </LastSurveysCardContainer>
      </Container>
    </PageWrapper>
  );
};

// export default WithFeature("prescription")(Home);
export default Home;

const Title = styled.div`
  color: ${(props) => props.theme.colors.darkGreenMain};
  ${Heading1};
  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.laptop13Max}) {
    ${Heading2}
  }
`;

const Subtitle = styled.div`
  color: ${(props) => props.theme.colors.darkGreenL4};
  ${Heading2};
  margin: 16px 0;
  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.laptop13Max}) {
    ${Heading3}
  }
`;

const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  max-width: 1400px;
  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.tabletMax}) {
    flex-direction: column;
    width: 100%;
    min-width: 0;
  }
`;

const CalendarCardContainer = styled.div`
  height: 648px;
  width: 50%;
  position: relative;
  margin-right: 30px;
  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.laptop13Max}) {
    height: 580px;
  }
  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.tabletMax}) {
    width: 100%;
    margin: 10px 0;
  }
`;

const LastSurveysCardContainer = styled.div`
  width: 50%;
  position: relative;
  height: fit-content;
  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.tabletMax}) {
    width: 100%;
    margin: 10px 0;
  }
`;

const LastSurveysContainer = styled.div`
  margin-top: 25px;
  height: 380px;
  overflow-y: auto;
  width: 100%;
`;

const LastSurveysRow = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin: 8px 0;
  cursor: pointer;
`;

const LastSurveyName = styled.div`
  width: calc(30% - 10px);
  margin-right: 10px;
  ${Heading3}
  @media screen and (max-width: ${(props) =>
    props.theme.breakpoints.laptop13Max}) {
    ${Heading4}
  }
`;
const LastSurveyTypeValue = styled.div<{ even: boolean }>`
  width: calc(50% - 10px);
  padding: 13px 16px;
  margin-right: 10px;
  background: ${(props) =>
    props.even
      ? props.theme.colors.lightGreenL2
      : props.theme.colors.lightGreenL1};
  border-radius: 12px;
  ${BodyMain}
  @media screen and (max-width: ${(props) =>
    props.theme.breakpoints.laptop13Max}) {
    ${BodySmall}
  }
`;
const LastSurveyDate = styled.div`
  width: 20%;
  color: ${(props) => props.theme.colors.darkGreenMain};
  ${Heading4} @media screen and
    (max-width: ${(props) => props.theme.breakpoints.laptop13Max}) {
    ${Heading5}
  }
`;
