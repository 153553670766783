import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import styled from "styled-components";
import CustomButton from "../CustomButton/CustomButton";
import { theme } from "../../theme/index";
import { Heading2, Heading3 } from "../../theme/fonts";
import { ReactComponent as IconClose } from "../../theme/icons/close.svg";

interface CustomModalProps {
  showModal: boolean;
  handleCloseModal?: () => void;
  buttonLeftContent?: string;
  buttonRightContent?: string;
  textContent?: string;
  component?: React.ReactNode;
  children?: React.ReactNode;
  backgroundColor?: string;
  shouldCloseOnOverlayClick?: boolean;
  borderRadius?: number;
  handleClickButtonLeft?: () => void;
  handleClickButtonRight?: () => void;
  maxWidth?: string;
  closeIcon?: boolean;
}

const CustomModal: React.FC<CustomModalProps> = ({
  showModal,
  handleCloseModal,
  buttonLeftContent,
  buttonRightContent,
  textContent,
  component,
  children,
  backgroundColor,
  shouldCloseOnOverlayClick,
  borderRadius,
  handleClickButtonLeft,
  handleClickButtonRight,
  maxWidth,
  closeIcon,
}) => {
  const [customStyle, setCustomStyle] = useState<any>();

  useEffect(() => {
    setCustomStyle({
      overlay: {
        zIndex: 99999999,
        background: `rgba(0, 0, 0, 0.6)`,
      },
      content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
        maxHeight: "100%",
        maxWidth: maxWidth || "1200px",
        borderRadius: borderRadius ? `${borderRadius}px` : "24px",
        backgroundColor: backgroundColor
          ? backgroundColor
          : theme.colors._white,
      },
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  if (children && !component) component = children;

  return (
    <Modal
      isOpen={showModal}
      style={customStyle}
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
      onRequestClose={() =>
        shouldCloseOnOverlayClick && handleCloseModal
          ? handleCloseModal()
          : null
      }
    >
      <ModalContent>
        <MainContainer>
          {closeIcon && <IconCloseContainer onClick={handleCloseModal} />}
          {textContent && <Text>{textContent}</Text>}
          {component && <Container>{component}</Container>}
        </MainContainer>
      </ModalContent>
      <ModalButtonsContainer>
        {buttonLeftContent && (
          <ButtonContainer>
            <CustomButton
              handleOnClick={handleClickButtonLeft}
              content={buttonLeftContent}
              borderRadius="12px"
            />
          </ButtonContainer>
        )}
        {buttonRightContent && (
          <ButtonContainer>
            <CustomButton
              handleOnClick={handleClickButtonRight}
              content={buttonRightContent}
              borderRadius="12px"
            />
          </ButtonContainer>
        )}
      </ModalButtonsContainer>
    </Modal>
  );
};

const ModalContent = styled.div`
  ${Heading2};
  overflow-x: auto;
`;

const ModalButtonsContainer = styled.div`
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const MainContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;
const Text = styled.div`
  white-space: pre-wrap;
  padding: 10px;
  ${Heading3};
  text-align: center;
`;
const Container = styled.div``;

const IconCloseContainer = styled(IconClose)`
  position: absolute;
  top: 23px;
  right: 35px;
  cursor: pointer;
`;

export default CustomModal;
