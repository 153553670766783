const colors = {
  //Primary
  //TODO remplacer primary
  primaryDarker: "#224675",
  primaryMainColor: "#1BA3C1",
  primaryLighter: "#5DCDE6",
  primarySubtle: "#DDF3F6",
  //Green
  greenDarker: "#0B8C3A",
  greenDark: "#349F5A",
  greenMain: "#1BC157",
  greenLighter: "#60DD8D",
  greenSubtle: "#DDF6E6",
  //Blue
  blueDarker: "#224675",
  blueDark: "#046697",
  blueMain: "#1BA3C1",
  blueLighter: "#5DCDE6",
  blueSubtle: "#DDF3F6",
  //Yellow
  yellowDarker: "#DD6F0D",
  yellowDark: "#F2994A",
  yellowMain: "#F2C94C",
  yellowLighter: "#FCE396",
  yellowSubtle: "#FCF1D1",
  //Red
  redDarker: "#CB3434",
  redDark: "#EB5757",
  // redMain: "#F38A7C",
  redLighter: "#F5BAB1",
  redSubtle: "#FEF4F4",
  //Purple
  purpleDarker: "#4D0099",
  purpleDark: "#6600CC",
  purpleMain: "#AC5DD9",
  purpleLighter: "#DDA5E9",
  purpleSubtle: "#FFE6FF",
  //Pink
  pinkDarker: "#E35E76",
  pinkDark: "#EF5CA3",
  pinkMain: "#EF85B8",
  pinkLighter: "#F9BBD5",
  pinkSubtle: "#FEDEE6",
  //Teal
  tealDarker: "#00B7C4",
  tealDark: "#00CFDE",
  tealMain: "#73DFE7",
  tealLighter: "#A9EFF2",
  tealSubtle: "#E6FFFF",
  //Black
  blackPure: "#000000",
  blackLight: "#262626", //151515
  blackLighter: "#404040",
  //Gray
  greyDarker: "#787878",
  greyDark: "#999999",
  greyMain: "#BFBFBF",
  greyLighter: "#D9D9D9",
  //White
  whiteDarker: "#F0F0F0",
  whiteDark: "#FAFAFA",
  whitePure: "#FFFFFF",

  //TODO delete colors above

  /* ******************************** */
  /* *****  New theme colors  ******* */
  /* ******************************** */

  /*  */
  _white: "#FFF",
  blackMain: "#000000",
  blackL4: "#000000CC",
  blackL3: "#00000099",
  blackL2: "#00000066",
  blackL1: "#00000033",
  /*  */
  darkGreenMain: "#2E666D",
  darkGreenL4: "#58858A",
  darkGreenL3: "#82A3A7",
  darkGreenL2: "#ABC2C5",
  darkGreenL1: "#D5E0E2",
  /*  */
  lightGreenMain: "#AED1CD",
  lightGreenL4: "#BEDAD7",
  lightGreenL3: "#CEE3E1",
  lightGreenL2: "#DFEDEB",
  lightGreenL1: "#EFF6F5",
  /*  */
  creamMain: "#F6E9DD",
  creamL4: "#F6E9DD",
  creamL3: "#F8EFE5",
  creamL2: "#FBF4EE",
  creamL1: "#FDFAF6",
  /*  */
  salmonMain: "#FF9D8C",
  salmonL4: "#FFB1A3",
  salmonL3: "#FFC4BA",
  salmonL2: "#FFD8D1",
  salmonL1: "#FFEBE8",
  /*  */
  redMain: "#F76D7D",
  redL4: "#F98A97",
  redL3: "#FAA7B1",
  redL2: "#FCC5CB",
  redL1: "#FDE2E5",
};

const _mainTheme = {
  colors: {
    // hoverPrimary: colors.blueDarker,  TODO delete dependencies
    primary: {
      darker: colors.primaryDarker,
      mainColor: colors.primaryMainColor,
      lighter: colors.primaryLighter,
      subtle: colors.primarySubtle,
    },
    green: {
      darker: colors.greenDarker,
      dark: colors.greenDark,
      main: colors.greenMain,
      lighter: colors.greenLighter,
      subtle: colors.greenSubtle,
    },
    blue: {
      darker: colors.blueDarker,
      dark: colors.blueDark,
      main: colors.blueMain,
      lighter: colors.blueLighter,
      subtle: colors.blueSubtle,
    },
    yellow: {
      darker: colors.yellowDarker,
      dark: colors.yellowDark,
      main: colors.yellowMain,
      lighter: colors.yellowLighter,
      subtle: colors.yellowSubtle,
    },
    red: {
      darker: colors.redDarker,
      dark: colors.redDark,
      main: colors.redMain,
      lighter: colors.redLighter,
      subtle: colors.redSubtle,
    },
    purple: {
      darker: colors.purpleDarker,
      dark: colors.purpleDark,
      main: colors.purpleMain,
      lighter: colors.purpleLighter,
      subtle: colors.purpleSubtle,
    },
    pink: {
      darker: colors.pinkDarker,
      dark: colors.pinkDark,
      main: colors.pinkMain,
      lighter: colors.pinkLighter,
      subtle: colors.pinkSubtle,
    },
    teal: {
      darker: colors.tealDarker,
      dark: colors.tealDark,
      main: colors.tealMain,
      lighter: colors.tealLighter,
      subtle: colors.tealSubtle,
    },
    black: {
      pure: colors.blackPure,
      light: colors.blackLight,
      lighter: colors.blackLighter,
    },
    grey: {
      darker: colors.greyDarker,
      dark: colors.greyDark,
      main: colors.greyMain,
      lighter: colors.greyLighter,
    },
    white: {
      darker: colors.whiteDarker,
      dark: colors.whiteDark,
      pure: colors.whitePure,
    },
    /* ********* */
    /* New theme */
    /* ********* */
    darkGreenMain: colors.darkGreenMain,
    darkGreenL4: colors.darkGreenL4,
    darkGreenL3: colors.darkGreenL3,
    darkGreenL2: colors.darkGreenL2,
    darkGreenL1: colors.darkGreenL1,
    /*  */
    lightGreenMain: colors.lightGreenMain,
    lightGreenL4: colors.lightGreenL4,
    lightGreenL3: colors.lightGreenL3,
    lightGreenL2: colors.lightGreenL2,
    lightGreenL1: colors.lightGreenL1,
    /*  */
    creamMain: colors.creamMain,
    creamL4: colors.creamL4,
    creamL3: colors.creamL3,
    creamL2: colors.creamL2,
    creamL1: colors.creamL1,
    /*  */
    salmonMain: colors.salmonMain,
    salmonL4: colors.salmonL4,
    salmonL3: colors.salmonL3,
    salmonL2: colors.salmonL2,
    salmonL1: colors.salmonL1,
    /*  */
    redMain: colors.redMain,
    redL4: colors.redL4,
    redL3: colors.redL3,
    redL2: colors.redL2,
    redL1: colors.redL1,
    /*  */
    _white: colors._white,
    blackMain: colors.blackMain,
    blackL1: colors.blackL1,
    blackL2: colors.blackL2,
    blackL3: colors.blackL3,
    blackL4: colors.blackL4,
  },
  componentObjects: {
    formContainer: {
      background: colors.whitePure,
      border: `1px solid ${colors.whiteDarker}`,
      boxSizing: "border-box",
      borderRadius: "12px",
      padding: "8px 45px 8px 15px",
      fontWeight: "500",
      fontSize: "15px",
      lineHeight: "16px",
      // color: colors.blackLight,
      color: "#8898aa",
      // borderFocus: colors.blackLighter,
      borderFocus: "#525f7f",
    },
    formLabel: {
      fontWeight: "600",
      fontSize: "14px",
      lineHeight: "18px",
      color: colors.blackMain,
    },
    formSubLabel: {
      fontWeight: "500",
      fontSize: "12px",
      lineHeight: "16px",
      color: colors.greyDarker,
    },
    formErrorLabel: {
      fontWeight: "600",
      fontSize: "12px",
      lineHeight: "14px",
      color: colors.redDark,
    },
    formSimpleCheck: {
      fontWeight: "500",
      fontSize: "15px",
      lineHeight: "24px",
    },
    formMultipleCheck: {
      fontWeight: "500",
      fontSize: "13px",
      lineHeight: "20px",
      color: colors.blackLight,
      padding: "5px 45px 5px 15px",
      borderChecked: `1px solid ${colors.darkGreenMain}`,
      borderNotChecked: `1px solid ${colors.whiteDarker}`,
      textChecked: colors.darkGreenMain,
      textNotChecked: colors.blackLight,
    },
    formMultipleRadioButton: {
      padding: "20px 0px 20px 15px",
    },
  },
  breakpoints: {
    mobilePortraitMin: "320px",
    mobilePortraitMax: "479px",
    mobileLandscapeMin: "480px",
    mobileLandscapeMax: "767px",
    tabletMin: "768px",
    tabletMax: "991px",
    laptop13Min: "992px",
    laptop13Max: "1280px",
    laptop15Min: "1281px",
    laptop15Max: "1599px",
    desktop21Min: "1600px",
    desktop21Max: "1999px",
    desktop27Min: "2000px",
  },
  textStyles: {
    typography: {
      heading: {
        h1: {
          fontFamily: "Raleway",
          fontWeight: 700,
          fontSize: "30px",
          lineHeight: "36px",
        },
        h2: {
          fontFamily: "Raleway",
          fontWeight: 700,
          fontSize: "22px",
          lineHeight: "26px",
        },
        h3: {
          fontFamily: "Raleway",
          fontWeight: 700,
          fontSize: "16px",
          lineHeight: "19px",
        },
        h4: {
          fontFamily: "Raleway",
          fontWeight: 700,
          fontSize: "14px",
          lineHeight: "17px",
        },
        h5: {
          fontFamily: "Raleway",
          fontWeight: 700,
          fontSize: "12px",
          lineHeight: "14px",
        },
      },
      body: {
        extraExtraLarge: {
          fontFamily: "Raleway",
          fontWeight: 500,
          fontSize: "22px",
          lineHeight: "26px",
        },
        extraLarge: {
          fontFamily: "Raleway",
          fontWeight: 500,
          fontSize: "18px",
          lineHeight: "20px",
        },
        large: {
          fontFamily: "Raleway",
          fontWeight: 500,
          fontSize: "14px",
          lineHeight: "17px",
        },
        main: {
          fontFamily: "Raleway",
          fontWeight: 400,
          fontSize: "14px",
          lineHeight: "16px",
        },
        small: {
          fontFamily: "Raleway",
          fontWeight: 400,
          fontSize: "12px",
          lineHeight: "14px",
        },
      },
    },
  },
};

export interface Theme {
  theme: typeof _mainTheme;
}

export const theme = _mainTheme;
