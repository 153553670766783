import { isValidPhoneNumber } from "react-phone-number-input/input-mobile";

export function validateCellPhoneNumber(value) {
  let error = "";

  if (value && !isValidPhoneNumber(value))
    error =
      "Le numéro de téléphone n'est pas valide ou ce n'est pas un numéro de téléphone mobile";

  return error;
}
