import React from "react";
import styled from "styled-components";

interface SeparatorSectionFormProps {}

const SeparatorSectionForm: React.FC<SeparatorSectionFormProps> = () => {
  return <Container></Container>;
};

export default SeparatorSectionForm;

const Container = styled.div`
  width: 100%;
  height: 3px;
  background: linear-gradient(135.02deg, #095c66 0%, #209aa2 100%);
  border-radius: 6px;
`;
