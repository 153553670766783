import { useQuery } from "@apollo/client";
import { Field, Form, Formik } from "formik";
import { loader } from "graphql.macro";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import * as Yup from "yup";
import { LANGUAGES } from "../../../constants/languages_types";
import CustomButton from "../../CustomButton/CustomButton";
import { DropdownForm } from "../../Form/DropdownForm/DropdownForm";
import { LongTextInputForm } from "../../Form/LongTextInputForm/LongTextInputForm";
import { SimpleCheckForm } from "../../Form/SimpleCheckForm/SimpleCheckForm";
import TitleSectionForm from "../../Form/TitleSectionForm/TitleSectionForm";
import CustomContour from "../../Layout/CustomContour/CustomContour";

const getInstructionsTypesQuery = loader(
  "../../../graphql/getInstructionsTypes.graphql"
);

const getSurgeryCategoryQuery = loader(
  "../../../graphql/getSurgeryCategory.graphql"
);

interface FormValues {
  id?: string;
  type: string;
  surgery_category_id: string;
  active: boolean;
  value_fr: string;
  value_en: string;
}

type FormMode = "creation" | "update";

interface FormInstructionProps {
  title: string;
  mode: FormMode;
  initialValues: FormValues;
  handleSubmit: (values: FormValues) => void;
}

const validationSchema = Yup.object().shape({
  type: Yup.string().required("Veuillez entrer une catégorie d'instruction"),
  surgery_category_id: Yup.string().required(
    "Veuillez entrer une catégorie d'opération"
  ),
  value_fr: Yup.string().required("Veuillez entrer une instruction"),
  value_en: Yup.string().required("Veuillez entrer une instruction"),
});

const FormInstruction: React.FC<FormInstructionProps> = ({
  title,
  mode,
  initialValues,
  handleSubmit,
}) => {
  const [instructionsTypes, setInstructionsTypes] = useState([]);
  const [surgeryCategory, setSurgeryCategory] = useState([]);

  const { data: dataInstructionsTypes } = useQuery(getInstructionsTypesQuery);
  const { data: dataSurgeryCategory } = useQuery(getSurgeryCategoryQuery);

  useEffect(() => {
    if (dataInstructionsTypes) {
      const instructionsTypesTmp = dataInstructionsTypes.instruction_type.map(
        (e: any) => {
          return {
            value: e.id,
            label: e.instruction_type_i18ns.filter(
              (f: any) => f.language.language_code === LANGUAGES.FR_FR
            )[0].label,
          };
        }
      );
      setInstructionsTypes(instructionsTypesTmp);
    }
  }, [dataInstructionsTypes]);

  useEffect(() => {
    if (dataSurgeryCategory?.surgery_category) {
      const surgeryCategoryTmp = dataSurgeryCategory.surgery_category.map(
        (e: { id: string; name: string }) => {
          return { label: e.name, value: e.id };
        }
      );
      setSurgeryCategory(surgeryCategoryTmp);
    }
  }, [dataSurgeryCategory]);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={async (values: FormValues, { resetForm }) => {
        await handleSubmit(values);
        if (mode === "creation") {
          resetForm();
        }
      }}
      validationSchema={validationSchema}
    >
      {(props) => {
        return (
          <FormContainer>
            <CustomContour title={title}>
              <Section>
                <TitleSectionForm title="Informations concernant l'instruction" />
                <FieldSection>
                  <FieldContainer>
                    <Field
                      name={"type"}
                      label={"Catégorie d'instruction"}
                      placeholder={"--"}
                      component={DropdownForm}
                      data={instructionsTypes}
                    />
                  </FieldContainer>
                  <FieldContainer>
                    <Field
                      name={"surgery_category_id"}
                      label={"Catégorie d'opération"}
                      placeholder={"--"}
                      component={DropdownForm}
                      data={surgeryCategory}
                    />
                  </FieldContainer>
                  <FieldContainer>
                    <Field
                      name={"active"}
                      label={"Activé"}
                      component={SimpleCheckForm}
                    />
                  </FieldContainer>
                </FieldSection>
                <FieldSection>
                  <FieldContainer>
                    <Field
                      name={"value_fr"}
                      label={"Instruction en français"}
                      placeholder={"Instruction"}
                      component={LongTextInputForm}
                    />
                  </FieldContainer>
                </FieldSection>
                <FieldSection>
                  <FieldContainer>
                    <Field
                      name={"value_en"}
                      label={"Instruction en anglais"}
                      placeholder={"Instruction"}
                      component={LongTextInputForm}
                    />
                  </FieldContainer>
                </FieldSection>
              </Section>
            </CustomContour>
            <ButtonContainer>
              <CustomButton
                type="submit"
                content={
                  mode === "creation"
                    ? "Enregistrer l'instruction"
                    : "Sauvegarder l'instruction"
                }
              />
            </ButtonContainer>
          </FormContainer>
        );
      }}
    </Formik>
  );
};

export default FormInstruction;

const FormContainer = styled(Form)``;

const Section = styled.div`
  width: 100%;
`;

const FieldSection = styled.div`
  width: 100%;
  display: flex;
  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.tabletMax}) {
    flex-direction: column;
  }
`;

const FieldContainer = styled.div`
  width: 100%;
  margin: 16px 8px;

  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.tabletMax}) {
    width: 100%;
    margin: 8px 0;
  }
`;

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 30px;
`;
