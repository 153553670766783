import { Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";
import * as Yup from "yup";
import { Heading3, Heading4 } from "../../../theme/fonts";
import { ReactComponent as LoginImage } from "../../../theme/images/login.svg";
import { changeFirebasePassword, validatePassword } from "../../../utils/auth";
import { displayToastNotification } from "../../../utils/toastNotification";
import AuthCardForm from "../../AuthCard/AutchCardForm/AuthCardForm";
import AuthCardButton from "../../AuthCard/AuthCardButton/AuthCardButton";
import { AuthCardImage } from "../../AuthCard/AuthCardImage/AuthCardImage";
import AuthCardLogo from "../../AuthCard/AuthCardLogo/AuthCardLogo";
import AuthCardTitle from "../../AuthCard/AuthCardTitle/AuthCardTitle";
import { TextInputForm } from "../../Form/TextInputForm/TextInputForm";
import { Loader } from "../../Loader/Loader";
import PasswordStrengthMeter from "../../PasswordStrengthMeter/PasswordStrengthMeter";

interface FormValues {
  password: string;
  confirmPassword: string;
}

const validationSchema = Yup.object().shape({
  password: Yup.string().required("Veuillez entrer votre nouveau mot de passe"),
  confirmPassword: Yup.string().required(
    "Veuillez confirmer votre mot de passe"
  ),
});

const ResetPasswordForm: React.FunctionComponent<{}> = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const queryParams = useLocation().search;

  const [status, setStatus] = useState({
    successMessage: "",
    isLoading: false,
  });

  useEffect(() => {
    if (status.successMessage) {
      setTimeout(() => {
        history.push("/");
      }, 4000);
    }
  }, [status.successMessage, history]);

  const handleResetPassword = async (
    values: FormValues,
    setFieldError: (field: string, errorMsg: string) => void
  ) => {
    if (!validatePassword(values.password)) {
      setFieldError("password", t("common:passwordInvalid"));
      displayToastNotification("error", t("common:passwordWeak"), 15000);
    } else if (values.password !== values.confirmPassword) {
      setFieldError("confirmPassword", t("common:passwordConfirmation"));
    } else {
      setStatus({ successMessage: "", isLoading: true });
      const oobCode = new URLSearchParams(queryParams).get("oobCode");
      try {
        const { successMessage } = await changeFirebasePassword(
          values,
          oobCode
        );
        if (successMessage) {
          setStatus({
            isLoading: false,
            successMessage:
              "Mot de passe modifié avec succès. Vous allez être redirigé vers la page de connexion",
          });
        }
      } catch (err: any) {
        console.error(err);
        setStatus({
          ...status,
          isLoading: false,
        });
        setFieldError("password", err.error);
      }
    }
  };

  return (
    <Container>
      <AuthCardForm>
        <Formik
          initialValues={{
            password: "",
            confirmPassword: "",
          }}
          onSubmit={async (values: FormValues, { setFieldError }) => {
            await handleResetPassword(values, setFieldError);
          }}
          validationSchema={validationSchema}
        >
          {(props: any) => (
            <FormStyled>
              <AuthCardLogo />
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "100%",
                  width: "100%",
                }}
              >
                <AuthCardTitle title={"Nouveau mot de passe"} />
                <div style={{ width: "100%" }}>
                  {!status.successMessage && !status.isLoading && (
                    <>
                      <FieldContainer>
                        <Field
                          name="password"
                          label="Nouveau mot de passe"
                          placeholder="Nouveau mot de passe"
                          type="password"
                          component={TextInputForm}
                          iconType="password"
                        />
                      </FieldContainer>
                      <FieldContainer>
                        <Field
                          name="confirmPassword"
                          label="Confirmer le mot de passe"
                          placeholder="Confirmer le mot de passe"
                          type="password"
                          component={TextInputForm}
                          iconType="password"
                        />
                      </FieldContainer>
                      <PasswordStrengthContainer>
                        <PasswordStrengthMeter
                          password={props.values.password}
                        />
                      </PasswordStrengthContainer>
                    </>
                  )}
                </div>
                {!status.successMessage && !status.isLoading && (
                  <AuthCardButton
                    content="Valider"
                    handleOnClick={() => props.handleSubmit()}
                  />
                )}
                {status.successMessage && (
                  <Title>{status.successMessage}</Title>
                )}
                {(status.isLoading || status.successMessage) && (
                  <LoaderContainer>
                    <Loader />
                  </LoaderContainer>
                )}
              </div>
            </FormStyled>
          )}
        </Formik>
      </AuthCardForm>
      <AuthCardImage imageComponent={<LoginImage />} />
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Title = styled.div`
  margin-bottom: 5px;
  text-align: center;
  ${Heading3};
  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.laptop13Max}) {
    ${Heading4}
  }

  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.mobilePortraitMax}) {
    ${Heading3}
  }
`;

const FormStyled = styled(Form)`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
`;

const FieldContainer = styled.div`
  width: 100%;
  margin: 12px 0;
`;

const LoaderContainer = styled.div`
  margin-top: 30px;
`;

const PasswordStrengthContainer = styled.div`
  margin-top: 25px;
`;

export default ResetPasswordForm;
