import React from "react";
import styled from "styled-components";
import FormAdmin from "../../components/FormTemplates/Admin/FormAdmin";
import { PageWrapper } from "../../components/Layout/PageWrapper/PageWrapper";
import { loader } from "graphql.macro";
import { useMutation } from "@apollo/client";
import { displayToastNotification } from "../../utils/toastNotification";

const addSurveyTypeSurgeryTypeQuery = loader(
  "../../graphql/addSurveyTypeSurgeryType.graphql"
);

const deleteSurveyTypeSurgeryTypeQuery = loader(
  "../../graphql/deleteSurveyTypeSurgeryType.graphql"
);

interface AdminProps {}

interface FormValues {
  surgeryType: string;
}

const Admin: React.FC<AdminProps> = () => {
  const initialValues = { surgeryType: "" };

  const [addSurveyTypeSurgeryType] = useMutation(addSurveyTypeSurgeryTypeQuery);

  const [deleteSurveyTypeSurgeryType] = useMutation(
    deleteSurveyTypeSurgeryTypeQuery
  );

  const handleSubmit = async (
    values: FormValues,
    surveysType: { label: string; value: string; selected: boolean }[]
  ) => {
    try {
      await deleteSurveyTypeSurgeryType({
        variables: { surgeryType: values.surgeryType },
      });
      const surveyTypeSurgeryType = surveysType
        .map((e) => {
          if (e.selected) {
            return {
              survey_type_id: e.value,
              surgery_type_id: values.surgeryType,
            };
          } else {
            return undefined;
          }
        })
        .filter((e) => e);
      if (surveyTypeSurgeryType.length > 0) {
        await addSurveyTypeSurgeryType({
          variables: { objects: surveyTypeSurgeryType },
        });
      }
      displayToastNotification(
        "success",
        "Les modifications ont bien été enregistrées"
      );
    } catch (err) {
      displayToastNotification("error", "Une erreur est survenue");
    }
  };

  return (
    <PageWrapper>
      <Container>
        <FormAdmin
          title=""
          initialValues={initialValues!}
          handleSubmit={(values, surveysType) =>
            handleSubmit(values, surveysType)
          }
        />
      </Container>
    </PageWrapper>
  );
};

export default Admin;

const Container = styled.div`
  height: 100%;
  margin: 0 auto;
  max-width: 1030px;
`;
