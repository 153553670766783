import { useState, useEffect } from "react";
import CustomList from "../../../components/List/CustomList/CustomList";
import moment from "moment";

export const Daily: any = ({ reservation }: any) => {
  const [search, setSearch] = useState("");
  const [rows, setRows] = useState([]);

  useEffect(() => {
    if (reservation) {
      setRows(
        reservation.map((x: any, i: number) => ({
          customData: {},
          rowValues: [
            new Date(x.start_time).toLocaleString("fr-FR").slice(12, -3), //TODO check if this is safe
            `${x.surgery.patient.name} \
          ${x.surgery.patient.surname}`,
            x.surgery.surgery_type.value,
            moment(x.surgery.date).format("DD/MM/YYYY"),
            x.surgery.patient.parent_patients[0].parent.phone,
          ],
        }))
      );
    }
    //
  }, [reservation]);

  return (
    <div>
      <CustomList
        title=""
        columns={[
          { value: "Appel prévu", ratio: "20%" },
          { value: "Patient", ratio: "20%" },
          { value: "Intervention", ratio: "20%" },
          { value: "Date de l'intervention", ratio: "20%" },
          { value: "Téléphone", ratio: "20%" },
        ]}
        data={rows}
        pagesNumber={1}
        currentPage={1}
        handleChangeCurrentPage={() => {}}
        displaySearchBar={false}
        searchBarValue={search}
        setSearchBarValue={(x) => {
          // setRows()
          // const a = rows.filter((x: any) => x.rowValues.filter((x: string) => x.includes(search)).length > 0)
          // setRows(a)
          setSearch(x);
        }}
        handleSearch={() => {}}
        handleClick={() => {}}
        handleClickSort={() => {}}
        activeRowStyle={true}
      />
    </div>
  );
};

export default Daily;
