import { useLazyQuery, useQuery } from "@apollo/client";
import { loader } from "graphql.macro";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Modal, ModalBody } from "reactstrap";
import styled from "styled-components";
import BasicCard from "../../components/Card/BasicCard/BasicCard";
import CustomButton from "../../components/CustomButton/CustomButton";
import CustomModal from "../../components/CustomModal/CustomModal";
import { PageWrapper } from "../../components/Layout/PageWrapper/PageWrapper";
import { Loader } from "../../components/Loader/Loader";
import PdfViewer from "../../components/PdfViewer";
import {
  ORGANIZATION_FEATURE,
  PRESCRIPTION_FILE_TYPE,
} from "../../constants/db_types";
import { Mixpanel } from "../../lib/mixpanel";
import {
  BodyMain,
  BodySmall,
  Heading3,
  Heading4,
  Heading5,
} from "../../theme/fonts";
import { ReactComponent as IconArrowBack } from "../../theme/icons/arrowBack.svg";
import { ReactComponent as IconOpenWindow } from "../../theme/icons/openWindow.svg";
import ApplicationContext from "../../utils/context";
import { base64ToBlob } from "../../utils/global";
import { parseSuveysAnswers } from "../../utils/patient-profile";
import { sendPrescriptionEmail } from "../../utils/prescription";
import { displayToastNotification } from "../../utils/toastNotification";
import DosesCompute from "../DosesCompute";
import SymptomChart from "./SymptomChart";
import * as Sentry from "@sentry/react";

const getEmailsByPatientId = loader(
  "../../graphql/getEmailsByPatientId.graphql"
);

const getSurveysTypesBySurgeryAndAgeQuery = loader(
  "../../graphql/getSurveysTypesBySurgeryAndAge.graphql"
);

const getSurveysAnswersQuery = loader(
  "../../graphql/getSurveysAnswers.graphql"
);

interface UserDataState {
  patientId: string;
  name: string;
  surname: string;
  birthDate: Date;
  date: string;
  surgeryId: string;
  surgeryTypeValue: string;
  surgeryTypeId: string;
  email: string;
  prescriptions: Array<{ [key: string]: string }>;
}

interface PatientsProfileProps {}

const PatientsProfile: React.FC<PatientsProfileProps> = () => {
  const history = useHistory();

  const { meCxt, patientCxt, currentOrganizationCxt } =
    useContext<any>(ApplicationContext);

  const [modal, setModal] = useState(false);
  const [userData, setUserData] = useState<UserDataState | null>(null);

  const [isLoading, setIsLoading] = useState(true);
  const [timelineData, setTimelineData] = useState<any>(null);
  const [modalPrescription, setModalPrescription] = useState({
    show: false,
    documentName: "",
  });
  const [documentBase64, setDocumentBase64] = useState("");

  const [getSurveysAnswers] = useLazyQuery(getSurveysAnswersQuery, {
    onCompleted: (data) => flattenSurveysAnswers(data),
  });

  const emails = useQuery(getEmailsByPatientId, {
    variables: {
      patient_id: userData?.patientId,
    },
  });

  const [showSubsurvey, setShowSubsurvey] = useState<string | null>(null);

  const [getSurveysTypesBySurgeryAndAge, { data: surveysTypeBySurgeryAndAge }] =
    useLazyQuery(getSurveysTypesBySurgeryAndAgeQuery);

  useEffect(() => {
    if (meCxt?.meToEdit?.id) {
      const organization = getOrganizationInfos(
        currentOrganizationCxt.currentOrganizationToEdit
      );
      Mixpanel?.viewPatientProfile(
        meCxt.meToEdit?.id,
        meCxt.meToEdit?.name,
        meCxt.meToEdit?.surname,
        organization?.id,
        organization?.name,
        patientCxt?.patientToEdit?.surgeries?.[0]?.surgery_type?.id,
        patientCxt?.patientToEdit?.surgeries?.[0]?.surgery_type?.value
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meCxt?.meToEdit]);

  useEffect(() => {
    if (patientCxt.patientToEdit.id) {
      setUserData({
        patientId: patientCxt.patientToEdit.id,
        name: patientCxt.patientToEdit.name,
        surname: patientCxt.patientToEdit.surname,
        date: convertDateToFr(patientCxt.patientToEdit.surgeries[0].date),
        birthDate: patientCxt.patientToEdit.birth_date,
        surgeryId: patientCxt.patientToEdit.surgeries[0].id,
        surgeryTypeValue:
          patientCxt.patientToEdit.surgeries[0].surgery_type.value,
        surgeryTypeId: patientCxt.patientToEdit.surgeries[0].surgery_type.id,
        email: patientCxt.patientToEdit.parent_patients[0].parent.email,
        prescriptions: patientCxt.patientToEdit.prescriptions,
      });
    } else {
      const currentPatient = localStorage.getItem("currentPatient");
      if (currentPatient) {
        const currentPatientParse = JSON.parse(currentPatient);
        setUserData({
          patientId: currentPatientParse.id,
          name: currentPatientParse.name,
          surname: currentPatientParse.surname,
          birthDate: currentPatientParse.birth_date,
          date: convertDateToFr(currentPatientParse.surgeries[0].date),
          surgeryId: currentPatientParse.surgeries[0].id,
          surgeryTypeValue: currentPatientParse.surgeries[0].surgery_type.value,
          surgeryTypeId: currentPatientParse.surgeries[0].surgery_type.id,
          email: currentPatientParse.parent_patients[0].parent.email,
          prescriptions: currentPatientParse.prescriptions,
        });
      } else {
        history.push("/patients/list");
      }
    }
  }, [patientCxt.patientToEdit, history]);

  useEffect(() => {
    if (userData) {
      getSurveysTypesBySurgeryAndAge({
        variables: {
          age: moment().diff(userData.birthDate, "years"),
          surgeryTypeId: userData.surgeryTypeId,
        },
      });
      setIsLoading(false);
    }
  }, [userData, getSurveysTypesBySurgeryAndAge]);

  useEffect(() => {
    if (
      surveysTypeBySurgeryAndAge &&
      userData &&
      currentOrganizationCxt.currentOrganizationToEdit.id
    ) {
      getSurveysAnswers({
        variables: {
          patientId: userData.patientId,
          surgeryId: userData.surgeryId,
        },
      });
    }
  }, [
    surveysTypeBySurgeryAndAge,
    getSurveysAnswers,
    userData,
    currentOrganizationCxt.currentOrganizationToEdit,
  ]);

  //Concatenate organization + hospital name if hospital service
  const getOrganizationInfos = (organization: any) => {
    let organizationTmp = {
      id: organization.id,
      name: organization.name,
    };
    if (organization?.hospital_service?.hospital.name) {
      organizationTmp.name += ` - ${organization.hospital_service.hospital.name}`;
    }
    return organizationTmp;
  };

  const convertDateToFr = (date: Date) => {
    return moment(date).format("LL");
  };

  const convertDateToAge = (date: Date) => {
    const years = moment().diff(date, "years");
    const months = moment().diff(date, "month") % 12;
    if (years > 1) {
      return years + " ans " + months + " mois";
    } else if (years === 1) {
      return "1 an " + months + " mois";
    } else {
      return months + " mois";
    }
  };

  const flattenSurveysAnswers = (data: any) => {
    const timelineData = parseSuveysAnswers(
      data,
      surveysTypeBySurgeryAndAge.survey_type_surgery_type_by_surgery_and_age
    );
    // Here we delete covid checker regarding organization features
    if (
      !currentOrganizationCxt.currentOrganizationToEdit?.organization_subscription?.organization_plan?.organization_plan_features?.find(
        (e: any) =>
          e.organization_feature.name === ORGANIZATION_FEATURE.COVID_CHECKER
      )
    ) {
      delete timelineData.covidChecker;
    }
    setTimelineData(timelineData);
  };

  const scrollToTop = (id: string) => {
    const div = document.getElementById(id);
    if (!div) return;
    div.scrollTop = 0;
  };

  const showPrescription = (filePath: string) => {
    setModalPrescription({
      show: true,
      documentName: filePath,
    });
  };

  const sendPrescritpion = async () => {
    if (documentBase64 && userData?.patientId) {
      const file = base64ToBlob(documentBase64, "pdf");
      if (!file) {
        displayToastNotification("error", "Une erreur est survenue");
        return;
      }
      const data = new FormData();
      data.append("file", file);
      data.append("patientId", userData.patientId);
      const { error } = await sendPrescriptionEmail(data);
      if (error) {
        Sentry.captureException(error);
        displayToastNotification("error", "Une erreur est survenue");
        return;
      }
      displayToastNotification("success", "La prescription a bien été envoyée");
    }
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <PageWrapper
      previousPageIconOn={true}
      previousPageText={"Retour à la liste des patients"}
      handleOnClickPreviousPage={() => history.push("/patients/list")}
    >
      <Container>
        <CardContainer width="50%">
          <BasicCard title="Profil du patient" titleSeparator={true}>
            <CardContent>
              <RowItem>
                <RowTitle>Name:</RowTitle>
                <RowValue>{userData!.surname}</RowValue>
              </RowItem>
              <RowItem>
                <RowTitle>Prénom:</RowTitle>
                <RowValue>{userData!.name}</RowValue>
              </RowItem>
              <RowItem>
                <RowTitle>Age:</RowTitle>
                <RowValue>{convertDateToAge(userData!.birthDate)}</RowValue>
              </RowItem>
              <RowItem>
                <RowTitle>Date de l'intervention:</RowTitle>
                <RowValue>{userData!.date}</RowValue>
              </RowItem>
              <RowItem>
                <RowTitle>Intervention:</RowTitle>
                <RowValue>{userData!.surgeryTypeValue}</RowValue>
              </RowItem>
              <RowItem>
                <RowTitle>Email:</RowTitle>
                <RowValue>{userData!.email}</RowValue>
              </RowItem>
            </CardContent>

            <ButtonContainer>
              <CustomButton
                content="Modifier/Ajouter des informations"
                handleOnClick={() => history.push("/patients/update")}
              />
            </ButtonContainer>
          </BasicCard>
        </CardContainer>

        {currentOrganizationCxt.currentOrganizationToEdit?.organization_subscription?.organization_plan?.organization_plan_features?.find(
          (e: any) =>
            e.organization_feature.name === ORGANIZATION_FEATURE.DOSES_COMPUTE
        ) && (
          <CardContainer width="50%">
            <DosesCompute userData={userData} />
          </CardContainer>
        )}
        <CardContainer width="50%">
          <BasicCard
            title={showSubsurvey ? "Questionnaire" : "Actions réalisées"}
            titleSeparator={true}
          >
            {showSubsurvey && (
              <BackButtonContainer
                onClick={() => {
                  scrollToTop("actions-taken-container");
                  setShowSubsurvey(null);
                }}
              >
                <IconArrowBack style={{ width: "12px", height: "11px" }} />
                <BackButtonText>Retour</BackButtonText>
              </BackButtonContainer>
            )}
            {showSubsurvey === "covidChecker" && (
              <CovidCheckerOpenGraphContainer>
                <CovidCheckerOpenGraphButton onClick={() => setModal(true)}>
                  <CovidCheckerOpenGraphText>
                    Accéder à l'évolution hébdomadaire
                  </CovidCheckerOpenGraphText>
                  <IconOpenWindowContainer>
                    <IconOpenWindow />
                  </IconOpenWindowContainer>
                </CovidCheckerOpenGraphButton>
              </CovidCheckerOpenGraphContainer>
            )}

            {timelineData ? (
              <TimelineDataContainer
                id="actions-taken-container"
                subsurveyBtn={showSubsurvey === "covidChecker"}
              >
                {showSubsurvey ? (
                  <>
                    {timelineData[showSubsurvey].subsurvey.map(
                      (e: any, i: number) => (
                        <TimelineItemContainer key={i}>
                          <SubsurveyItemNumber>{++i}</SubsurveyItemNumber>
                          <SubsurveyItemBox even={i % 2 === 0}>
                            <SubsurveyItemTitle>{e.title}</SubsurveyItemTitle>
                            <SubsurveyItemAnswerContainer>
                              <SubsurveyItemAnswer>
                                {e.tags[0].title}
                              </SubsurveyItemAnswer>
                              <SubsurveyItemStatus status={e.tags[0].status} />
                            </SubsurveyItemAnswerContainer>
                          </SubsurveyItemBox>
                        </TimelineItemContainer>
                      )
                    )}
                  </>
                ) : (
                  <>
                    {Object.keys(timelineData)
                      .sort(
                        (a, b) =>
                          timelineData[b].timestamp - timelineData[a].timestamp
                      )
                      .map((e: any, i: number) => {
                        return (
                          <TimelineItemContainer key={i}>
                            <TimelineItemDate>
                              {timelineData[e].date}
                            </TimelineItemDate>
                            <TimelineItemBox even={i % 2 === 0}>
                              <TimelineItemText>
                                {timelineData[e].title}
                                {timelineData[e].subsurvey && (
                                  <SubsurveyButton
                                    onClick={() => {
                                      scrollToTop("actions-taken-container");
                                      setShowSubsurvey(e);
                                    }}
                                  >
                                    Voir les résultats
                                  </SubsurveyButton>
                                )}
                              </TimelineItemText>
                              {timelineData[e].tags?.length > 0 && (
                                <TimelineContainerScore>
                                  {timelineData[e].tags.map(
                                    (_: any, j: number) => (
                                      <TimelineItemScore
                                        key={j}
                                        status={
                                          timelineData[e].tags?.[j].status
                                        }
                                      >
                                        {timelineData[e].tags?.[j].title}
                                      </TimelineItemScore>
                                    )
                                  )}
                                </TimelineContainerScore>
                              )}
                            </TimelineItemBox>
                          </TimelineItemContainer>
                        );
                      })}
                  </>
                )}
              </TimelineDataContainer>
            ) : (
              <Loader />
            )}
          </BasicCard>
        </CardContainer>
        <CardContainer width="50%">
          <BasicCard title="Historique" titleSeparator={true}>
            <TimelineDataContainer>
              {emails.data?.sent_emails.map((x: any, i: any) => {
                if (x.parent.parent_patients.length === 0) return null;
                return (
                  <TimelineItemContainer key={i}>
                    <TimelineItemDate
                      children={new Date(x.sent_at).toLocaleDateString(
                        "fr-FR",
                        {
                          year: "2-digit",
                          month: "short",
                          day: "numeric",
                        }
                      )}
                    />
                    <TimelineItemBox even={i % 2 === 0}>
                      <TimelineItemText>
                        {((x) => {
                          switch (x) {
                            case "D-7":
                              return "Évaluation de l’état de santé (à 7 jours)";
                            case "D-3":
                              return "Évaluation de l’état de santé (à 3 jours)";
                            case "D-1":
                              return "Rappel sur les documents administratifs";
                            case "D+1":
                              return "Rappel sur l’évaluation de la douleur";
                            case "D+7":
                              return "Rappel sur l’évaluation des douleurs post-opératoires";
                            case "reminder-signup":
                              return "Bienvenue sur Koalou - Plus qu’un pas !";
                            default:
                              return typeof x === "string"
                                ? x
                                : JSON.stringify(x);
                          }
                        })(x.email_type)}
                      </TimelineItemText>
                    </TimelineItemBox>
                  </TimelineItemContainer>
                );
              })}
            </TimelineDataContainer>
          </BasicCard>
        </CardContainer>

        {currentOrganizationCxt.currentOrganizationToEdit?.organization_subscription?.organization_plan?.organization_plan_features?.find(
          (e: any) =>
            e.organization_feature.name === ORGANIZATION_FEATURE.PRESCRIPTIONS
        ) && (
          <CardContainer width="50%">
            <BasicCard title="Prescription" titleSeparator={true}>
              <PrescriptionsContainer>
                <PrescriptionsRowContainer>
                  {userData?.prescriptions?.map((p, i) => {
                    return (
                      <PrescriptionRow
                        key={i}
                        onClick={() => showPrescription(p.filePath)}
                      >
                        Prescription - {moment(p.date).format("DD/MM/YYYY")}
                      </PrescriptionRow>
                    );
                  })}
                  {userData?.prescriptions?.length === 0 && (
                    <PrescriptionEmptyContainer>
                      Ici, dans ce module, vous pouvez retrouver les
                      prescriptions que vous avez données à ce patient
                      précédemment.
                      <br />
                      <br />
                      Vous pouvez créer et envoyer une prescription en cliquant
                      sur le bouton ci-dessous, ou dans le menu de gauche dans
                      la section « Prescriptions ».
                    </PrescriptionEmptyContainer>
                  )}
                </PrescriptionsRowContainer>
                <PrescriptionButtonContainer>
                  <CustomButton
                    handleOnClick={() =>
                      history.push(
                        `/prescriptions/create?surgeryId=${userData?.surgeryId}`
                      )
                    }
                    content="Générer une prescription"
                  />
                </PrescriptionButtonContainer>
              </PrescriptionsContainer>
            </BasicCard>
          </CardContainer>
        )}
      </Container>
      <CustomModal
        showModal={modal}
        maxWidth="100%"
        handleCloseModal={() => setModal(false)}
      >
        <div>
          <div
            onClick={() => setModal(false)}
            style={{
              borderRadius: "4px",
              padding: "4px",
              border: "1px solid transparent",
              marginLeft: "97%",
              textAlign: "center",
              fontSize: 50,
              fontWeight: 300,
            }}
            role="button"
          >
            ×
          </div>
          <SymptomChart
            patient={userData?.patientId}
            surgeryId={userData?.surgeryId}
          />
        </div>
      </CustomModal>
      <ModalContainer
        isOpen={modalPrescription.show}
        className="reactstrap-modal-full-size"
      >
        <ModalBody>
          <PdfViewer
            toggle={() =>
              setModalPrescription({ show: false, documentName: "" })
            }
            documentName={modalPrescription.documentName}
            fileCategory={PRESCRIPTION_FILE_TYPE.PRESCRIPTION}
            onLoadDocumentBase64={(data) => setDocumentBase64(data)}
            secondCustomButtonText={"Envoyer"}
            handleClickSecondCustomButton={() => {
              sendPrescritpion();
            }}
          />
        </ModalBody>
      </ModalContainer>
    </PageWrapper>
  );
};

export default PatientsProfile;

const RowItem = styled.div`
  display: flex;
  align-items: center;
  margin: 30px 0;
`;

const RowTitle = styled.div`
  margin-right: 10px;
  ${Heading3};
  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.laptop13Max}) {
    ${Heading4}
  }
`;

const RowValue = styled.div`
  ${BodyMain}
  @media screen and (max-width: ${(props) =>
    props.theme.breakpoints.laptop13Max}) {
    ${BodySmall}
  }
`;

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  max-width: 1600px;
  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.tabletMax}) {
    flex-direction: column;
    width: 100%;
    min-width: 0;
  }
`;

const CardContainer = styled.div<{ width: string }>`
  position: relative;
  width: 50%;
  height: 100%;
  padding: 1%;
  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.tabletMax}) {
    width: 100%;
    margin: 10px 0;
  }
`;

const CardContent = styled.div``;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 50px;
`;

const TimelineDataContainer = styled.div<{ subsurveyBtn?: boolean }>`
  margin-top: 20px;
  height: ${({ subsurveyBtn }) =>
    subsurveyBtn ? `calc(380px - 40px - 9px)` : "380px"};
  overflow-y: auto;
  scroll-behavior: smooth;
`;

const TimelineItemContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin: 8px 0;
`;

const TimelineItemBox = styled.div<{ even: boolean }>`
  display: flex;
  width: 85%;
  padding: 13px 16px;
  align-items: center;
  background: ${(props) =>
    props.even
      ? props.theme.colors.lightGreenL2
      : props.theme.colors.lightGreenL1};
  border-radius: 12px;
`;

const TimelineItemText = styled.div`
  width: 70%;
  ${BodyMain};
  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.laptop13Max}) {
    ${Heading5}
  }
`;
const TimelineItemDate = styled.div`
  width: 10%;
  ${Heading4};
  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.laptop13Max}) {
    ${Heading5}
  }
  color: ${(props) => props.theme.colors.darkGreenMain};
`;

const TimelineContainerScore = styled.div`
  width: 30%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const TimelineItemScore = styled.div<{ status?: string }>`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ status, theme }) =>
    status === "success"
      ? theme.colors.darkGreenL3
      : status === "danger"
      ? theme.colors.redMain
      : `linear-gradient(135.02deg, #095c66 0%, #209aa2 100%)`};
  border-radius: 6px;
  height: 29px;
  color: ${(props) => props.theme.colors._white};
  margin: 5px 0 5px 18px;
  ${Heading3};
  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.laptop13Max}) {
    ${Heading5}
  }
`;

const SubsurveyButton = styled.div`
  border: 2px solid ${(props) => props.theme.colors.darkGreenMain};
  color: ${(props) => props.theme.colors.darkGreenMain};
  border-radius: 6px;
  padding: 3px 9px;
  text-align: center;
  margin-top: 10px;
  cursor: pointer;
  position: relative;
`;

const BackButtonContainer = styled.div`
  position: absolute;
  top: 35px;
  right: 30px;
  display: flex;
  align-items: center;
  cursor: pointer;
  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.laptop13Max}) {
    top: 25px;
  }
`;

const BackButtonText = styled.div`
  ${Heading4};
  color: ${(props) => props.theme.colors.darkGreenMain};
  margin-left: 5px;
`;

const SubsurveyItemNumber = styled.div`
  width: 10%;
  ${Heading4};
  text-align: center;
  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.laptop13Max}) {
    ${Heading5}
  }
  color: ${(props) => props.theme.colors.darkGreenMain};
`;

const SubsurveyItemBox = styled.div<{ even: boolean }>`
  width: 90%;
  padding: 13px 16px;
  align-items: center;
  background: ${(props) =>
    props.even
      ? props.theme.colors.lightGreenL2
      : props.theme.colors.lightGreenL1};
  border-radius: 12px;
`;

const SubsurveyItemTitle = styled.div`
  ${Heading4};
  color: ${(props) => props.theme.colors.blackMain};
  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.laptop13Max}) {
    ${Heading5}
  }
`;

const SubsurveyItemAnswerContainer = styled.div`
  margin-top: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const SubsurveyItemAnswer = styled.div`
  ${Heading4};
  color: ${(props) => props.theme.colors.darkGreenL4};
  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.laptop13Max}) {
    ${Heading5}
  }
`;

const SubsurveyItemStatus = styled.div<{ status: "sucess" | "danger" }>`
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: ${({ theme, status }) =>
    status === "danger" ? theme.colors.redMain : "none"};
`;

const IconOpenWindowContainer = styled.div`
  width: 10%;
  margin-left: 10px;
  & > svg > path {
    fill: ${({ theme }) => theme.colors.darkGreenL4};
  }
`;

const CovidCheckerOpenGraphContainer = styled.div`
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: center;
`;

const CovidCheckerOpenGraphButton = styled.div`
  border: 2px solid ${(props) => props.theme.colors.darkGreenMain};
  color: ${(props) => props.theme.colors.darkGreenMain};
  border-radius: 6px;
  padding: 3px 9px;
  text-align: center;
  cursor: pointer;
  display: flex;
  align-items: center;
  width: 80%;
`;

const CovidCheckerOpenGraphText = styled.div`
  ${Heading4}
  width: 90%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const PrescriptionsContainer = styled.div``;
const PrescriptionsRowContainer = styled.div`
  display: flex;
  flex-direction: column-reverse;
`;

const PrescriptionRow = styled.div`
  ${Heading4}
  color: ${({ theme }) => theme.colors.darkGreenMain};
  margin: 5px;
  text-decoration: underline;
  cursor: pointer;
`;

const PrescriptionButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 30px;
`;

const ModalContainer = styled(Modal)``;

const PrescriptionEmptyContainer = styled.div`
  margin-top: 20px;
  ${Heading3};
  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.laptop13Max}) {
    ${Heading4}
  }
`;
