// https://www.chusj.org/fr/soins-services/P/Pharmacie/Outils/Guide-Pratique/Outils-pratiques/Calculs-Rappels

const mList = require("../../pages/DosesCompute/medication.json").map(
  (x) => x.name
);

const medication = mList.map((x, i) => ({
  value: i,
  label: x,
  compute: (() => {
    try {
      return require(`./${x.toLowerCase()}`).default;
    } catch (e) {
      return (kg, age, type) => ({
        amount_scale: 0,
        unit: "",
        comment: "Médicament non implémenté",
      });
    }
  })(),
}));

export const medicationList = medication;

export const compute = (identifier) => {
  if (isNaN(parseInt(identifier))) {
    return medication.find((x) => x.label === identifier).compute;
  } else if (typeof parseInt(identifier) === "number") {
    return medication[parseInt(identifier)].compute;
  } else {
    return (kg, age, type) => ({
      amount_scale: 0,
      unit: "",
      comment: "Médicament non implémenté",
    });
  }
};
