import "firebase/auth";
import { Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import styled from "styled-components";
import * as Yup from "yup";
import { Heading3, Heading4 } from "../../../theme/fonts";
import { ReactComponent as SignupImage } from "../../../theme/images/signup.svg";
import { signup, validatePassword } from "../../../utils/auth";
import { displayToastNotification } from "../../../utils/toastNotification";
import AuthCardForm from "../../AuthCard/AutchCardForm/AuthCardForm";
import AuthCardButton from "../../AuthCard/AuthCardButton/AuthCardButton";
import { AuthCardImage } from "../../AuthCard/AuthCardImage/AuthCardImage";
import AuthCardLogo from "../../AuthCard/AuthCardLogo/AuthCardLogo";
import AuthCardTitle from "../../AuthCard/AuthCardTitle/AuthCardTitle";
import { TextInputForm } from "../../Form/TextInputForm/TextInputForm";
import { Loader } from "../../Loader/Loader";
import { useTranslation } from "react-i18next";
import PasswordStrengthMeter from "../../PasswordStrengthMeter/PasswordStrengthMeter";

interface SignUpFormProps {
  email: string;
  type: string;
}

interface FormValues {
  email: string;
  password: string;
  confirmPassword: string;
}

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .required("Veuillez entrer votre email")
    .email("Email invalide"),
  password: Yup.string().required("Veuillez entrer votre nouveau mot de passe"),
  confirmPassword: Yup.string().required(
    "Veuillez confirmer votre mot de passe"
  ),
});

const SignUpForm: React.FunctionComponent<SignUpFormProps> = ({
  email,
  type,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [status, setStatus] = useState({
    successMessage: "",
    formCompleted: false,
    accountReady: false,
  });
  const PATIENT_APP_SIGNIN_URL = process.env.REACT_APP_PATIENT_APP_URL;

  const handleSignUp = async (
    values: FormValues,
    setFieldError: (field: string, errorMsg: string) => void
  ) => {
    if (!validatePassword(values.password)) {
      setFieldError("password", t("common:passwordInvalid"));
      displayToastNotification("error", t("common:passwordWeak"), 15000);
    } else if (values.password !== values.confirmPassword) {
      setFieldError("confirmPassword", t("common:passwordConfirmation"));
    } else {
      const res = await signup(email, values.password);
      //@ts-ignore
      if (res.error) {
        if (res.type === "password") {
          displayToastNotification("error", t("common:passwordWeak"), 15000);
          setFieldError("password", res.error);
        } else {
          setFieldError("email", res.error);
        }
      } else {
        setStatus({
          ...status,
          successMessage: "Votre compte a bien été créé.",
          formCompleted: true,
        });
      }
    }
  };

  useEffect(() => {
    if (status.formCompleted) {
      const timer = setTimeout(() => {
        setStatus({
          ...status,
          accountReady: true,
        });
      }, 4000);
      return () => clearTimeout(timer);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status.formCompleted]);

  const redirectUserToLoginPage = () => {
    if (type === "parent") {
      window.location.replace(PATIENT_APP_SIGNIN_URL!);
    } else {
      history.push("/");
    }
  };

  return (
    <Container>
      <AuthCardForm>
        <Formik
          initialValues={{
            email,
            password: "",
            confirmPassword: "",
          }}
          onSubmit={async (values: FormValues, { setFieldError }) => {
            await handleSignUp(values, setFieldError);
          }}
          validationSchema={validationSchema}
        >
          {(props: any) => (
            <FormStyled>
              <AuthCardLogo />
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "100%",
                  width: "100%",
                }}
              >
                <AuthCardTitle
                  title={"Finaliser la création de votre compte"}
                />
                {!status.formCompleted && (
                  <>
                    <div style={{ width: "100%" }}>
                      <FieldContainer>
                        <Field
                          name={"email"}
                          placeholder={"Email"}
                          disabled={true}
                          component={TextInputForm}
                          iconType="email"
                        />
                      </FieldContainer>
                      <FieldContainer>
                        <Field
                          name="password"
                          placeholder="Mot de Passe"
                          type="password"
                          component={TextInputForm}
                          iconType="password"
                        />
                      </FieldContainer>
                      <FieldContainer>
                        <Field
                          name="confirmPassword"
                          placeholder="Confirmation mot de passe"
                          type="password"
                          component={TextInputForm}
                          iconType="password"
                        />
                      </FieldContainer>
                      <PasswordStrengthMeter password={props.values.password} />
                    </div>
                    <AuthCardButton
                      content="Créer votre compte"
                      handleOnClick={() => props.handleSubmit()}
                    />
                  </>
                )}
                {status.formCompleted && !status.accountReady && (
                  <FormCompletedContainer>
                    <Title>Nous préparons votre compte...</Title>
                    <LoaderContainer>
                      <Loader />
                    </LoaderContainer>
                  </FormCompletedContainer>
                )}
                {status.formCompleted && status.accountReady && (
                  <FormCompletedContainer>
                    <Title>Votre compte a été crée avec succès</Title>
                    <AuthCardButton
                      content="Se connecter"
                      handleOnClick={() => redirectUserToLoginPage()}
                    />
                  </FormCompletedContainer>
                )}
              </div>
            </FormStyled>
          )}
        </Formik>
      </AuthCardForm>
      <AuthCardImage imageComponent={<SignupImage />} />
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const FormStyled = styled(Form)`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
`;

const Title = styled.div`
  margin-bottom: 5px;
  ${Heading3};

  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.laptop13Max}) {
    ${Heading4}
  }

  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.mobilePortraitMax}) {
    ${Heading3}
  }
`;

const FieldContainer = styled.div`
  width: 100%;
  margin: 26px 0;
`;

const FormCompletedContainer = styled.div`
  margin-top: 30px;
  text-align: center;
`;

const LoaderContainer = styled.div`
  margin: 50px 0;
`;

export default SignUpForm;
