import HelloSign from "hellosign-embedded";
import React, { useContext, useState } from "react";
import styled from "styled-components";
import FormEsignTemplate from "../../components/FormTemplates/EsignTemplate/FormEsignTemplate";
import { PageWrapper } from "../../components/Layout/PageWrapper/PageWrapper";
import { Loader } from "../../components/Loader/Loader";
import ApplicationContext from "../../utils/context";
import { createEmbeddedTemplateDraft } from "../../utils/esign";
import { displayToastNotification } from "../../utils/toastNotification";

interface SignatoriesValues {
  key: string;
  label: string;
  checked: boolean;
  order: number | null;
}

interface FormValues {
  name: string;
  fileToUpload: File | null;
  currentFileName: string | null;
  signatoriesOrder: boolean;
}

interface EsignTemplateCreateProps {}

const EsignTemplateCreate: React.FC<EsignTemplateCreateProps> = () => {
  const { helloSignClientCxt, currentOrganizationCxt } =
    useContext<any>(ApplicationContext);

  const [templateUrl, setTemplateUrl] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const initialValues: FormValues = {
    name: "",
    fileToUpload: null,
    currentFileName: null,
    signatoriesOrder: false,
  };

  const editTemplate = (templateUrl: string) => {
    /* TODO multiple events if client cancel template and recreate */
    // helloSignClientCxt.helloSignClientId.on("cancel", () => {
    //   helloSignClientCxt.helloSignClientId.off("ready");
    //   helloSignClientCxt.helloSignClientId.off("createTemplate");
    //   helloSignClientCxt.helloSignClientId.off("cancel");
    // });

    /* When the template is ready we remove the loader*/
    helloSignClientCxt.helloSignClientId.on("ready", () => {
      setIsLoading(false);
      helloSignClientCxt.helloSignClientId.off("ready");
    });

    helloSignClientCxt.helloSignClientId.on("createTemplate", () => {
      displayToastNotification(
        "success",
        "Le nouveau modèle est en cours de traitement"
      );
      setIsLoading(true);
      setTimeout(() => {
        displayToastNotification(
          "success",
          "Le nouveau modèle est bien enregistré"
        );
        setIsLoading(false);
        setTemplateUrl("");
      }, 5000);
      helloSignClientCxt.helloSignClientId.off("createTemplate");
    });

    /* Load HelloSign templateUrl in a custom container */
    helloSignClientCxt.helloSignClientId.open(templateUrl, {
      testMode: parseInt(process.env.REACT_APP_HELLOSIGN_TEST_MODE!),
      locale: HelloSign.locales.FR_FR,
      container: document.getElementById("template-draft-create")!,
    });
  };

  const generateTemplatePreview = async (
    values: FormValues,
    signatories: Array<SignatoriesValues>
  ) => {
    const organizationId =
      currentOrganizationCxt?.currentOrganizationToEdit?.id;
    if (!organizationId) {
      displayToastNotification("error", "L'établissement n'est pas initialisé");
      return;
    }
    setIsLoading(true);
    const data = new FormData();
    data.append("file", values.fileToUpload!);
    data.append("templateName", values.name);
    data.append("organizationId", organizationId);
    data.append("signatories", JSON.stringify(signatories));
    const { error, templateUrl } = await createEmbeddedTemplateDraft(data);
    if (error) {
      displayToastNotification("error", "Une erreur est survenue");
      return;
    } else if (templateUrl) {
      setTemplateUrl(templateUrl);
      editTemplate(templateUrl);
    }
  };

  return (
    <PageWrapper
      previousPageIconOn={!!templateUrl}
      previousPageText={!!templateUrl ? "Retour" : ""}
      handleOnClickPreviousPage={() =>
        !!templateUrl ? setTemplateUrl("") : null
      }
    >
      <Container>
        {isLoading && (
          <LoaderContainer>
            <Loader />
          </LoaderContainer>
        )}
        {!isLoading && !templateUrl && (
          <FormEsignTemplate
            title="Creation de modèle"
            initialValues={initialValues}
            handleSubmit={(values, signatories) =>
              generateTemplatePreview(values, signatories)
            }
          />
        )}

        <TemplateContainer
          id="template-draft-create"
          hidden={!!!templateUrl}
        ></TemplateContainer>
      </Container>
    </PageWrapper>
  );
};

export default EsignTemplateCreate;

const Container = styled.div`
  height: 100%;
  width: 100%;
  margin: 0 auto;
`;

const TemplateContainer = styled.div<{ hidden: boolean }>`
  height: calc(
    100vh - 60px - 46px
  ); /* Full screen minus page wrapper padding, minus options back arrow  */

  display: ${(props) => (props.hidden ? "none" : "")};
`;

const LoaderContainer = styled.div`
  position: absolute;
  top: calc(50% - 50px / 2);
  left: calc(50% - 50px / 2);
`;
