import { useLazyQuery, useQuery } from "@apollo/client";
import { loader } from "graphql.macro";
import cookie from "js-cookie";
import React, { useEffect, useState, useContext } from "react";
import { Redirect, Route } from "react-router-dom";
import { COOKIES } from "../../../constants/cookies_types";
import { APPLICATION_ROLE } from "../../../constants/db_types";
import SessionExpirationModal from "../../SessionExpirationModal/SessionExpirationModal";
import ApplicationContext from "../../../utils/context";

const getUserRoleQuery = loader("../../../graphql/getUserRole.graphql");
const getMeQuery = loader("../../../graphql/getMe.graphql");
const getLanguagesQuery = loader("../../../graphql/getLanguages.graphql");

/* TODO check why email is used. Maybe we should just grab the role based on user id in the jwt */
const PrivateRoute = ({
  component: Component,
  email,
  adminOnly = false,
  ...rest
}) => {
  const [getUserRole, { data: userRole }] = useLazyQuery(getUserRoleQuery);

  const { meCxt, languagesCxt } = useContext(ApplicationContext);
  /* Retreive me information */
  const { data: dataMe } = useQuery(getMeQuery, {
    variables: {
      userId: cookie.get(COOKIES.ID),
    },
  });

  const { data: dataLanguages } = useQuery(getLanguagesQuery);

  const [role, setRole] = useState({
    isLoading: true,
    value: null,
  });

  useEffect(() => {
    if (email) {
      const role = cookie.get(COOKIES.ROLE);
      if (!role) {
        getUserRole({
          variables: { email },
        });
      } else {
        setRole({ isLoading: false, value: role });
      }
    } else {
      setRole({ isLoading: false, value: null });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (userRole) {
      const role =
        userRole.user?.[0]?.user_application_roles?.[0]?.application_role?.name;
      setRole({
        isLoading: false,
        value: role,
      });
      cookie.set(COOKIES.ROLE, role);
    }
  }, [userRole]);

  useEffect(() => {
    if (dataMe?.practitioner?.length > 0) {
      const meTmp = dataMe.practitioner[0];
      meCxt.setMeToEdit({
        id: meTmp.id,
        name: meTmp.name,
        surname: meTmp.surname,
        rpps: meTmp.rpps,
        phone: meTmp.phone,
        signatureFilePath: meTmp.signature_file_path,
        specialization:
          meTmp.practitioner_specializations?.[0]?.specialization.name,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataMe]);

  useEffect(() => {
    if (dataLanguages?.language) {
      languagesCxt.setLanguagesToEdit(dataLanguages.language);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataLanguages]);

  if (role.isLoading) {
    return <></>;
  }
  return (
    <Route
      {...rest}
      render={(props) => {
        if (
          !role.value ||
          (adminOnly && role.value !== APPLICATION_ROLE.ADMIN)
        ) {
          return <Redirect to={{ pathname: "/" }} />;
        } else if (
          role.value !== APPLICATION_ROLE.PRACTITIONER &&
          role.value !== APPLICATION_ROLE.ADMIN
        ) {
          return <Redirect to={{ pathname: "/unauthorized" }} />;
        } else {
          return (
            <SessionExpirationModal>
              <Component {...props} />
            </SessionExpirationModal>
          );
        }
      }}
    />
  );
};

export default PrivateRoute;
