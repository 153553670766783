import React, { useCallback } from "react";
import styled from "styled-components";
import * as _ from "lodash";
import { ReactComponent as IconSearch } from "../../theme/icons/search.svg";
import { Heading3, Heading4 } from "../../theme/fonts";

interface SearchBarProps {
  searchBarValue: string;
  sendQuery: Function;
  setSearchBarValue: Function;
}

const SearchBar: React.FunctionComponent<SearchBarProps> = ({
  searchBarValue,
  sendQuery,
  setSearchBarValue,
}) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const delayedQuery = useCallback(
    _.debounce((v: string) => sendQuery(v), 350),
    []
  );

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearchBarValue(value);
    delayedQuery(value);
  };

  return (
    <SearchBarContainer>
      <Input
        type="text"
        placeholder="Recherche..."
        id="search"
        name="search"
        value={searchBarValue}
        onChange={(e) => handleSearch(e)}
      />
      <IconSearchContainer>
        <IconSearch />
      </IconSearchContainer>
    </SearchBarContainer>
  );
};

const SearchBarContainer = styled.div`
  position: relative;
  width: 100%;
`;

const Input = styled.input`
  width: 100%;
  padding: 10px 10px 10px 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  background: ${({ theme }) => theme.colors._white};
  box-shadow: -6px -6px 12px 0.5px rgba(197, 197, 197, 0.21),
    6px 6px 12px 0.5px rgba(197, 197, 197, 0.21);
  border: none;
  border-radius: 36px;
  :focus {
    outline: none;
  }
  ::placeholder {
    color: ${({ theme }) => theme.colors.blackMain};
  }
  ${Heading3}

  @media screen and (max-width: ${(props) =>
    props.theme.breakpoints.laptop13Max}) {
    ${Heading4}
  }
`;

const IconSearchContainer = styled.div`
  position: absolute;
  top: 8px;
  left: 15px;
`;

export default SearchBar;
