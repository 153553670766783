const def = (kg, ageMonth, type) => {
  let amount_scale = 0;
  let comment = "";

  if (type === "induction") {
    if (kg < 5) {
      comment = "Pas d'AMM en dessous de 5kg";
    } else {
      amount_scale = [0.3 * kg, 0.6 * kg];
    }
    return {
      amount_scale,
      unit: "mg",
      comment,
    };
  } else if (type === "entretiens") {
    if (kg < 5) {
      comment = "Pas d'AMM en dessous de 5kg";
    } else {
      amount_scale = [0.1 * kg, 0.6 * kg];
    }
    return {
      amount_scale,
      unit: "mg/h",
      comment,
    };
  }
};

export default def;
