import { useLazyQuery, useMutation } from "@apollo/client";
import { Field, Form, Formik } from "formik";
import { loader } from "graphql.macro";
// import HelloSign from "hellosign-embedded";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { useCallback } from "react";
import styled from "styled-components";
import CustomButton from "../../components/CustomButton/CustomButton";
import CustomModal from "../../components/CustomModal/CustomModal";
import { TextInputForm } from "../../components/Form/TextInputForm/TextInputForm";
import { PageWrapper } from "../../components/Layout/PageWrapper/PageWrapper";
import CustomList from "../../components/List/CustomList/CustomList";
import PatientListTemplates from "../../components/ListTemplates/Patient/PatientListTemplates";
import { Loader } from "../../components/Loader/Loader";
import {
  CardStyle2,
  LinearGradientText,
} from "../../theme/components/ThemeComponents";
import ApplicationContext from "../../utils/context";
import {
  // getTemplate,
  sendEmbeddedSignatureRequestWithTemplate,
} from "../../utils/esign";
import * as Yup from "yup";

import { displayToastNotification } from "../../utils/toastNotification";
import { Modal, ModalBody } from "reactstrap";
import PdfViewer from "../../components/PdfViewer";
import { ESIGN_FILE_TYPE } from "../../constants/db_types";

const getEsignTemplatesCountQuery = loader(
  "../../graphql/esign/getEsignTemplatesCountByOrganizationId.graphql"
);

const getEsignTemplatesWithLimitOffsetQuery = loader(
  "../../graphql/esign/getEsignTemplatesWithLimitOffset.graphql"
);

const updateEsignTemplateQuery = loader(
  "../../graphql/esign/updateEsignTemplate.graphql"
);

interface EsignTemplatesListProps {}

const limit = 10;

const columns = [
  { value: "Nom", ratio: "45%", type: "string" },
  { value: "Date de création", ratio: "30%", type: "string" },
  { value: "Actions", ratio: "25%", type: "component" },
];

const validationSchema = (emailParent1: string) =>
  Yup.object().shape({
    email: Yup.string()
      .required("Ce champ est requis")
      .email("Email invalide")
      .test(
        "email-different",
        "L'email doit être différent du parent 1",
        (value) => value !== emailParent1
      ),
  });

const EsignTemplatesList: React.FC<EsignTemplatesListProps> = () => {
  const [getEsignTemplatesCount, { data: dataEsignTemplatesCount }] =
    useLazyQuery(getEsignTemplatesCountQuery, { fetchPolicy: "network-only" });

  const [getEsignTemplatesWithLimitOffset, { data: dataEsignTemplates }] =
    useLazyQuery(getEsignTemplatesWithLimitOffsetQuery, {
      fetchPolicy: "network-only",
    });

  const [updateEsignTemplate] = useMutation(updateEsignTemplateQuery);

  const [pagesNumber, setPagesNumber] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("%%");
  const [searchBarValue, setSearchBarValue] = useState("");
  const [deleteTemplateModal, setDeleteTemplateModal] = useState({
    show: false,
    templateId: "",
    delete: false,
  });
  const [patientsList, setPatientsList] = useState({
    show: false,
    templateId: "",
  });

  const [showParent2EmailModal, setShowParent2EmailModal] = useState(false);

  const [modalDocument, setModalDocument] = useState({
    show: false,
    documentName: "",
  });

  const { currentOrganizationCxt } = useContext<any>(ApplicationContext);
  // const [templateUrl, setTemplateUrl] = useState("");

  const [templates, setTemplates] = useState<Array<{
    customData: {
      id: string;
      name: string;
      date: string;
      hellosignTemplateId: string;
      filePath: string;
    };
    rowValues: Array<string>;
  }> | null>(null);

  const [dataToBeSentSignatureRequest, setDataToBeSentSignatureRequest] =
    useState<any>(null); //TODO props !!

  const [isLoading, setIsLoading] = useState(false);

  //TODO check double request made when initial load due to fetchPolicy
  const fetchEsignTemplates = useCallback(() => {
    if (currentOrganizationCxt?.currentOrganizationToEdit?.id) {
      getEsignTemplatesWithLimitOffset({
        variables: {
          limit,
          offset: currentPage * limit - limit,
          search,
          organizationId: currentOrganizationCxt?.currentOrganizationToEdit?.id,
        },
      });
    }
  }, [
    currentPage,
    currentOrganizationCxt?.currentOrganizationToEdit,
    getEsignTemplatesWithLimitOffset,
    search,
  ]);

  const fetchEsignTemplatesCount = useCallback(() => {
    if (currentOrganizationCxt?.currentOrganizationToEdit?.id) {
      getEsignTemplatesCount({
        variables: {
          organizationId: currentOrganizationCxt.currentOrganizationToEdit.id,
          search,
        },
      });
    }
  }, [
    currentOrganizationCxt?.currentOrganizationToEdit,
    getEsignTemplatesCount,
    search,
  ]);

  useEffect(() => {
    fetchEsignTemplatesCount();
  }, [fetchEsignTemplatesCount]);

  /* Calculate number of pages */
  useEffect(() => {
    if (dataEsignTemplatesCount) {
      const templatesCount = dataEsignTemplatesCount.esign_template.length;
      let pagesNumberTmp = Math.ceil(templatesCount / limit);
      setPagesNumber(pagesNumberTmp);
    }
  }, [dataEsignTemplatesCount]);

  useEffect(() => {
    fetchEsignTemplates();
  }, [fetchEsignTemplates]);

  useEffect(() => {
    if (dataEsignTemplates) {
      const templatesTmp = dataEsignTemplates.esign_template.map((e: any) => {
        return {
          customData: {
            id: e.id,
            name: e.name,
            date: e.created_at,
            hellosignTemplateId: e.hellosign_template_id,
            filePath: e.file_path,
          },
          rowValues: [
            e.name,
            moment(e.created_at).format("DD/MM/YYYY"),
            <ActionsButtonsContainer>
              <DeleteButton
                onClick={(event) => {
                  event.stopPropagation();
                  setDeleteTemplateModal({
                    show: true,
                    templateId: e.hellosign_template_id,
                    delete: false,
                  });
                }}
              >
                Désactiver
              </DeleteButton>
              <SendButton
                onClick={(event) => {
                  event.stopPropagation();
                  setPatientsList({
                    show: true,
                    templateId: e.hellosign_template_id,
                  });
                }}
              >
                Envoyer
              </SendButton>
            </ActionsButtonsContainer>,
          ],
        };
      });
      setTemplates(templatesTmp);
    }
  }, [dataEsignTemplates]);

  // useEffect(() => {
  //   if (templateUrl) {
  //     editTemplate();
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [templateUrl]);

  // const editTemplate = () => {
  //   //TODO test with premium mode if user can edit in preview mode
  //   helloSignClientCxt.helloSignClientId.open(templateUrl, {
  //     testMode: parseInt(process.env.REACT_APP_HELLOSIGN_TEST_MODE!),
  //     locale: HelloSign.locales.FR_FR,
  //     // container: document.getElementById("template-draft")!,
  //     // whiteLabeling: {
  //     //   "page_background_color": "#000000",
  //     // },
  //   });

  //   //Close HelloSign view
  //   // setTimeout(() => {
  //   //   client.close();
  //   // }, 3000);
  // };

  const handleChangeCurrentPage = (value: number) => {
    if (value > 0 && value <= pagesNumber && value !== currentPage) {
      setCurrentPage(value);
    }
  };

  const handleFilter = (value: string) => {
    setSearch("%" + value + "%");
    setCurrentPage(1);
  };

  const handleClickDeleteTemplate = async (templateId: string) => {
    setDeleteTemplateModal({ show: false, templateId: "", delete: true });
    try {
      await updateEsignTemplate({ variables: { templateId } });
      fetchEsignTemplatesCount();
      fetchEsignTemplates();
      displayToastNotification(
        "success",
        "Le modèle a été désactivé avec succès"
      );
    } catch (err) {
      displayToastNotification("error", "Une erreur est survenue");
    }
  };

  const handleOpenTemplate = async (index: number) => {
    setModalDocument({
      show: true,
      documentName: templates![index].customData.filePath,
    });
    // const templateId = templates![index].customData.hellosignTemplateId;
    // const { error, templateUrl } = await getTemplate(templateId);
    // if (error) {
    //   displayToastNotification("error", "Impossible d'ouvrir le document");
    // } else if (templateUrl) {
    //   setTemplateUrl(templateUrl);
    // }
  };

  const handleClickPatient = (data: any) => {
    setDataToBeSentSignatureRequest(null);
    const organizationId = data.surgeries[0].organization_id;
    const patient = {
      id: data.id,
      name: data.name,
      surname: data.surname,
      birthDate: moment(data.birth_date).format("DD/MM/YYYY"),
    };
    const parent1 = {
      name: data.parent_patients[0].parent.name,
      surname: data.parent_patients[0].parent.surname,
      email: data.parent_patients[0].parent.email,
    };
    const surgeon1 = { name: "", surname: "", email: "" }; //TODO
    const parent2 = { email: "" }; //TODO
    const surgery = {
      id: data.surgeries[0].id,
      value: data.surgeries[0].surgery_type.value,
      date: moment(data.surgeries[0].date).format("DD/MM/YYYY"),
    };

    setDataToBeSentSignatureRequest({
      organizationId,
      templateId: patientsList.templateId,
      patient,
      parent1,
      parent2,
      surgeon1,
      surgery,
    });
  };

  const handleClickSendSignatureRequest = async (parent2Email?: string) => {
    // console.log(parent2Email);
    setIsLoading(true);
    //TODO here request template infos to know signer roles -> for example if parent2 is set and not in DB show input
    const res = await sendEmbeddedSignatureRequestWithTemplate({
      ...dataToBeSentSignatureRequest,
      parent2: { email: parent2Email },
    });
    setIsLoading(false);
    if (res.error === "parent2_email_missing") {
      setShowParent2EmailModal(true);
      return;
    }
    setPatientsList({ show: false, templateId: "" });
    if (res.error) {
      displayToastNotification("error", res.error);
    } else {
      displayToastNotification("success", "Le document a bien été envoyé");
    }
  };

  return (
    <PageWrapper>
      <Container>
        <CustomList
          title="Liste des modéles"
          columns={columns}
          data={templates}
          pagesNumber={pagesNumber}
          currentPage={currentPage}
          handleChangeCurrentPage={handleChangeCurrentPage}
          displaySearchBar={true}
          searchBarValue={searchBarValue}
          setSearchBarValue={(v: string) => setSearchBarValue(v)}
          handleSearch={(v: string) => handleFilter(v)}
          handleClick={(i: number) => handleOpenTemplate(i)}
        />
        <CustomModal
          showModal={deleteTemplateModal.show}
          buttonLeftContent="Annuler"
          buttonRightContent="Désactiver"
          handleClickButtonLeft={() =>
            setDeleteTemplateModal({
              show: false,
              templateId: "",
              delete: false,
            })
          }
          handleClickButtonRight={() => {
            handleClickDeleteTemplate(deleteTemplateModal.templateId);
          }}
          textContent="Voulez-vous vraiment désactiver ce modèle ? Il ne sera plus accessible."
        />
        <CustomModal
          showModal={patientsList.show}
          component={
            <PatientListContainer>
              {isLoading ? (
                <LoaderContainer>
                  <Loader />
                </LoaderContainer>
              ) : (
                <>
                  <PatientListTemplates
                    customHandleClick={(data: any) => handleClickPatient(data)}
                    activeRowStyle={true}
                  />

                  <CustomButtonContainer>
                    <CustomButton
                      content="Annuler"
                      handleOnClick={() =>
                        setPatientsList({ show: false, templateId: "" })
                      }
                    />
                    <CustomButton
                      content="Envoyer"
                      disabled={!!!dataToBeSentSignatureRequest}
                      handleOnClick={() => handleClickSendSignatureRequest()}
                    />
                  </CustomButtonContainer>
                </>
              )}
            </PatientListContainer>
          }
        />
        <CustomModal
          showModal={showParent2EmailModal}
          shouldCloseOnOverlayClick={true}
          handleCloseModal={() => {
            setPatientsList({ show: false, templateId: "" });
            setShowParent2EmailModal(false);
          }}
          component={
            <Parent2EmailFormContainer>
              <Formik
                initialValues={{ email: "" }}
                onSubmit={async (values: { email: string }) => {
                  setShowParent2EmailModal(false);
                  await handleClickSendSignatureRequest(values.email);
                }}
                validationSchema={() =>
                  validationSchema(dataToBeSentSignatureRequest.parent1.email)
                }
                validateOnChange={false}
              >
                {() => (
                  <FormContainer>
                    <FieldContainer>
                      <Field
                        name={"email"}
                        label={"Email du deuxième parent"}
                        placeholder={"Email"}
                        component={TextInputForm}
                      />
                    </FieldContainer>
                    <CustomButtonContainer>
                      <CustomButton type="submit" content="Valider" />
                    </CustomButtonContainer>
                  </FormContainer>
                )}
              </Formik>
            </Parent2EmailFormContainer>
          }
        />
        <ModalContainer
          isOpen={modalDocument.show}
          className="reactstrap-modal-full-size"
        >
          <ModalBody>
            <PdfViewer
              toggle={() => setModalDocument({ show: false, documentName: "" })}
              documentName={modalDocument.documentName}
              fileCategory={ESIGN_FILE_TYPE.TEMPLATE}
            />
          </ModalBody>
        </ModalContainer>
      </Container>
    </PageWrapper>
  );
};

export default EsignTemplatesList;

const Container = styled.div`
  height: 100%;
  ${CardStyle2}
  max-width: 1030px;
  min-width: 566px;
  margin: 0 auto;
`;

const ActionsButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const DeleteButton = styled.div`
  ${LinearGradientText}
`;

const SendButton = styled.div`
  ${LinearGradientText}
`;

const PatientListContainer = styled.div`
  min-width: 1036px;

  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.laptop13Max}) {
    min-width: 800px;
  }
  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.tabletMax}) {
    min-width: 566px;
  }
`;

const CustomButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-evenly;
`;

const LoaderContainer = styled.div`
  height: 200px;
`;

const Parent2EmailFormContainer = styled.div`
  overflow-x: hidden;
`;

const FormContainer = styled(Form)``;

const FieldContainer = styled.div`
  margin-bottom: 30px;
`;

const ModalContainer = styled(Modal)``;
