import { Line } from 'react-chartjs-2'
import { loader } from 'graphql.macro'
import { useQuery } from '@apollo/client'
import { useState, useEffect } from 'react'

const getSymptomatologyResults = loader(
  "../../graphql/getSymptomatologyResults.graphql"
)

const dateFormat = ['fr-FR', {
  weekday: 'short', year: '2-digit', month: 'short', day: 'numeric'
}]

const fontOptions = {
  family: 'Raleway',
  size: '14',
  weight: '700'
}

const SymptomChart = ({ patient, surgeryId }) => {
  const [surveyStats, setSurveyStats] = useState(
    [null, null, null, null, null, null, null]
  )

  const [labels, setLabels] = useState(
    ['L', 'M', 'M', 'J', 'V', 'S', 'D']
  )

  const [questions, setQuestions] = useState(
    [[], [], [], [], [], [], []]
  )

  const [noData, setNoData] = useState(false)

  const query = useQuery(getSymptomatologyResults, {
    variables: {
      patientId: patient,
      surgeryId: surgeryId
    }
  })

  useEffect(() => {
    if (!patient || !surgeryId) {
      setNoData(true)
      return
    }
    let values = []
    let q = []
    let lastDate = { getTime: () => null }
    if (!query?.data?.patient[0].survey_result_linked_to_surgeries)
      return
    for (const s of query?.data?.patient[0].survey_result_linked_to_surgeries) {
      let date = new Date(s.survey_results[0].created_at)
      date.setHours(0,0,0,0)
      if (lastDate.getTime() !== date.getTime())
        lastDate = date
      else
        continue
      let dateString = date.toLocaleDateString(...dateFormat)
      values.push({
        results: s.survey_results.map(x => {
          let ret = x.answer.survey_answer_i18ns.map(x => {
            return x.value
          })[0]
          if (ret === 'true') {
            if (!q[dateString])
              q[dateString] = []
            q[dateString].push(x.question.survey_question_i18ns.map(x => {
              return x.value
            })[0])
          }
          return ret
        }),
        date
      })
    }
    if (values.length === 0)
      return setNoData(true)
    let iDate = new Date()
    // let iDate = new Date(values[0].date) //
    iDate.setHours(0,0,0,0)
    let chartData = []
    let lb = []
    for (let i = 6 ; i >= 0 ; --i) {
      lb[i] = iDate.toLocaleDateString(...dateFormat)
      chartData[i] = values.filter(
        x => x.date.getTime() === iDate.getTime()
      )[0]?.results.reduce(
        (a, b) => a + (b === 'true' ? 1 : 0), 0
      )
      iDate.setDate(iDate.getDate() - 1)
    }
    if (chartData.every(x => x !== undefined))
      setNoData(true)
    setQuestions(q)
    setLabels(lb)
    setSurveyStats(chartData)
  }, [query, patient, surgeryId])

  const options = {
    plugins: {
      tooltip: {
        backgroundColor: '#EFF6F5',
        titleColor: '#095C66',
        bodyColor: '#095C66',
        titleFont: fontOptions,
        bodyFont: fontOptions,
        displayColors: false,
        padding: 30,
        cornerRadius: 20,
        callbacks: {
          title: () => null,
          label: ({ label }) =>
            questions[label]?.length > 0 ? questions[label] : 'Aucun symptôme'
        }
      },
      legend: {
        display: false
      }
    },
    scales: {
      y: {
        ticks: {
          precision: 0,
          color: '#2E666D',
          font: {
            family: 'Raleway',
            size: '18px',
            weight: '500',
            lineHeight: '20px'
          }
        },
        grid: {
          color: '#D5E0E2',
          borderColor: '#2E666D'
        },
        min: 0,
        max: 15,
        beginAtZero: true
      },
      x: {
        ticks: {
          precision: 0,
          color: '#2E666D',
          font: {
            family: 'Raleway',
            size: '18px',
            weight: '500',
            lineHeight: '20px'
          }
        },
        grid: {
          color: '#D5E0E2',
          borderColor: '#2E666D'
        }
      }
    }
  }

  const data = {
    labels: labels,
    datasets: [
      {
        label: 'Nombre de symptômes',
        data: surveyStats,
        borderColor: '#FAA7B1',
        pointRadius: 8,
        pointHoverRadius: 10,
        pointBackgroundColor: '#F76D7D'
      }
    ]
  }

  return (
    <div style={{ minWidth: window.innerWidth / 1.5, minHeight: window.innerHeight / 1.5 }}>
      {noData === false ?
        <Line data={data} legend={{}} options={options} />
        :
        <div style={{ textAlign: 'center' }}>
          <h2 >Aucune donnée disponible</h2>
          <h4 >L'utilisateur n'a rempli aucun formulaire cette semaine.</h4>
        </div>
      }
    </div>
  )
}

export default SymptomChart
