import { toast } from "react-toastify";

export const displayToastNotification = (
  type: "error" | "success",
  content: string,
  autoClose?: number
) => {
  toast(content, {
    position: "top-right",
    autoClose: autoClose ?? 5000,
    hideProgressBar: true,
    closeOnClick: true,
    closeButton: false,
    pauseOnHover: true,
    draggable: false,
    progress: undefined,
    type,
    // transition TODO custom transition
  });
};
