// "posology": "Posologie en pédiatrie\n0,1 à 1 μg/kg/minute (rarement utilisé).",

const def = (kg, ageMonth, type) => {
  return {
    amount_scale: [kg * 0.1, kg * 1],
    unit: "μg/minute",
    comment: "",
  };
};

export default def;
