import { useLazyQuery } from "@apollo/client";
import { Field, Form, Formik } from "formik";
import { loader } from "graphql.macro";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import * as Yup from "yup";
import { APPLICATION_ROLE } from "../../../constants/db_types";
import { checkUserEmail } from "../../../utils/auth";
import { validateCellPhoneNumber } from "../../../utils/validateCellPhoneNumber";
import CustomButton from "../../CustomButton/CustomButton";
import { DatePickerForm } from "../../Form/DatePickerForm/DatePickerForm";
import { DropdownForm } from "../../Form/DropdownForm/DropdownForm";
import PhoneInputForm from "../../Form/PhoneInputForm/PhoneInputForm";
import SeparatorSectionForm from "../../Form/SeparatorSectionForm/SeparatorSectionForm";
import { TextInputForm } from "../../Form/TextInputForm/TextInputForm";
import TitleSectionForm from "../../Form/TitleSectionForm/TitleSectionForm";
import CustomContour from "../../Layout/CustomContour/CustomContour";

const getOrganizationSurgeryTypeQuery = loader(
  "../../../graphql/getOrganizationSurgeryType.graphql"
);

interface FormValues {
  name: string;
  surname: string;
  birth_date: string;
  email: string;
  phone: string;
  date: string;
  type: string;
}

type FormMode = "creation" | "update";

interface FormPatientProps {
  title: string;
  mode: FormMode;
  handleSubmit: (values: FormValues) => void;
  initialValues: FormValues;
  organizationId: string;
}

const validationSchema = (mode: FormMode) =>
  Yup.object().shape({
    name: Yup.string()
      .required("Ce champ est requis")
      .min(2, "2 lettres minimum"),
    surname: Yup.string()
      .required("Ce champ est requis")
      .min(2, "2 lettres minimum"),
    birth_date: Yup.string().nullable().required("Ce champ est requis"),
    email: Yup.string().required("Ce champ est requis").email("Email invalide"),
    date: Yup.string().nullable().required("Ce champ est requis"),
    type: Yup.string().required("Ce champ est requis"),
  });

const FormPatient: React.FunctionComponent<FormPatientProps> = ({
  title,
  mode,
  handleSubmit,
  initialValues,
  organizationId,
}) => {
  const [organizationSurgeriesTypes, setOrganizationSurgeriesTypes] = useState(
    []
  );

  const [getOrganizationSurgeryType, { data: organizationSurgeriesTypesData }] =
    useLazyQuery(getOrganizationSurgeryTypeQuery);

  useEffect(() => {
    if (organizationSurgeriesTypesData) {
      const organizationSurgeriesTypesTmp =
        organizationSurgeriesTypesData?.organization_surgery_type?.map(
          (e: { surgery_type: { value: string; id: string } }) => {
            return { label: e.surgery_type?.value, value: e.surgery_type?.id };
          }
        );
      setOrganizationSurgeriesTypes(organizationSurgeriesTypesTmp);
    }
  }, [organizationSurgeriesTypesData]);

  useEffect(() => {
    if (organizationId) {
      getOrganizationSurgeryType({
        variables: { organizationId },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationId]);

  const validateEmail = async (value: string) => {
    let error = "";
    if (value && mode === "creation") {
      const res = await checkUserEmail(value);
      if (res.error) {
        error = res.error;
      } else if (res.role === APPLICATION_ROLE.PRACTITIONER) {
        error = "Cet email est déjà utilisé par un praticien";
      }
    }
    return error;
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={async (values: FormValues, { resetForm }) => {
        await handleSubmit(values);
        if (mode === "creation") {
          resetForm();
        }
      }}
      validationSchema={() => validationSchema(mode)}
      validateOnChange={false}
    >
      {(props) => {
        return (
          <FormContainer>
            <CustomContour title={title}>
              <Section>
                <TitleSectionForm title="Informations concernant l'enfant" />
                <FieldSection>
                  <FieldContainer>
                    <Field
                      name={"name"}
                      label={"Prénom"}
                      placeholder={"Prénom"}
                      component={TextInputForm}
                    />
                  </FieldContainer>
                  <FieldContainer>
                    <Field
                      name={"surname"}
                      label={"Nom"}
                      placeholder={"Nom"}
                      component={TextInputForm}
                    />
                  </FieldContainer>
                  <FieldContainer>
                    <Field
                      name={"birth_date"}
                      label={"Date de naissance"}
                      placeholder={"jj/mm/aaaa"}
                      component={DatePickerForm}
                    />
                  </FieldContainer>
                </FieldSection>
              </Section>
              <SeparatorContainer>
                <SeparatorSectionForm />
              </SeparatorContainer>
              <Section>
                <TitleSectionForm title="Informations concernant le parent" />
                <FieldSection>
                  <FieldContainer>
                    <Field
                      name={"phone"}
                      label={"Téléphone Portable"}
                      placeholder={"06 XX XX XX XX"}
                      component={PhoneInputForm}
                      validate={validateCellPhoneNumber}
                    />
                  </FieldContainer>
                  <FieldContainer>
                    <Field
                      name={"email"}
                      label={"Email"}
                      placeholder={"Email"}
                      component={TextInputForm}
                      disabled={mode === "update"}
                      validate={validateEmail}
                    />
                  </FieldContainer>
                </FieldSection>
              </Section>
              <SeparatorContainer>
                <SeparatorSectionForm />
              </SeparatorContainer>
              <Section>
                <TitleSectionForm title="Informations concernant l'opération" />
                <FieldSection>
                  <FieldContainer>
                    <Field
                      name={"date"}
                      label={"Date de l'intervention"}
                      placeholder={"jj/mm/aaaa"}
                      component={DatePickerForm}
                    />
                  </FieldContainer>
                  <FieldContainer>
                    <Field
                      name={"type"}
                      label={"Type d'intervention"}
                      placeholder={"Sélectionnez le type d'intervention"}
                      component={DropdownForm}
                      data={organizationSurgeriesTypes}
                    />
                  </FieldContainer>
                </FieldSection>
              </Section>
            </CustomContour>
            <ButtonContainer>
              <CustomButton
                type="submit"
                content={
                  mode === "creation"
                    ? "Enregistrer le patient"
                    : "Sauvegarder les modifications"
                }
                disabled={props.isSubmitting}
              />
            </ButtonContainer>
          </FormContainer>
        );
      }}
    </Formik>
  );
};

export default FormPatient;

const FormContainer = styled(Form)``;

const Section = styled.div`
  width: 100%;
`;

const FieldSection = styled.div`
  width: 100%;
  display: flex;
  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.tabletMax}) {
    flex-direction: column;
  }
`;

const SeparatorContainer = styled.div`
  margin: 40px 0;
`;

const FieldContainer = styled.div`
  width: calc(100% / 3);
  margin: 0 8px;

  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.tabletMax}) {
    width: 100%;
    margin: 8px 0;
  }
`;

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 30px;
`;
