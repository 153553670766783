import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useDropzone } from "react-dropzone";
import styled from "styled-components";
import { ThemeComponents } from "../../theme/components";
import { BodyMain } from "../../theme/fonts";

type Props = {
  label?: string;
  sublabel?: string;
  placeholder?: string;
  form: any;
  field: any;
  currentFileName: string;
  acceptFile?: string[];
};

const Dropzone: React.FunctionComponent<Props> = ({
  label,
  sublabel,
  placeholder,
  form,
  field,
  currentFileName,
  acceptFile,
}) => {
  const [currentFile, setCurrentFile] = useState<
    null | File | { path: string }
  >(null);
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    multiple: false,
    accept: acceptFile ? acceptFile : "application/pdf",
  });

  const error = form.errors[field.name];
  const touched = form.touched[field.name]; //TODO

  useEffect(() => {
    if (currentFileName) {
      setCurrentFile({
        /* 
          Here we truncate the full name for only display the original name
          36: Uuid length
          1: /
        */
        path: currentFileName.substring(36 + 1),
      });
    }
  }, [currentFileName]);

  useEffect(() => {
    if (acceptedFiles && acceptedFiles.length > 0) {
      setCurrentFile(acceptedFiles[0]);
      form.setFieldValue("fileToUpload", acceptedFiles[0]);
      form.setFieldValue("fileToUploadIsSet", true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [acceptedFiles]);

  return (
    <DropzoneContainer>
      <DropzoneRow noGutters>
        <Col xs={12}>
          <DropzoneLabelContainer>
            <DropzoneLabel>{label}</DropzoneLabel>
            <DropzoneSubLabel>{sublabel}</DropzoneSubLabel>
          </DropzoneLabelContainer>
        </Col>
        <Col xs={12}>
          <div
            {...getRootProps({ className: "container py-5" })}
            style={{
              border:
                touched && error ? "1px solid #CB3434" : "1px dashed #2E666D",
              boxSizing: "border-box",
              borderRadius: "5px",
              height: "200px",
              background: "#D5E0E2",
            }}
          >
            <input {...getInputProps()} />
            <span style={{ color: "black", textAlign: "center" }}>
              {currentFile ? (
                <div>
                  <div
                    style={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {(currentFile as any).path}
                  </div>
                </div>
              ) : (
                <Placeholder>
                  <div>{placeholder}</div>
                </Placeholder>
              )}
            </span>
          </div>
          {touched && error && <DropzoneError>{error}</DropzoneError>}
        </Col>
      </DropzoneRow>
    </DropzoneContainer>
  );
};

// const DropdownError = styled(ThemeComponents.FormErrorLabel)``;
//TODO component for error

const DropzoneContainer = styled(Container)`
  width: 100%;
  display: flex;
  flex-direction: row;
  margin: 16px 0;
  padding: 0;
`;

const DropzoneRow = styled(Row)`
  width: 100%;
`;

const DropzoneLabelContainer = styled.div`
  margin: 5px 15px 5px 0;
`;

const DropzoneLabel = styled(ThemeComponents.FormLabel)``;

const DropzoneSubLabel = styled(ThemeComponents.FormSubLabel)``;

const DropzoneError = styled(ThemeComponents.FormErrorLabel)`
  top: 205px !important;
`;

const Placeholder = styled.div`
  ${BodyMain}
`;

export default Dropzone;
