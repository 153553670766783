import React from "react";
import styled from "styled-components";
import { ReactComponent as KoalouLogo } from "../../../theme/images/koalouLogoInlineDark.svg";

interface AuthCardLogoProps {}

const AuthCardLogo: React.FC<AuthCardLogoProps> = () => {
  return (
    <KoalouLogoContainer>
      <KoalouLogo style={{ width: "100px", height: "45px" }} />
    </KoalouLogoContainer>
  );
};

export default AuthCardLogo;

const KoalouLogoContainer = styled.div`
  width: 100%;
  margin-bottom: 10px;
`;
