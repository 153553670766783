import React, { useCallback, useContext, useState } from "react";
import { useEffect } from "react";
import styled from "styled-components";
import { PageWrapper } from "../../components/Layout/PageWrapper/PageWrapper";
import CustomList from "../../components/List/CustomList/CustomList";
import {
  CardStyle2,
  LinearGradientText,
} from "../../theme/components/ThemeComponents";
import { Heading3, Heading4 } from "../../theme/fonts";
import { loader } from "graphql.macro";
import { useLazyQuery, useMutation } from "@apollo/client";
import ApplicationContext from "../../utils/context";
import moment from "moment";
import { ReactComponent as IconArchive } from "../../theme/icons/archive.svg";
import { displayToastNotification } from "../../utils/toastNotification";
import { Modal, ModalBody } from "reactstrap";
import PdfViewer from "../../components/PdfViewer";
import { ESIGN_FILE_TYPE } from "../../constants/db_types";

const getEsignDocumentsCountQuery = loader(
  "../../graphql/esign/getEsignDocumentsCountByOrganizationId.graphql"
);

const getEsignDocumentsWithLimitOffsetQuery = loader(
  "../../graphql/esign/getEsignDocumentsWithLimitOffset.graphql"
);

const updateEsignDocumentIsArchivedQuery = loader(
  "../../graphql/esign/updateEsignDocumentIsArchived.graphql"
);

interface EsignStatusProps {}

const STATUS_TYPE = {
  AWAITING_SIGNATURES: "awaiting_signatures",
  SIGNED: "signed",
  ARCHIVED: "archived",
};

const columns = [
  { value: "Nom", ratio: "15%", type: "string" },
  { value: "Prénom", ratio: "15%", type: "string" },
  { value: "Intervention", ratio: "20%", type: "string" },
  { value: "Date de l'intervention", ratio: "20%", type: "string" },
  { value: "Modèle", ratio: "20%", type: "string" },
  { value: "", ratio: "10%", type: "component" },
];

const limit = 10;

const EsignStatus: React.FC<EsignStatusProps> = () => {
  const { currentOrganizationCxt } = useContext<any>(ApplicationContext);

  const [pagesNumber, setPagesNumber] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("%%");
  const [searchBarValue, setSearchBarValue] = useState("");
  const [statusType, setStatusType] = useState(STATUS_TYPE.AWAITING_SIGNATURES);

  const [documents, setDocuments] = useState<Array<{
    customData: {
      id: string;
      filePath: string;
    };
    rowValues: Array<string>;
  }> | null>(null);

  const [modalDocument, setModalDocument] = useState({
    show: false,
    documentName: "",
  });

  const [getEsignDocumentsCount, { data: dataEsignDocumentsCount }] =
    useLazyQuery(getEsignDocumentsCountQuery, { fetchPolicy: "network-only" });

  const [getEsignDocumentsWithLimitOffset, { data: dataEsignDocuments }] =
    useLazyQuery(getEsignDocumentsWithLimitOffsetQuery, {
      fetchPolicy: "network-only",
    });

  const [updateEsignDocumentIsArchived] = useMutation(
    updateEsignDocumentIsArchivedQuery
  );

  const fetchEsignDocuments = useCallback(() => {
    if (currentOrganizationCxt?.currentOrganizationToEdit?.id) {
      getEsignDocumentsWithLimitOffset({
        variables: {
          limit,
          offset: currentPage * limit - limit,
          search,
          organizationId: currentOrganizationCxt?.currentOrganizationToEdit?.id,
          isArchived: statusType === STATUS_TYPE.ARCHIVED,
          isFilePathNull: statusType === STATUS_TYPE.AWAITING_SIGNATURES,
        },
      });
    }
  }, [
    statusType,
    currentPage,
    currentOrganizationCxt?.currentOrganizationToEdit,
    getEsignDocumentsWithLimitOffset,
    search,
  ]);

  const fetchEsignDocumentsCount = useCallback(() => {
    if (currentOrganizationCxt?.currentOrganizationToEdit?.id) {
      getEsignDocumentsCount({
        variables: {
          organizationId: currentOrganizationCxt.currentOrganizationToEdit.id,
          search,
          isArchived: statusType === STATUS_TYPE.ARCHIVED,
          isFilePathNull: statusType === STATUS_TYPE.AWAITING_SIGNATURES,
        },
      });
    }
  }, [
    statusType,
    currentOrganizationCxt?.currentOrganizationToEdit,
    getEsignDocumentsCount,
    search,
  ]);

  useEffect(() => {
    fetchEsignDocumentsCount();
  }, [fetchEsignDocumentsCount]);

  /* Calculate number of pages */
  useEffect(() => {
    if (dataEsignDocumentsCount) {
      const documentsCount = dataEsignDocumentsCount.esign_document.length;
      let pagesNumberTmp = Math.ceil(documentsCount / limit);
      setPagesNumber(pagesNumberTmp);
    }
  }, [dataEsignDocumentsCount]);

  useEffect(() => {
    fetchEsignDocuments();
  }, [fetchEsignDocuments]);

  useEffect(() => {
    if (dataEsignDocuments) {
      const documentsTmp = dataEsignDocuments.esign_document.map((e: any) => {
        return {
          customData: {
            id: e.id,
            filePath: e.file_path,
          },
          rowValues: [
            e.patient.surname,
            e.patient.name,
            e.surgery.surgery_type.value,
            moment(e.surgery.date).format("DD/MM/YYYY"),
            e.esign_template.name,
            <ArchivedButtonContainer active={statusType === STATUS_TYPE.SIGNED}>
              <ArchiveButtonText
                onClick={(event) => {
                  event.stopPropagation();
                  handleClickArchiveDocument(e.id);
                }}
              >
                Archiver
              </ArchiveButtonText>
              <IconArchive style={{ width: "12px", height: "12px" }} />
            </ArchivedButtonContainer>,
          ],
        };
      });
      setDocuments(documentsTmp);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataEsignDocuments]);

  const handleChangeCurrentPage = (value: number) => {
    if (value > 0 && value <= pagesNumber && value !== currentPage) {
      setCurrentPage(value);
    }
  };

  const handleFilter = (value: string) => {
    setSearch("%" + value + "%");
    setCurrentPage(1);
  };

  const getTitleStatus = () => {
    if (statusType === STATUS_TYPE.AWAITING_SIGNATURES)
      return "Liste des documents en attente de signature";
    else if (statusType === STATUS_TYPE.SIGNED)
      return "Liste des documents signés";
    else if (statusType === STATUS_TYPE.ARCHIVED)
      return "Liste des documents archivés";
    else return "";
  };

  const handleClickArchiveDocument = async (esignDocumentId: string) => {
    try {
      await updateEsignDocumentIsArchived({ variables: { esignDocumentId } });
      fetchEsignDocumentsCount();
      fetchEsignDocuments();
      displayToastNotification("success", "Le document a bien été archivé");
    } catch (err) {
      console.error(err);
      displayToastNotification(
        "error",
        "Une erreur est survenue lors de l'archivage"
      );
    }
  };

  const showDocumentPDF = (i: number) => {
    if (
      statusType === STATUS_TYPE.SIGNED ||
      statusType === STATUS_TYPE.ARCHIVED
    ) {
      setModalDocument({
        show: true,
        documentName: documents![i].customData.filePath,
      });
    }
  };
  return (
    <PageWrapper>
      <Container>
        <StatusTabContainer>
          <StatusTabItem
            onClick={() => setStatusType(STATUS_TYPE.AWAITING_SIGNATURES)}
            active={statusType === STATUS_TYPE.AWAITING_SIGNATURES}
          >
            Documents en attente de signature
          </StatusTabItem>
          <StatusTabItem
            onClick={() => setStatusType(STATUS_TYPE.SIGNED)}
            active={statusType === STATUS_TYPE.SIGNED}
          >
            Documents signés
          </StatusTabItem>
          <StatusTabItem
            onClick={() => setStatusType(STATUS_TYPE.ARCHIVED)}
            active={statusType === STATUS_TYPE.ARCHIVED}
          >
            Documents archivés
          </StatusTabItem>
        </StatusTabContainer>
        <ListContainer>
          <CustomList
            title={getTitleStatus()}
            columns={columns}
            data={documents}
            pagesNumber={pagesNumber}
            currentPage={currentPage}
            handleChangeCurrentPage={handleChangeCurrentPage}
            displaySearchBar={true}
            searchBarValue={searchBarValue}
            setSearchBarValue={(v: string) => setSearchBarValue(v)}
            handleSearch={(v: string) => handleFilter(v)}
            handleClick={(i: number) => showDocumentPDF(i)}
          />
        </ListContainer>

        <ModalContainer
          isOpen={modalDocument.show}
          className="reactstrap-modal-full-size"
        >
          <ModalBody>
            <PdfViewer
              toggle={() => setModalDocument({ show: false, documentName: "" })}
              documentName={modalDocument.documentName}
              fileCategory={ESIGN_FILE_TYPE.DOCUMENT}
            />
          </ModalBody>
        </ModalContainer>
      </Container>
    </PageWrapper>
  );
};

export default EsignStatus;

const Container = styled.div`
  height: 100%;
  max-width: 1030px;
  min-width: 566px;
  margin: 0 auto;
`;

const ListContainer = styled.div`
  ${CardStyle2}
`;

const StatusTabContainer = styled.div`
  width: 100%;
  display: flex;
  border-radius: 12px;
  background-color: ${(props) => props.theme.colors.darkGreenL1};
  user-select: none;
  margin-bottom: 30px;
`;

const StatusTabItem = styled.div<{ active: boolean }>`
  width: calc(100% / 3);
  text-align: center;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  background: ${(props) =>
    props.active
      ? `linear-gradient(135.02deg, #095C66 0%, #209AA2 100%);`
      : props.theme.colors.darkGreenL1};
  color: ${(props) =>
    props.active ? props.theme.colors._white : props.theme.colors.blackMain};
  padding: 14px 0;
  border-radius: 12px;
  ${Heading3}
  @media screen and (max-width: ${(props) =>
    props.theme.breakpoints.laptop13Max}) {
    ${Heading4}
  }
`;

const ArchivedButtonContainer = styled.div<{ active: boolean }>`
  display: ${({ active }) => (active ? "flex" : "none")};
  align-items: center;
`;

const ArchiveButtonText = styled.div`
  margin-right: 10px;
  cursor: pointer;
  ${LinearGradientText};
`;

const ModalContainer = styled(Modal)``;
