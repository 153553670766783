import React from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import CustomButton from "../../components/CustomButton/CustomButton";
import PageWrapperNoAuth from "../../components/Layout/PageWrapperNoAuth/PageWrapperNoAuth";
import { CardStyle1 } from "../../theme/components/ThemeComponents";
import { Heading3, Heading4 } from "../../theme/fonts";

const SessionExpired = () => {
  const history = useHistory();

  return (
    <PageWrapperNoAuth displayLogo={true}>
      <Container>
        <Title>Vous avez été déconnecté en toute sécurité.</Title>
        <Title>
          Pour des raisons de sécurité, vos sessions Koalou se terminent après 2
          heures.
        </Title>
        <Title>Vous avez donc été automatiquement déconnecté.</Title>

        <ButtonContainer>
          <CustomButton
            handleOnClick={() => history.push("/login")}
            content="Se connecter"
          />
        </ButtonContainer>
      </Container>
    </PageWrapperNoAuth>
  );
};

const Container = styled.div`
  ${CardStyle1}
  width: 724px;
  height: 393px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.laptop13Max}) {
    width: 540px;
    height: 323px;
    padding: 0 40px;
  }

  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.mobileLandscapeMax}) {
    width: 100%;
  }
`;

const Title = styled.div`
  ${Heading3}
  margin-bottom: 20px;
  text-align: center;

  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.laptop13Max}) {
    ${Heading4}
  }

  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.mobilePortraitMax}) {
    ${Heading3}
  }
`;

const ButtonContainer = styled.div`
  margin-top: 15px;
`;

export default SessionExpired;
