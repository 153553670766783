export const COOKIES = {
  TOKEN: "koalou-pro-token",
  ID: "koalou-pro-id",
  ROLE: "koalou-pro-role",
  ALLOWED_ROLES: "koalou-pro-allowed-roles",
  EMAIL: "koalou-pro-email",
  EXPIRATION_SESSION: "koalou-pro-expiration-session",
};

//TODO do the same for local storage variables
