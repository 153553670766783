import { useLazyQuery } from "@apollo/client";
import moment from "moment";
import React, { useEffect, useState, useContext } from "react";
import styled from "styled-components";
import { loader } from "graphql.macro";
import { useHistory } from "react-router-dom";
import ApplicationContext from "../../utils/context";
import { theme } from "../../theme";
import {
  BodyMain,
  Heading2,
  Heading3,
  Heading4,
  Heading5,
} from "../../theme/fonts";
import { ReactComponent as IconArrowLeft } from "../../theme/icons/arrowLeft.svg";
import { ReactComponent as IconArrowRight } from "../../theme/icons/arrowRight.svg";
import SeparatorSectionForm from "../Form/SeparatorSectionForm/SeparatorSectionForm";
import { ReactComponent as EllipseImage } from "../../theme/images/ellipse.svg";
import { ReactComponent as IconArrowDownFlat } from "../../theme/icons/arrowDownFlat.svg";
import { ReactComponent as IconArrowUpFlat } from "../../theme/icons/arrowUpFlat.svg";
import Dropdown from "../Dropdown/Dropdown";

const CALENDAR_TYPE = { DAILY_VIEW: "daily-view", WEEKLY_VIEW: "weekly-view" };

const dropdownOptions = [
  { value: CALENDAR_TYPE.DAILY_VIEW, label: "Vue journalière" },
  { value: CALENDAR_TYPE.WEEKLY_VIEW, label: "Vue hebdomadaire" },
];

const getSurgeriesByDateQuery = loader(
  "../../graphql/getSurgeriesByDate.graphql"
);

interface CalendarProps {
  selectedDay: Date;
  setSelectedDay: (date: Date) => void;
  selectedWeek?: string[];
  setSelectedWeek?: (date: string[]) => void;
  displayEllipse?: boolean;
  displayPatients?: boolean;
  handleView?: (data: any) => void;
  subData?: {
    time: string;
    fullname: string;
    surgery: string;
    date: string;
    phone: string;
  }[][];
  clickable?: boolean;
  singleView?: boolean;
}

const formatWeekInterval = (date: Date) => {
  const weekInterval = `${moment(date)
    .subtract(2, "days")
    .format("DD")} - ${moment(date).add(2, "days").format("DD")} ${moment(date)
    .add(2, "days")
    .format("MMM")}`;
  return weekInterval;
};

export const Calendar: React.FC<CalendarProps> = ({
  selectedDay,
  setSelectedDay,
  selectedWeek,
  setSelectedWeek,
  displayEllipse,
  displayPatients,
  handleView,
  subData,
  clickable,
  singleView,
}) => {
  const history = useHistory();
  const { patientCxt, currentOrganizationCxt } =
    useContext<any>(ApplicationContext);
  const [state, setState] = useState<{
    currentYear: string;
    currentMonth: string;
    currentDay: Date;
    currentIndex: number;
    currentWeek: string;
  }>({
    currentYear: "",
    currentMonth: "",
    currentDay: new Date(),
    currentIndex: 0,
    currentWeek: "",
  });

  const [numberOfDays, setNumberOfDay] = useState(3);
  const [currentCalendarType, setCurrentCalendarType] = useState<{
    value: string;
    label: string;
  }>(dropdownOptions[0]);

  const [getSurgeriesByDate, { data: surgeriesByDate, loading }] = useLazyQuery(
    getSurgeriesByDateQuery
    // { fetchPolicy: "network-only" }
  );

  useEffect(() => {
    const r = /([0-9]{1,2})\s*[^0-9\s]*\s*([0-9]{1,2})\s*(.*)/g.exec(
      state.currentWeek
    );
    if (setSelectedWeek && r) setSelectedWeek(r.slice(1) || []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  useEffect(() => {
    const updateNumberOfDays = () => {
      if (window.innerWidth < parseInt(theme.breakpoints.tabletMin)) {
        setNumberOfDay(5);
      } else if (window.innerWidth > parseInt(theme.breakpoints.tabletMin)) {
        setNumberOfDay(7);
      }
    };

    window.addEventListener("resize", updateNumberOfDays);
    updateNumberOfDays();

    return () => window.removeEventListener("resize", updateNumberOfDays);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (currentOrganizationCxt.currentOrganizationToEdit.id) {
      const dateMin = moment(state.currentDay).subtract(2, "days").toDate();
      const dateMax = moment(state.currentDay).add(2, "days").toDate();
      getSurgeriesByDate({
        variables: {
          dateMin: dateMin,
          dateMax: dateMax,
          organizationId: currentOrganizationCxt.currentOrganizationToEdit.id,
        },
      });
    }
  }, [
    state.currentDay,
    getSurgeriesByDate,
    currentOrganizationCxt.currentOrganizationToEdit,
  ]);

  useEffect(() => {
    setState({
      currentYear: moment(selectedDay).format("YYYY"),
      currentMonth: moment(selectedDay).format("MMMM"),
      currentDay: selectedDay,
      currentIndex: 3,
      currentWeek: formatWeekInterval(selectedDay),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCalendarType]);

  const next = (type: "days" | "month" | "year" | "week") => {
    let nextCurrentDay = null;
    if (type === "week") {
      nextCurrentDay = moment(state.currentDay).add(5, "days");
    } else {
      nextCurrentDay = moment(state.currentDay).add(1, type);
    }
    setState({
      ...state,
      currentDay: nextCurrentDay.toDate(),
      currentYear: nextCurrentDay.format("YYYY"),
      currentMonth: nextCurrentDay.format("MMMM"),
      currentWeek: formatWeekInterval(nextCurrentDay.toDate()),
    });
  };

  const prev = (type: "days" | "month" | "year" | "week") => {
    let prevCurrentDay = null;
    if (type === "week") {
      prevCurrentDay = moment(state.currentDay).subtract(5, "days");
    } else {
      prevCurrentDay = moment(state.currentDay).subtract(1, type);
    }
    setState({
      ...state,
      currentDay: prevCurrentDay.toDate(),
      currentYear: prevCurrentDay.format("YYYY"),
      currentMonth: prevCurrentDay.format("MMMM"),
      currentWeek: formatWeekInterval(prevCurrentDay.toDate()),
    });
  };

  const renderDays = () => {
    const range = (start: number, end: number) =>
      [...Array(end - start + 1)].map((el, ind) => ind + start);

    return (
      <DaysContainer>
        {range(0, numberOfDays - 1).map((value, index) => {
          let date = moment(state.currentDay);
          const middle = Math.floor(numberOfDays / 2);
          if (index < middle) {
            date = moment(state.currentDay).subtract(middle - index, "days");
          } else if (index > middle) {
            date = moment(state.currentDay).add(index - middle, "days");
          }
          return (
            <DayBlock
              key={index}
              selected={index === middle}
              onClick={() => {
                setState({
                  ...state,
                  currentIndex: index,
                  currentDay: date.toDate(),
                  currentYear: date.format("YYYY"),
                  currentMonth: date.format("MMMM"),
                });
                setSelectedDay(date.toDate());
              }}
            >
              <>
                <DayNumberItem selected={index === middle}>
                  {date.format("D")}
                </DayNumberItem>
                {/* <DayStringItem>
                    {date.format("ddd").toUpperCase().slice(0, -1)}
                  </DayStringItem> */}
              </>
            </DayBlock>
          );
        })}
      </DaysContainer>
    );
  };

  const renderHeader = () => {
    return (
      <HeaderContainer>
        <DropdownCalendarTypeContainer>
          {!singleView && (
            <DropdownContainer>
              <Dropdown
                options={dropdownOptions}
                defaultValue={currentCalendarType}
                handleOnChange={(data) => {
                  setCurrentCalendarType(data);
                  handleView && handleView(data);
                }}
              />
            </DropdownContainer>
          )}
        </DropdownCalendarTypeContainer>
        <CurrentYearMonthWeekContainer>
          <CurrentYearMonthWeekSubContainer>
            <IconYearMonthWeekContainer onClick={() => prev("year")}>
              <IconArrowLeft style={{ width: "8px", height: "15px" }} />
            </IconYearMonthWeekContainer>
            <CurrentYearText>{state.currentYear}</CurrentYearText>
            <IconYearMonthWeekContainer onClick={() => next("year")}>
              <IconArrowRight style={{ width: "8px", height: "15px" }} />
            </IconYearMonthWeekContainer>
          </CurrentYearMonthWeekSubContainer>
          {currentCalendarType.value === CALENDAR_TYPE.DAILY_VIEW && (
            <CurrentYearMonthWeekSubContainer>
              <IconYearMonthWeekContainer onClick={() => prev("month")}>
                <IconArrowLeft style={{ width: "11px", height: "18px" }} />
              </IconYearMonthWeekContainer>
              <CurrentMonthText>{state.currentMonth}</CurrentMonthText>
              <IconYearMonthWeekContainer onClick={() => next("month")}>
                <IconArrowRight style={{ width: "11px", height: "18px" }} />
              </IconYearMonthWeekContainer>
            </CurrentYearMonthWeekSubContainer>
          )}
          {currentCalendarType.value === CALENDAR_TYPE.WEEKLY_VIEW && (
            <CurrentYearMonthWeekSubContainer>
              <IconYearMonthWeekContainer onClick={() => prev("week")}>
                <IconArrowLeft style={{ width: "11px", height: "18px" }} />
              </IconYearMonthWeekContainer>
              <CurrentMonthText>{state.currentWeek}</CurrentMonthText>
              <IconYearMonthWeekContainer onClick={() => next("week")}>
                <IconArrowRight style={{ width: "11px", height: "18px" }} />
              </IconYearMonthWeekContainer>
            </CurrentYearMonthWeekSubContainer>
          )}
        </CurrentYearMonthWeekContainer>
        <TodayButton
          onClick={() =>
            setState({
              ...state,
              currentDay: new Date(),
              currentYear: moment(new Date()).format("YYYY"),
              currentMonth: moment(new Date()).format("MMMM"),
              currentWeek: formatWeekInterval(new Date()),
            })
          }
        >
          Aller à aujourd'hui
        </TodayButton>
        {currentCalendarType.value === CALENDAR_TYPE.DAILY_VIEW && (
          <DayContainer>
            <div>{renderDays()}</div>
          </DayContainer>
        )}
      </HeaderContainer>
    );
  };

  const handleProfilePatient = (surgery: any) => {
    if (clickable === false) return null;
    //Here we convert surgery object to patient object (because of sort feature (2 march 2021) on surgery value and date)
    const patient = {
      id: surgery.patient?.id,
      birth_date: surgery.patient?.birth_date,
      name: surgery.patient?.name,
      surname: surgery.patient?.surname,
      parent_patients: [
        {
          parent: surgery.patient?.parent_patients?.[0]?.parent,
        },
      ],
      surgeries: [
        {
          id: surgery.id,
          date: surgery.date,
          type: surgery.type,
          organization_id: surgery.organization_id,
          organization: surgery.organization,
          surgery_type: surgery.surgery_type,
        },
      ],
    };
    patientCxt.setPatientToEdit(patient);
    localStorage.setItem("currentPatient", JSON.stringify(patient));
    history.push("/patients/profile");
  };

  const moovePatientItemWeeklyViewContainerScrollBar = (
    e: any,
    direction: "up" | "down",
    index: number
  ) => {
    const div = document.getElementById("patient-list-weekly-" + index);
    if (!div) return;
    if (direction === "up") {
      div.scrollTop -= 150;
    } else if (direction === "down") {
      div.scrollTop += 150;
    }
    e.preventDefault();
  };

  const renderPatients = () => {
    if (currentCalendarType.value === CALENDAR_TYPE.DAILY_VIEW) {
      return (
        <PatientsContainerDailyView>
          {surgeriesByDate?.surgery
            ?.filter((s: any) => moment(s.date).isSame(state.currentDay, "day"))
            .map((surgery: any, index: number) => {
              return (
                <div key={index} style={{ padding: "0 40px" }}>
                  <PatientBox
                    bottomLine={index !== 0}
                    onClick={() => handleProfilePatient(surgery)}
                  >
                    <PatientSurgeryType>
                      {surgery.surgery_type?.value}
                    </PatientSurgeryType>
                    <PatientNameSurname>
                      {surgery.patient?.surname} {surgery.patient?.name}
                    </PatientNameSurname>
                  </PatientBox>
                  <SeparatorSectionForm />
                </div>
              );
            })}
        </PatientsContainerDailyView>
      );
    } else if (currentCalendarType.value === CALENDAR_TYPE.WEEKLY_VIEW) {
      return (
        <PatientsContainerWeeklyView>
          {Array(5)
            .fill(0)
            .map((_, i) => {
              let med = 2;
              let currentDayInLoop = moment(state.currentDay);
              if (i < med) {
                currentDayInLoop = moment(state.currentDay).subtract(
                  med - i,
                  "days"
                );
              } else if (i > med) {
                currentDayInLoop = moment(state.currentDay).add(
                  i - med,
                  "days"
                );
              }

              const today = moment(currentDayInLoop).isSame(new Date(), "day");
              const surgeries = surgeriesByDate?.surgery?.filter((s: any) =>
                moment(s.date).isSame(currentDayInLoop, "day")
              );
              return (
                <PatientColumnWeeklyView key={i}>
                  <PatientColumnTitleWeeklyView>
                    {currentDayInLoop.format("dddd")}
                  </PatientColumnTitleWeeklyView>
                  <PatientItemWeeklyViewContainer today={today}>
                    <IconArrowContainer
                      position="up"
                      today={today}
                      totalItems={surgeries.length}
                    >
                      <IconArrowUpFlat
                        onClick={(e) =>
                          moovePatientItemWeeklyViewContainerScrollBar(
                            e,
                            "up",
                            i
                          )
                        }
                      />
                    </IconArrowContainer>
                    <ThisDivExistsBecauseOfTheComplexDesign
                      id={`patient-list-weekly-${i}`}
                    >
                      {/* TODO thibaut's fix for call before day not clean and opti but no time to refacto */}
                      {!subData &&
                        surgeries.map((s: any, i: number) => (
                          <PatientItemWeeklyView
                            key={i}
                            today={today}
                            onClick={() => handleProfilePatient(s)}
                          >
                            <PatientItemNameWeeklyView>
                              {s.patient.name.slice(0, 1)}. {s.patient.surname}
                            </PatientItemNameWeeklyView>
                            <PatientItemSurgeryWeeklyView>
                              {s.surgery_type.value}
                            </PatientItemSurgeryWeeklyView>
                          </PatientItemWeeklyView>
                        ))}
                      {subData?.[i]?.map(
                        (
                          s:
                            | {
                                time: string;
                                fullname: string;
                                surgery: string;
                                date: string;
                                phone: string;
                              }
                            | undefined,
                          j: number
                        ) => {
                          if (!s) return <div />;
                          return (
                            <PatientItemWeeklyView
                              key={j}
                              today={today}
                              onClick={() => handleProfilePatient(s)}
                            >
                              <PatientItemNameWeeklyView>
                                {s.time}
                              </PatientItemNameWeeklyView>
                              <PatientItemNameWeeklyView>
                                {s.fullname}
                              </PatientItemNameWeeklyView>
                              <PatientItemSurgeryWeeklyView>
                                {s.surgery}
                              </PatientItemSurgeryWeeklyView>
                              <PatientItemSurgeryWeeklyView>
                                {s.date}
                              </PatientItemSurgeryWeeklyView>
                              <PatientItemSurgeryWeeklyView>
                                {s.phone}
                              </PatientItemSurgeryWeeklyView>
                            </PatientItemWeeklyView>
                          );
                        }
                      )}
                    </ThisDivExistsBecauseOfTheComplexDesign>
                    <IconArrowContainer
                      position="down"
                      today={today}
                      totalItems={surgeries.length}
                    >
                      <IconArrowDownFlat
                        onClick={(e) =>
                          moovePatientItemWeeklyViewContainerScrollBar(
                            e,
                            "down",
                            i
                          )
                        }
                      />
                    </IconArrowContainer>
                  </PatientItemWeeklyViewContainer>
                </PatientColumnWeeklyView>
              );
            })}
        </PatientsContainerWeeklyView>
      );
    } else {
      return null;
    }
  };

  return (
    <Container>
      {renderHeader()}
      {displayEllipse !== false && <EllipseImage style={{ width: "100%" }} />}
      {!loading && displayPatients !== false && renderPatients()}
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
`;

const HeaderContainer = styled.div`
  width: 100%;
`;

const DayContainer = styled.div`
  display: flex;
  padding: 15px 0;
  border-radius: 10px;
  justify-content: center;
  overflow-x: hidden;
`;

const CurrentYearMonthWeekContainer = styled.div`
  text-align: center;
  text-transform: capitalize;
  color: #32325d;
  user-select: none;
`;

const CurrentYearMonthWeekSubContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 10px 0;
`;

const TodayButton = styled.div`
  text-align: center;
  text-decoration: underline;
  cursor: pointer;
  margin-top: 10px;
  ${BodyMain};
  color: ${({ theme }) => theme.colors.blackMain};
`;

const IconYearMonthWeekContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 0 10px;
`;

const DaysContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const DayNumberItem = styled.div<{ selected?: boolean }>`
  ${Heading2};
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(props) =>
    props.selected
      ? props.theme.colors.blackMain
      : props.theme.colors.darkGreenMain};
  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.laptop13Max}) {
    ${Heading3}
  }
`;

// const DayStringItem = styled.div<{ selected?: boolean }>`
//   width: 100%;
//   text-align: center;
//   font-size: 12px;
//   color: #8898aa;
// `;

const DayBlock = styled.div<{ selected: boolean }>`
  margin: 10px 15px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  user-select: none;
  cursor: pointer;

  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.tabletMin}) {
    margin: 0 5px;
  }
`;

const PatientsContainerDailyView = styled.div`
  width: 100%;
  height: 310px;
  overflow-y: auto;
  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.laptop13Max}) {
    height: 270px;
  }
`;

const PatientBox = styled.div<{ bottomLine: boolean }>`
  width: 100%;
  display: flex;
  align-items: baseline;
  cursor: pointer;
  margin-top: 30px;
  margin-bottom: 8px;
  cursor: pointer;
`;

const PatientSurgeryType = styled.div`
  width: 70%;
  ${Heading2};
  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.laptop13Max}) {
    ${Heading3}
  }
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const PatientNameSurname = styled.div`
  width: 30%;
  text-align: center;
  ${Heading3};
  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.laptop13Max}) {
    ${Heading4}
  }
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const CurrentYearText = styled.div`
  ${Heading3}
  @media screen and (max-width: ${(props) =>
    props.theme.breakpoints.laptop13Max}) {
    ${Heading4}
  }
  color: ${(props) => props.theme.colors.blackMain};
`;

const CurrentMonthText = styled.div`
  ${Heading2}
  @media screen and (max-width: ${(props) =>
    props.theme.breakpoints.laptop13Max}) {
    ${Heading3}
  }
  width: 150px;
  text-transform: uppercase;
  color: ${(props) => props.theme.colors.blackMain};
`;

const DropdownCalendarTypeContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  ${Heading4}
  @media screen and (max-width: ${(props) =>
    props.theme.breakpoints.laptop13Max}) {
    ${Heading5}
  }
`;

const DropdownContainer = styled.div`
  width: 170px;
`;

const PatientsContainerWeeklyView = styled.div`
  display: flex;
  width: 100%;
  overflow-x: hidden;
`;

const PatientColumnWeeklyView = styled.div`
  width: calc(20% - 6px);
  margin: 3px;
`;

const PatientColumnTitleWeeklyView = styled.div`
  text-align: center;
  color: ${({ theme }) => theme.colors.darkGreenMain};
  text-transform: capitalize;
  ${Heading3}
  @media screen and (max-width: ${(props) =>
    props.theme.breakpoints.laptop13Max}) {
    ${Heading4}
  }
  padding: 20px 0 10px 0;
`;

const PatientItemWeeklyViewContainer = styled.div<{ today: boolean }>`
  width: 100%;
  position: relative;
  background: ${({ theme, today }) =>
    today
      ? `linear-gradient(135.02deg, #095C66 0%, #209AA2 100%)`
      : theme.colors.lightGreenL1};
  border-radius: 12px;
  height: 330px;
  padding: 20px 10px;
  ${Heading5};

  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.laptop13Max}) {
    height: 285px;
  }

  ::-webkit-scrollbar {
    width: 0; /* Remove scrollbar space */
    background: transparent; /* Optional: just make scrollbar invisible */
  }
`;

const PatientItemWeeklyView = styled.div<{ today: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 60px;
  color: ${({ theme, today }) =>
    today ? theme.colors._white : theme.colors.darkGreenL3};
  border-bottom: 0.5px solid
    ${({ theme, today }) =>
      today ? theme.colors._white : theme.colors.darkGreenL3};
  cursor: pointer;
`;

const PatientItemNameWeeklyView = styled.div`
  width: 100%;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const PatientItemSurgeryWeeklyView = styled.div`
  width: 100%;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ThisDivExistsBecauseOfTheComplexDesign = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: hidden;
  scroll-behavior: smooth;
`;

const IconArrowContainer = styled.div<{
  position: "up" | "down";
  today: boolean;
  totalItems: number;
}>`
  display: ${({ totalItems }) => (totalItems > 5 ? "flex" : "none")};
  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.laptop13Max}) {
    display: ${({ totalItems }) => (totalItems > 4 ? "flex" : "none")};
  }

  justify-content: center;
  position: absolute;
  top: ${({ position }) => (position === "up" ? "8px" : "unset")};
  bottom: ${({ position }) => (position === "down" ? "8px" : "unset")};
  left: 0;
  width: 100%;

  & > svg {
    cursor: pointer;
  }

  & > svg > path {
    stroke: ${({ theme, today }) =>
      today ? theme.colors._white : theme.colors.darkGreenL3};
  }
`;
