import React, { useEffect, useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import styled from "styled-components";
import { ReactComponent as IconClose } from "../../theme/icons/close.svg";
import { downloadOrganizationFile } from "../../utils/gcp-file";
import CustomButton from "../CustomButton/CustomButton";
pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

interface PdfViewerProps {
  toggle: () => void;
  documentName: string;
  fileCategory?: string;
  fileBase64?: string;
  secondCustomButtonText?: string;
  thirdCustomButtonText?: string;
  handleClickSecondCustomButton?: () => void;
  handleClickThirdCustomButton?: () => void;
  onLoadDocumentBase64?: (documentB64: string) => void;
}

const PdfViewer: React.FC<PdfViewerProps> = ({
  toggle,
  documentName,
  fileCategory,
  fileBase64,
  secondCustomButtonText,
  thirdCustomButtonText,
  handleClickSecondCustomButton,
  handleClickThirdCustomButton,
  onLoadDocumentBase64,
}) => {
  const [fileContentBase64, setFileContentBase64] = useState("");
  const [numPages, setNumPages] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function downloadOrganizationFileAsync() {
      try {
        const data = await downloadOrganizationFile(
          documentName,
          fileCategory!
        );
        setFileContentBase64(data.dataBase64);
      } catch (err) {
        console.error(err);
      }
    }
    if (fileBase64) {
      setFileContentBase64(fileBase64);
    } else if (fileCategory) {
      downloadOrganizationFileAsync();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (fileContentBase64 && onLoadDocumentBase64) {
      onLoadDocumentBase64(fileContentBase64);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileContentBase64]);

  function onDocumentLoadSuccess(params: any) {
    setNumPages(params.numPages);
    setLoading(false);
  }

  function computeBase64Prefix(value: string) {
    if (value.startsWith("data:application/pdf;base64,")) return value;
    else return "data:application/pdf;base64," + value;
  }

  /**
   * Creates an anchor element `<a></a>` with
   * the base64 pdf source and a filename with the
   * HTML5 `download` attribute then clicks on it.
   * @param  {string} pdf
   * @return {void}
   */
  function downloadPDF() {
    const linkSource = computeBase64Prefix(fileContentBase64);
    const downloadLink = document.createElement("a");
    const originalDocumentName = documentName.substring(36 + 1); //Uuid length + slash
    downloadLink.href = linkSource;
    downloadLink.download = originalDocumentName;
    downloadLink.click();
  }

  return (
    <Container>
      {fileContentBase64 && (
        <>
          {loading && (
            <LoaderContainer>
              <div className="mb-2">Chargement...</div>
              <div className="loader" />
            </LoaderContainer>
          )}
          <DocumentContainer
            file={computeBase64Prefix(fileContentBase64)}
            onLoadSuccess={onDocumentLoadSuccess}
            loading={""}
          >
            {!loading && (
              <>
                <ButtonContainer>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <CustomButton
                      type="button"
                      handleOnClick={downloadPDF}
                      content="Télécharger"
                      size="sm"
                    />
                    <Separator />
                    {secondCustomButtonText && (
                      <CustomButton
                        type="button"
                        handleOnClick={handleClickSecondCustomButton}
                        content={secondCustomButtonText}
                        size="sm"
                      />
                    )}
                    <Separator />
                    {thirdCustomButtonText && (
                      <CustomButton
                        type="button"
                        handleOnClick={handleClickThirdCustomButton}
                        content={thirdCustomButtonText}
                        size="sm"
                      />
                    )}
                  </div>

                  <IconCloseContainer onClick={toggle}>
                    <IconClose />
                  </IconCloseContainer>
                </ButtonContainer>
                {Array.from(new Array(numPages), (el, index) => (
                  <Page
                    loading={""}
                    key={`page_${index + 1}`}
                    pageNumber={index + 1}
                  />
                ))}
              </>
            )}
          </DocumentContainer>
        </>
      )}
    </Container>
  );
};

const Container = styled.div`
  position: relative;
`;

const DocumentContainer = styled(Document)`
  & > .react-pdf__Page > .react-pdf__Page__canvas {
    margin: auto !important;
  }
`;

const ButtonContainer = styled.div`
  position: fixed;
  top: 50px;
  right: 50px;
  z-index: 1000;
  display: flex;
  align-items: flex-start;
`;

const LoaderContainer = styled.div`
  width: 100%;
  height: calc(100vh - 48px);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const IconCloseContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 20px;
  cursor: pointer;
  & > svg > path {
    stroke: ${({ theme }) => theme.colors.darkGreenMain};
  }
`;
const Separator = styled.div`
  margin: 5px 0;
`;

export default PdfViewer;
