import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { PageWrapper } from "../../components/Layout/PageWrapper/PageWrapper";
import { CardStyle2 } from "../../theme/components/ThemeComponents";
import { loader } from "graphql.macro";
import { useLazyQuery } from "@apollo/client";
import ApplicationContext from "../../utils/context";
import CustomList from "../../components/List/CustomList/CustomList";

const getPractitionersCountQuery = loader(
  "../../graphql/getPractitionersCountByOrganizationId.graphql"
);

const getPractitionersWithLimitOffsetQuery = loader(
  "../../graphql/getPractitionersWithLimitOffset.graphql"
);

const limit = 10;

interface PractitionersListProps {}

const columns = [
  {
    value: "Nom",
    ratio: "20%",
    type: "string",
    sortable: true,
    sortId: "surname",
  },
  {
    value: "Prénom",
    ratio: "20%",
    type: "string",
    sortable: true,
    sortId: "name",
  },
  { value: "Email", ratio: "20%", type: "string" },
  { value: "Téléphone", ratio: "20%", type: "string" },
  { value: "Spécialité", ratio: "20%", type: "string" },
];

const PractitionersList: React.FC<PractitionersListProps> = () => {
  const history = useHistory();

  const [getPractitionersCount, { data: dataPractitionersCount }] =
    useLazyQuery(getPractitionersCountQuery, { fetchPolicy: "network-only" });

  const [getPractitionersWithLimitOffset, { data: dataPractitioners }] =
    useLazyQuery(getPractitionersWithLimitOffsetQuery, {
      fetchPolicy: "network-only",
    });

  const { currentOrganizationCxt, practitionerCxt } =
    useContext<any>(ApplicationContext);

  const [pagesNumber, setPagesNumber] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("%%");
  const [searchBarValue, setSearchBarValue] = useState("");
  const [orderBy, setOrderBy] = useState<any>({
    surname: "desc",
  });
  const [practitioners, setPractitioners] = useState<Array<{
    customData: any;
    rowValues: Array<string>;
  }> | null>(null);

  useEffect(() => {
    if (currentOrganizationCxt?.currentOrganizationToEdit?.id) {
      getPractitionersCount({
        variables: {
          organizationId: currentOrganizationCxt.currentOrganizationToEdit.id,
          search,
        },
      });
    }
  }, [
    currentOrganizationCxt?.currentOrganizationToEdit,
    getPractitionersCount,
    search,
  ]);

  /* Calculate number of pages */
  useEffect(() => {
    if (dataPractitionersCount) {
      const practitionersCount = dataPractitionersCount.practitioner.length;
      let pagesNumberTmp = Math.ceil(practitionersCount / limit);
      setPagesNumber(pagesNumberTmp);
    }
  }, [dataPractitionersCount]);

  useEffect(() => {
    if (currentOrganizationCxt?.currentOrganizationToEdit?.id) {
      getPractitionersWithLimitOffset({
        variables: {
          limit,
          offset: currentPage * limit - limit,
          orderBy,
          search,
          organizationId: currentOrganizationCxt?.currentOrganizationToEdit?.id,
        },
      });
    }
  }, [
    currentPage,
    currentOrganizationCxt?.currentOrganizationToEdit,
    getPractitionersWithLimitOffset,
    search,
    orderBy,
  ]);

  useEffect(() => {
    if (dataPractitioners) {
      const practitionersTmp = dataPractitioners.practitioner.map((e: any) => {
        return {
          customData: {
            ...e,
            rpps: e.rpps ? e.rpps : "",
          },
          rowValues: [
            e.surname,
            e.name,
            e.email,
            e.phone,
            e.practitioner_specializations[0].specialization.name,
          ],
        };
      });
      setPractitioners(practitionersTmp);
    }
  }, [dataPractitioners]);

  const handleChangeCurrentPage = (value: number) => {
    if (value > 0 && value <= pagesNumber && value !== currentPage) {
      setCurrentPage(value);
    }
  };

  const handleFilter = (value: string) => {
    setSearch("%" + value + "%");
    setCurrentPage(1);
  };

  const handleClickPractitioner = (index: number) => {
    //TODO old legacy logic
    const practitioner = practitioners?.[index].customData;
    let practitionerTmp = {
      ...practitioner,
      specialty:
        practitioner?.practitioner_specializations?.[0]?.specialization?.id,
    };
    delete practitionerTmp.practitioner_specializations;
    localStorage.setItem(
      "currentPractitioner",
      JSON.stringify(practitionerTmp)
    );
    practitionerCxt.setPractitionerToEdit(practitionerTmp);
    history.push("/practitioners/update");
  };

  const handleClickSort = (sortId: string) => {
    let direction;
    if (sortId === "surname") {
      direction = orderBy.surname === "asc" ? "desc" : "asc";
      setOrderBy({ surname: direction });
    } else if (sortId === "name") {
      direction = orderBy.name === "asc" ? "desc" : "asc";
      setOrderBy({ name: direction });
    }
  };

  return (
    <PageWrapper>
      <Container>
        <CustomList
          title="Liste des praticiens"
          columns={columns}
          data={practitioners}
          pagesNumber={pagesNumber}
          currentPage={currentPage}
          handleChangeCurrentPage={handleChangeCurrentPage}
          displaySearchBar={true}
          searchBarValue={searchBarValue}
          setSearchBarValue={(v: string) => setSearchBarValue(v)}
          handleSearch={(v: string) => handleFilter(v)}
          handleClick={(i: number) => handleClickPractitioner(i)}
          handleClickSort={(sortId: string) => handleClickSort(sortId)}
        />
      </Container>
    </PageWrapper>
  );
};

export default PractitionersList;

const Container = styled.div`
  height: 100%;
  ${CardStyle2}
  max-width: 1030px;
  min-width: 566px;
  margin: 0 auto;
`;
