export const SURVEY_TYPE_VALUE = {
  ANXIETY_EVALUATION: "anxiety-test",
  PARENT_ANXIETY_EVALUATION: "parent-anxiety-test",
  SYMPTOMATOLOGY: "symptomatology-d-3",
  PAIN_EVALUATION: "pain-test",
  PHBQ_EVALUATION: "phbq",
  MODIFIED_DENTAL_ANXIETY_EVALUATION: "modified-dental-anxiety",
  DENTAL_FIRST_EVALUATION: "dental-first-survey",
  STAI_NOW: "stai-now",
  STAI_GLOBAL: "stai-global",
  COVID_CHECKER: "covid-checker",
};

export const APPLICATION_ROLE = {
  ADMIN: "admin",
  PRACTITIONER: "practitioner",
  PARENT: "parent",
};

export const ORGANIZATION_ROLE = {
  ORG_OWNER: "organization_owner",
  ORG_ADMIN: "organization_admin",
  ORG_MEMBER: "organization_member",
};

export const ORGANIZATION_FILE_TYPE = {
  GUIDELINE: "guideline",
  DOCUMENT: "document",
};

export const ESIGN_FILE_TYPE = {
  DOCUMENT: "esign-signed-document",
  TEMPLATE: "esign-template",
};

export const PRESCRIPTION_FILE_TYPE = {
  PRESCRIPTION: "prescription",
};

//TODO ask product team to exact name for plans and feature
export const ORGANIZATION_PLAN = {
  HOSPITAL_PLAN_A: "hospital-plan-a",
};

export const ORGANIZATION_FEATURE = {
  COVID_CHECKER: "covid-checker",
  ESIGN: "electronic-signature",
  PRESCRIPTIONS: "prescriptions",
  CALL_DAY_BEFORE: "call-day-before",
  CUSTOM_SURVEYS: "custom-surveys",
  DOSES_COMPUTE: "doses-compute",
  ORGANIZATION_DOCUMENTS: "organization-documents",
  ORGANIZATION_GUIDELINES: "organization-guidelines",
};
