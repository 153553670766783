import cookie from "js-cookie";
import { COOKIES } from "../constants/cookies_types";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import * as Sentry from "@sentry/react";
import { APPLICATION_ROLE } from "../constants/db_types";
import { Mixpanel } from "../lib/mixpanel";

// const cookiesExpirationInTwoHour = 2 / 24;

if (!firebase.apps.length) {
  firebase.initializeApp({
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_DATABASE_URL,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_ID,
    measurementId: process.env.REACT_APP_MESUREMENT_ID,
  });
}

const canAccessAdmin = () => {
  const allowedRolesCookie = cookie.get(COOKIES.ALLOWED_ROLES);

  if (!allowedRolesCookie) return undefined;

  const adminRoles = [APPLICATION_ROLE.ADMIN, APPLICATION_ROLE.PRACTITIONER];
  const allowedRoles = JSON.parse(cookie.get(COOKIES.ALLOWED_ROLES));

  return adminRoles.filter((adminRole) => allowedRoles.includes(adminRole))
    .length;
};

const getErrorMessage = (error) => {
  if (
    error.code === "auth/wrong-password" ||
    error.code === "auth/invalid-email"
  ) {
    return { error: "Identifiant / Mot de passe invalide" };
  } else if (error.code === "auth/user-not-found") {
    return { error: "Identifiant / Mot de passe invalide" };
  } else if (error.code === "auth/invalid-action-code") {
    return { error: "Demande de réinitialisation du mot de passe invalide" };
  } else if (error.code === "auth/weak-password") {
    return { error: "Le mot de passe doit contenir au moins 6 caractères" };
  } else {
    console.error(error);
    return { error: "Une erreur est survenue" };
  }
};

const loginWithEmailAndPassword = async (userData) => {
  return new Promise((resolve, reject) => {
    firebase
      .auth()
      .signInWithEmailAndPassword(userData.email, userData.password)
      .then(() => {
        return firebase.auth().currentUser.getIdTokenResult();
      })
      .then((idTokenResult) => {
        if (
          idTokenResult &&
          !idTokenResult.claims["https://hasura.io/jwt/claims"]
        ) {
          reject({
            error:
              " Votre compte est en cours d'activation. \nMerci de réessayer dans quelques minutes.",
          });
        }
        setAuthenticationCookies(idTokenResult, userData.email);
        return idTokenResult;
      })
      .then((idTokenResult) => {
        if (canAccessAdmin()) {
          const hasuraClaims =
            idTokenResult.claims["https://hasura.io/jwt/claims"];
          if (Mixpanel) {
            Mixpanel?.login(
              hasuraClaims["x-hasura-user-id"],
              idTokenResult?.claims.email,
              hasuraClaims["x-hasura-default-role"],
              hasuraClaims["x-hasura-allowed-roles"]
            );
          }
          resolve({ redirect: "SUCCESS" });
        } else {
          resolve({ redirect: "UNAUTHORIZED" });
        }
      })
      .catch((error) => {
        reject(getErrorMessage(error));
      });
  });
};

const setAuthenticationCookies = (idTokenResult, email) => {
  const { token } = idTokenResult;
  const hasuraClaim = idTokenResult.claims["https://hasura.io/jwt/claims"];

  if (!hasuraClaim) return;

  cookie.set(COOKIES.TOKEN, token, { secure: true });
  cookie.set(COOKIES.ID, hasuraClaim["x-hasura-user-id"], { secure: true });
  cookie.set(COOKIES.ROLE, hasuraClaim["x-hasura-default-role"], {
    secure: true,
  });
  cookie.set(COOKIES.EMAIL, email, { secure: true });
  cookie.set(
    COOKIES.ALLOWED_ROLES,
    JSON.stringify(hasuraClaim["x-hasura-allowed-roles"]),
    { secure: true }
  );
  cookie.set(
    COOKIES.EXPIRATION_SESSION,
    (Date.now() + 1000 * 60 * 60 * 2).toString(), //TWO HOURs
    { secure: true }
  );
};

const sendResetPasswordLink = (userData) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await fetch(
        `${process.env.REACT_APP_AUTH_SERVER_URL}/reset-password`,
        {
          headers: {
            "Content-Type": "application/json",
          },
          method: "POST",
          body: JSON.stringify({
            email: userData.email,
            type: APPLICATION_ROLE.PRACTITIONER,
          }),
        }
      );
      if (res.ok) {
        resolve({
          successMessage:
            "Un email contenant les instructions de réinitialisation a été envoyé",
        });
      } else {
        const resJson = await res.json();
        reject(getErrorMessage(resJson.error));
      }
    } catch (err) {
      reject(getErrorMessage(err));
    }
  });
};

const changeFirebasePassword = (userData, actionCode) => {
  return new Promise(async (resolve, reject) => {
    if (!actionCode) {
      reject({ error: "Code invalide" });
    }
    return firebase
      .auth()
      .verifyPasswordResetCode(actionCode)
      .then(() => {
        firebase
          .auth()
          .confirmPasswordReset(actionCode, userData.password)
          .then(() => {
            resolve({ successMessage: "Nouveau mot de passe enregistré" });
          })
          .catch((err) => {
            console.error(err);
            reject(getErrorMessage(err));
          });
      })
      .catch((err) => {
        console.error(err);
        Sentry.captureException(err);
        reject(getErrorMessage(err));
      });
  });
};

const clearCookies = () => {
  Object.entries(COOKIES).map((e) => {
    cookie.remove(e[1], { secure: true });
    return null;
  });
};

const logout = async (path) => {
  clearCookies();
  if (Mixpanel) {
    Mixpanel.logout();
  }
  //TODO localStorage list value
  localStorage.clear();
  if (path) {
    window.location.href = path;
  }
  Sentry.setContext("koalou user", {});
  await firebase.auth().signOut();
};

const getFirebaseToken = async () => {
  return await firebase
    .auth()
    .currentUser.getIdToken()
    .then((token) => {
      return token;
    })
    .catch((err) => {
      console.error(err);
      if (err) throw err;
    });
};

const checkIfCookiesAreSet = () => {
  let inc = 0;
  let defaultCookiesNbr = 0;
  Object.entries(COOKIES).map((e) => {
    defaultCookiesNbr++;
    if (cookie.get(e[1])) {
      inc++;
    }
    return null;
  });
  return inc === defaultCookiesNbr;
};

const signup = async (email, password) => {
  try {
    const res = await fetch(
      `${process.env.REACT_APP_AUTH_SERVER_URL}/signup-pro`,
      {
        headers: {
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify({
          email,
          password,
        }),
      }
    );
    const resJson = await res.json();
    if (resJson.error === "auth/weak-password") {
      return {
        error: "Mot de passe non valide",
        type: "password",
      };
    } else if (resJson.error === "auth/email-already-in-use") {
      return {
        error: "L'adresse email est déjà utilisée",
      };
    } else if (resJson.error) {
      return { error: "Une erreur est survenue" };
    } else {
      return { msg: "Votre compte a bien été créé" };
    }
  } catch (err) {
    Sentry.captureException(err);
    return { error: "Une erreur est survenue" };
  }
};

const checkUserEmail = async (email) => {
  try {
    const res = await fetch(
      `${process.env.REACT_APP_AUTH_SERVER_URL}/check-user-email-signup`,
      {
        headers: {
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify({
          email,
        }),
      }
    );
    if (res.status === 500) {
      return {
        error: "Erreur. Veuillez contacter Koalou pour obtenir de l'aide",
        role: null,
      };
    } else {
      const resJson = await res.json();
      if (resJson.error) {
        return { error: "Email invalide" };
      } else {
        return { role: resJson.role };
      }
    }
  } catch (err) {
    return {
      error: "Erreur. Veuillez contacter Koalou pour obtenir de l'aide",
      role: null,
    };
  }
};

const validatePassword = (password) => {
  const regex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)[A-Za-z\d@$!%*#?&^_-]{6,}$/;
  if (regex.test(password)) {
    return true;
  } else {
    return false;
  }
};

export {
  loginWithEmailAndPassword,
  sendResetPasswordLink,
  changeFirebasePassword,
  clearCookies,
  logout,
  getFirebaseToken,
  checkIfCookiesAreSet,
  signup,
  checkUserEmail,
  validatePassword,
};
