import { useMutation } from "@apollo/client";
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import FormOrganizationGuideline from "../../components/FormTemplates/OrganizationGuideline/FormOrganizationGuideline";
import { PageWrapper } from "../../components/Layout/PageWrapper/PageWrapper";
import { Loader } from "../../components/Loader/Loader";
import { ORGANIZATION_FILE_TYPE } from "../../constants/db_types";
import ApplicationContext from "../../utils/context";
import {
  deleteOrganizationFile,
  uploadOrganizationFile,
} from "../../utils/gcp-file";
import { loader } from "graphql.macro";
import * as Sentry from "@sentry/react";
import { displayToastNotification } from "../../utils/toastNotification";

const updateOrganizationGuidelineQuery = loader(
  "../../graphql/updateOrganizationGuideline.graphql"
);

interface Values {
  id?: string;
  name: string;
  active: boolean;
  type: string;
  fileToUpload: File | null;
  fileToUploadIsSet: boolean;
  currentFileName: string | null;
}

interface OrganizationGuidelinesUpdateProps {}

const OrganizationGuidelinesUpdate: React.FC<OrganizationGuidelinesUpdateProps> =
  () => {
    const history = useHistory();

    const [updateOrganizationGuideline] = useMutation(
      updateOrganizationGuidelineQuery
    );

    const { currentOrganizationCxt, organizationGuidelineCxt } =
      useContext<any>(ApplicationContext);

    const [initialValues, setInitialValues] = useState<Values | null>(null);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
      if (organizationGuidelineCxt.organizationGuidelineToEdit.id) {
        const curatedOrganizationGuideline = Object.assign(
          {},
          organizationGuidelineCxt.organizationGuidelineToEdit
        );
        setInitialValues({
          id: curatedOrganizationGuideline.id,
          name: curatedOrganizationGuideline.name,
          type: curatedOrganizationGuideline.surgery_type.id,
          fileToUpload: null,
          fileToUploadIsSet: false,
          currentFileName: curatedOrganizationGuideline.document_name,
          active: curatedOrganizationGuideline.active,
        });
      } else {
        const currentOrganizationGuideline = localStorage.getItem(
          "currentOrganizationGuideline"
        );
        if (currentOrganizationGuideline) {
          const currentOrganizationGuidelineParse = JSON.parse(
            currentOrganizationGuideline
          );
          setInitialValues({
            id: currentOrganizationGuidelineParse.id,
            name: currentOrganizationGuidelineParse.name,
            type: currentOrganizationGuidelineParse.surgery_type.id,
            fileToUpload: null,
            fileToUploadIsSet: false,
            currentFileName: currentOrganizationGuidelineParse.document_name,
            active: currentOrganizationGuidelineParse.active,
          });
        } else {
          history.push("/organization-guidelines/list");
        }
      }
    }, [organizationGuidelineCxt.organizationGuidelineToEdit, history]);

    useEffect(() => {
      if (initialValues) {
        setIsLoading(false);
      }
    }, [initialValues]);

    const modifyOrganizationGuideline = async (values: Values) => {
      const organizationGuidelineVariables = {
        id: values.id,
        name: values.name,
        surgeryTypeId: values.type,
        documentName: values.currentFileName!,
        active: values.active,
      };
      if (values.fileToUpload && typeof values.fileToUpload.name === "string") {
        const { error: deleteOrganizationFileError } =
          await deleteOrganizationFile(
            organizationGuidelineVariables.documentName,
            organizationGuidelineVariables.id!,
            ORGANIZATION_FILE_TYPE.GUIDELINE
          );
        if (deleteOrganizationFileError) {
          console.error(deleteOrganizationFileError);
          return;
        }
        const { fileName: fileNameTmp, error: uploadOrganizationFileError } =
          await uploadOrganizationFile(
            values.fileToUpload,
            currentOrganizationCxt.currentOrganizationToEdit?.id,
            ORGANIZATION_FILE_TYPE.GUIDELINE
          );
        if (uploadOrganizationFileError) {
          console.error(uploadOrganizationFileError);
          return;
        }
        organizationGuidelineVariables.documentName = fileNameTmp;
      }
      updateOrganizationGuideline({
        variables: { ...organizationGuidelineVariables },
      })
        .then((...args) => {
          const [
            {
              data: {
                update_organization_guideline: {
                  returning: organization_guideline,
                },
              },
            },
          ] = args;
          console.info(
            "Organization guideline updated",
            organization_guideline
          );
          localStorage.setItem(
            "currentOrganizationGuideline",
            JSON.stringify(organization_guideline[0])
          );
          organizationGuidelineCxt.setOrganizationGuidelineToEdit(
            organization_guideline[0]
          );
          // Display a toast notification if operation succeded
          displayToastNotification(
            "success",
            "Les modifications ont bien été enregistrées"
          );
        })
        .catch((e) => {
          console.error("Invalid input:", organizationGuidelineVariables);
          console.error(e);
          Sentry.captureException(e);
        });
    };

    const handleSubmit = async (values: Values) => {
      await modifyOrganizationGuideline(values);
    };

    if (isLoading) {
      return <Loader />;
    }

    return (
      <PageWrapper
        previousPageIconOn={true}
        previousPageText={"Retour à la liste des consignes médicales"}
        handleOnClickPreviousPage={() =>
          history.push("/organization-guidelines/list")
        }
      >
        <Container>
          <FormOrganizationGuideline
            title="Modifier le document"
            initialValues={initialValues!}
            mode="update"
            handleSubmit={(values) => handleSubmit(values)}
            organizationId={currentOrganizationCxt.currentOrganizationToEdit.id}
          />
        </Container>
      </PageWrapper>
    );
  };

export default OrganizationGuidelinesUpdate;

const Container = styled.div`
  height: 100%;
  margin: 0 auto;
  max-width: 1030px;
`;
