import { useMutation, useQuery } from "@apollo/client";
import * as Sentry from "@sentry/react";
import { loader } from "graphql.macro";
import cookie from "js-cookie";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";
import FormPatient from "../../components/FormTemplates/Patient/FormPatient";
import { PageWrapper } from "../../components/Layout/PageWrapper/PageWrapper";
import { COOKIES } from "../../constants/cookies_types";
import { client } from "../../lib/apolloClient";
import { Mixpanel } from "../../lib/mixpanel";
import ApplicationContext from "../../utils/context";
import { notifyUserCreation } from "../../utils/creation-notifier";
import { displayToastNotification } from "../../utils/toastNotification";

const getRolePatientQuery = loader("../../graphql/getRolePatient.graphql");
const getUserByEmailQuery = loader("../../graphql/getUserByEmail.graphql");
const addPatientSurgeryQuery = loader(
  "../../graphql/addPatientSurgery.graphql"
);
const updatePhoneParentQuery = loader(
  "../../graphql/updatePhoneParent.graphql"
);

const addPatientAndParentQuery = loader(
  "../../graphql/addPatientAndParent.graphql"
);
const addPatientParentRelationshipQuery = loader(
  "../../graphql/addPatientParentRelationship.graphql"
);
const addUserQuery = loader("../../graphql/addUser.graphql");
const addUserRoleQuery = loader("../../graphql/addUserRole.graphql");

// interface FormValues {
//   name: string;
//   surname: string;
//   birth_date: string;
//   email: string;
//   phone: string;
//   date: string;
//   type: string;
// }

interface PatientsCreateProps {}

const PatientsCreate: React.FC<PatientsCreateProps> = () => {
  const queryParams = useLocation().search;
  const history = useHistory();

  const { currentOrganizationCxt, meCxt } = useContext<any>(ApplicationContext);

  const { data: dataApplicationRole } = useQuery(getRolePatientQuery);

  const [addSurgery] = useMutation(addPatientSurgeryQuery);
  const [updatePhoneParent] = useMutation(updatePhoneParentQuery);
  const [addPatientAndParent] = useMutation(addPatientAndParentQuery);
  const [addParentPatientRelationship] = useMutation(
    addPatientParentRelationshipQuery
  );
  const [addUser] = useMutation(addUserQuery);

  const [rolePatient, setRolePatient] = useState<any>({});
  const [addUserRole] = useMutation(addUserRoleQuery);

  const initialValues = {
    name: "",
    surname: "",
    birth_date: "",
    email: "",
    phone: "",
    date: "",
    type: "",
  };

  useEffect(() => {
    if (
      dataApplicationRole &&
      dataApplicationRole.application_role &&
      dataApplicationRole.application_role.length > 0
    ) {
      setRolePatient(dataApplicationRole.application_role[0]);
    }
  }, [dataApplicationRole]);

  const getOrganizationInfos = (organization: any) => {
    let organizationTmp = {
      id: organization.id,
      name: organization.name,
      phone: "",
    };
    if (
      organization.hospital_service &&
      organization.hospital_service.hospital &&
      organization.hospital_service.hospital.name
    ) {
      organizationTmp.name += ` - ${organization.hospital_service.hospital.name}`;
      organizationTmp.phone = organization.hospital_service.phone;
    } else if (organization.liberal_practice) {
      organizationTmp.phone = organization.liberal_practice.phone;
    }
    return organizationTmp;
  };

  const createUserRole = async (userRoleVariables: any) => {
    return await addUserRole({
      variables: userRoleVariables,
    })
      .then((...response) => {
        const [
          {
            data: {
              insert_user_application_role: { returning: userRole },
            },
          },
        ] = response;
        console.info("user role patient created", userRole[0]);
        return userRole[0];
      })
      .catch((err) => {
        console.error(
          "Invalid input for user role creation:",
          userRoleVariables
        );
        console.error(err);
        Sentry.captureException(err);
      });
  };

  const createUser = async (userVariables: any) => {
    return await addUser({
      variables: userVariables,
    })
      .then((...response) => {
        const [
          {
            data: {
              insert_user: { returning: user },
            },
          },
        ] = response;
        console.info("user created", user[0]);
        return [null, user[0]];
      })
      .catch((err) => {
        console.error("Invalid input for user creation:", userVariables);
        console.error(err);
        Sentry.captureException(err);
        return [err, null];
      });
  };

  const createPatientAndParent = async (patientVariables: any) => {
    return await addPatientAndParent({
      variables: patientVariables,
      // refetchQueries: [{ query: GET_PATIENTS }],
      awaitRefetchQueries: true,
    })
      .then((...response) => {
        const [
          {
            data: { ...data },
          },
        ] = response;
        console.info("patient created", data.insert_patient?.returning?.[0]);
        console.info("parent created", data.insert_parent?.returning?.[0]);
        return [
          null,
          data.insert_patient?.returning?.[0],
          data.insert_parent?.returning?.[0],
        ];
      })
      .catch((err) => {
        console.error(
          "Invalid input for patient / parent creation:",
          patientVariables
        );
        Sentry.captureException(err);
        console.error(err);
        return [err, null, null];
      });
  };

  async function createPatientUser(values: any) {
    let surgeryId = "";
    let organizationId = null;
    let organizationName = null;
    let organizationPhone = null;
    if (
      currentOrganizationCxt &&
      currentOrganizationCxt.currentOrganizationToEdit &&
      currentOrganizationCxt.currentOrganizationToEdit.id
    ) {
      const organizationTmp = getOrganizationInfos(
        currentOrganizationCxt.currentOrganizationToEdit
      );
      organizationId = organizationTmp.id;
      organizationName = organizationTmp.name;
      organizationPhone = organizationTmp.phone;
    } else {
      console.error("Error: organizationId is not set");
      Sentry.captureException("Practitioner organizationId is not set");
      displayToastNotification("error", "Organisation non reconnue");
      return;
    }

    //Check if parent already exists
    let emailResponse = await client.query({
      query: getUserByEmailQuery,
      variables: {
        email: values.email.toLowerCase(),
      },
      fetchPolicy: "network-only",
    });
    const currentParent = emailResponse?.data?.user?.[0];
    let currentPatientInDb = null;
    //Parent already exists in DB
    if (currentParent) {
      //Check if patient already exists based on name, surname and birth_date
      for (let i = 0; i < currentParent.parent?.parent_patients.length; i++) {
        if (
          currentParent.parent?.parent_patients[
            i
          ].patient.name.toLowerCase() === values.name.toLowerCase() &&
          currentParent.parent?.parent_patients[
            i
          ].patient.surname.toLowerCase() === values.surname.toLowerCase() &&
          currentParent.parent?.parent_patients[i].patient.birth_date ===
            values.birth_date
        ) {
          currentPatientInDb = currentParent.parent?.parent_patients[i].patient;
          break;
        }
      }
    }
    if (currentPatientInDb) {
      // Patient already exists in DB. We only have to add a surgery
      try {
        const surgery = await addSurgery({
          variables: {
            patient_id: currentPatientInDb.id,
            date: values.date,
            organization_id: organizationId,
            type: values.type,
          },
        });
        if (values.phone !== "") {
          await updatePhoneParent({
            variables: {
              parent_id: currentParent.id,
              phone: values.phone,
            },
          });
        }
        surgeryId = surgery.data.insert_surgery.returning[0].id;
      } catch (err) {
        console.error(err);
        Sentry.captureException(err);
      }
      //update phone number
    } else {
      // Patient not in db
      const patientVariables = {
        ...values,
        email: values.email.toLowerCase(),
        organization_id: organizationId,
        phone: values.phone === "" ? null : values.phone,
        createParent: !!!currentParent,
      };

      const [errorPatientCreation, newPatient, newParent] =
        await createPatientAndParent(patientVariables);
      if (errorPatientCreation) {
        return errorPatientCreation;
      }
      try {
        await addParentPatientRelationship({
          variables: {
            parent_id: currentParent ? currentParent.id : newParent.id,
            patient_id: newPatient.id,
          },
        });
      } catch (err) {
        console.error("Error addParentPatientRelationship :", err);
        Sentry.captureException(err);
        return;
      }
      console.info("Parent-patient relationship success");
      if (!currentParent) {
        const userVariables = {
          user_id: newParent.id,
          email: newParent.email,
          active: true,
        };
        // create user
        const [errorUserCreation, user] = await createUser(userVariables);
        if (errorUserCreation) {
          return errorUserCreation;
        }

        const userRoleVariable = {
          role_id: rolePatient.id,
          user_id: user.id,
          approver_id: cookie.get(COOKIES.ID),
        };

        // Create user role
        await createUserRole(userRoleVariable);
        try {
          await notifyUserCreation({
            email: newParent.email,
            patientName: newPatient.name,
            organizationName: organizationName,
            organizationPhone: organizationPhone,
            type: "parent",
            parentId: newParent.id,
            surgeryId: newPatient.surgeries[0].id,
          });
        } catch (err) {
          displayToastNotification("error", "Une erreur est survenue");
          console.error(err);
          Sentry.captureException(err);
        }
      } else {
        //If parent already exists in DB, we have to update the phone number
        if (values.phone !== "") {
          try {
            await updatePhoneParent({
              variables: {
                parent_id: currentParent.id,
                phone: values.phone,
              },
            });
          } catch (err) {
            console.error(err);
            Sentry.captureException(err);
          }
        }
      }
      surgeryId = newPatient.surgeries[0].id;
      // Display a toast notification if operation succeded
      displayToastNotification(
        "success",
        "Le nouveau patient est bien enregistré"
      );

      if (Mixpanel) {
        Mixpanel.createPatient(
          meCxt.meToEdit?.id,
          meCxt.meToEdit?.name,
          meCxt.meToEdit?.surname,
          organizationId,
          organizationName,
          newPatient.surgeries[0].surgery_type.id,
          newPatient.surgeries[0].surgery_type.value
        );
      }
    }
    // If user comes from a defined workflow (for example creation of a prescription, we redirect him)
    const origin = new URLSearchParams(queryParams).get("origin");
    if (origin === "create-prescription") {
      history.push(`/prescriptions/create?surgeryId=${surgeryId}`);
    }
  }

  const handleSubmit = async (values: any) => {
    //Format dates to insert in DB
    await createPatientUser({
      ...values,
      birth_date: moment(values.birth_date).format("YYYY-MM-DD"),
      date: moment(values.date).format("YYYY-MM-DD"),
    });
  };

  return (
    <PageWrapper>
      <Container>
        <FormPatient
          title="Créer un patient"
          initialValues={initialValues}
          mode="creation"
          handleSubmit={(values) => handleSubmit(values)}
          organizationId={currentOrganizationCxt.currentOrganizationToEdit.id}
        />
      </Container>
    </PageWrapper>
  );
};

export default PatientsCreate;

const Container = styled.div`
  height: 100%;
  margin: 0 auto;
  max-width: 1030px;
`;
