import { useQuery } from "@apollo/client";
import { Field, Form, Formik } from "formik";
import { loader } from "graphql.macro";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import * as Yup from "yup";
import { validatePhoneNumber } from "../../../utils/validatePhoneNumber";
import CustomButton from "../../CustomButton/CustomButton";
import { DropdownForm } from "../../Form/DropdownForm/DropdownForm";
import { MultipleCheckForm } from "../../Form/MultipleCheckForm/MultipleCheckForm.component";
import PhoneInputForm from "../../Form/PhoneInputForm/PhoneInputForm";
import { TextInputForm } from "../../Form/TextInputForm/TextInputForm";
import TitleSectionForm from "../../Form/TitleSectionForm/TitleSectionForm";
import CustomContour from "../../Layout/CustomContour/CustomContour";

const getHospitalsQuery = loader("../../../graphql/getHospitals.graphql");

interface FormValues {
  name: string;
  phone: string;
  organization_type: string;
  access: string;
  hospital_id: string;
  am?: string;
  addressNumber?: string;
  addressStreet?: string;
  addressCity?: string;
  addressPostalCode?: string;
}

type FormMode = "creation" | "update";

interface FormEstablishmentProps {
  title: string;
  mode: FormMode;
  handleSubmit: (values: FormValues) => void;
  initialValues: FormValues;
  organizationMembership?: { [key: string]: string };
  organizationMembershipLength?: number;
}

const validationSchema = (mode: FormMode) =>
  Yup.object().shape({
    name: Yup.string()
      .required("Ce champ est requis")
      .min(3, "3 lettres minimum"),
    phone: Yup.string().required("Ce champ est requis"),
    organization_type:
      mode === "creation"
        ? Yup.string().required("Ce champ est requis")
        : Yup.string(),
    hospital_id: Yup.string().when("organization_type", {
      is: (val: string) => val === "hospital_service",
      then:
        mode === "creation"
          ? Yup.string().required("Ce champ est requis")
          : Yup.string(),
    }),
    am: Yup.string().when("organization_type", {
      is: (val: string) => val === "liberal_practice",
      then: Yup.string()
        .required("Ce champ est requis")
        .matches(/^[0-9]+$/, "Le nᵒ AM doit contenir uniquement des chiffres")
        .length(9, "Le nᵒ AM doit contenir 9 chiffres")
        .nullable(),
    }),
    addressNumber: Yup.string().when("organization_type", {
      is: (val: string) => val === "liberal_practice",
      then: Yup.string().required("Ce champ est requis"),
    }),
    addressStreet: Yup.string().when("organization_type", {
      is: (val: string) => val === "liberal_practice",
      then: Yup.string().required("Ce champ est requis"),
    }),
    addressCity: Yup.string().when("organization_type", {
      is: (val: string) => val === "liberal_practice",
      then: Yup.string().required("Ce champ est requis"),
    }),
    addressPostalCode: Yup.string().when("organization_type", {
      is: (val: string) => val === "liberal_practice",
      then: Yup.string().required("Ce champ est requis"),
    }),
  });

const FormEstablishment: React.FunctionComponent<FormEstablishmentProps> = ({
  title,
  mode,
  handleSubmit,
  initialValues,
}) => {
  const { data: hospitalsData } = useQuery(getHospitalsQuery);

  const [hospitals, setHospitals] = useState([]);

  useEffect(() => {
    if (hospitalsData?.hospital) {
      const hospitalsTmp = hospitalsData.hospital.map(
        (e: { id: string; name: string }) => {
          return { label: e.name, value: e.id };
        }
      );
      setHospitals(hospitalsTmp);
    }
  }, [hospitalsData]);

  return (
    <Formik
      initialValues={{ ...initialValues }}
      onSubmit={async (values: FormValues, { resetForm }) => {
        await handleSubmit(values);
        if (mode === "creation") {
          resetForm();
        }
      }}
      validationSchema={() => validationSchema(mode)}
      validateOnChange={false}
    >
      {(props) => {
        return (
          <FormContainer>
            <CustomContour title={title}>
              <Section>
                <TitleSectionForm title=" Informations de l'établissement" />
                <FieldSection>
                  <FieldContainer>
                    <Field
                      name={"name"}
                      label={"Nom"}
                      placeholder={"Nom de l'établissement"}
                      component={TextInputForm}
                    />
                  </FieldContainer>
                  <FieldContainer>
                    <Field
                      name={"phone"}
                      label={"Téléphone"}
                      placeholder={"01 XX XX XX"}
                      component={PhoneInputForm}
                      validate={validatePhoneNumber}
                    />
                  </FieldContainer>
                </FieldSection>
                <SeparatorContainer></SeparatorContainer>
                <FieldSection>
                  {mode === "creation" && (
                    <FieldContainer>
                      <Field
                        name={"organization_type"}
                        label={"Type"}
                        placeholder={"--"}
                        component={DropdownForm}
                        data={[
                          {
                            label: "liberal_practice",
                            value: "liberal_practice",
                          },
                          {
                            label: "hospital_service",
                            value: "hospital_service",
                          },
                        ]}
                      />
                    </FieldContainer>
                  )}

                  <FieldContainer>
                    {mode === "creation" &&
                      props.values.organization_type === "hospital_service" && (
                        <Field
                          name={"hospital_id"}
                          label={"Hopital"}
                          placeholder={"--"}
                          component={DropdownForm}
                          data={hospitals}
                        />
                      )}
                  </FieldContainer>
                </FieldSection>
                <SeparatorContainer></SeparatorContainer>
                <FieldSection>
                  <FieldContainer>
                    <Field
                      name={"access"}
                      label={"Disponibilité"}
                      placeholder={
                        "7h00 – 20h30, Bâtiment Hamburger, porte H1, 1er étage, lundi au vendredi"
                      }
                      component={TextInputForm}
                    />
                  </FieldContainer>
                  <FieldContainer>
                    {props.values.organization_type === "liberal_practice" && (
                      <Field
                        name={"am"}
                        label={"N° AM"}
                        placeholder={"AM"}
                        component={TextInputForm}
                      />
                    )}
                  </FieldContainer>
                </FieldSection>
                <SeparatorContainer></SeparatorContainer>

                {props.values.organization_type === "liberal_practice" && (
                  <FieldSection>
                    <FieldContainer>
                      <Field
                        name={"addressNumber"}
                        label={"Adresse Numéro"}
                        placeholder={"Adresse Numéro"}
                        component={TextInputForm}
                      />
                    </FieldContainer>
                    <FieldContainer>
                      <Field
                        name={"addressStreet"}
                        label={"Adresse Rue"}
                        placeholder={"Adresse Rue"}
                        component={TextInputForm}
                      />
                    </FieldContainer>
                    <FieldContainer>
                      <Field
                        name={"addressCity"}
                        label={"Ville"}
                        placeholder={"Ville"}
                        component={TextInputForm}
                      />
                    </FieldContainer>
                    <FieldContainer>
                      <Field
                        name={"addressPostalCode"}
                        label={"Code postal"}
                        placeholder={"Code postal"}
                        component={TextInputForm}
                      />
                    </FieldContainer>
                  </FieldSection>
                )}
                <SeparatorContainer></SeparatorContainer>
              </Section>
              <SeparatorContainer />
              <Section>
                <TitleSectionForm title=" Opérations liées à l'établissement" />
                <FieldSection>
                  <FieldContainer fullWidth>
                    <Field
                      name={"surgeriesType"}
                      component={MultipleCheckForm}
                    />
                  </FieldContainer>
                </FieldSection>
              </Section>
            </CustomContour>
            <ButtonContainer>
              <CustomButton
                type="submit"
                content={
                  mode === "creation"
                    ? "Enregistrer l'établissement"
                    : "Sauvegarder les modifications"
                }
                disabled={props.isSubmitting}
              />
            </ButtonContainer>
          </FormContainer>
        );
      }}
    </Formik>
  );
};

export default FormEstablishment;

const FormContainer = styled(Form)``;

const Section = styled.div`
  width: 100%;
`;

const FieldSection = styled.div`
  width: 100%;
  display: flex;
  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.tabletMax}) {
    flex-direction: column;
  }
`;

const SeparatorContainer = styled.div`
  margin: 40px 0;
`;

const FieldContainer = styled.div<{ fullWidth?: boolean }>`
  width: ${({ fullWidth }) => (fullWidth ? "100%" : `calc(100% / 2)`)};
  margin: 0 8px;

  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.tabletMax}) {
    width: 100%;
    margin: 8px 0;
  }
`;

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 30px;
`;
