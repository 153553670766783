import React, { Component } from "react";
import { Col, Row } from "react-bootstrap";
import styled from "styled-components";
import { ThemeComponents } from "../../../theme/components";
import { ReactComponent as IconArrowDown } from "../../../theme/icons/arrowDown.svg";

export type Props = {
  label?: string;
  sublabel?: string;
  placeholder?: string;
  required?: boolean;
  form: any;
  field: any;
  data: Array<{ label: string; value: string }>;
  onChange?: (v: string) => void;
  disabled: boolean;
};
export class DropdownForm extends Component<Props> {
  public render() {
    const {
      form,
      label,
      sublabel,
      placeholder,
      field,
      required,
      data,
      onChange,
      disabled,
    } = this.props;
    const error = form.errors[field.name];
    const touched = form.touched[field.name];
    return (
      <DropdownContainer>
        <DropdownRow noGutters>
          <Col xs={12}>
            <DropdownLabelContainer>
              <DropdownLabel>{label}</DropdownLabel>
              <DropdownSubLabel>{sublabel}</DropdownSubLabel>
            </DropdownLabelContainer>
          </Col>
          <Col xs={12}>
            <Dropdown
              {...field}
              placeholder={
                placeholder && required ? `${placeholder + " *"}` : placeholder
              }
              error={error}
              touched={touched}
              onChange={(changeEvent) => {
                form.setFieldValue(field.name, changeEvent.target.value);
                return onChange ? onChange(changeEvent.target.value) : null; //TODO TEST IF IT WORKS !!!!!!!!!!!!!!!!!
              }}
              disabled={disabled}
            >
              <OptionPlaceholder value="">
                {placeholder && required
                  ? `${placeholder + " *"}`
                  : placeholder}
              </OptionPlaceholder>
              {data.map((option, i) => (
                <OptionItem value={option.value} key={i}>
                  {option.label}
                </OptionItem>
              ))}
              {/* OptGroup : No truncated label on ios mobile */}
              <OptGroup label=""></OptGroup>
            </Dropdown>
            <DropdownIcon>
              <IconArrowDownContainer />
            </DropdownIcon>
            {touched && error && <DropdownError>{error}</DropdownError>}
          </Col>
        </DropdownRow>
      </DropdownContainer>
    );
  }
}

const DropdownContainer = styled.div`
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: 0;
`;

const DropdownRow = styled(Row)`
  width: 100%;
`;

const Dropdown = styled(ThemeComponents.FormDropdown)<{
  value: string;
  error: string;
  touched: boolean;
}>`
  border-color: ${(props) => {
    const colorType =
      !!props.error && props.touched
        ? props.theme.colors.red.dark
        : props.theme.colors.white.darker;
    return colorType;
  }};

  :nth-child(1) {
    color: ${(props) =>
      props.value === ""
        ? props.theme.colors.darkGreenL3
        : props.theme.colors.blackMain};
  }
  background: ${(props) => props.theme.colors.darkGreenL1};
  color: ${(props) =>
    props.disabled
      ? props.theme.colors.darkGreenL4
      : props.theme.colors.blackMain};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
`;

const DropdownLabelContainer = styled.div`
  margin: 5px 15px 5px 0;
`;

const DropdownLabel = styled(ThemeComponents.FormLabel)``;

const DropdownSubLabel = styled(ThemeComponents.FormSubLabel)``;

const DropdownError = styled(ThemeComponents.FormErrorLabel)``;

const DropdownIcon = styled.div`
  position: absolute;
  top: 13px;
  right: 15px;

  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.laptop13Max}) {
    top: 4px;
    right: 15px;
  }
`;

const OptionPlaceholder = styled.option``;

const OptionItem = styled.option``;

const OptGroup = styled.optgroup``;

const IconArrowDownContainer = styled(IconArrowDown)`
  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.laptop13Max}) {
    width: 11px;
    height: 18px;
  }
  & > * {
    stroke: ${(props) => props.theme.colors.darkGreenL3};
  }
`;
