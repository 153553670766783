import cookie from "js-cookie";
import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import FormPractitioner from "../../components/FormTemplates/Practitioner/FormPractitioner";
import { PageWrapper } from "../../components/Layout/PageWrapper/PageWrapper";
import { COOKIES } from "../../constants/cookies_types";
import ApplicationContext from "../../utils/context";
import * as Sentry from "@sentry/react";
import { useMutation, useQuery } from "@apollo/client";
import { loader } from "graphql.macro";
import { notifyUserCreation } from "../../utils/creation-notifier";
import { displayToastNotification } from "../../utils/toastNotification";

const getRolePractitionerQuery = loader(
  "../../graphql/getRolePractitioner.graphql"
);
const addPractitionerQuery = loader("../../graphql/addPractitioner.graphql");
const addUserQuery = loader("../../graphql/addUser.graphql");
const addUserRoleQuery = loader("../../graphql/addUserRole.graphql");
const addOrganizationMembershipAndSpecializationQuery = loader(
  "../../graphql/addOrganizationMembershipAndSpecialization.graphql"
);
const addOrganizationMembershipRoleQuery = loader(
  "../../graphql/addOrganizationMembershipRole.graphql"
);

interface PractitionersCreateProps {}

const PractitionersCreate: React.FC<PractitionersCreateProps> = () => {
  const { currentOrganizationCxt } = useContext<any>(ApplicationContext);

  const { data } = useQuery(getRolePractitionerQuery);

  const [addPractitioner] = useMutation(addPractitionerQuery);
  const [addUser] = useMutation(addUserQuery);
  const [addUserRole] = useMutation(addUserRoleQuery);
  const [addOrganizationMembershipAndSpecialization] = useMutation(
    addOrganizationMembershipAndSpecializationQuery
  );
  const [addOrganizationMembershipRole] = useMutation(
    addOrganizationMembershipRoleQuery
  );

  const [applicationRolePractitioner, setApplicationRolePractitioner] =
    useState<any>({});

  const [organizationRolePractitioner, setOrganizationRolePractitioner] =
    useState<any>({});

  useEffect(() => {
    if (data && data.application_role) {
      if (data.application_role) {
        setApplicationRolePractitioner(data.application_role[0]);
      }
      if (data.organization_role) {
        setOrganizationRolePractitioner(data.organization_role[0]);
      }
    }
  }, [data]);

  const initialValues = {
    title: "",
    specialty: "",
    surname: "",
    name: "",
    email: "",
    phone: "",
    rpps: "",
    organization_role_id: "",
  };

  //Concatenate organization + hospital name if hospital service
  const getOrganizationName = (organization: any) => {
    let name = organization.name;
    if (
      organization.hospital_service &&
      organization.hospital_service.hospital &&
      organization.hospital_service.hospital.name
    ) {
      name += ` - ${organization.hospital_service.hospital.name}`;
    }
    return name;
  };

  const createOrganizationMembershipRole = async (variables: any) => {
    return await addOrganizationMembershipRole({
      variables,
    }).then((...response) => {
      const [
        {
          data: {
            insert_organization_membership_role: {
              returning: organizationMembershipRole,
            },
          },
        },
      ] = response;
      console.info(
        "organization membership created",
        organizationMembershipRole[0]
      );
      return organizationMembershipRole[0];
    });
  };

  const createOrganizationMembershipAndSpecialization = async (
    variables: any
  ) => {
    return await addOrganizationMembershipAndSpecialization({
      variables,
    }).then((...response) => {
      const [
        {
          data: {
            insert_organization_membership: {
              returning: organizationMembership,
            },
            insert_practitioner_specialization: {
              returning: practitionerSpecialization,
            },
          },
        },
      ] = response;
      console.info(
        "organization membership created",
        organizationMembership[0]
      );
      console.info(
        "practitioner specialty created",
        practitionerSpecialization[0]
      );
      return [organizationMembership[0], practitionerSpecialization[0]];
    });
  };

  const createUserRole = async (userRoleVariables: any) => {
    return await addUserRole({
      variables: userRoleVariables,
    }).then((...response) => {
      const [
        {
          data: {
            insert_user_application_role: { returning: userRole },
          },
        },
      ] = response;
      console.info("user role practitioner created", userRole[0]);
      return userRole[0];
    });
  };

  const createUser = async (userVariables: any) => {
    return await addUser({
      variables: userVariables,
    }).then((...response) => {
      const [
        {
          data: {
            insert_user: { returning: user },
          },
        },
      ] = response;
      console.info("user created", user[0]);
      return user[0];
    });
  };

  const createPractitioner = async (practitionerVariables: any) => {
    return await addPractitioner({
      variables: practitionerVariables,
    })
      .then((...response) => {
        const [
          {
            data: {
              insert_practitioner: { returning: practitioner },
            },
          },
        ] = response;
        console.info("Practitioner created", practitioner[0]);
        return practitioner[0];
      })
      .catch((err) => {
        console.error(
          "Invalid input for practitioner creation:",
          practitionerVariables
        );
        console.error(err);
        Sentry.captureException(err);
      });
  };

  const createPractitionerUser = async (values: any) => {
    values.organization_id =
      currentOrganizationCxt.currentOrganizationToEdit.id;
    if (!values.organization_id) {
      console.error("organization id not set");
      displayToastNotification("error", "Organisation non reconnue");
      Sentry.captureException("organizationId is not set");
      return;
    }
    const practitionerVariables = Object.assign(
      {},
      { ...values, email: values.email.toLowerCase() }
    );
    delete practitionerVariables.organization_id;
    let practitioner;
    try {
      practitioner = await createPractitioner(practitionerVariables);
      const userVariables = {
        user_id: practitioner.id,
        email: practitioner.email,
        active: true,
      };
      try {
        const user = await createUser(userVariables);

        const userRoleVariable = {
          role_id: applicationRolePractitioner.id,
          user_id: user.id,
          approver_id: cookie.get(COOKIES.ID),
        };

        try {
          await createUserRole(userRoleVariable);
          try {
            const newOrganizationMembership =
              await createOrganizationMembershipAndSpecialization({
                user_id: user.id,
                organization_id: values.organization_id,
                specialty: values.specialty,
              });
            try {
              const organization_role_id = values.organization_role_id
                ? values.organization_role_id
                : organizationRolePractitioner.id;
              await createOrganizationMembershipRole({
                organization_membership_id: newOrganizationMembership[0].id,
                organization_role_id,
              });
              try {
                await notifyUserCreation({
                  email: user.email,
                  organizationName: getOrganizationName(
                    newOrganizationMembership[0].organization
                  ),
                  type: "practitioner",
                });
                // Display a toast notification if operation succeded
                displayToastNotification(
                  "success",
                  "Le nouveau praticien est bien enregistré"
                );
                //TODO update cxt + localStorage
                return;
              } catch (err) {
                console.error("Error notify user creation ", err);
                Sentry.captureException(err);
              }
            } catch (err) {
              console.error(
                "Error when creating organization membership role ",
                err
              );
              Sentry.captureException(err);
            }
          } catch (err) {
            console.error("Invalid input for organization membership:", {
              user_id: user.id,
              organization_id: values.organization_id,
            });
            console.error(err);
            Sentry.captureException(err);
          }
        } catch (err) {
          console.error(
            "Invalid input for user role creation:",
            userRoleVariable
          );
          console.error(err);
          Sentry.captureException(err);
        }
      } catch (err) {
        console.error("Invalid input for user creation:", userVariables);
        console.error(err);
        Sentry.captureException(err);
      }
    } catch (err) {
      console.error("Invalid practitioner input:", values);
      console.error(err);
      Sentry.captureException(err);
    }
  };

  const handleSubmit = async (values: any) => {
    await createPractitionerUser(values);
  };

  return (
    <PageWrapper>
      <Container>
        <FormPractitioner
          title="Créer un praticien"
          initialValues={initialValues}
          mode="creation"
          handleSubmit={(values) => handleSubmit(values)}
          // organizationId={currentOrganizationCxt.currentOrganizationToEdit.id}
        />
      </Container>
    </PageWrapper>
  );
};

export default PractitionersCreate;

const Container = styled.div`
  height: 100%;
  margin: 0 auto;
  max-width: 1030px;
`;
