import { ORGANIZATION_FEATURE } from "../constants/db_types";
import { ROUTES_AUTH } from "../constants/routes";

export const checkIfOrganizationCanAccessFeature = (
  features: string[],
  pathname: string
) => {
  /* Prescription feature */
  if (
    pathname === ROUTES_AUTH.PRESCRIPTIONS_CONFIGURATION ||
    pathname === ROUTES_AUTH.PRESCRIPTIONS_CREATE ||
    pathname === ROUTES_AUTH.PRESCRIPTIONS_LIST
  ) {
    return features.includes(ORGANIZATION_FEATURE.PRESCRIPTIONS);
  }

  /* Esign feature */
  if (
    pathname === ROUTES_AUTH.ESIGNATURE_TEMPLATES_CREATE ||
    pathname === ROUTES_AUTH.ESIGNATURE_TEMPLATES_LIST ||
    pathname === ROUTES_AUTH.ESIGNATURE_STATUS
  ) {
    return features.includes(ORGANIZATION_FEATURE.ESIGN);
  }

  /* Organization documents feature */
  if (
    pathname === ROUTES_AUTH.ORGANIZATION_DOCUMENTS_CREATE ||
    pathname === ROUTES_AUTH.ORGANIZATION_DOCUMENTS_UPDATE ||
    pathname === ROUTES_AUTH.ORGANIZATION_DOCUMENTS_LIST
  ) {
    return features.includes(ORGANIZATION_FEATURE.ORGANIZATION_DOCUMENTS);
  }

  /* Organization guidelines feature */
  if (
    pathname === ROUTES_AUTH.ORGANIZATION_GUIDELINES_CREATE ||
    pathname === ROUTES_AUTH.ORGANIZATION_GUIDELINES_UPDATE ||
    pathname === ROUTES_AUTH.ORGANIZATION_GUIDELINES_LIST
  ) {
    return features.includes(ORGANIZATION_FEATURE.ORGANIZATION_GUIDELINES);
  }

  /* Call-day-before  feature */
  if (
    pathname === ROUTES_AUTH.REMINDER_AVAILABILITY ||
    pathname === ROUTES_AUTH.REMINDER_CONFIGURE
  ) {
    return features.includes(ORGANIZATION_FEATURE.CALL_DAY_BEFORE);
  }

  return true;
};
