import React, { useContext, useState } from "react";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import FormPatient from "../../components/FormTemplates/Patient/FormPatient";
import { PageWrapper } from "../../components/Layout/PageWrapper/PageWrapper";
import { Loader } from "../../components/Loader/Loader";
import ApplicationContext from "../../utils/context";
import moment from "moment";
import { useMutation } from "@apollo/client";
import { loader } from "graphql.macro";
import * as Sentry from "@sentry/react";
import { displayToastNotification } from "../../utils/toastNotification";

const updatePatientQuery = loader("../../graphql/updatePatient.graphql");

interface FormValues {
  id?: string;
  name: string;
  surname: string;
  birth_date: string;
  email: string;
  phone: string;
  date: string;
  type: string;
  surgery_id?: string;
}

interface PatientsUpdateProps {}

const PatientsUpdate: React.FC<PatientsUpdateProps> = () => {
  const history = useHistory();

  const [updatePatient] = useMutation(updatePatientQuery);

  const { patientCxt } = useContext<any>(ApplicationContext);

  const [initialValues, setInitialValues] = useState<FormValues | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [organizationId, setOrganizationId] = useState("");

  useEffect(() => {
    if (patientCxt.patientToEdit.id) {
      setInitialValues({
        id: patientCxt.patientToEdit.id,
        name: patientCxt.patientToEdit.name,
        surname: patientCxt.patientToEdit.surname,
        birth_date: patientCxt.patientToEdit.birth_date,
        email: patientCxt.patientToEdit.parent_patients[0].parent.email,
        phone: patientCxt.patientToEdit.parent_patients[0].parent.phone,
        date: patientCxt.patientToEdit.surgeries?.[0].date,
        surgery_id: patientCxt?.patientToEdit?.surgeries?.[0]?.id,
        type: patientCxt.patientToEdit.surgeries?.[0].surgery_type.id,
      });
      setOrganizationId(
        patientCxt.patientToEdit.surgeries?.[0].organization.id
      );
    } else {
      const currentPatient = localStorage.getItem("currentPatient");
      if (currentPatient) {
        const currentPatientParse = JSON.parse(currentPatient);
        setInitialValues({
          id: currentPatientParse.id,
          name: currentPatientParse.name,
          surname: currentPatientParse.surname,
          birth_date: currentPatientParse.birth_date,
          email: currentPatientParse.parent_patients[0].parent.email,
          phone: currentPatientParse.parent_patients[0].parent.phone,
          date: currentPatientParse.surgeries?.[0].date,
          surgery_id: currentPatientParse.surgeries?.[0]?.id,
          type: currentPatientParse.surgeries?.[0].surgery_type.id,
        });
        setOrganizationId(currentPatientParse.surgeries?.[0].organization.id);
      } else {
        history.push("/patients/list");
      }
    }
  }, [patientCxt.patientToEdit, history]);

  useEffect(() => {
    if (initialValues && organizationId) {
      setIsLoading(false);
    }
  }, [initialValues, organizationId]);

  const modifyPatient = async (values: any) => {
    const variables = { ...values };
    console.info("Modify patient", values);
    updatePatient({
      variables: variables,
    })
      .then((...args) => {
        const [
          {
            data: {
              update_patient: { returning: patient },
              update_parent: { returning: parent },
              update_surgery: { returning: surgery },
            },
          },
        ] = args;
        // console.log("Patient updated", patient);
        // console.log("surgery updated", surgery);
        const updatedPatientCtxFormat = {
          ...patient[0],
          parent_patients: [
            {
              parent: {
                ...parent[0],
              },
            },
          ],
          surgeries: [
            {
              ...surgery[0],
            },
          ],
        };
        localStorage.setItem(
          "currentPatient",
          JSON.stringify(updatedPatientCtxFormat)
        );
        patientCxt.setPatientToEdit(updatedPatientCtxFormat);
        // Display a toast notification if operation succeded
        displayToastNotification(
          "success",
          "Les modifications ont bien été enregistrées"
        );
      })
      .catch((e) => {
        console.error("Invalid input:", variables);
        console.error(e);
        Sentry.captureException(e);
      });
  };

  const handleSubmit = async (values: FormValues) => {
    await modifyPatient({
      ...values,
      birth_date: moment(values.birth_date).format("YYYY-MM-DD"),
      date: moment(values.date).format("YYYY-MM-DD"),
    });
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <PageWrapper
      previousPageIconOn={true}
      previousPageText={"Retour au profil du patient"}
      handleOnClickPreviousPage={() => history.push("/patients/profile")}
    >
      <Container>
        <FormPatient
          title="Modifier le patient"
          initialValues={initialValues!}
          mode="update"
          handleSubmit={(values) => handleSubmit(values)}
          organizationId={organizationId}
        />
      </Container>
    </PageWrapper>
  );
};

export default PatientsUpdate;

const Container = styled.div`
  height: 100%;
  margin: 0 auto;
  max-width: 1030px;
`;
