import React, { Component } from "react";
import styled from "styled-components";
// import { ReactComponent as CheckIcon } from "../../../../theme/icons/iconCheckboxTickWhite.svg";

export type Props = {
  checked: boolean;
  onChange: () => void;
  label?: string;
  labelComponent?: React.ReactNode;
  width?: number;
  height?: number;
  radioBtn?: boolean;
};

const DEFAULT_SIZE = {
  width: "16px",
  height: "16px",
};

export class CheckBoxForm extends Component<Props> {
  public render() {
    const {
      checked,
      onChange,
      labelComponent,
      width,
      height,
      label,
      radioBtn,
    } = this.props;
    return (
      <CheckBoxContainer>
        {!radioBtn && <CheckBoxInput hidden onChange={() => onChange()} />}
        {radioBtn && <RadioButtonInput hidden onChange={() => onChange()} />}
        <CheckBoxCheckmark
          checked={checked}
          style={{
            width: width ? width + "px" : DEFAULT_SIZE.width,
            height: height ? height + "px" : DEFAULT_SIZE.height,
          }}
          radioBtn={radioBtn}
        >
          {radioBtn && <RadioButton checked={checked} />}
          {!radioBtn && (
            <CheckBoxIcon checked={checked}>
              <svg
                width="14"
                height="14"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M13.8839 3.61612C14.372 4.10427 14.372 4.89573 13.8839 5.38388L6.88388 12.3839C6.39573 12.872 5.60427 12.872 5.11612 12.3839L2.11612 9.38388C1.62796 8.89573 1.62796 8.10427 2.11612 7.61612C2.60427 7.12796 3.39573 7.12796 3.88388 7.61612L6 9.73223L12.1161 3.61612C12.6043 3.12796 13.3957 3.12796 13.8839 3.61612Z"
                  fill="white"
                />
              </svg>
            </CheckBoxIcon>
          )}
        </CheckBoxCheckmark>
        <Label>{label ? label : labelComponent}</Label>
      </CheckBoxContainer>
    );
  }
}

const CheckBoxContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 20px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  min-height: 16px;
`;

const CheckBoxInput = styled.input.attrs({ type: "button" })``;
const RadioButtonInput = styled.input.attrs({ type: "radio" })``;

const CheckBoxCheckmark = styled.span<{ checked: boolean; radioBtn?: boolean }>`
  position: absolute;
  top: 2px;
  left: 0px;
  background-color: ${(props) =>
    props.radioBtn
      ? props.theme.colors.white.pure
      : props.checked
      ? props.theme.colors.darkGreenL3
      : props.theme.colors.white.pure};
  border: ${(props) =>
    props.checked
      ? `${props.radioBtn ? "2px" : "2px"} solid ${
          props.theme.colors.darkGreenL3
        }`
      : `${props.radioBtn ? "2px" : "2px"} solid ${
          props.theme.colors.darkGreenL3
        }`};
  border-radius: ${(props) => (props.radioBtn ? "50%" : "50%")};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CheckBoxIcon = styled.div<{ checked: boolean }>``;

const RadioButton = styled.div<{ checked: boolean }>`
  background-color: ${(props) =>
    props.checked
      ? props.theme.colors.darkGreenL4
      : props.theme.colors.white.darker};
  width: 12px;
  height: 12px;
  border-radius: 50%;
`;

const Label = styled.div``;
