import { useLazyQuery, useQuery } from "@apollo/client";
import { loader } from "graphql.macro";
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import ApplicationContext from "../../utils/context";
import { PageWrapper } from "../../components/Layout/PageWrapper/PageWrapper";
import CustomList from "../../components/List/CustomList/CustomList";
import { LANGUAGES } from "../../constants/languages_types";
import { CardStyle2 } from "../../theme/components/ThemeComponents";

const getInstructionsTypesQuery = loader(
  "../../graphql/getInstructionsTypes.graphql"
);

const getInstructionsCountQuery = loader(
  "../../graphql/getAllInstructionsCountByType.graphql"
);

const getInstructionsWithLimitOffsetByTypeQuery = loader(
  "../../graphql/getAllInstructionsWithLimitOffsetByType.graphql"
);

interface InstructionsListProps {}

const limit = 10;

const columns = [
  {
    value: "Valeur",
    ratio: "85%",
    type: "string",
  },
  {
    value: "Statut",
    ratio: "15%",
    type: "component",
  },
];

const InstructionsList: React.FC<InstructionsListProps> = () => {
  const history = useHistory();
  const { instructionCxt } = useContext<any>(ApplicationContext);

  const [pagesNumber, setPagesNumber] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [instructions, setInstructions] = useState<Array<{
    customData: any;
    rowValues: Array<string>;
  }> | null>(null);

  const [instructionsTypes, setInstructionsTypes] = useState<Array<{
    value: string;
    label: string;
  }> | null>(null);

  const [currentInstructionTypeId, setCurrentInstructionTypeId] = useState("");

  const { data: dataInstructionsTypes } = useQuery(getInstructionsTypesQuery);

  const [getAllInstructionsCount, { data: dataInstructionsCount }] =
    useLazyQuery(getInstructionsCountQuery);

  const [getAllInstructionsWithLimitOffset, { data: dataInstructions }] =
    useLazyQuery(getInstructionsWithLimitOffsetByTypeQuery, {
      fetchPolicy: "network-only",
    });

  useEffect(() => {
    if (dataInstructionsTypes) {
      const instructionsTypesTmp = dataInstructionsTypes.instruction_type.map(
        (e: any) => {
          return {
            value: e.id,
            label: e.instruction_type_i18ns.filter(
              (f: any) => f.language.language_code === LANGUAGES.FR_FR
            )[0].label,
          };
        }
      );
      setCurrentInstructionTypeId(instructionsTypesTmp?.[0].value);
      setInstructionsTypes(instructionsTypesTmp);
    }
  }, [dataInstructionsTypes]);

  useEffect(() => {
    if (currentInstructionTypeId) {
      getAllInstructionsCount({
        variables: {
          instructionTypeId: currentInstructionTypeId,
        },
      });
    }
  }, [currentInstructionTypeId, getAllInstructionsCount]);

  useEffect(() => {
    if (currentInstructionTypeId) {
      getAllInstructionsWithLimitOffset({
        variables: {
          limit,
          offset: currentPage * limit - limit,
          instructionTypeId: currentInstructionTypeId,
        },
      });
    }
  }, [
    currentPage,
    currentInstructionTypeId,
    getAllInstructionsWithLimitOffset,
  ]);

  /* Calculate number of pages */
  useEffect(() => {
    if (dataInstructionsCount) {
      let pagesNumberTmp = Math.ceil(
        dataInstructionsCount.instruction.length / limit
      );
      setPagesNumber(pagesNumberTmp);
    }
  }, [dataInstructionsCount]);

  useEffect(() => {
    if (dataInstructions) {
      const instructionsTmp = dataInstructions.instruction.map((e: any) => {
        const instructionValueFr = e.instruction_i18ns.filter(
          (f: any) => f.language.language_code === LANGUAGES.FR_FR
        )[0].value;
        return {
          customData: {
            ...e,
          },
          rowValues: [
            instructionValueFr,
            <InstructionStatus active={e.active}>
              {e.active ? "Activé" : "Inactif"}
            </InstructionStatus>,
          ],
        };
      });
      setInstructions(instructionsTmp);
    }
  }, [dataInstructions]);

  const handleChangeCurrentPage = (value: number) => {
    if (value > 0 && value <= pagesNumber && value !== currentPage) {
      setCurrentPage(value);
    }
  };

  const handleClickInstruction = (index: number) => {
    const instruction = instructions?.[index].customData;
    instructionCxt.setInstructionToEdit(instruction);
    localStorage.setItem("currentInstruction", JSON.stringify(instruction));
    history.push("/instructions/update");
  };

  return (
    <PageWrapper>
      <Container>
        <CustomList
          title="Liste des instructions"
          columns={columns}
          data={instructions}
          pagesNumber={pagesNumber}
          currentPage={currentPage}
          handleChangeCurrentPage={handleChangeCurrentPage}
          displaySearchBar={false}
          handleClick={(i: number) => handleClickInstruction(i)}
          displayDropdownBar
          dropdownBarValues={instructionsTypes}
          handleDropdownChange={(v: string) => {
            setCurrentPage(1);
            setCurrentInstructionTypeId(v);
          }}
        />
      </Container>
    </PageWrapper>
  );
};

export default InstructionsList;

const Container = styled.div`
  height: 100%;
  ${CardStyle2}
  max-width: 1030px;
  min-width: 566px;
  margin: 0 auto;
`;

const InstructionStatus = styled.div<{ active: boolean }>`
  color: ${({ active, theme }) =>
    active ? theme.colors.darkGreenMain : theme.colors.redMain};
`;
