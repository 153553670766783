import { getFirebaseToken } from "./auth";

export const deleteOrganizationFile = async (
  fileName: string,
  fileId: string,
  fileType: string
) => {
  try {
    const token = await getFirebaseToken();
    const res = await fetch(
      `${process.env.REACT_APP_AUTH_SERVER_URL}/delete-organization-file`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify({ fileName, fileId, fileType }),
      }
    );
    if (res.ok) {
      return {
        error: null,
      };
    } else {
      return {
        error: "Error while deleting the file",
      };
    }
  } catch (err) {
    return {
      error: "Error while deleting the file",
    };
  }
};

export const uploadOrganizationFile = async (
  file: File,
  organizationId: string,
  fileType: string
) => {
  const data = new FormData();
  data.append("file", file);
  data.append("organizationId", organizationId);
  data.append("fileType", fileType);
  try {
    const token = await getFirebaseToken();
    const res = await fetch(
      `${process.env.REACT_APP_AUTH_SERVER_URL}/upload-organization-file`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        method: "POST",
        body: data,
      }
    );
    if (res.ok) {
      const resJson = await res.json();
      return {
        fileName: resJson.fileName,
        error: null,
      };
    } else {
      return {
        fileName: null,
        error: "Error while uploading the file",
      };
    }
  } catch (err) {
    return {
      fileName: null,
      error: "Error while uploading the file",
    };
  }
};

export const downloadOrganizationFile = async (
  fileName: string,
  fileType: string
) => {
  try {
    const token = await getFirebaseToken();
    const res = await fetch(
      `${process.env.REACT_APP_AUTH_SERVER_URL}/download-organization-file`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify({ fileName, fileType }),
      }
    );
    if (res.ok) {
      const resJson = await res.json();
      return {
        dataBase64: resJson.data,
        error: null,
      };
    } else {
      return {
        dataBase64: null,
        error: "Error while downloading the file",
      };
    }
  } catch (err) {
    return {
      dataBase64: null,
      error: "Error while downloading the file",
    };
  }
};
