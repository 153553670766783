import React, { useState } from "react";
import styled from "styled-components";
import {
  BodyMain, BodySmall, Heading2, Heading3, Heading4, Heading5
} from "../../../theme/fonts";
import { ReactComponent as SortIcon } from "../../../theme/icons/sort.svg";
import Dropdown from "../../Dropdown/Dropdown";
import { Loader } from "../../Loader/Loader";
import SearchBar from "../../SearchBar/SearchBar";
import PaginationButtons from "../PaginationButtons/PaginationButtons";
interface RowType {
  customData: any;
  rowValues: Array<string>;
}

interface CustomListProps {
  title: string;
  data: Array<RowType> | null;
  pagesNumber: number;
  currentPage: number;
  handleChangeCurrentPage: (value: number) => void;
  columns: Array<{
    value: string;
    ratio: string;
    type?: string;
    sortable?: boolean;
    sortId?: string;
  }>;
  displaySearchBar?: boolean;
  searchBarValue?: string;
  setSearchBarValue?: (value: string) => void;
  handleSearch?: (value: string) => void;
  handleClick?: (index: number) => void;
  handleClickSort?: (sortId: string) => void;
  activeRowStyle?: boolean;
  displayDropdownBar?: boolean;
  dropdownBarValues?: Array<{ value: string; label: string }> | null;
  handleDropdownChange?: (value: string) => void;
}

const CustomList: React.FC<CustomListProps> = ({
  title,
  data,
  pagesNumber,
  currentPage,
  handleChangeCurrentPage,
  columns,
  displaySearchBar,
  searchBarValue,
  setSearchBarValue,
  handleSearch,
  handleClick,
  handleClickSort,
  activeRowStyle,
  displayDropdownBar,
  dropdownBarValues,
  handleDropdownChange,
}) => {
  const [currentRowSelectedIndex, setCurrentRowSelectedIndex] = useState(-1);

  return (
    <ListContainer>
      <ListHeader>
        <ListHeaderTitle>{title}</ListHeaderTitle>
        {displaySearchBar && (
          <ListHeaderSearch>
            <SearchBar
              searchBarValue={searchBarValue!}
              setSearchBarValue={setSearchBarValue!}
              sendQuery={handleSearch!}
            />
          </ListHeaderSearch>
        )}
        {displayDropdownBar && handleDropdownChange && dropdownBarValues && (
          <ListHeaderDropdownContainer>
            <DropdownContainer>
              <Dropdown
                options={dropdownBarValues}
                defaultValue={dropdownBarValues?.[0]}
                handleOnChange={(data) => handleDropdownChange(data.value)}
                borderRadius="36px"
              />
            </DropdownContainer>
            {/* <ListHeaderDropdown
              onChange={(e) => handleDropdownChange(e.target.value)}
            >
              {dropdownBarValues?.map((e, i) => (
                <option key={i} value={e.value}>
                  {e.label}
                </option>
              ))}
            </ListHeaderDropdown>
            <ArrowDownIcon /> */}
          </ListHeaderDropdownContainer>
        )}
      </ListHeader>
      <ListSeparatorContainer>
        <ListSeparator />
      </ListSeparatorContainer>
      <ListRowsContainer>
        <ListRowsHeader>
          {columns.map((e, i) => (
            <ListRowsHeaderItem
              key={i}
              ratio={e.ratio}
              sortable={e.sortable}
              onClick={() =>
                e.sortable && e.sortId && handleClickSort
                  ? handleClickSort(e.sortId)
                  : null
              }
            >
              {e.value}
              {e.sortable && (
                <SortIconContainer>
                  <SortIcon />
                </SortIconContainer>
              )}
            </ListRowsHeaderItem>
          ))}
        </ListRowsHeader>
        <ListRowsCore>
          {!data && (
            <LoaderContainer>
              <Loader />
            </LoaderContainer>
          )}
          {data?.map((row: RowType, i: number) => {
            return (
              <ListRow
                active={!!activeRowStyle && i === currentRowSelectedIndex}
                even={i % 2 === 0}
                key={i}
                onClick={() => {
                  if (handleClick) {
                    setCurrentRowSelectedIndex(i);
                    handleClick(i);
                  }
                }}
              >
                {row.rowValues.map((e, j) => (
                  <ListRowItem key={j} ratio={columns[j].ratio}>
                    <span>{e}</span>
                  </ListRowItem>
                ))}
              </ListRow>
            );
          })}
        </ListRowsCore>
        {data && (
          <ListRowPagination>
            <PaginationButtons
              pagesNumber={pagesNumber}
              currentPage={currentPage}
              setCurrentPage={(value: number) => handleChangeCurrentPage(value)}
            />
          </ListRowPagination>
        )}
      </ListRowsContainer>
    </ListContainer>
  );
};

export default CustomList;

const ListContainer = styled.div`
  padding: 10px;
`;

const ListHeader = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 18px;
`;

const ListHeaderTitle = styled.div`
  ${Heading2}

  @media screen and (max-width: ${(props) =>
    props.theme.breakpoints.laptop13Max}) {
    ${Heading3}
  }
`;

const ListHeaderSearch = styled.div``;

const ListSeparatorContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const ListSeparator = styled.div`
  width: 100%;
  height: 4px;
  background: linear-gradient(#095c66 0%, #209aa1 100%);
`;

const ListRowsContainer = styled.div`
  padding: 27px 0;
`;

const ListRowsHeader = styled.div`
  width: calc(100% - 0.9em); /* Sub scrollbar  */
  display: flex;
  padding: 0 20px 10px 20px;
`;

const ListRowsHeaderItem = styled.div<{ ratio: string; sortable?: boolean }>`
  display: flex;
  align-items: center;
  cursor: ${({ sortable }) => (sortable ? "pointer" : "")};
  ${Heading4}
  width: ${({ ratio }) => ratio};
  text-transform: uppercase;

  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.laptop13Max}) {
    ${Heading5}
  }
`;

const ListRowsCore = styled.div`
  height: 500px; // TODO check for responsivness
  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.laptop13Max}) {
    height: 380px; // TODO check for responsivness
  }
  overflow-y: scroll;
`;

const ListRow = styled.div<{ even: boolean; active: boolean }>`
  width: calc(100%); /* Sub scrollbar  */
  padding: 20px;
  display: flex;
  cursor: pointer;
  div:nth-child(-n + 2) {
    ${Heading4}

    @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.laptop13Max}) {
      ${Heading5}
    }
  }

  div:nth-child(n + 3) {
    ${BodyMain}

    @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.laptop13Max}) {
      ${BodySmall}
    }
  }
  color: ${({ active, theme }) =>
    active ? theme.colors._white : theme.colors.blackMain};
  background: ${({ theme, even, active }) =>
    active
      ? `linear-gradient(135.02deg, #095c66 0%, #209aa2 100%);`
      : even
      ? theme.colors.darkGreenL1
      : theme.colors.lightGreenL1};
  border-radius: 12px;
  margin: 7px 0;
`;

const ListRowItem = styled.div<{ ratio: string }>`
  width: ${({ ratio }) => ratio};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 10px;
`;

const ListRowPagination = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
`;

const LoaderContainer = styled.div`
  margin: 100px;
`;

const SortIconContainer = styled.div`
  margin-left: 5px;
`;

const ListHeaderDropdownContainer = styled.div`
  box-shadow: -6px -6px 12px 0.5px rgba(197, 197, 197, 0.21),
    6px 6px 12px 0.5px rgba(197, 197, 197, 0.21);
  border-radius: 36px;

  ${Heading4}
  @media screen and (max-width: ${(props) =>
    props.theme.breakpoints.laptop13Max}) {
    ${Heading5}
  }
`;

const DropdownContainer = styled.div`
  width: 200px;
  padding: 0 10px;
`;
