import React, { ReactNode, useEffect, useState, useRef } from "react";
import styled from "styled-components";
import { logout } from "../../utils/auth";
import { COOKIES } from "../../constants/cookies_types";
import cookie from "js-cookie";
import { Modal, ModalBody, ModalFooter } from "reactstrap";
import { Heading3 } from "../../theme/fonts";
import CustomButton from "../CustomButton/CustomButton";

const TWO_HOURS_IN_MS = 1000 * 60 * 60 * 2;
const FITEEN_MINUTES_IN_MS = 1000 * 60 * 15;

const SessionExpirationModal: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [sessionExpirationVariable, setSessionExpirationVariable] =
    useState<any>(null);
  const sessionExpirationVariableRef = useRef<any>();
  const [redirectionVariable, setRedirectionVariable] = useState<any>(null);
  const redirectionVariableRef = useRef<any>();

  const [showSessionExpirationModal, setShowSessionExpirationModal] =
    useState(false);

  const isLessThanTwoHours = (cookieExpirationSession: string) => {
    return Date.now() < parseInt(cookieExpirationSession);
  };

  const isInFifteenMinutesInterval = () => {
    const a = cookie.get(COOKIES.EXPIRATION_SESSION);
    return parseInt(a!) - Date.now() <= FITEEN_MINUTES_IN_MS;
  };

  const setSessionExpirationTimeout = (extendSession: boolean) => {
    if (redirectionVariable) {
      clearTimeout(redirectionVariable!);
    }
    if (sessionExpirationVariable) {
      clearTimeout(sessionExpirationVariable!);
    }
    if (extendSession) {
      cookie.set(
        COOKIES.EXPIRATION_SESSION,
        (Date.now() + TWO_HOURS_IN_MS).toString()
      );
    }
    if (!extendSession && isInFifteenMinutesInterval()) {
      setShowSessionExpirationModal(true);
    } else {
      const currentTimeout = setTimeout(
        () => {
          setShowSessionExpirationModal(true);
        },
        extendSession
          ? TWO_HOURS_IN_MS - FITEEN_MINUTES_IN_MS
          : parseInt(cookie.get(COOKIES.EXPIRATION_SESSION)!) -
              Date.now() -
              FITEEN_MINUTES_IN_MS
      ); // After 1h45 show session expiration modal
      sessionExpirationVariableRef.current = currentTimeout;
      setSessionExpirationVariable(currentTimeout);
    }
  };

  useEffect(() => {
    if (showSessionExpirationModal) {
      const currentTimeout = setTimeout(() => {
        setShowSessionExpirationModal(false);
        logout("/session-expired");
      }, parseInt(cookie.get(COOKIES.EXPIRATION_SESSION)!) - Date.now()); //after 15 minutes redirect to /session-expired
      redirectionVariableRef.current = currentTimeout;
      setRedirectionVariable(currentTimeout);
    }
  }, [showSessionExpirationModal]);

  useEffect(() => {
    const cookieExpirationSession = cookie.get(COOKIES.EXPIRATION_SESSION);
    if (
      !cookieExpirationSession ||
      !isLessThanTwoHours(cookieExpirationSession)
    ) {
      logout("/session-expired");
    } else {
      setSessionExpirationTimeout(false);
    }
    return () => {
      if (redirectionVariableRef.current) {
        clearTimeout(redirectionVariableRef.current);
      }
      if (sessionExpirationVariableRef.current) {
        clearTimeout(sessionExpirationVariableRef.current);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {children}
      <ModalContainer isOpen={showSessionExpirationModal} centered={true}>
        <ModalBodyContainer>
          Votre session va bientot expirer, veuillez cliquer sur continuer pour
          ne pas être déconnecté
        </ModalBodyContainer>
        <ModalFooter>
          <CustomButton
            type="button"
            handleOnClick={() => {
              setSessionExpirationTimeout(true);
              setShowSessionExpirationModal(false);
            }}
            content="continuer"
          />
        </ModalFooter>
      </ModalContainer>
    </>
  );
};

const ModalContainer = styled(Modal)``;

const ModalBodyContainer = styled(ModalBody)`
  ${Heading3};
  text-align: center;
`;

export default SessionExpirationModal;
