import { getFirebaseToken } from "./auth";

export const createEmbeddedTemplateDraft = async (
  data: FormData
): Promise<{
  error: string | null;
  templateUrl: string | null;
}> => {
  try {
    const token = await getFirebaseToken();
    const res = await fetch(
      `${process.env.REACT_APP_ESIGN_URL}/create-embedded-template-draft`,
      {
        headers: {
          "Authorization": `Bearer ${token}`,
        },
        method: "POST",
        body: data,
      }
    );
    const resJson = await res.json();
    if (resJson.error) {
      return { error: resJson.error, templateUrl: null };
    } else {
      return { error: null, templateUrl: resJson.templateUrl };
    }
  } catch (err) {
    console.log(err);
    return { error: "Error", templateUrl: null };
  }
};

export const getTemplate = async (
  templateId: string
): Promise<{
  error: string | null;
  //   templateBase64: string | null;
  templateUrl: string | null;
}> => {
  try {
    const token = await getFirebaseToken();
    const res = await fetch(`${process.env.REACT_APP_ESIGN_URL}/get-template`, {
      headers: {
        "Authorization": `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify({ templateId }),
    });
    const resJson = await res.json();
    if (resJson.error) {
      return { error: resJson.error, templateUrl: null };
    } else {
      return { error: null, templateUrl: resJson.templateUrl };
    }
  } catch (err) {
    console.log(err);
    return { error: "Error", templateUrl: null };
  }
};

export const sendEmbeddedSignatureRequestWithTemplate = async (data: {
  organizationId: string;
  templateId: string;
  patient: {
    id: string;
    name: string;
    surname: string;
  };
  parent1: {
    name: string;
    surname: string;
    email: string;
  };
  parent2: {
    email: string;
  };
  surgeon1: {
    name: string;
    surname: string;
    email: string;
  };
  surgery: {
    id: string;
    value: string;
  };
}): Promise<{
  error: string | null;
}> => {
  try {
    const token = await getFirebaseToken();
    const res = await fetch(
      `${process.env.REACT_APP_ESIGN_URL}/send-embedded-signature-request-template`,
      {
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify({
          ...data,
        }),
      }
    );
    const resJson = await res.json();
    if (resJson.error) {
      return { error: resJson.error };
    } else {
      return { error: null };
    }
  } catch (err) {
    console.log(err);
    return { error: "Une erreur est survenue" };
  }
};

export const listSignaturesRequestData = async (): Promise<{
  error: string | null;
  signaturesRequestData: Array<string> | null;
}> => {
  try {
    const token = await getFirebaseToken();
    const res = await fetch(
      `${process.env.REACT_APP_ESIGN_URL}/list-signatures-request`,
      {
        headers: {
          "Authorization": `Bearer ${token}`,
        },
        method: "GET",
      }
    );
    const resJson = await res.json();
    if (resJson.error) {
      return { error: resJson.error, signaturesRequestData: null };
    } else {
      return {
        error: null,
        signaturesRequestData: resJson.signaturesRequestData,
      };
    }
  } catch (err) {
    return { error: "Une erreur est survenue.", signaturesRequestData: null };
  }
};
