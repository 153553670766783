import React, { useEffect, useState } from "react";
import zxcvbn from "zxcvbn";
import { Progress } from "reactstrap";
import styled from "styled-components";

interface PasswordStrengthMeterProps {
  password: string;
}

const PasswordStrengthMeter: React.FC<PasswordStrengthMeterProps> = ({
  password,
}) => {
  const [score, setScore] = useState({ data: 0, color: "", text: "" });

  useEffect(() => {
    if (password) {
      const { score } = zxcvbn(password);
      switch (score) {
        case 0:
          setScore({ data: 20, color: "danger", text: "Faible" });
          break;
        case 1:
          setScore({ data: 40, color: "warning", text: "Moyen" });
          break;
        case 2:
          setScore({ data: 60, color: "info", text: "Bon" });
          break;
        case 3:
          setScore({ data: 80, color: "success", text: "Très bon" });
          break;
        case 4:
          setScore({ data: 100, color: "success", text: "Excellent" });
          break;
        default:
          break;
      }
    } else {
      setScore({ data: 0, color: "", text: "" });
    }
  }, [password]);

  return (
    <Container>
      <Progress animated color={score.color} value={score.data}>
        {score.text}
      </Progress>
    </Container>
  );
};

const Container = styled.div``;

export default PasswordStrengthMeter;
