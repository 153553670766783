import React from "react";
import styled, { css } from "styled-components";
import { Heading3 } from "../../../theme/fonts";
import { ReactComponent as IconArrowLeft } from "../../../theme/icons/arrowLeft.svg";
import { ReactComponent as IconArrowRight } from "../../../theme/icons/arrowRight.svg";

interface PaginationButtonsProps {
  pagesNumber: number;
  currentPage: number;
  setCurrentPage: (value: number) => void;
}

const PaginationButtons: React.FC<PaginationButtonsProps> = ({
  pagesNumber,
  currentPage,
  setCurrentPage,
}) => {
  const renderPagination = () => {
    if (pagesNumber <= 5) {
      return (
        <>
          {new Array(pagesNumber).fill(null).map((e, i) => {
            return (
              <PageNumber
                key={i}
                active={currentPage === i + 1}
                onClick={() => setCurrentPage(i + 1)}
              >
                {i + 1}
              </PageNumber>
            );
          })}
        </>
      );
    } else {
      if (
        currentPage === 1 ||
        currentPage === 2 ||
        currentPage === pagesNumber ||
        currentPage === pagesNumber - 1
      ) {
        return (
          <>
            <PageNumber
              active={currentPage === 1}
              onClick={() => setCurrentPage(1)}
            >
              1
            </PageNumber>
            <PageNumber
              active={currentPage === 2}
              onClick={() => setCurrentPage(2)}
            >
              2
            </PageNumber>
            <DotsContainer>
              <Dots />
              <Dots />
              <Dots />
            </DotsContainer>
            <PageNumber
              active={currentPage === pagesNumber - 1}
              onClick={() => setCurrentPage(pagesNumber - 1)}
            >
              {pagesNumber - 1}
            </PageNumber>
            <PageNumber
              active={currentPage === pagesNumber}
              onClick={() => setCurrentPage(pagesNumber)}
            >
              {pagesNumber}
            </PageNumber>
          </>
        );
      } else {
        return (
          <>
            <PageNumber
              active={currentPage === 1}
              onClick={() => setCurrentPage(1)}
            >
              1
            </PageNumber>
            <DotsContainer>
              <Dots />
              <Dots />
              <Dots />
            </DotsContainer>
            <PageNumber active={true}>{currentPage}</PageNumber>
            <DotsContainer>
              <Dots />
              <Dots />
              <Dots />
            </DotsContainer>
            <PageNumber
              active={currentPage === pagesNumber}
              onClick={() => setCurrentPage(pagesNumber)}
            >
              {pagesNumber}
            </PageNumber>
          </>
        );
      }
    }
  };

  return (
    <Container>
      {pagesNumber > 1 && (
        <ArrowContainer onClick={() => setCurrentPage(currentPage - 1)}>
          <IconArrowLeft />
        </ArrowContainer>
      )}
      {renderPagination()}
      {pagesNumber > 1 && (
        <ArrowContainer onClick={() => setCurrentPage(currentPage + 1)}>
          <IconArrowRight />
        </ArrowContainer>
      )}
    </Container>
  );
};

export default PaginationButtons;

const shadow = css`
  box-shadow: -1px -1px 2px rgba(71, 71, 71, 0.11),
    1px 1px 2px rgba(71, 71, 71, 0.11);
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  user-select: none;
`;

const ArrowContainer = styled.div`
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 6px;
  cursor: pointer;
  color: ${({ theme }) => theme.colors._white};
`;

const PageNumber = styled.div<{ active: boolean }>`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 6px;
  background: ${({ theme, active }) =>
    active ? theme.colors.darkGreenL4 : theme.colors.darkGreenL1};
  cursor: pointer;
  color: ${({ theme }) => theme.colors._white};
  ${Heading3}
  ${shadow}
`;

const DotsContainer = styled.div`
  display: flex;
`;

const Dots = styled.div`
  width: 4px;
  height: 4px;
  border-radius: 50%;
  margin: 0 2px;
  background: ${({ theme }) => theme.colors.darkGreenL3};
  ${shadow}
`;
