import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import styled from "styled-components";
import { ThemeComponents } from "../../../theme/components";
import { BodyMain, BodySmall } from "../../../theme/fonts";

type Props = {
  name: string;
  label?: string;
  sublabel?: string;
  placeholder?: string;
  required?: boolean;
  form: any;
  field: any;
  disabled?: boolean;
};

export const DatePickerForm: React.FunctionComponent<Props> = ({
  form,
  label,
  sublabel,
  placeholder,
  field,
  required,
  disabled,
}) => {
  const [value, setValue] = useState<Date | null>(null);
  const error = form.errors[field.name];
  const touched = form.touched[field.name];

  useEffect(() => {
    if (field.value) {
      setValue(new Date(field.value));
    } else {
      setValue(null);
    }
  }, [field.value]);

  return (
    <DatePickerContainer disabled={disabled}>
      <DatePickerRow noGutters>
        <Col xs={12}>
          <DatePickerLabelContainer>
            <DatePickerLabel>{label}</DatePickerLabel>
            <DatePickerSubLabel>{sublabel}</DatePickerSubLabel>
          </DatePickerLabelContainer>
        </Col>
        <Col xs={12}>
          <DatePickerButton
            {...field}
            value={value}
            placeholderText={
              placeholder && required ? `${placeholder + " *"}` : placeholder
            }
            selected={value}
            onChange={(val: string) => {
              form.setFieldValue(field.name, val);
            }}
            error={error}
            touched={touched}
            dateFormat={"dd/MM/yyyy"}
            showYearDropdown
            minDate={new Date("01-01-1970")}
            maxDate={new Date("01-01-2070")}
          />
          <DatePickerIcon>
            <i className="ni ni-calendar-grid-58" />
          </DatePickerIcon>
          {touched && error && <DatePickerError>{error}</DatePickerError>}
        </Col>
      </DatePickerRow>
    </DatePickerContainer>
  );
};

const DatePickerContainer = styled.div<{ disabled?: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: 0;
  position: relative;

  /* Overide DatePicker component style  */
  & {
    .react-datepicker-wrapper,
    .react-datepicker__input-container {
      display: block;
    }

    .react-datepicker-wrapper .react-datepicker__input-container input {
      width: 100%;
    }

    input {
      ${BodyMain};
      color: ${(props) => props.theme.colors.blackMain};
      height: 45px;
      border-radius: 6px;
      padding-left: 15px;
      padding-right: 45px;
      background: ${(props) =>
        props.disabled
          ? props.theme.colors.darkGreenL1
          : props.theme.colors.darkGreenL1};
      border: ${(props) => props.theme.componentObjects.formContainer.border};
      box-sizing: ${(props) =>
        props.theme.componentObjects.formContainer.borderBox};

      ::placeholder {
        ${BodyMain};
        color: ${(props) => props.theme.colors.darkGreenL3};

        @media screen and (max-width: ${(props) =>
            props.theme.breakpoints.laptop13Max}) {
          ${BodySmall};
        }
      }
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      position: relative;

      @media screen and (max-width: ${(props) =>
          props.theme.breakpoints.laptop13Max}) {
        height: 32px;
        ${BodySmall};
      }

      @media screen and (max-width: ${(props) =>
          props.theme.breakpoints.mobilePortraitMax}) {
        height: 36px;
      }
    }
  }
`;

const DatePickerRow = styled(Row)`
  width: 100%;
`;

const DatePickerLabelContainer = styled.div`
  margin: 5px 15px 5px 0;
`;

const DatePickerLabel = styled(ThemeComponents.FormLabel)``;

const DatePickerSubLabel = styled(ThemeComponents.FormSubLabel)``;

const DatePickerError = styled(ThemeComponents.FormErrorLabel)``;

const DatePickerButton = styled(DatePicker)<{
  error: string;
  touched: boolean;
}>`
  :focus-within {
    outline: none !important;
    border: 1px solid "#262626" !important; //TODO theme
  }
  border-radius: ${(props) =>
    props.theme.componentObjects.formContainer.borderRadius};
  box-sizing: ${(props) =>
    props.theme.componentObjects.formContainer.borderBox};
  border: ${(props) => {
    const colorType =
      !!props.error && props.touched
        ? props.theme.colors.red.dark
        : props.theme.colors.white.darker;
    return `1px solid ${colorType} !important`;
  }};
`;

const DatePickerIcon = styled.div`
  position: absolute;
  top: 10px;
  right: 15px;
`;
