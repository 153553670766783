import { useMutation, useQuery } from "@apollo/client";
import * as Sentry from "@sentry/react";
import { loader } from "graphql.macro";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import FormEstablishment from "../../components/FormTemplates/Establishment/FormEstablishment";
import { PageWrapper } from "../../components/Layout/PageWrapper/PageWrapper";
import { Loader } from "../../components/Loader/Loader";
import { displayToastNotification } from "../../utils/toastNotification";

const updateOrganizationHospitalServiceQuery = loader(
  "../../graphql/updateOrganizationHospitalService.graphql"
);
const updateOrganizationLiberalPracticeQuery = loader(
  "../../graphql/updateOrganizationLiberalPractice.graphql"
);

const updateAddressQuery = loader("../../graphql/updateAddress.graphql");

const getSurgeriesTypeQuery = loader("../../graphql/getSurgeriesType.graphql");

const addOrganizationSurgeryTypeQuery = loader(
  "../../graphql/addOrganizationSurgeryType.graphql"
);

const deleteOrganizationSurgeryTypeQuery = loader(
  "../../graphql/deleteOrganizationSurgeryType.graphql"
);

interface Values {
  id?: string;
  name: string;
  phone: string;
  access: string;
  am?: string;
  organization_type: string;
  hospital_id: string;
  surgeriesType?: Array<{ label: string; value: string; checked: boolean }>;
  surveysType?: Array<{ label: string; value: string; checked: boolean }>;
  addressId?: string;
  addressNumber?: string;
  addressStreet?: string;
  addressCity?: string;
  addressPostalCode?: string;
}

interface EstablishmentsUpdateProps {}

const EstablishmentsUpdate: React.FC<EstablishmentsUpdateProps> = () => {
  const history = useHistory();

  const [updateOrganizationHospitalService] = useMutation(
    updateOrganizationHospitalServiceQuery
  );
  const [updateOrganizationLiberalPractice] = useMutation(
    updateOrganizationLiberalPracticeQuery
  );

  const [updateAddress] = useMutation(updateAddressQuery);

  const [addOrganizationSurgeryType] = useMutation(
    addOrganizationSurgeryTypeQuery
  );

  const [deleteOrganizationSurgeryType] = useMutation(
    deleteOrganizationSurgeryTypeQuery
  );

  const { data: dataSurgeriesType } = useQuery(getSurgeriesTypeQuery);

  const [initialValues, setInitialValues] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  //TODO refacto this same logic !
  useEffect(() => {
    if (!dataSurgeriesType) {
      return;
    }
    let organizationTmp: any;
    const localStorageOrganization = localStorage.getItem(
      "currentEstablishment"
    );
    if (!localStorageOrganization) {
      history.push("/establishments/list");
    }
    if (localStorageOrganization) {
      organizationTmp = { ...JSON.parse(localStorageOrganization) };
    }
    if (organizationTmp.liberal_practice) {
      organizationTmp.access = organizationTmp.liberal_practice.access;
      organizationTmp.phone = organizationTmp.liberal_practice.phone;
      organizationTmp.am = organizationTmp.liberal_practice.am;
      organizationTmp.addressId = organizationTmp.liberal_practice.address.id;
      organizationTmp.addressNumber =
        organizationTmp.liberal_practice.address.number;
      organizationTmp.addressStreet =
        organizationTmp.liberal_practice.address.street;
      organizationTmp.addressCity =
        organizationTmp.liberal_practice.address.city;
      organizationTmp.addressPostalCode =
        organizationTmp.liberal_practice.address.postal_code;
    } else if (organizationTmp.hospital_service) {
      organizationTmp.access = organizationTmp.hospital_service.access;
      organizationTmp.phone = organizationTmp.hospital_service.phone;
      organizationTmp.hospital_id =
        organizationTmp.hospital_service.hospital.id;
    }
    organizationTmp.surgeriesType = dataSurgeriesType.surgery_type.map(
      (e: any) => {
        const surgeryIsChecked =
          organizationTmp.organization_surgery_types?.some(
            (f: any) => f.surgery_type.id === e.id
          );
        return { label: e.value, value: e.id, checked: surgeryIsChecked };
      }
    );
    delete organizationTmp.liberal_practice;
    delete organizationTmp.hospital_service;
    delete organizationTmp.organization_surgery_types;
    setInitialValues(organizationTmp);
  }, [history, dataSurgeriesType]);

  useEffect(() => {
    if (initialValues) {
      setIsLoading(false);
    }
  }, [initialValues]);

  const modifyOrganization = async (values: Values) => {
    const variableToUpdate = {
      id: values.id,
      name: values.name,
      access: values.access,
      phone: values.phone,
    };
    try {
      //Delete all previous surgeries type
      await deleteOrganizationSurgeryType({
        variables: { organizationId: variableToUpdate.id },
      });
      //Add organization surgeries type
      const organizationSurgeryType = values.surgeriesType
        ?.filter((e) => e.checked)
        .map((e) => {
          return {
            organization_id: variableToUpdate.id,
            surgery_type_id: e.value,
          };
        });
      await addOrganizationSurgeryType({
        variables: { objects: organizationSurgeryType },
      });
      if (values.organization_type === "hospital_service") {
        await updateOrganizationHospitalService({
          variables: variableToUpdate,
        });
      } else if (values.organization_type === "liberal_practice") {
        await updateOrganizationLiberalPractice({
          variables: { ...variableToUpdate, am: values.am },
        });
        await updateAddress({
          variables: {
            id: values.addressId,
            number: values.addressNumber,
            street: values.addressStreet,
            city: values.addressCity,
            postalCode: values.addressPostalCode,
          },
        });
      }

      console.log("Establishment updated");
      displayToastNotification(
        "success",
        "Les modifications ont bien été enregistrées"
      );
    } catch (err) {
      console.error(err);
      displayToastNotification("error", "Une erreur est survenue");
      Sentry.captureException(err);
    }
  };

  const handleSubmit = async (values: Values) => {
    await modifyOrganization(values);
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <PageWrapper
      previousPageIconOn={true}
      previousPageText={"Retour à la liste des établissements"}
      handleOnClickPreviousPage={() => history.push("/establishments/list")}
    >
      <Container>
        <FormEstablishment
          title="Modifier l'établissement"
          initialValues={initialValues!}
          mode="update"
          handleSubmit={(values) => handleSubmit(values)}
        />
      </Container>
    </PageWrapper>
  );
};

export default EstablishmentsUpdate;

const Container = styled.div`
  height: 100%;
  margin: 0 auto;
  max-width: 1030px;
`;
