import styled, { css } from "styled-components";
import { BodyMain, BodySmall, Heading4 } from "../fonts";

/***************** GENERICS STYLED COMPONENTS FOR FORMS ******************/

const FormContainer = css`
  width: 100%;
  height: 45px;
  border-radius: 6px;
  padding: 8px;
  ${BodyMain};
  /*  */
  background: ${(props) =>
    props.theme.componentObjects.formContainer.background};
  border: ${(props) => props.theme.componentObjects.formContainer.border};
  box-sizing: ${(props) =>
    props.theme.componentObjects.formContainer.boxSizing};
  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.laptop13Max}) {
    height: 32px;
    ${BodySmall};
  }

  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.mobilePortraitMax}) {
    height: 36px;
  }
`;

const InputPlaceholder = css`
  ::-webkit-input-placeholder {
    color: ${(props) => props.theme.colors.darkGreenL3};
  }

  ::-moz-placeholder {
    color: ${(props) => props.theme.colors.darkGreenL3};
    opacity: 1;
  }

  :-ms-input-placeholder {
    color: ${(props) => props.theme.colors.darkGreenL3};
  }
`;

const InputFocus = css`
  :focus {
    outline: none !important;
    border: 1px solid
      ${(props) => props.theme.componentObjects.formContainer.borderFocus};
  }
`;

const TextEllipsis = css`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
`;

export const FormInput = styled.input`
  ${FormContainer}
  ${InputPlaceholder}
  ${InputFocus}
  ${TextEllipsis}
`;

export const FormLongInput = styled.textarea`
  ${FormContainer}
  ${InputPlaceholder}
  ${InputFocus}
  height: 120px !important;
  resize: none;
`;

export const FormLabel = styled.div`
  color: ${(props) => props.theme.componentObjects.formLabel.color};
  margin-bottom: 7px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  ${Heading4};
  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.laptop13Max}) {
    ${Heading4}
  }
`;

export const FormSubLabel = styled.div`
  font-weight: ${(props) =>
    props.theme.componentObjects.formSubLabel.fontWeight};
  font-size: ${(props) => props.theme.componentObjects.formSubLabel.fontSize};
  line-height: ${(props) =>
    props.theme.componentObjects.formSubLabel.lineHeight};
  color: ${(props) => props.theme.componentObjects.formSubLabel.color};
  margin-bottom: 5px;
`;

export const FormErrorLabel = styled.div`
  ${BodySmall};
  color: ${(props) => props.theme.componentObjects.formErrorLabel.color};
  white-space: normal;
  position: absolute;
  top: 48px;
  left: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  z-index: 1;
  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.laptop13Max}) {
    top: 34px;
  }

  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.mobilePortraitMax}) {
    top: 40px;
  }
`;

export const FormDropdown = styled.select`
  ${FormContainer}
  :focus {
    outline: none !important;
    border: 1px solid
      ${(props) => props.theme.componentObjects.formContainer.borderFocus};
  }
  -moz-appearance: none;
  -webkit-appearance: none;
  ::-ms-expand {
    display: none;
  }
`;

export const FormImport = styled.button.attrs({ type: "button" })`
  ${FormContainer}
  ${InputFocus}
  ${TextEllipsis}
  text-align: left;
`;

export const FormImportNameFile = styled.div`
  ${FormContainer}
  ${InputFocus}
  ${TextEllipsis}
  text-align: left;
`;

export const FormSimpleCheck = styled.button.attrs({ type: "button" })`
  ${FormContainer}
  user-select: none;
  text-align: left;
  height: auto !important;
  :focus-within {
    outline: none !important;
    border: 1px solid
      ${(props) => props.theme.componentObjects.formContainer.borderFocus} !important;
  }
`;

/***************** GENERICS STYLED COMPONENTS FOR CARDS ******************/

/* For auth card */
export const CardStyle1 = css`
  background: ${(props) => props.theme.colors._white};
  box-shadow: -6px -6px 12px 0.5px rgba(49, 49, 49, 0.21),
    6px 6px 12px 0.5px rgba(49, 49, 49, 0.21);
  border-radius: 24px;
  overflow-y: auto;
  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.mobilePortraitMax}) {
    padding: 10px;
  }
`;

/* For custom List */
export const CardStyle2 = css`
  background: ${(props) => props.theme.colors._white};
  box-shadow: -6px -6px 12px 0.5px rgba(197, 197, 197, 0.21),
    6px 6px 12px 0.5px rgba(197, 197, 197, 0.21);
  border-radius: 36px;
`;

/* Linear-gradient text */

export const LinearGradientText = css`
  background: -webkit-linear-gradient(#095c66, #209aa2);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;
