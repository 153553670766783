import { Field, Form, Formik } from "formik";
import React, { useRef, useState } from "react";
import { useEffect } from "react";
import SignaturePad from "signature_pad";
import styled from "styled-components";
import * as Yup from "yup";
import { BodySmall, Heading3, Heading4 } from "../../../theme/fonts";
import CustomButton from "../../CustomButton/CustomButton";
import Dropzone from "../../Dropzone";
import { TextInputForm } from "../../Form/TextInputForm/TextInputForm";
import TitleSectionForm from "../../Form/TitleSectionForm/TitleSectionForm";
import CustomContour from "../../Layout/CustomContour/CustomContour";

interface FormValues {
  rpps: string;
  signature: string;
  fileToUpload?: File | null;
  fileToUploadIsSet: boolean;
  currentFileName: string;
}

interface FormConfigurationProps {
  title: string;
  subtitle?: string;
  initialValues: FormValues;
  handleSubmit: (values: FormValues) => void;
}

const FILE_SIZE = 5 * 1024 * 1024; //5mb

const validationSchema = Yup.object().shape({
  rpps: Yup.string()
    .required("Ce champ est requis")
    .matches(/^[0-9]+$/, "Le nᵒ RPPS doit contenir uniquement des chiffres")
    .length(11, "Le nᵒ RPPS doit contenir 11 chiffres")
    .nullable(),
  fileToUpload: Yup.mixed().when("fileToUploadIsSet", {
    is: (val: boolean) => val,
    then: Yup.mixed()
      .test(
        "fileSize",
        "Le poids du fichier doit être au maximum 5mb",
        (value) => value && value.size <= FILE_SIZE
      )
      .test(
        "fileFormat",
        "Le fichier doit être un PNG ou JPEG",
        (value) => value && ["image/png", "image/jpeg"].includes(value.type)
      ),
  }),
});

const FormConfiguration: React.FC<FormConfigurationProps> = ({
  title,
  subtitle,
  initialValues,
  handleSubmit,
}) => {
  const canvasEl = useRef(null);
  const [signature, setSignature] = useState<SignaturePad | string>();
  const [signatureError, setSignatureError] = useState("");

  useEffect(() => {
    // if (initialValues.signature) {
    //   const signatureCanvas = canvasEl.current as unknown as HTMLCanvasElement;
    //   var ctx = signatureCanvas.getContext("2d");
    //   if (ctx) {
    //     var image = new Image();
    //     image.onload = function () {
    //       ctx!.drawImage(image, 0, 0);
    //     };
    //     image.src = initialValues.signature;
    //     setSignature(initialValues.signature);
    //   }
    // } else {
    // }
    handleClickOpenSignaturePad();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClickOpenSignaturePad = () => {
    setSignatureError("");
    const signatureCanvas = canvasEl.current as unknown as HTMLCanvasElement;
    const signature = new SignaturePad(signatureCanvas, {});
    setSignature(signature);
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={async (values: FormValues) => {
        if (signature instanceof SignaturePad && !signature.isEmpty()) {
          setSignatureError("");
          await handleSubmit({ ...values, signature: signature?.toDataURL() });
        } else if (values.currentFileName || values.fileToUpload) {
          await handleSubmit(values);
        } else if (!values.fileToUpload) {
          setSignatureError("Ce champ est requis");
          return;
        }
      }}
      validationSchema={validationSchema}
    >
      {(props) => {
        return (
          <FormContainer>
            <CustomContour title={title} subtitle={subtitle}>
              <Section>
                <TitleSectionForm title="Informations requises pour la prescription" />
                <FieldSection>
                  <FieldContainer>
                    <Field
                      name={"rpps"}
                      label={"Numéro RPPS"}
                      placeholder={"RPPS"}
                      component={TextInputForm}
                    />
                  </FieldContainer>
                </FieldSection>
                <FieldSection>
                  <FieldContainer>
                    <Field
                      name={"fileToUpload"}
                      label={"Signature existante (format PNG / JPEG)"}
                      placeholder={
                        props.values.currentFileName
                          ? "Votre signature est prise en compte ✅"
                          : "Cliquez pour sélectionner un fichier"
                      }
                      component={Dropzone}
                      acceptFile={["image/jpeg", "image/png"]}
                    />
                    <SignatureOr>OU</SignatureOr>
                    <SignatureLabel>Signer directement ici</SignatureLabel>
                    <SignatureCanvas
                      id="canvas-signature-prescription"
                      ref={canvasEl}
                      error={!!signatureError}
                    />

                    {signatureError && (
                      <SignatureError>{signatureError}</SignatureError>
                    )}
                    <div style={{ marginTop: "10px" }}>
                      <CustomButton
                        content="Réinitialiser la signature"
                        handleOnClick={handleClickOpenSignaturePad}
                        size="sm"
                      />
                    </div>
                  </FieldContainer>
                </FieldSection>
              </Section>
            </CustomContour>
            <ButtonContainer>
              <CustomButton
                type="submit"
                content="Sauvegarder"
                disabled={props.isSubmitting}
              />
            </ButtonContainer>
          </FormContainer>
        );
      }}
    </Formik>
  );
};

export default FormConfiguration;

const FormContainer = styled(Form)``;

const Section = styled.div`
  width: 100%;
`;

const FieldSection = styled.div`
  width: 100%;
  display: flex;
  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.tabletMax}) {
    flex-direction: column;
  }
`;

const FieldContainer = styled.div`
  width: 50%;
  margin: 16px 8px;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.tabletMax}) {
    width: 100%;
    margin: 8px 0;
  }
`;

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 30px;
`;

const SignatureLabel = styled.div`
  ${Heading4};
  margin-bottom: 7px;
`;

const SignatureCanvas = styled.canvas<{ error: boolean }>`
  background: ${({ theme }) => theme.colors.white};
  box-shadow: -6px -6px 12px 0.5px rgba(197, 197, 197, 0.21),
    6px 6px 12px 0.5px rgba(197, 197, 197, 0.21);
  border-radius: 6px;
  border: ${({ theme, error }) =>
    error ? `1px solid ${theme.colors.redMain}` : ""};
  width: 100%;
  max-width: 300px;
`;

const SignatureError = styled.div`
  ${BodySmall};
  color: ${({ theme }) => theme.colors.redMain};
  margin-left: 3px;
`;

const SignatureOr = styled.div`
  display: flex;
  justify-content: center;
  margin: 20px 0;
  ${Heading3}
`;
