import { useMutation } from "@apollo/client";
import { loader } from "graphql.macro";
import cookie from "js-cookie";
import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import FormConfiguration from "../../components/FormTemplates/Prescription/FormConfiguration";
import { PageWrapper } from "../../components/Layout/PageWrapper/PageWrapper";
import { Loader } from "../../components/Loader/Loader";
import { COOKIES } from "../../constants/cookies_types";
import { APPLICATION_ROLE } from "../../constants/db_types";
import ApplicationContext from "../../utils/context";
import {
  uploadPractitionerSignature
} from "../../utils/prescription";
import { displayToastNotification } from "../../utils/toastNotification";

const updatePractitionerPrescriptionConfigQuery = loader(
  "../../graphql/updatePractitionerPrescriptionConfig.graphql"
);

interface FormValues {
  rpps: string;
  signature: string;
  fileToUpload?: File | null;
  fileToUploadIsSet: boolean;
  currentFileName: string;
}

interface PrescriptionConfigurationProps {}

const PrescriptionConfiguration: React.FC<PrescriptionConfigurationProps> =
  () => {
    const { meCxt } = useContext<any>(ApplicationContext);

    const [updatePractitionerPrescriptionConfig] = useMutation(
      updatePractitionerPrescriptionConfigQuery
    );

    const [initialValues, setInitialValues] = useState<FormValues | null>(null);

    useEffect(() => {
      const role = cookie.get(COOKIES.ROLE);
      if (role === APPLICATION_ROLE.ADMIN) {
        setInitialValues({
          rpps: "Admin can't have a rpps number !",
          signature: "Admin can't have a signature !",
          fileToUploadIsSet: false,
          currentFileName: "",
        });
      } else {
        if (meCxt.meToEdit.id) {
          const { signatureFilePath, rpps } = meCxt.meToEdit;
          // if (practitionerHasSignature) {
          // signatureB64Tmp = "data:image/png;base64," + signatureB64.data;
          // }
          setInitialValues({
            rpps,
            signature: "",
            fileToUploadIsSet: false,
            currentFileName: signatureFilePath,
          });
        }
      }
    }, [meCxt.meToEdit]);

    const handleSubmit = async (values: FormValues) => {
      const role = cookie.get(COOKIES.ROLE);
      const id = cookie.get(COOKIES.ID);
      if (role === APPLICATION_ROLE.ADMIN) return;
      const data = new FormData();
      try {
        //If typeof signature is a string, this means the user has not changed his signature
        if (
          !values.signature &&
          initialValues?.currentFileName &&
          !values.fileToUpload
        ) {
          await updatePractitionerPrescriptionConfig({
            variables: {
              id,
              rpps: values.rpps,
              signatureFilePath: initialValues?.currentFileName,
            },
          });
        } else {
          if (values.signature) {
            //Else we have to vonvert b64 signature to File and store it in GCP
            const blobBin = atob(values.signature.split(",")[1]);
            const array = [];
            for (var i = 0; i < blobBin.length; i++) {
              array.push(blobBin.charCodeAt(i));
            }
            const file = new Blob([new Uint8Array(array)], {
              type: "image/png",
            });
            data.append("file", file);
          } else if (values.fileToUpload) {
            data.append("file", values.fileToUpload);
          }
          const uploadData = await uploadPractitionerSignature(data);
          if (uploadData.error) {
            displayToastNotification("error", "Une erreur est survenue");
            return;
          }
          await updatePractitionerPrescriptionConfig({
            variables: {
              id,
              rpps: values.rpps,
              signatureFilePath: uploadData.fileName,
            },
          });
        }

        displayToastNotification(
          "success",
          "Les modifications ont bien été enregistrées"
        );
        return;
      } catch (err) {
        console.log(err);
        displayToastNotification("error", "Une erreur est survenue");
        return;
      }
    };

    if (!initialValues) {
      return <Loader />;
    }

    return (
      <PageWrapper>
        <Container>
          <FormConfiguration
            title="Configuration"
            subtitle="Nous avons besoin de certains détails pour que vous puissiez créer une ordonnance. Vous pouvez toujours modifier ces informations ici sur cette page."
            handleSubmit={(values: FormValues) => handleSubmit(values)}
            initialValues={initialValues!}
          />
        </Container>
      </PageWrapper>
    );
  };

export default PrescriptionConfiguration;

const Container = styled.div`
  height: 100%;
  margin: 0 auto;
  max-width: 1030px;
`;
