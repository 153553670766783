import React from "react";
import styled from "styled-components";
import { BodyLarge, BodySmall, Heading3, Heading4 } from "../../../theme/fonts";

interface AuthCardTitleProps {
  title: string;
  subtitle?: string;
}

const AuthCardTitle: React.FC<AuthCardTitleProps> = ({ title, subtitle }) => {
  return (
    <TitleContainer>
      <Title>{title}</Title>
      <Subtitle>{subtitle}</Subtitle>
    </TitleContainer>
  );
};

export default AuthCardTitle;

const TitleContainer = styled.div`
  width: 100%;
  text-align: center;
  color: ${(props) => props.theme.colors.blackMain};
  margin: 30px 0;
  position: absolute;
  top: 0px;
  left: calc(50% + 40px);

  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.laptop13Max}) {
    left: calc(50% + 20px);
  }

  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.mobilePortraitMax}) {
    position: relative;
    text-align: center;
    left: 0;
    margin: 20px 0 0 0;
  }
`;

const Title = styled.div`
  text-align: center;
  margin-bottom: 5px;
  ${Heading3};

  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.laptop13Max}) {
    ${Heading4}
  }

  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.mobilePortraitMax}) {
    ${Heading3}
  }
`;

const Subtitle = styled.div`
  ${BodyLarge};

  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.laptop13Max}) {
    ${BodySmall}
  }

  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.mobilePortraitMax}) {
    ${BodyLarge}
  }
`;
