import React from "react";
import styled from "styled-components";

interface AuthCardFormProps {}

const AuthCardForm: React.FC<AuthCardFormProps> = ({ children }) => {
  return <Container>{children}</Container>;
};

export default AuthCardForm;

const Container = styled.div`
  width: calc(50% - 40px);
  padding: 10px 20px;
  height: calc(100% - 20px);
  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.laptop13Max}) {
    width: calc(50% - 20px);
    padding: 10px;
  }

  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.mobilePortraitMax}) {
    width: calc(100% - 20px);
    padding: 10px;
  }
`;
