export const ROUTES_NOT_AUTH = {
  LOGIN: "/login",
  SESSION_EXPIRED: "/session-expired",
  RESET_PASSWORD: "/reset-password",
  SIGNUP: "/signup",
  DOSES_COMPUTE: "/dosescompute",
};

export const ROUTES_AUTH = {
  LOGIN: "/login",
  SESSION_EXPIRED: "/session-expired",
  RESET_PASSWORD: "/reset-password",
  SIGNUP: "/signup",
  DOSES_COMPUTE: "/dosescompute",
  UNAUTHORIZED: "/unauthorized",
  HOME: "/home",
  PATIENTS_CREATE: "/patients/create",
  PATIENTS_UPDATE: "/patients/update",
  PATIENTS_LIST: "/patients/list",
  PATIENTS_PROFILE: "/patients/profile",
  PRACTITIONERS_CREATE: "/practitioners/create",
  PRACTITIONERS_UPDATE: "/practitioners/update",
  PRACTITIONERS_LIST: "/practitioners/list",
  ORGANIZATION_DOCUMENTS_CREATE: "/organization-documents/create",
  ORGANIZATION_DOCUMENTS_UPDATE: "/organization-documents/update",
  ORGANIZATION_DOCUMENTS_LIST: "/organization-documents/list",
  ORGANIZATION_GUIDELINES_CREATE: "/organization-guidelines/create",
  ORGANIZATION_GUIDELINES_UPDATE: "/organization-guidelines/update",
  ORGANIZATION_GUIDELINES_LIST: "/organization-guidelines/list",
  ESTABLISHMENTS_CREATE: "/establishments/create",
  ESTABLISHMENTS_UPDATE: "/establishments/update",
  ESTABLISHMENTS_LIST: "/establishments/list",
  ESIGNATURE_TEMPLATES_CREATE: "/esignature/templates/create",
  ESIGNATURE_TEMPLATES_LIST: "/esignature/templates/list",
  ESIGNATURE_STATUS: "/esignature/status",
  INSTRUCTIONS_CREATE: "/instructions/create",
  INSTRUCTIONS_UPDATE: "/instructions/update",
  INSTRUCTIONS_LIST: "/instructions/list",
  ADMIN: "/admin",
  PRESCRIPTIONS_CREATE: "/prescriptions/create",
  PRESCRIPTIONS_CONFIGURATION: "/prescriptions/configuration",
  PRESCRIPTIONS_LIST: "/prescriptions/list",
  REMINDER_AVAILABILITY: "/reminder/availability",
  REMINDER_CONFIGURE: "/reminder/configure",
};
