import React from "react";
import styled from "styled-components";
import PageWrapperNoAuth from "../../components/Layout/PageWrapperNoAuth/PageWrapperNoAuth";
import LogInForm from "../../components/FormTemplates/LogInForm/LogInForm";
import { CardStyle1 } from "../../theme/components/ThemeComponents";

const Login: React.FunctionComponent<{}> = () => {
  return (
    <PageWrapperNoAuth title="Connexion">
      <LoginFormContainer>
        <LogInForm />
      </LoginFormContainer>
    </PageWrapperNoAuth>
  );
};

const LoginFormContainer = styled.div`
  ${CardStyle1}
  width: 724px;
  height: 393px;
  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.laptop13Max}) {
    width: 540px;
    height: 323px;
  }

  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.mobilePortraitMax}) {
    width: 100%;
    height: auto;
  }
`;

export default Login;
