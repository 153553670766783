import React, { Component } from "react";
import styled from "styled-components";
// import { FieldProps, FormikProps } from 'formik';
import { Container, Row, Col } from "react-bootstrap";
import { ThemeComponents } from "../../../theme/components";

export type Props = {
  label?: string;
  sublabel?: string;
  placeholder?: string;
  required?: boolean;
  // direction: "column" | "auto";
  form: any;
  field: any;
};

export class LongTextInputForm extends Component<Props> {
  public render() {
    const {
      form,
      label,
      sublabel,
      placeholder,
      field,
      required,
      // direction = "auto",
    } = this.props;
    const error = form.errors[field.name];
    const touched = form.touched[field.name];
    return (
      <LongTextInputContainer fluid>
        <LongTextInputRow noGutters>
          {/* <Col xs={12} sm={direction === "column" ? 12 : 4}> */}
          <Col xs={12}>
            <LongTextInputLabelContainer>
              <LongTextInputLabel>{label}</LongTextInputLabel>
              <LongTextInputSubLabel>{sublabel}</LongTextInputSubLabel>
            </LongTextInputLabelContainer>
          </Col>
          {/* <Col xs={12} sm={direction === "column" ? 12 : 8}> */}
          <Col xs={12}>
            <LongTextInput
              {...field}
              placeholder={
                placeholder && required ? `${placeholder + " *"}` : placeholder
              }
              error={error}
              touched={touched}
              rows={"5"}
            ></LongTextInput>
            {touched && error && (
              <LongTextInputError>{error}</LongTextInputError>
            )}
          </Col>
        </LongTextInputRow>
      </LongTextInputContainer>
    );
  }
}

const LongTextInputContainer = styled(Container)`
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: 0;
`;

const LongTextInputRow = styled(Row)`
  width: 100%;
`;

const LongTextInput = styled(ThemeComponents.FormLongInput)<{
  error: string;
  touched: boolean;
}>`
  border-color: ${(props) => {
    const colorType =
      !!props.error && props.touched
        ? props.theme.colors.red.dark
        : props.theme.colors.white.darker;
    return colorType;
  }};
  background: ${(props) => props.theme.colors.darkGreenL1};
  padding-right: 15px;
`;

const LongTextInputLabelContainer = styled.div`
  margin: 5px 15px 5px 0;
`;

const LongTextInputLabel = styled(ThemeComponents.FormLabel)``;

const LongTextInputSubLabel = styled(ThemeComponents.FormSubLabel)``;

const LongTextInputError = styled(ThemeComponents.FormErrorLabel)`
  top: 125px !important;
`;
