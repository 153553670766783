import React, { useContext, useState } from "react";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { PageWrapper } from "../../components/Layout/PageWrapper/PageWrapper";
import ApplicationContext from "../../utils/context";
import { COOKIES } from "../../constants/cookies_types";
import { APPLICATION_ROLE } from "../../constants/db_types";
import cookie from "js-cookie";
import FormPractitioner from "../../components/FormTemplates/Practitioner/FormPractitioner";
import { Loader } from "../../components/Loader/Loader";
import * as Sentry from "@sentry/react";

import { useMutation } from "@apollo/client";
import { loader } from "graphql.macro";
import { displayToastNotification } from "../../utils/toastNotification";

const upsertOrganizationMembershipRoleQuery = loader(
  "../../graphql/upsertOrganizationMembershipRole.graphql"
);

const updatePractitionerQuery = loader(
  "../../graphql/updatePractitioner.graphql"
);

interface FormValues {
  title: string;
  specialty: string;
  surname: string;
  name: string;
  email: string;
  phone: string;
  rpps: string;
  organization_role_id: string;
}

interface PractitionersUpdateProps {}

const PractitionersUpdate: React.FC<PractitionersUpdateProps> = () => {
  const history = useHistory();

  const [upsertOrganizationMembershipRole] = useMutation(
    upsertOrganizationMembershipRoleQuery
  );

  const [updatePractitioner] = useMutation(updatePractitionerQuery);

  const { practitionerCxt, currentOrganizationCxt } =
    useContext<any>(ApplicationContext);

  const [initialValues, setInitialValues] = useState<FormValues | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [organizationMembership, setOrganizationMembership] = useState(null);
  const [organizationMembershipLength, setOrganizationMembershipLength] =
    useState(0);

  //TODO refacto common logic parsing
  useEffect(() => {
    if (
      practitionerCxt.practitionerToEdit.id &&
      currentOrganizationCxt?.currentOrganizationToEdit?.id
    ) {
      const curatedPractitioner = Object.assign(
        {},
        practitionerCxt.practitionerToEdit
      );
      parseOganizationRole(curatedPractitioner);
      delete curatedPractitioner.__typename;
      setInitialValues(Object.assign({}, curatedPractitioner));
    } else if (currentOrganizationCxt?.currentOrganizationToEdit?.id) {
      const currentPractitioner = localStorage.getItem("currentPractitioner");
      if (currentPractitioner) {
        const curatedPractitioner = Object.assign(
          {},
          JSON.parse(currentPractitioner)
        );
        parseOganizationRole(curatedPractitioner);
        delete curatedPractitioner.__typename;
        setInitialValues(Object.assign({}, curatedPractitioner));
      } else {
        history.push("/practitioners/list");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    practitionerCxt.practitionerToEdit,
    currentOrganizationCxt.currentOrganizationToEdit,
    history,
  ]);

  useEffect(() => {
    if (initialValues) {
      setIsLoading(false);
    }
  }, [initialValues]);

  const parseOganizationRole = (curatedPractitioner: any) => {
    // Note: Refacto this  because in the previous feature admin could udpate multiple role in different organizations. Now, only one update because admin select an organization
    if (cookie.get(COOKIES.ROLE) === APPLICATION_ROLE.ADMIN) {
      const om = curatedPractitioner.user?.organization_memberships ?? [];
      const organizationMembershipTmp: any = {};
      for (let i = 0; i < om.length; i++) {
        //   organizationMembershipTmp[`organization_id_${i}`] =
        //   om[i].organization.id;

        // organizationMembershipTmp[`organization_membership_role_id_${i}`] =
        //   om[i].organization_membership_roles[0].id;

        // organizationMembershipTmp[`organization_membership_id_${i}`] =
        //   om[i].id;

        // organizationMembershipTmp[`organization_role_id_${i}`] =
        //   om[i].organization_membership_roles[0].organization_role_id;
        if (
          om[i].organization.id ===
          currentOrganizationCxt?.currentOrganizationToEdit?.id
        ) {
          organizationMembershipTmp[`organization_id_${0}`] =
            om[i].organization.id;

          organizationMembershipTmp[`organization_membership_role_id_${0}`] =
            om[i].organization_membership_roles[0].id;

          organizationMembershipTmp[`organization_membership_id_${0}`] =
            om[i].id;

          organizationMembershipTmp[`organization_role_id_${0}`] =
            om[i].organization_membership_roles[0].organization_role_id;
        }
      }
      setOrganizationMembership(organizationMembershipTmp);
      // Note: Admin select his organization so now the length is always 1
      setOrganizationMembershipLength(organizationMembershipTmp?.length);
    }
  };

  async function modifyPractitioner(values: any) {
    //TODO props
    const isAdmin = cookie.get(COOKIES.ROLE) === APPLICATION_ROLE.ADMIN;
    if (isAdmin) {
      let membershipRoles = Array(organizationMembershipLength)
        .fill(null)
        .map((e, i) => {
          return {
            id: values[`organization_membership_role_id_${i}`],
            organization_membership_id:
              values[`organization_membership_id_${i}`],
            organization_role_id: values[`organization_role_id_${i}`],
          };
        });
      try {
        await upsertOrganizationMembershipRole({
          variables: {
            membershipRoles,
          },
        });
      } catch (err) {
        console.log(err);
        Sentry.captureException(err);
      }
    }
    const variables = Object.assign(
      {},
      {
        ...values,
      }
    );
    console.info("Modify practitioner", variables);
    updatePractitioner({
      variables,
    })
      .then((...args) => {
        const [
          {
            data: {
              update_practitioner: { returning: practitioner },
              update_practitioner_specialization: {
                returning: practitioner_specialization,
              },
            },
          },
        ] = args;
        // Display a toast notification if operation succeded
        displayToastNotification(
          "success",
          "Les modifications ont bien été enregistrées"
        );
        console.info("Practitioner updated", practitioner);
        console.info(
          "Practitioner specialization updated",
          practitioner_specialization
        );
        //TODO update cxt + localStorage
      })
      .catch((e) => {
        console.error("Invalid Input", variables);
        console.error(e);
        Sentry.captureException(e);
      });
  }

  const handleSubmit = async (values: any) => {
    await modifyPractitioner(values);
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <PageWrapper
      previousPageIconOn={true}
      previousPageText={"Retour à la liste des praticiens"}
      handleOnClickPreviousPage={() => history.push("/practitioners/list")}
    >
      <Container>
        <FormPractitioner
          title="Modifier le praticien"
          initialValues={initialValues!}
          mode="update"
          handleSubmit={(values) => handleSubmit(values)}
          organizationMembership={organizationMembership!}
          organizationMembershipLength={organizationMembershipLength!}
        />
      </Container>
    </PageWrapper>
  );
};

export default PractitionersUpdate;

const Container = styled.div`
  height: 100%;
  margin: 0 auto;
  max-width: 1030px;
`;
