import {
  Document,
  Font,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import moment from "moment";
import React from "react";
import fontBold from "../../theme/fonts/Raleway/Raleway-Bold.ttf";
import fontItalic from "../../theme/fonts/Raleway/Raleway-Italic.ttf";
import fontMedium from "../../theme/fonts/Raleway/Raleway-Medium.ttf";

Font.register({
  family: "RalewayMedium",
  src: fontMedium,
});

Font.register({
  family: "RalewayBold",
  src: fontBold,
});

Font.register({
  family: "RalewayItalic",
  src: fontItalic,
});

type Medications = { name: string; text: string }[];
type Practitioner = {
  name: string;
  surname: string;
  phone: string;
  rpps: string;
  signature: string;
  specialization: string;
};
type Organization = {
  id: string;
  address: { city: string; postalCode: string; street: string; number: string };
  am: string | null;
  finess: string | null;
};
type Patient = {
  id: string;
  name: string;
  surname: string;
  birthDate: string;
  surgeryId: string;
};
interface PrescriptionPdfLayoutProps {
  medications: Medications;
  practitioner: Practitioner;
  organization: Organization;
  patient: Patient;
}

const Header: React.FC<{
  practitioner: Practitioner;
  organization: Organization;
}> = ({ practitioner, organization }) => {
  return (
    <View fixed style={styles.header}>
      <View style={styles.headerPractitionerInfos}>
        <Text
          style={[
            styles.bold,
            {
              textAlign: "center",
              fontSize: "16px",
              textTransform: "capitalize",
            },
          ]}
        >
          Dr {practitioner.name} {practitioner.surname}
        </Text>
        <Text style={{ textAlign: "center", textTransform: "uppercase" }}>
          {practitioner.specialization ?? ""}
        </Text>
        <Text style={[styles.italic, { textAlign: "center" }]}>
          {organization.address.number} {organization.address.street}
        </Text>
        <Text style={[styles.italic, { textAlign: "center" }]}>
          {organization.address.postalCode} {organization.address.city}
        </Text>
        <Text style={[styles.italic, { textAlign: "center" }]}>
          Tel: {practitioner.phone}
        </Text>
      </View>
      <View style={styles.barCodeContainer}>
        <View style={styles.barCodeItem}>
          <Text style={styles.barCodeLabel}>
            N° {organization.am ? "AM" : organization.finess ? "FINESS" : ""} :
          </Text>
          <Image
            style={styles.barCodeImage}
            src={(
              document.getElementById("barcode-am-finess")! as HTMLCanvasElement
            ).toDataURL()}
          />
          <Text style={styles.barCodeNumber}>
            {organization.am ?? organization.finess}
          </Text>
        </View>
        <View style={styles.barCodeItem}>
          <Text style={styles.barCodeLabel}>N° RPPS :</Text>
          <Image
            style={styles.barCodeImage}
            src={(
              document.getElementById("barcode-rpps")! as HTMLCanvasElement
            ).toDataURL()}
          />
          <Text style={styles.barCodeNumber}>{practitioner.rpps}</Text>
        </View>
      </View>
    </View>
  );
};

const PrescriptionPdfLayout: React.FC<PrescriptionPdfLayoutProps> = ({
  medications,
  practitioner,
  patient,
  organization,
}) => {
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.section}>
          <Header practitioner={practitioner} organization={organization} />
          <View style={styles.mainContainer}>
            <View style={styles.medicationsContainer}>
              {medications.map((e, i) => (
                <View key={i} style={styles.medicationsItem}>
                  <Text style={styles.bold}>{e.name}</Text>
                  <Text>{e.text}</Text>
                </View>
              ))}
            </View>
            <View style={styles.patientInfosContainer}>
              <Text style={{ textAlign: "right" }}>
                Le {moment(new Date()).format("LL")}
              </Text>
              <Text
                style={[
                  styles.bold,
                  {
                    textTransform: "capitalize",
                    textAlign: "right",
                    paddingTop: 25,
                  },
                ]}
              >
                {patient.name} {patient.surname}
              </Text>
              <Text style={{ textAlign: "right", paddingTop: 5 }}>
                Né(e) le {moment(patient.birthDate).format("DD/MM/YYYY")}
              </Text>
            </View>
          </View>
          <View style={styles.signatureRow} fixed>
            <View style={styles.signatureContainer}>
              <Text style={styles.signatureLabel}>
                Signé via Koalou le {moment(new Date()).format("DD/MM/YYYY")},
              </Text>
              <Text style={[styles.signatureName, styles.bold]}>
                {practitioner.name} {practitioner.surname}
              </Text>
              <Image style={styles.signature} src={practitioner.signature} />
            </View>
          </View>
        </View>
        <Text
          style={styles.footer}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
          fixed
        />
      </Page>
    </Document>
  );
};

export default PrescriptionPdfLayout;

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: "row",
    fontFamily: "RalewayMedium",
    fontSize: 12,
    backgroundColor: "#FFFFFF",
    paddingTop: 50,
    paddingBottom: 40,
    paddingRight: 60,
    paddingLeft: 60,
  },
  header: {
    flexDirection: "row",
    marginBottom: 60,
  },
  headerPractitionerInfos: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "40%",
  },
  barCodeContainer: {
    display: "flex",
    flexDirection: "row",
    width: "60%",
    justifyContent: "space-between",
  },
  barCodeItem: {
    display: "flex",
    flexDirection: "column",
    paddingLeft: "30px",
  },
  barCodeLabel: {
    paddingLeft: "5px",
    fontSize: "10px",
  },
  barCodeImage: {
    width: "100px",
    height: "40px",
  },
  barCodeNumber: {
    textAlign: "center",
    width: "100px",
    fontSize: "10px",
  },
  mainContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    // backgroundColor: "#9A40E0",
  },
  patientInfosContainer: {
    // backgroundColor: "#40EAA0",
    width: "30%",
  },
  medicationsContainer: {
    display: "flex",
    flexDirection: "column",
    // backgroundColor: "#40E0D0",
    width: "70%",
    paddingTop: 100,
    paddingRight: 10,
  },
  medicationsItem: {
    marginBottom: 20,
  },
  signatureRow: {
    textAlign: "right",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  signatureContainer: {
    borderLeft: "2px solid #0B8C3A",
    borderBottom: "2px solid #0B8C3A",
    display: "flex",
    flexDirection: "column",
    borderBottomLeftRadius: "5px",
    marginRight: "20px",
    marginBottom: "30px",
  },
  signatureLabel: {
    fontSize: "7px",
    paddingLeft: "10px",
  },
  signatureName: {
    fontSize: "10px",
    paddingLeft: "10px",
    textTransform: "capitalize",
  },
  signature: {
    width: "120px",
    height: "60px",
  },
  footer: {
    position: "absolute",
    fontSize: 12,
    bottom: 40,
    left: 0,
    right: 45,
    textAlign: "right",
  },
  section: {
    // backgroundColor: "pink",
  },
  bold: {
    fontFamily: "RalewayBold",
  },
  italic: {
    fontFamily: "RalewayItalic",
  },
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 0,
    right: 0,
    textAlign: "center",
  },
});
