import { ApolloProvider } from "@apollo/client";
import "@fortawesome/fontawesome-free/css/all.min.css";
import * as Sentry from "@sentry/react";
import "bootstrap/dist/css/bootstrap.min.css";
import firebase from "firebase/app";
import HelloSign from "hellosign-embedded";
import cookie from "js-cookie";
import React, { useEffect, useState } from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import styled, { ThemeProvider } from "styled-components";
import "./App.css";
import Crisp from "./components/Crisp/Crisp";
import LayoutWithSidebar from "./components/Layout/LayoutWithSidebar/LayoutWithSidebar";
import PrivateRoute from "./components/Route/PrivateRoute/PrivateRoute";
import { COOKIES } from "./constants/cookies_types";
import { client } from "./lib/apolloClient";
import Admin from "./pages/Admin/Admin";
import Login from "./pages/Auth/Login";
import ResetPassword from "./pages/Auth/ResetPassword";
import SessionExpired from "./pages/Auth/SessionExpired";
import Signup from "./pages/Auth/Signup";
import Unauthorized from "./pages/Auth/Unauthorized";
import EsignStatus from "./pages/Esign/EsignStatus";
import ReminderAvailability from "./pages/Reminder/Availability";
import ReminderConfigure from "./pages/Reminder/Configure";
import EsignTemplatesCreate from "./pages/Esign/EsignTemplatesCreate";
import EsignTemplatesList from "./pages/Esign/EsignTemplatesList";
import EstablishmentsCreate from "./pages/Establishments/EstablishmentsCreate";
import EstablishmentsList from "./pages/Establishments/EstablishmentsList";
import EstablishmentsUpdate from "./pages/Establishments/EstablishmentsUpdate";
import Home from "./pages/Home/Home";
import InstructionsCreate from "./pages/Instructions/InstructionsCreate";
import InstructionsList from "./pages/Instructions/InstructionsList";
import InstructionsUpdate from "./pages/Instructions/InstructionsUpdate";
import OrganizationDocumentsCreate from "./pages/OrganizationDocuments/OrganizationDocumentsCreate";
import OrganizationDocumentsList from "./pages/OrganizationDocuments/OrganizationDocumentsList";
import OrganizationDocumentsUpdate from "./pages/OrganizationDocuments/OrganizationDocumentsUpdate";
import OrganizationGuidelinesCreate from "./pages/OrganizationGuidelines/OrganizationGuidelinesCreate";
import OrganizationGuidelinesList from "./pages/OrganizationGuidelines/OrganizationGuidelinesList";
import OrganizationGuidelinesUpdate from "./pages/OrganizationGuidelines/OrganizationGuidelinesUpdate";
import PatientsCreate from "./pages/Patients/PatientsCreate";
import PatientsList from "./pages/Patients/PatientsList";
import PatientsProfile from "./pages/Patients/PatientsProfile";
import DosesCompute from "./pages/DosesCompute";
import PatientsUpdate from "./pages/Patients/PatientsUpdate";
import PractitionersCreate from "./pages/Practitioners/PractitionersCreate";
import PractitionersList from "./pages/Practitioners/PractitionersList";
import PractitionersUpdate from "./pages/Practitioners/PractitionersUpdate";
import PrescriptionsCreate from "./pages/Prescriptions/PrescriptionsCreate";
import { theme } from "./theme/index";
import ApplicationContext from "./utils/context";
import PrescriptionConfiguration from "./pages/Prescriptions/PrescriptionConfiguration";
import PrescriptionsList from "./pages/Prescriptions/PrescriptionsList";
import { ROUTES_AUTH, ROUTES_NOT_AUTH } from "./constants/routes";

declare global {
  interface Window {
    $crisp: any;
  }
}

//TODO replace with const routes var
function renderNotConnectedRoutes() {
  return (
    <Switch>
      <Route exact path={ROUTES_NOT_AUTH.LOGIN}>
        <Login />
      </Route>
      <Route exact path={ROUTES_NOT_AUTH.SESSION_EXPIRED}>
        <SessionExpired />
      </Route>
      <Route exact path={ROUTES_NOT_AUTH.RESET_PASSWORD}>
        <ResetPassword />
      </Route>
      <Route exact path={ROUTES_NOT_AUTH.SIGNUP}>
        <Signup />
      </Route>
      <Route exact path={ROUTES_NOT_AUTH.DOSES_COMPUTE}>
        <DosesCompute />
      </Route>
      <Redirect to={ROUTES_NOT_AUTH.LOGIN} />
    </Switch>
  );
}

function renderConnectedRoutes(email: string) {
  Sentry.setContext("koalou user", {
    email: cookie.get(COOKIES.EMAIL),
    role: cookie.get(COOKIES.ROLE),
    id: cookie.get(COOKIES.ID),
  });

  return (
    <Switch>
      <Route exact path={ROUTES_AUTH.LOGIN}>
        <Login />
      </Route>
      <Route exact path={ROUTES_AUTH.RESET_PASSWORD}>
        <ResetPassword />
      </Route>
      <Route exact path={ROUTES_AUTH.SIGNUP}>
        <Signup />
      </Route>
      <Route exact path={ROUTES_AUTH.DOSES_COMPUTE}>
        <DosesCompute />
      </Route>
      <Route exact path={ROUTES_AUTH.SESSION_EXPIRED}>
        <SessionExpired />
      </Route>
      <Route exact path={ROUTES_AUTH.UNAUTHORIZED}>
        <Unauthorized />
      </Route>
      <LayoutWithSidebar>
        {/* TODO email in every props ? why not in context once ? */}
        <Switch>
          <PrivateRoute
            exact
            path={ROUTES_AUTH.HOME}
            component={Home}
            email={email}
          />
          <PrivateRoute
            exact
            path={ROUTES_AUTH.PATIENTS_CREATE}
            component={PatientsCreate}
            email={email}
          />
          <PrivateRoute
            exact
            path={ROUTES_AUTH.PATIENTS_UPDATE}
            component={PatientsUpdate}
            email={email}
          />
          <PrivateRoute
            exact
            path={ROUTES_AUTH.PATIENTS_LIST}
            component={PatientsList}
            email={email}
          />
          <PrivateRoute
            exact
            path={ROUTES_AUTH.PATIENTS_PROFILE}
            component={PatientsProfile}
            email={email}
          />
          <PrivateRoute
            exact
            path={ROUTES_AUTH.REMINDER_AVAILABILITY}
            component={ReminderAvailability}
            email={email}
          />
          <PrivateRoute
            exact
            path={ROUTES_AUTH.REMINDER_CONFIGURE}
            component={ReminderConfigure}
            email={email}
          />
          <PrivateRoute
            exact
            path={ROUTES_AUTH.PRACTITIONERS_CREATE}
            component={PractitionersCreate}
            email={email}
          />
          <PrivateRoute
            exact
            path={ROUTES_AUTH.PRACTITIONERS_UPDATE}
            component={PractitionersUpdate}
            email={email}
          />
          <PrivateRoute
            exact
            path={ROUTES_AUTH.PRACTITIONERS_LIST}
            component={PractitionersList}
            email={email}
          />
          <PrivateRoute
            exact
            path={ROUTES_AUTH.ORGANIZATION_DOCUMENTS_CREATE}
            component={OrganizationDocumentsCreate}
            email={email}
          />
          <PrivateRoute
            exact
            path={ROUTES_AUTH.ORGANIZATION_DOCUMENTS_UPDATE}
            component={OrganizationDocumentsUpdate}
            email={email}
          />
          <PrivateRoute
            exact
            path={ROUTES_AUTH.ORGANIZATION_DOCUMENTS_LIST}
            component={OrganizationDocumentsList}
            email={email}
          />

          <PrivateRoute
            exact
            path={ROUTES_AUTH.ORGANIZATION_GUIDELINES_CREATE}
            component={OrganizationGuidelinesCreate}
            email={email}
          />
          <PrivateRoute
            exact
            path={ROUTES_AUTH.ORGANIZATION_GUIDELINES_UPDATE}
            component={OrganizationGuidelinesUpdate}
            email={email}
          />
          <PrivateRoute
            exact
            path={ROUTES_AUTH.ORGANIZATION_GUIDELINES_LIST}
            component={OrganizationGuidelinesList}
            email={email}
          />
          <PrivateRoute
            exact
            path={ROUTES_AUTH.ESTABLISHMENTS_CREATE}
            component={EstablishmentsCreate}
            email={email}
          />
          <PrivateRoute
            exact
            path={ROUTES_AUTH.ESTABLISHMENTS_UPDATE}
            component={EstablishmentsUpdate}
            email={email}
          />
          <PrivateRoute
            exact
            path={ROUTES_AUTH.ESTABLISHMENTS_LIST}
            component={EstablishmentsList}
            email={email}
          />
          <PrivateRoute
            exact
            path={ROUTES_AUTH.ESIGNATURE_TEMPLATES_CREATE}
            component={EsignTemplatesCreate}
            email={email}
          />
          <PrivateRoute
            exact
            path={ROUTES_AUTH.ESIGNATURE_TEMPLATES_LIST}
            component={EsignTemplatesList}
            email={email}
          />
          <PrivateRoute
            exact
            path={ROUTES_AUTH.ESIGNATURE_STATUS}
            component={EsignStatus}
            email={email}
          />
          <PrivateRoute
            exact
            path={ROUTES_AUTH.INSTRUCTIONS_CREATE}
            component={InstructionsCreate}
            email={email}
          />
          <PrivateRoute
            exact
            path={ROUTES_AUTH.INSTRUCTIONS_UPDATE}
            component={InstructionsUpdate}
            email={email}
          />
          <PrivateRoute
            exact
            path={ROUTES_AUTH.INSTRUCTIONS_LIST}
            component={InstructionsList}
            email={email}
          />
          <PrivateRoute
            exact
            path={ROUTES_AUTH.ADMIN}
            component={Admin}
            email={email}
            adminOnly
          />
          <PrivateRoute
            exact
            path={ROUTES_AUTH.PRESCRIPTIONS_LIST}
            component={PrescriptionsList}
            email={email}
          />
          <PrivateRoute
            exact
            path={ROUTES_AUTH.PRESCRIPTIONS_CREATE}
            component={PrescriptionsCreate}
            email={email}
          />
          <PrivateRoute
            exact
            path={ROUTES_AUTH.PRESCRIPTIONS_CONFIGURATION}
            component={PrescriptionConfiguration}
            email={email}
          />
          <Redirect to={ROUTES_AUTH.LOGIN} />
        </Switch>
      </LayoutWithSidebar>
    </Switch>
  );
}

function renderRoutes(isAuth: boolean, email: string) {
  if (isAuth) {
    return renderConnectedRoutes(email);
  } else {
    return renderNotConnectedRoutes();
  }
}

function App() {
  const [firebaseAuth, setFirebaseAuth] = useState<{
    isLoading: boolean;
    isAuth: boolean;
    email: string;
  }>({ isLoading: true, isAuth: false, email: "" });

  /* Manage Hellosign client */
  const [helloSignClientId, setHelloSignClientId] = useState<HelloSign>();
  /* Manage Patient */
  const [patientToEdit, setPatientToEdit] = useState({});
  /* Manage Practitioner */
  const [practitionerToEdit, setPractitionerToEdit] = useState({});
  /* Manage all organizations*/
  const [organizationToEdit, setOrganizationToEdit] = useState({});
  /* Manage Instruction */
  const [instructionToEdit, setInstructionToEdit] = useState({});
  /* Manage Organization guideline */
  const [organizationGuidelineToEdit, setOrganizationGuidelineToEdit] =
    useState({});
  /* Manage Organization document*/
  const [organizationDocumentToEdit, setOrganizationDocumentToEdit] = useState(
    {}
  );
  /* Manage the current organization */
  const [currentOrganizationToEdit, setCurrentOrganizationToEdit] = useState(
    {}
  );
  /* Manage current me profile */
  const [meToEdit, setMeToEdit] = useState({});
  /* Manage languages */
  const [languagesToEdit, setLanguagesToEdit] = useState({});

  /* Context configuration */
  const contextValue = {
    helloSignClientCxt: { helloSignClientId, setHelloSignClientId },
    patientCxt: { patientToEdit, setPatientToEdit },
    practitionerCxt: { practitionerToEdit, setPractitionerToEdit },
    organizationCxt: { organizationToEdit, setOrganizationToEdit },
    instructionCxt: { instructionToEdit, setInstructionToEdit },
    organizationGuidelineCxt: {
      organizationGuidelineToEdit,
      setOrganizationGuidelineToEdit,
    },
    organizationDocumentCxt: {
      organizationDocumentToEdit,
      setOrganizationDocumentToEdit,
    },
    currentOrganizationCxt: {
      currentOrganizationToEdit,
      setCurrentOrganizationToEdit,
    },
    meCxt: {
      meToEdit,
      setMeToEdit,
    },
    languagesCxt: {
      languagesToEdit,
      setLanguagesToEdit,
    },
  };

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged(function (user) {
      // console.log(user);
      if (user) {
        // If user is set, user is logged
        setFirebaseAuth({ isLoading: false, isAuth: true, email: user.email! });
        setHelloSignClientId(
          new HelloSign({
            clientId: process.env.REACT_APP_HELLOSIGN_CLIENT_ID,
            skipDomainVerification: true, // Uncomment this line for local test ? TODO test in prod.
          })
        );
      } else {
        setFirebaseAuth({ isLoading: false, isAuth: false, email: "" });
      }
    });
    return () => {
      // Remove firebase listener
      unsubscribe();
    };
  }, []);

  // useEffect(() => {
  //   if (dataMe?.practitioner?.length > 0) {
  //     const meTmp = dataMe.practitioner[0];
  //     setMeToEdit({ id: meTmp.id, name: meTmp.name, surname: meTmp.surname });
  //   }
  // }, [dataMe]);

  // useEffect(() => {
  //   if (dataLanguages?.language) {
  //     setLanguagesToEdit(dataLanguages.language);
  //   }
  // }, [dataLanguages]);

  return (
    <ApolloProvider client={client}>
      <ApplicationContext.Provider value={contextValue}>
        <ThemeProvider theme={theme}>
          <BrowserRouter>
            {!firebaseAuth.isLoading && (
              <Container>
                <ToastContainer />
                {renderRoutes(firebaseAuth.isAuth, firebaseAuth.email)}
                <Crisp />
              </Container>
            )}
          </BrowserRouter>
        </ThemeProvider>
      </ApplicationContext.Provider>
    </ApolloProvider>
  );
}

const Container = styled.div`
  height: 100%;
  width: 100%;
`;

export default App;
