const def = (kg, ageMonth, type) => {
  if (type === "induction")
    return {
      amount_scale: [kg * 0.2, kg * 0.5],
      unit: "μg",
      comment: "",
    };
  if (type === "entretiens")
    return {
      amount_scale: [kg * 0.3, kg * 2],
      unit: "μg/h",
      comment: "",
    };
};

export default def;
