import { Field, Form, Formik } from "formik";
import React, { useState } from "react";
import styled from "styled-components";
import * as Yup from "yup";
import {
  BodyMain,
  Heading1,
  Heading2,
  Heading3,
  Heading4,
} from "../../../theme/fonts";
import { ReactComponent as IconArrowDown } from "../../../theme/icons/arrowDown.svg";
import BasicCard from "../../Card/BasicCard/BasicCard";
import CustomButton from "../../CustomButton/CustomButton";
import { CheckBoxForm } from "../../Form/CheckBoxForm/CheckBoxForm";
import { SimpleCheckForm } from "../../Form/SimpleCheckForm/SimpleCheckForm";
import { TextInputForm } from "../../Form/TextInputForm/TextInputForm";
import Dropzone from "../../Dropzone";

interface SignatoriesValues {
  key: string;
  label: string;
  checked: boolean;
  order: number | null;
}

interface FormValues {
  name: string;
  fileToUpload: File | null;
  currentFileName: string | null;
  signatoriesOrder: boolean;
}

interface FormEsignTemplateProps {
  title: string;
  initialValues: FormValues;
  handleSubmit: (
    values: FormValues,
    signatories: Array<SignatoriesValues>
  ) => void;
}

const FILE_SIZE = 5 * 1024 * 1024; //5mb

const validationSchema = () =>
  Yup.object().shape({
    name: Yup.string()
      .required("Veuillez entrer le nom du document")
      .min(3, "3 lettres minimum")
      .trim()
      .matches(/^[a-zA-Z0-9 .+_-]+$/, "Caractères autorisés: a-z A-z 0-9 .+_-"),
    fileToUpload: Yup.mixed()
      .required("Veuillez uploader un fichier PDF")
      .test(
        "fileSize",
        "Le poids du fichier doit être au maximum 5mb",
        (value) => value && value.size <= FILE_SIZE
      )
      .test(
        "fileFormat",
        "Le fichier doit être un PDF",
        (value) => value && ["application/pdf"].includes(value.type)
      ),
  });

const FormEsignTemplate: React.FC<FormEsignTemplateProps> = ({
  initialValues,
  handleSubmit,
}) => {
  const [signatories, setSignatories] = useState<Array<SignatoriesValues>>([
    {
      key: "parent1",
      label: "Parent 1",
      checked: false,
      order: null,
    },
    {
      key: "parent2",
      label: "Parent 2",
      checked: false,
      order: null,
    },
    // {
    //   key: "surgeon1",
    //   label: "Chirurgien",
    //   checked: false,
    //   order: null,
    // },
  ]);

  const [signatoriesOrderDropdown, setSignatoriesOrderDropdown] = useState({
    show: false,
    index: 0,
  });

  const handleChangeSignatoriesRole = (i: number) => {
    const signatoriesTmp = [...signatories];
    signatoriesTmp[i].checked = !signatoriesTmp[i].checked;
    setSignatories(signatoriesTmp);
  };

  const handleClickSelectSignatoriesOrder = (i: number, order: number) => {
    const signatoriesTmp = [...signatories];
    signatoriesTmp[i].order = order;
    setSignatories(signatoriesTmp);
  };

  const validateSignatories = (
    setFieldError: (field: string, errorMsg: string) => void,
    signatoriesOrder: boolean
  ) => {
    let error = null;
    if (signatories.filter((e) => e.checked).length === 0) {
      /* If there is not at least one signatory we display an error */
      error = "Veuillez selectionner au moins un signataire";
    } else if (signatoriesOrder) {
      /* If the order is not set for each signatory we display an error */
      if (signatories.filter((e) => !e.order && e.checked).length > 0) {
        error = "Veuillez selectionner un ordre pour chaque signataire";
      } else {
        const signatoriesFilter = signatories
          .filter((e) => e.order && e.checked)
          .map((e) => e.order);
        if (new Set(signatoriesFilter).size !== signatoriesFilter.length) {
          /* If the is a duplicate we display an error */
          error =
            "Veuillez selectionner un ordre unique pour chaque signataire";
        } else if (
          signatoriesFilter.reduce((a, b) => a! + b!) !==
          signatories
            .filter((e) => e.checked)
            .map((e, i) => i + 1)
            .reduce((a, b) => a! + b!)
        ) {
          /* If the order does not follow a logic suite (1, 2 , ..., n) we display an error */
          error =
            "Veuillez selectionner un ordre cohérent à partit du 1er signataire";
        }
      }
    }

    if (error) {
      setFieldError("signatoriesOrder", error);
      return false;
    } else {
      return true;
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={async (values: FormValues, { setFieldError }) => {
        if (!validateSignatories(setFieldError, values.signatoriesOrder)) {
          return;
        }
        await handleSubmit(values, signatories);
      }}
      validationSchema={() => validationSchema()}
      validateOnChange={false}
    >
      {(props) => {
        return (
          <Container>
            <Title>Création de modèle</Title>
            <FormContainer>
              <LeftCardContainer>
                <BasicCard title="Fichier PDF" titleSeparator={true}>
                  <FieldInputContainer>
                    <Field
                      name={"name"}
                      label={"Nom du document"}
                      placeholder={"Document..."}
                      component={TextInputForm}
                    />
                  </FieldInputContainer>
                  <FieldUploadContainer>
                    <Field
                      name={"fileToUpload"}
                      label={"Fichier PDF"}
                      placeholder={"Cliquez pour sélectionner un fichier"}
                      component={Dropzone}
                      currentFileName={initialValues.currentFileName}
                    />
                  </FieldUploadContainer>
                </BasicCard>
              </LeftCardContainer>
              <SignataireCardContainer>
                <BasicCard
                  title="Section des signataires"
                  titleSeparator={true}
                >
                  <SignatoriesOrderContainer>
                    <SignatoriesLabel>
                      Voulez-vous séléctionner un ordre de signature ?
                    </SignatoriesLabel>
                    <Field
                      name={"signatoriesOrder"}
                      component={SimpleCheckForm}
                    />
                  </SignatoriesOrderContainer>
                  <SignatoriesMainContainer>
                    <SignatoriesLabel>
                      Sélectionnez les signataires :
                    </SignatoriesLabel>
                    {signatories.map((e, i) => {
                      return (
                        <SignatoriesRow key={i}>
                          <SignatoriesRowLeftPart>
                            <SignatoriesRowLabel>{e.label}</SignatoriesRowLabel>
                            <CheckBoxContainer
                              onClick={() => handleChangeSignatoriesRole(i)}
                            >
                              <CheckBoxForm
                                checked={e.checked}
                                onChange={() => null} //TODO refacto checkbox form -> OnChange is useless
                              />
                            </CheckBoxContainer>
                          </SignatoriesRowLeftPart>
                          <SignatoriesRowRightPart>
                            <IconArrowDownContainer
                              hidden={
                                !props.values.signatoriesOrder || !e.checked
                              }
                              onClick={() =>
                                setSignatoriesOrderDropdown({
                                  show: !signatoriesOrderDropdown.show,
                                  index: i,
                                })
                              }
                            >
                              <IconArrowDownText>
                                <span>
                                  {e.order
                                    ? `Signataire ${e.order}`
                                    : "Séléctionnez un ordre"}
                                </span>
                              </IconArrowDownText>
                              <IconArrowDown />
                              {signatoriesOrderDropdown.show &&
                                signatoriesOrderDropdown.index === i && (
                                  <SignatoriesOrderDropdownContainer>
                                    {signatories
                                      .filter((e) => e.checked)
                                      .map((_, j) => {
                                        return (
                                          <div key={j}>
                                            <SignatoriesOrderDropdownItem
                                              onClick={() =>
                                                handleClickSelectSignatoriesOrder(
                                                  i,
                                                  j + 1
                                                )
                                              }
                                            >
                                              Signataire {j + 1}
                                            </SignatoriesOrderDropdownItem>
                                            <SignatoriesOrderDropdownSeparator />
                                          </div>
                                        );
                                      })}
                                  </SignatoriesOrderDropdownContainer>
                                )}
                            </IconArrowDownContainer>
                          </SignatoriesRowRightPart>
                        </SignatoriesRow>
                      );
                    })}
                  </SignatoriesMainContainer>
                </BasicCard>
              </SignataireCardContainer>
            </FormContainer>
            <ButtonContainer>
              <CustomButton
                content="Suivant"
                borderRadius={"12px"}
                handleOnClick={() => props.handleSubmit()}
              />
            </ButtonContainer>
          </Container>
        );
      }}
    </Formik>
  );
};

export default FormEsignTemplate;

const Container = styled.div`
  width: 100%;
  max-width: 1200px;
`;

const Title = styled.div`
  margin-bottom: 36px;
  ${Heading1};

  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.laptop13Max}) {
    ${Heading2}
  } ;
`;

const FormContainer = styled(Form)`
  width: 100%;
  display: flex;
  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.tabletMax}) {
    flex-direction: column;
  }
`;
const LeftCardContainer = styled.div`
  width: 475px;
  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.laptop13Max}) {
    width: 364px;
  }
  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.tabletMax}) {
    width: 100%;
  }
`;

const SignataireCardContainer = styled.div`
  width: 648px;
  margin-left: 5%;
  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.laptop13Max}) {
    width: 496px;
  }
  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.tabletMax}) {
    width: 100%;
    margin: 30px 0 0 0;
  }
`;

const FieldInputContainer = styled.div`
  margin: 20px 0;
  width: 100%;
  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.tabletMax}) {
    width: 100%;
  }
`;

const FieldUploadContainer = styled.div`
  margin: 20px 0;
  width: 100%;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 32px;
`;

const SignatoriesOrderContainer = styled.div``;

const SignatoriesLabel = styled.div`
  ${Heading3}
  @media screen and (max-width: ${(props) =>
    props.theme.breakpoints.laptop13Max}) {
    ${Heading4}
  }
  margin-top: 25px;
`;

const SignatoriesMainContainer = styled.div``;

const SignatoriesRow = styled.div`
  width: 100%;
  background-color: ${(props) => props.theme.colors.darkGreenL1};
  border-radius: 12px;
  padding: 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 15px;
`;

const SignatoriesRowLeftPart = styled.div`
  display: flex;
`;

const SignatoriesRowRightPart = styled.div`
  display: flex;
  position: relative;
  ${BodyMain}
`;

const SignatoriesRowLabel = styled.div`
  width: 80px;
  margin-right: 22px;
  ${Heading3}
  @media screen and (max-width: ${(props) =>
    props.theme.breakpoints.laptop13Max}) {
    ${Heading4}
  }
  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.mobilePortraitMax}) {
    margin-right: 10px;
  }
`;

const CheckBoxContainer = styled.div``;

const IconArrowDownContainer = styled.div<{ hidden: boolean }>`
  cursor: pointer;
  display: ${(props) => (props.hidden ? "none" : "flex")};
  align-items: center;
  & > svg > path {
    stroke: ${(props) => props.theme.colors.darkGreenMain};
  }
  user-select: none;
`;

const IconArrowDownText = styled.div`
  margin-right: 10px;
`;

const SignatoriesOrderDropdownContainer = styled.div`
  width: 120px;
  padding: 10px;
  z-index: 10;
  background-color: ${(props) => props.theme.colors.darkGreenL2};
  color: ${(props) => props.theme.colors._white};
  position: absolute;
  top: 20px;
  left: 0px;
  border-radius: 12px;
`;

const SignatoriesOrderDropdownItem = styled.div`
  text-align: center;
  margin: 15px 0;
  ${Heading3};
  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.laptop13Max}) {
    ${Heading4}
  }
`;

const SignatoriesOrderDropdownSeparator = styled.div`
  width: 100%;
  height: 1px;
  background: linear-gradient(135.02deg, #095c66 0%, #209aa2 100%);
`;
