import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import FormOrganizationDocument from "../../components/FormTemplates/OrganizationDocument/FormOrganizationDocument";
import { PageWrapper } from "../../components/Layout/PageWrapper/PageWrapper";
import { Loader } from "../../components/Loader/Loader";
import ApplicationContext from "../../utils/context";
import * as Sentry from "@sentry/react";
import { ORGANIZATION_FILE_TYPE } from "../../constants/db_types";
import {
  deleteOrganizationFile,
  uploadOrganizationFile,
} from "../../utils/gcp-file";
import { loader } from "graphql.macro";
import { useMutation } from "@apollo/client";
import { displayToastNotification } from "../../utils/toastNotification";

const updateOrganizationDocumentQuery = loader(
  "../../graphql/updateOrganizationDocument.graphql"
);

interface Values {
  id?: string;
  name: string;
  active: boolean;
  fileToUpload: File | null;
  fileToUploadIsSet: boolean;
  currentFileName: string | null;
}

interface OrganizationDocumentsUpdateProps {}

const OrganizationDocumentsUpdate: React.FC<OrganizationDocumentsUpdateProps> =
  () => {
    const history = useHistory();

    const [updateOrganizationDocument] = useMutation(
      updateOrganizationDocumentQuery
    );

    const { currentOrganizationCxt, organizationDocumentCxt } =
      useContext<any>(ApplicationContext);

    const [initialValues, setInitialValues] = useState<Values | null>(null);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
      if (organizationDocumentCxt.organizationDocumentToEdit.id) {
        const curatedOrganizationDocument = Object.assign(
          {},
          organizationDocumentCxt.organizationDocumentToEdit
        );
        setInitialValues({
          id: curatedOrganizationDocument.id,
          name: curatedOrganizationDocument.name,
          fileToUpload: null,
          fileToUploadIsSet: false,
          currentFileName: curatedOrganizationDocument.document_name,
          //   organization_id: curatedOrganizationDocument.organization.id,
          active: curatedOrganizationDocument.active,
        });
      } else {
        const currentOrganizationDocument = localStorage.getItem(
          "currentOrganizationDocument"
        );
        if (currentOrganizationDocument) {
          const currentOrganizationDocumentParse = JSON.parse(
            currentOrganizationDocument
          );
          setInitialValues({
            id: currentOrganizationDocumentParse.id,
            name: currentOrganizationDocumentParse.name,
            fileToUpload: null,
            fileToUploadIsSet: false,
            currentFileName: currentOrganizationDocumentParse.document_name,
            // organization_id: currentOrganizationDocumentParse.organization.id,
            active: currentOrganizationDocumentParse.active,
          });
        } else {
          history.push("/organization-documents/list");
        }
      }
    }, [organizationDocumentCxt.organizationDocumentToEdit, history]);

    useEffect(() => {
      if (initialValues) {
        setIsLoading(false);
      }
    }, [initialValues]);

    const modifyOrganizationDocument = async (values: Values) => {
      const organizationDocumentVariables = {
        id: values.id!,
        name: values.name,
        documentName: values.currentFileName!,
        active: values.active,
      };
      if (values.fileToUpload && typeof values.fileToUpload.name === "string") {
        const { error: deleteOrganizationFileError } =
          await deleteOrganizationFile(
            organizationDocumentVariables.documentName,
            organizationDocumentVariables.id,
            ORGANIZATION_FILE_TYPE.DOCUMENT
          );
        if (deleteOrganizationFileError) {
          console.error(deleteOrganizationFileError);
          return;
        }
        const { fileName: fileNameTmp, error: uploadOrganizationFileError } =
          await uploadOrganizationFile(
            //@ts-ignore
            values.fileToUpload,
            currentOrganizationCxt.currentOrganizationToEdit?.id,
            ORGANIZATION_FILE_TYPE.DOCUMENT
          );
        if (uploadOrganizationFileError) {
          console.error(uploadOrganizationFileError);
          return;
        }
        organizationDocumentVariables.documentName = fileNameTmp;
      }
      updateOrganizationDocument({
        variables: { ...organizationDocumentVariables },
      })
        .then((...args) => {
          const [
            {
              data: {
                update_organization_document: {
                  returning: organization_document,
                },
              },
            },
          ] = args;
          //   console.info("Organization document updated", organization_document);
          localStorage.setItem(
            "currentOrganizationDocument",
            JSON.stringify(organization_document[0])
          );
          organizationDocumentCxt.setOrganizationDocumentToEdit(
            organization_document[0]
          );
          // Display a toast notification if operation succeded
          displayToastNotification(
            "success",
            "Les modifications ont bien été enregistrées"
          );
        })
        .catch((e) => {
          console.error("Invalid input:", organizationDocumentVariables);
          console.error(e);
          Sentry.captureException(e);
        });
    };

    const handleSubmit = async (values: Values) => {
      await modifyOrganizationDocument(values);
    };

    if (isLoading) {
      return <Loader />;
    }

    return (
      <PageWrapper
        previousPageIconOn={true}
        previousPageText={"Retour à la liste des documents"}
        handleOnClickPreviousPage={() =>
          history.push("/organization-documents/list")
        }
      >
        <Container>
          <FormOrganizationDocument
            title="Modifier le document"
            initialValues={initialValues!}
            mode="update"
            handleSubmit={(values) => handleSubmit(values)}
          />
        </Container>
      </PageWrapper>
    );
  };

export default OrganizationDocumentsUpdate;

const Container = styled.div`
  height: 100%;
  margin: 0 auto;
  max-width: 1030px;
`;
