/*
  This file is a .js because react-phone-number-input/input doesn't work with
  typescript file
*/
import React from "react";
import PhoneInput from "react-phone-number-input/input";
import "react-phone-number-input/style.css";
import styled from "styled-components";
import { ThemeComponents } from "../../../theme/components";
import { Container, Row, Col } from "react-bootstrap";
import { BodyMain, BodySmall } from "../../../theme/fonts";

const PhoneInputForm = ({
  label,
  //inputDefaultCountry,
  placeholder,
  disabled,
  direction,
  field,
  form,
}) => {
  const error = form.errors[field.name];
  const touched = form.touched[field.name];

  if (direction === "column") {
    return (
      <TextInputColumnContainer>
        <TextInputLabelContainer>
          <TextInputLabel>{label}</TextInputLabel>
        </TextInputLabelContainer>
        <PhoneInputStyled
          {...field}
          defaultCountry="FR"
          placeholder={placeholder}
          disabled={disabled}
          //onChange={(e) => form.setFieldValue("phone", e)}
          smartCaret={false}
          useNationalFormatForDefaultCountryValue={false}
          error={error}
          touched={touched ? "true" : ""}
        />
        {touched && error && <TextInputError>{error}</TextInputError>}
      </TextInputColumnContainer>
    );
  } else {
    return (
      <TextInputAutoContainer fluid>
        <TextInputRow noGutters>
          {label && (
            <Col xs={12}>
              <TextInputLabelContainer>
                <TextInputLabel>{label}</TextInputLabel>
              </TextInputLabelContainer>
            </Col>
          )}
          {/* <Col xs={12} sm={label || sublabel ? 8 : 12}> */}
          <Col xs={12}>
            <PhoneInputStyled
              {...field}
              defaultCountry="FR"
              placeholder={placeholder}
              disabled={disabled}
              onChange={(e) => form.setFieldValue("phone", e)}
              smartCaret={false}
              useNationalFormatForDefaultCountryValue={false}
              error={error}
              touched={touched ? "true" : ""}
            />
            {touched && error && <TextInputError>{error}</TextInputError>}
          </Col>
        </TextInputRow>
      </TextInputAutoContainer>
    );
  }
};

const TextInputAutoContainer = styled(Container)`
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: 0;
  position: relative;
`;

const TextInputRow = styled(Row)`
  width: 100%;
`;

const PhoneInputStyled = styled(PhoneInput)`
  ${BodyMain};
  width: 100%;
  height: 45px;
  border-radius: 6px;
  padding: 8px;
  border: none !important;
  border: ${(props) => {
    const colorType =
      !!props.error && props.touched
        ? props.theme.colors.red.dark
        : props.theme.colors.white.darker;
    return `1px solid ${colorType} !important`;
  }};
  background: ${(props) => props.theme.colors.darkGreenL1};
  color: ${(props) =>
    props.disabled
      ? props.theme.colors.darkGreenL4
      : props.theme.colors.blackMain};

  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.laptop13Max}) {
    height: 32px;
    ${BodySmall}
  }

  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.mobilePortraitMax}) {
    height: 36px;
  }

  ::placeholder {
    color: ${(props) => props.theme.colors.darkGreenL3};
  }

  :focus {
    outline: none !important;
    border: 1px solid
      ${(props) => props.theme.componentObjects.formContainer.borderFocus};
  }
`;

const TextInputLabel = styled(ThemeComponents.FormLabel)``;

const TextInputError = styled(ThemeComponents.FormErrorLabel)``;

const TextInputLabelContainer = styled.div`
  margin: 5px 15px 5px 0;
`;

const TextInputColumnContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 16px 0;
  position: relative;
`;

export default PhoneInputForm;
