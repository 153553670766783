const def = (kg, ageMonth, type) => {
  let amount_scale = 0;
  let comment = "";
  if (type === "induction") {
    if (kg < 5) {
      amount_scale = [kg * 1, kg * 2];
    } else {
      amount_scale = [kg * 0.15, kg * 0.2];
    }
    return {
      amount_scale,
      unit: "mg",
      comment,
    };
  } else if (type === "association") {
    if (kg < 5) {
      amount_scale = [kg * 0.05, kg * 0.2];
    } else {
      amount_scale = [kg * 0.03, kg * 0.1];
    }
    return {
      amount_scale,
      unit: "mg",
      comment,
    };
  } else if (type === "entretiens") {
    if (kg < 1) {
      amount_scale = kg * 0.03;
    } else if (kg < 5) {
      amount_scale = kg * 0.06;
    } else {
      amount_scale = [kg * 0.06, kg * 0.12];
    }
    return {
      amount_scale,
      unit: "mg/h",
      comment,
    };
  }
};

export default def;
