import React, { useContext } from "react";
import styled from "styled-components";
import { PageWrapper } from "../../components/Layout/PageWrapper/PageWrapper";
import ApplicationContext from "../../utils/context";
import { uploadOrganizationFile } from "../../utils/gcp-file";
import { ORGANIZATION_FILE_TYPE } from "../../constants/db_types";
import { useMutation } from "@apollo/client";
import { loader } from "graphql.macro";
import * as Sentry from "@sentry/react";
import { displayToastNotification } from "../../utils/toastNotification";
import FormOrganizationGuideline from "../../components/FormTemplates/OrganizationGuideline/FormOrganizationGuideline";

const addOrganizationGuidelineQuery = loader(
  "../../graphql/addOrganizationGuideline.graphql"
);

interface Values {
  id?: string;
  name: string;
  active: boolean;
  type: string;
  fileToUpload: File | null;
  fileToUploadIsSet: boolean;
  currentFileName: string | null;
}

interface OrganizationDocumentsCreateProps {}

const OrganizationDocumentsCreate: React.FC<OrganizationDocumentsCreateProps> =
  () => {
    const { currentOrganizationCxt } = useContext<any>(ApplicationContext);

    const [addOrganizationGuideline] = useMutation(
      addOrganizationGuidelineQuery
    );

    const initialValues: Values = {
      name: "",
      type: "",
      fileToUpload: null,
      fileToUploadIsSet: false,
      active: false,
      currentFileName: null,
    };

    const addOrganizationGuidelineAsync = async (
      organizationGuidelineVariables: any
    ) => {
      return await addOrganizationGuideline({
        variables: organizationGuidelineVariables,
      }).then((...response) => {
        const [
          {
            data: {
              insert_organization_guideline: {
                returning: organization_guideline,
              },
            },
          },
        ] = response;
        console.info(
          "Organization guideline created",
          organization_guideline[0]
        );
        return organization_guideline[0];
      });
    };

    const createOrganizationGuideline = async (values: Values) => {
      const organizationId =
        currentOrganizationCxt.currentOrganizationToEdit.id;
      if (!organizationId) {
        console.error("organizatonId is not set");
        return;
      }
      try {
        const { fileName, error } = await uploadOrganizationFile(
          values.fileToUpload!,
          currentOrganizationCxt.currentOrganizationToEdit?.id,
          ORGANIZATION_FILE_TYPE.GUIDELINE
        );
        if (error) {
          console.error(error);
          displayToastNotification("error", "Organisation non reconnue");
          return;
        } else {
          const organizationGuidelineVariables = {
            name: values.name,
            surgeryTypeId: values.type,
            organizationId,
            documentName: fileName,
            active: values.active,
          };
          try {
            await addOrganizationGuidelineAsync(organizationGuidelineVariables);
            displayToastNotification(
              "success",
              "La nouvelle consigne est bien enregistrée"
            );
          } catch (err) {
            console.error("Invalid input:", organizationGuidelineVariables);
            console.error(err);
            Sentry.captureException(err);
          }
        }
      } catch (err) {
        console.error(err);
      }
    };

    const handleSubmit = async (values: Values) => {
      await createOrganizationGuideline(values);
    };

    return (
      <PageWrapper>
        <Container>
          <FormOrganizationGuideline
            title="Créer une consigne"
            initialValues={initialValues}
            mode="creation"
            handleSubmit={(values) => handleSubmit(values)}
            organizationId={currentOrganizationCxt.currentOrganizationToEdit.id}
          />
        </Container>
      </PageWrapper>
    );
  };

export default OrganizationDocumentsCreate;

const Container = styled.div`
  height: 100%;
  margin: 0 auto;
  max-width: 1030px;
`;
