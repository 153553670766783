import * as _ from "lodash";
import { SURVEY_TYPE_VALUE } from "../constants/db_types";

const dateTimeFormat = new Intl.DateTimeFormat("fr", {
  year: "numeric",
  month: "short",
  day: "2-digit",
  // hour: "2-digit",
  // minute: "2-digit",
});
interface TimelineDataBasicValue {
  title?: string;
  value?: string;
  date?: string;
  tags?: Array<{ title: string }>;
  button?: { content: string; onClick: () => void; disabled: boolean };
  subsurvey?: Array<TimelineDataBasicValue>;
  timestamp?: number;
}
interface TimelineDataState {
  childAnxiety?: TimelineDataBasicValue;
  parentAnxiety?: TimelineDataBasicValue;
  childPain?: TimelineDataBasicValue;
  symptomatologyDayMinus3?: TimelineDataBasicValue;
  phbq?: TimelineDataBasicValue;
  dentalFirstSurvey?: TimelineDataBasicValue;
  modifiedDentalAnxiety?: TimelineDataBasicValue;
  staiNow?: TimelineDataBasicValue;
  staiGlobal?: TimelineDataBasicValue;
  covidChecker?: TimelineDataBasicValue;
}

/* Store indexes of inv questions */
const QUESTIONS_INVERSE_SCORE_STAI_NOW = [1, 2, 5, 8, 10, 11, 15, 16, 19, 20];
const QUESTIONS_INVERSE_SCORE_STAI_GLOBAL = [1, 3, 6, 7, 10, 13, 14, 16, 19];

type StaiData = Array<{
  answer: { order: number };
  question: { order: number };
}>;
export const calculateScoreSurveyStai = (data: StaiData, type: string) => {
  if (!data) {
    return undefined;
  } else {
    let score = 0;
    let questionsInverseScoreIndex: Array<number> = [];
    if (type === SURVEY_TYPE_VALUE.STAI_NOW) {
      questionsInverseScoreIndex = QUESTIONS_INVERSE_SCORE_STAI_NOW;
    } else if (type === SURVEY_TYPE_VALUE.STAI_GLOBAL) {
      questionsInverseScoreIndex = QUESTIONS_INVERSE_SCORE_STAI_GLOBAL;
    }
    data.map((e) => {
      if (questionsInverseScoreIndex.includes(e.question.order)) {
        if (e.answer.order === 1) score += 4;
        else if (e.answer.order === 2) score += 3;
        else if (e.answer.order === 3) score += 2;
        else if (e.answer.order === 4) score += 1;
      } else {
        score += e.answer.order;
      }
      return null;
    });
    return score;
  }
};

/**
 * This function removes object entries which is display in the timeline
 * In fact we just need to display the survey_type linked to a specific surgery_type / age
 **/
const deleteSurveysNotLinkedToSurgeryType = (
  obj: TimelineDataState,
  surveyTypeBySurgeryAndAge: any
) => {
  const surveysTypeToObj = [
    { value: SURVEY_TYPE_VALUE.ANXIETY_EVALUATION, key: "childAnxiety" },
    {
      value: SURVEY_TYPE_VALUE.PARENT_ANXIETY_EVALUATION,
      key: "parentAnxiety",
    },
    { value: SURVEY_TYPE_VALUE.PAIN_EVALUATION, key: "childPain" },
    { value: SURVEY_TYPE_VALUE.PHBQ_EVALUATION, key: "phbq" },
    { value: SURVEY_TYPE_VALUE.SYMPTOMATOLOGY, key: "symptomatologyDayMinus3" },
    {
      value: SURVEY_TYPE_VALUE.DENTAL_FIRST_EVALUATION,
      key: "dentalFirstSurvey",
    },
    {
      value: SURVEY_TYPE_VALUE.MODIFIED_DENTAL_ANXIETY_EVALUATION,
      key: "modifiedDentalAnxiety",
    },
    { value: SURVEY_TYPE_VALUE.STAI_NOW, key: "staiNow" },
    { value: SURVEY_TYPE_VALUE.STAI_GLOBAL, key: "staiGlobal" },
    { value: SURVEY_TYPE_VALUE.COVID_CHECKER, key: "covidChecker" },
  ];
  const surveysTypeToDelete = surveyTypeBySurgeryAndAge.map(
    (e: any) => e.survey_type.value
  );

  surveysTypeToObj.forEach((e) => {
    if (!surveysTypeToDelete.includes(e.value)) {
      //@ts-ignore
      delete obj[e.key];
    }
  });

  return obj;
};

export const parseSuveysAnswers = (
  data: any,
  surveyTypeBySurgeryAndAge: any
) => {
  /* Anxiety */
  const latestChildAnxiety = data?.anxiety?.[0]
    ?.survey_result_linked_to_surgeries?.[0]?.survey_results?.[0]?.answer && {
    ...data.anxiety[0].survey_result_linked_to_surgeries[0].survey_results[0]
      .answer,
    created:
      data.anxiety[0].survey_result_linked_to_surgeries[0].survey_results[0]
        .created_at,
  };

  /* Parent Anxiety */
  const latestParentAnxiety = data?.parent_anxiety?.[0]
    ?.survey_result_linked_to_surgeries?.[0]?.survey_results?.[0]?.answer && {
    ...data.parent_anxiety[0].survey_result_linked_to_surgeries[0]
      .survey_results[0].answer,
    created:
      data.parent_anxiety[0].survey_result_linked_to_surgeries[0]
        .survey_results[0].created_at,
  };

  /* Pain */
  const latestChildPain = data?.pain_test?.[0]
    ?.survey_result_linked_to_surgeries?.[0]?.survey_results?.[0]?.answer && {
    ...data.pain_test[0].survey_result_linked_to_surgeries[0].survey_results[0]
      .answer,
    created:
      data.pain_test[0].survey_result_linked_to_surgeries[0].survey_results[0]
        .created_at,
  };

  /* Symptomatology D-3 */
  const symptomatologyDayMinus3Tmp =
    data?.symptomatologyDayMinus3?.[0]?.survey_result_linked_to_surgeries?.[0]
      ?.survey_results;

  const symptomatologyDayMinus3Date =
    data?.symptomatologyDayMinus3?.[0]?.survey_result_linked_to_surgeries?.[0]
      ?.survey_results?.[0]?.created_at;

  const symptomatologyDayMinus3 = symptomatologyDayMinus3Tmp
    ?.map((result: any) => {
      let status = "danger";
      if (result.answer.survey_answer_i18ns?.[0]?.value === "false") {
        status = "success";
      }
      if (result.question.order === 1) {
        return { title: "TOUX", status };
      } else if (result.question.order === 2) {
        return { title: "FIÈVRE", status };
      } else if (result.question.order === 3) {
        return { title: "RHINORHÉE", status };
      }
      return null;
    })
    .filter((e: any) => e);

  /* PHBQ */
  const lastestPhbqValue =
    data?.phbq_test?.[0]?.survey_result_linked_to_surgeries?.[0]?.survey_results
      .map((e: { answer: { order: number } }) => e.answer.order)
      .reduce((a: number, b: number) => a + b, 0);

  const latestPhbqDate =
    data?.phbq_test?.[0]?.survey_result_linked_to_surgeries?.[0]
      ?.survey_results?.[0]?.created_at;

  /* Dental First Survey */
  const dentalFirstSurvey = _.orderBy(
    data?.dental_first_survey?.[0]?.survey_result_linked_to_surgeries?.[0]
      ?.survey_results,
    (item) => item.question.order
  ).map(
    (e: {
      question: {
        order: number;
        survey_question_i18ns: Array<{ value: string }>;
      };
      answer: { survey_answer_i18ns: Array<{ value: string }> };
    }) => {
      return {
        title: e.question.survey_question_i18ns?.[0]?.value,
        tags: [{ title: e.answer.survey_answer_i18ns?.[0]?.value }],
      };
    }
  );

  const dentalFirstSurveyDate =
    data?.dental_first_survey?.[0]?.survey_result_linked_to_surgeries?.[0]
      ?.survey_results?.[0]?.created_at;

  /* Modified Dental Anxiety */
  const modifiedDentalAnxietyValue =
    data?.modified_dental_anxiety?.[0]?.survey_result_linked_to_surgeries?.[0]?.survey_results
      .map((e: { answer: { order: number } }) => e.answer.order)
      .reduce((a: number, b: number) => a + b, 0);

  const modifiedDentalAnxietyDate =
    data?.modified_dental_anxiety?.[0]?.survey_result_linked_to_surgeries?.[0]
      ?.survey_results?.[0]?.created_at;

  /* Stai-now */
  const staiNowValue = calculateScoreSurveyStai(
    data?.stai_now?.[0]?.survey_result_linked_to_surgeries?.[0]?.survey_results,
    SURVEY_TYPE_VALUE.STAI_NOW
  )?.toString();
  const staiNowDate =
    data?.stai_now?.[0]?.survey_result_linked_to_surgeries?.[0]
      ?.survey_results?.[0]?.created_at;

  /* Stai-global */
  const staiGlobalValue = calculateScoreSurveyStai(
    data?.stai_global?.[0]?.survey_result_linked_to_surgeries?.[0]
      ?.survey_results,
    SURVEY_TYPE_VALUE.STAI_GLOBAL
  )?.toString();
  const staiGlobalDate =
    data?.stai_global?.[0]?.survey_result_linked_to_surgeries?.[0]
      ?.survey_results?.[0]?.created_at;

  /* Covid Checker */
  const covidCherckerValue =
    data?.covid_checker?.[0]?.survey_result_linked_to_surgeries?.[0]?.survey_results
      ?.map((e: any) => {
        if (e.answer.survey_answer_i18ns?.[0]?.value === "true") {
          return 1;
        } else {
          return false;
        }
      })
      .reduce((a: number, b: number) => a + b, 0);

  const covidCheckerSuvey = _.orderBy(
    data?.covid_checker?.[0]?.survey_result_linked_to_surgeries?.[0]
      ?.survey_results,
    (item) => item.question.order
  ).map(
    (e: {
      question: {
        order: number;
        survey_question_i18ns: Array<{ value: string }>;
      };
      answer: { survey_answer_i18ns: Array<{ value: string }> };
    }) => {
      const answerValue = e.answer.survey_answer_i18ns?.[0]?.value;
      return {
        title: e.question.survey_question_i18ns?.[0]?.value,
        tags: [
          {
            title: answerValue === "true" ? "oui" : "non",
            status: answerValue === "true" ? "danger" : "success",
          },
        ],
      };
    }
  );

  const covidCheckerDate =
    data?.covid_checker?.[0]?.survey_result_linked_to_surgeries?.[0]
      ?.survey_results?.[0]?.created_at;

  /* All infos in Timeline */
  let timelineDataTmp: TimelineDataState = {
    childAnxiety: {
      title: "Ressenti de l'enfant grâce à une échelle des émotions",
      value: latestChildAnxiety?.order,
      timestamp: new Date(latestChildAnxiety?.created).getTime(),
      date: latestChildAnxiety
        ? dateTimeFormat.format(new Date(latestChildAnxiety?.created))
        : "",
      tags: [
        {
          title: latestChildAnxiety?.order
            ? `${latestChildAnxiety?.order}/6`
            : "-",
        },
      ],
    },
    parentAnxiety: {
      title: "Anxiété du parent mesurée grâce à une EVA",
      value: latestParentAnxiety?.order?.toString(),
      timestamp: new Date(latestParentAnxiety?.created).getTime(),
      date: latestParentAnxiety
        ? dateTimeFormat.format(new Date(latestParentAnxiety.created))
        : "",
      tags: [
        {
          title: !!latestParentAnxiety?.order.toString()
            ? `${latestParentAnxiety?.order}/10`
            : "-",
        },
      ],
    },
    childPain: {
      title: "Evaluation de la douleur de l'enfant",
      value: latestChildPain?.order?.toString(),
      timestamp: new Date(latestChildPain?.created).getTime(),
      date: latestChildPain
        ? dateTimeFormat.format(new Date(latestChildPain.created))
        : "",
      tags: [
        {
          title: !!latestChildPain?.order.toString()
            ? `${latestChildPain?.order}/10`
            : "-",
        },
      ],
    },
    symptomatologyDayMinus3: {
      title:
        "Les parents ont déclaré la présence des symptômes suivants:\nRouge = présence des symptômes\nVert = absence des symptômes",
      value: symptomatologyDayMinus3Date ? symptomatologyDayMinus3Date : "", //Random value. if data is available for green check
      timestamp: new Date(symptomatologyDayMinus3Date).getTime(),
      date: symptomatologyDayMinus3Date
        ? dateTimeFormat.format(new Date(symptomatologyDayMinus3Date))
        : "",
      tags: symptomatologyDayMinus3,
    },
    phbq: {
      title:
        "Apparition de troubles du comportement en postopératoire, mesurée grâce a une échelle PHBQ",
      value: lastestPhbqValue,
      timestamp: new Date(latestPhbqDate).getTime(),
      date: latestPhbqDate
        ? dateTimeFormat.format(new Date(latestPhbqDate))
        : "",
      tags: [
        {
          title: lastestPhbqValue ? `${lastestPhbqValue}/55` : "-",
        },
      ],
    },
    dentalFirstSurvey: {
      title: "Questionnaire découverte patient",
      value: dentalFirstSurveyDate,
      timestamp: new Date(dentalFirstSurveyDate).getTime(),
      date: dentalFirstSurveyDate
        ? dateTimeFormat.format(new Date(dentalFirstSurveyDate))
        : "",
      // button: {
      //   content: "Voir les résultats",
      //   onClick: () => {
      //     setShowSecondaryTimeline("dentalFirstSurvey");
      //   },
      //   disabled: !!!dentalFirstSurveyDate,
      // },
      subsurvey: dentalFirstSurvey,
    },
    modifiedDentalAnxiety: {
      title: 'Evaluation de l’anxiété dentaire grâce à une échelle "CORAH" ',
      value: modifiedDentalAnxietyValue,
      timestamp: new Date(modifiedDentalAnxietyDate).getTime(),
      date: modifiedDentalAnxietyDate
        ? dateTimeFormat.format(new Date(modifiedDentalAnxietyDate))
        : "",
      tags: [
        {
          title: modifiedDentalAnxietyValue
            ? `${modifiedDentalAnxietyValue}/25`
            : "-",
        },
      ],
    },
    staiNow: {
      title:
        "Anxiété du parent face à l’intervention mesurée grâce à une STAI now",
      value: staiNowValue,
      timestamp: new Date(staiNowDate).getTime(),
      date: staiNowDate ? dateTimeFormat.format(new Date(staiNowDate)) : "",
      tags: [
        {
          title: staiNowValue ? `${staiNowValue}/80` : "-",
        },
      ],
    },
    staiGlobal: {
      title:
        "Trait d’anxiété du parent en général mesuré grâce à une STAI global",
      value: staiGlobalValue,
      timestamp: new Date(staiGlobalDate).getTime(),
      date: staiGlobalDate
        ? dateTimeFormat.format(new Date(staiGlobalDate))
        : "",
      tags: [
        {
          title: staiGlobalValue ? `${staiGlobalValue}/80` : "-",
        },
      ],
    },
    covidChecker: {
      title: "Questionnaire COVID-19",
      value: covidCherckerValue?.toString(),
      date: covidCheckerDate
        ? dateTimeFormat.format(new Date(covidCheckerDate))
        : "",
      tags: [
        {
          title: covidCherckerValue >= 0 ? `${covidCherckerValue}/15` : "-",
        },
      ],
      subsurvey: covidCheckerSuvey,
    },
  };

  /* Filter based on surgery_type */
  const timelineDataTmpFiltered = deleteSurveysNotLinkedToSurgeryType(
    timelineDataTmp,
    surveyTypeBySurgeryAndAge
  );
  return timelineDataTmpFiltered;
};

export const getSurveyTypeValueFormat = (value: string) => {
  switch (value) {
    case SURVEY_TYPE_VALUE.STAI_GLOBAL:
      return "Trait d’anxiété du parent en général mesuré grâce à une STAI global";
    case SURVEY_TYPE_VALUE.STAI_NOW:
      return "Anxiété du parent face à l’intervention mesurée grâce à une STAI now";
    case SURVEY_TYPE_VALUE.MODIFIED_DENTAL_ANXIETY_EVALUATION:
      return "Evaluation de l’anxiété dentaire grâce à une échelle 'CORAH'";
    case SURVEY_TYPE_VALUE.DENTAL_FIRST_EVALUATION:
      return "Questionnaire découverte patient";
    case SURVEY_TYPE_VALUE.PHBQ_EVALUATION:
      return "Apparition de troubles du comportement en postopératoire, mesurée grâce a une échelle PHBQ";
    case SURVEY_TYPE_VALUE.SYMPTOMATOLOGY:
      return "Les parents ont déclaré la présence des symptômes suivants:\nRouge = présence des symptômes\nVert = absence des symptômes";
    case SURVEY_TYPE_VALUE.PAIN_EVALUATION:
      return "Evaluation de la douleur de l'enfant";
    case SURVEY_TYPE_VALUE.PARENT_ANXIETY_EVALUATION:
      return "Anxiété du parent mesurée grâce à une EVA";
    case SURVEY_TYPE_VALUE.ANXIETY_EVALUATION:
      return "Ressenti de l'enfant grâce à une échelle des émotions";
    case SURVEY_TYPE_VALUE.COVID_CHECKER:
      return "Questionnaire COVID-19";
    default:
      return "";
  }
};
