import { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import { PageWrapper } from "../../../components/Layout/PageWrapper/PageWrapper";
import Daily from "./Daily";
import { Calendar } from "../../../components/Calendar/Calendar";
import { Heading1, Heading2 } from "../../../theme/fonts";
import { useLazyQuery } from "@apollo/client";
import { loader } from "graphql.macro";
import ApplicationContext from "../../../utils/context";

//TODO change name + params because organization_id now
const getReservationsByOrganization = loader(
  "../../../graphql/getReservationsByOrganization.graphql"
);

const isSameDay = (d1: Date, d2: Date) =>
  d1.getFullYear() === d2.getFullYear() &&
  d1.getMonth() === d2.getMonth() &&
  d1.getDate() === d2.getDate();

// const isBetweenDays = (d1: Date, d2: Date, d3: Date) => {
//   d2.setHours(0, 0, 0);
//   d3.setHours(23, 59, 59);
//   return d1 > d2 && d1 < d3;
// };

export const Availability: any = () => {
  const { currentOrganizationCxt } = useContext<any>(ApplicationContext);

  const [getReservations, { data: reservations }] = useLazyQuery(
    getReservationsByOrganization,
    { fetchPolicy: "network-only" }
  );

  const [selectedDay, setSelectedDay] = useState(new Date());
  const [selectedWeek, setSelectedWeek] = useState<string[]>([]);
  const [view, setView] = useState("daily-view");
  const [dailyReservations, setDailyReservations] = useState([]);

  useEffect(() => {
    if (currentOrganizationCxt?.currentOrganizationToEdit?.id) {
      getReservations({
        variables: {
          organization_id:
            currentOrganizationCxt?.currentOrganizationToEdit?.id,
        },
      });
    }
  }, [currentOrganizationCxt?.currentOrganizationToEdit, getReservations]);

  useEffect(() => {
    if (reservations) {
      setDailyReservations(
        reservations?.reservation.filter((x: any) =>
          isSameDay(new Date(x.start_time), new Date())
        )
      );
    }
  }, [reservations]);

  useEffect(() => {
    setDailyReservations(
      reservations?.reservation.filter((x: any) =>
        isSameDay(new Date(x.start_time), selectedDay)
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDay]);

  return (
    <PageWrapper>
      <Container>
        <Title>Liste des disponibilités</Title>
        <p style={{ fontWeight: "bold" }}>
          Les patients suivants ont indiqué leurs disponibilités.
        </p>
        {/* TODO Currently for simplicity we only dispay reservation day by day (custom List) */}
        <Calendar
          selectedDay={selectedDay}
          setSelectedDay={(day) => setSelectedDay(day)}
          selectedWeek={selectedWeek} // TODO why select week ?
          setSelectedWeek={(week) => setSelectedWeek(week)} // TODO why select week ?
          displayEllipse={false}
          displayPatients={view === "weekly-view"}
          handleView={(e) => setView(e.value || e)}
          // subData={calendarData || undefined}
          clickable={false}
          singleView={true}
        />
        {/* TODO finish thibaut's work custom list */}
        {view === "daily-view" && <Daily reservation={dailyReservations} />}
      </Container>
    </PageWrapper>
  );
};

export default Availability;

const Title = styled.div`
  margin-bottom: 36px;
  ${Heading1};

  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.laptop13Max}) {
    ${Heading2}
  } ;
`;

const Container = styled.div`
  height: 100%;
  max-width: 1030px;
  min-width: 566px;
  margin: 0 auto;
`;
