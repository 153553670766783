import React from "react";
import styled from "styled-components";
import { Heading2, Heading3 } from "../../../theme/fonts";
import SeparatorSectionForm from "../../Form/SeparatorSectionForm/SeparatorSectionForm";

interface BasicCardProps {
  title?: string;
  titleSeparator?: boolean;
  borderRadius?: string;
}

const BasicCard: React.FC<BasicCardProps> = ({
  children,
  title,
  titleSeparator,
  borderRadius,
}) => {
  return (
    <Container borderRadius={borderRadius}>
      {title && <Title>{title}</Title>}
      {titleSeparator && (
        <SeparatorSectionContainer>
          <SeparatorSectionForm />
        </SeparatorSectionContainer>
      )}
      {children}
    </Container>
  );
};

export default BasicCard;

const Container = styled.div<{ borderRadius?: string }>`
  width: 100%;
  height: 100%;
  padding: 20px 15px;
  background: ${(props) => props.theme.colors._white};
  box-shadow: -6px -6px 12px 0.5px rgba(197, 197, 197, 0.21),
    6px 6px 12px 0.5px rgba(197, 197, 197, 0.21);
  border-radius: ${(props) =>
    props.borderRadius ? props.borderRadius : "16px"};
`;

const Title = styled.div`
  margin-bottom: 9px;
  ${Heading2};

  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.laptop13Max}) {
    ${Heading3}
  } ;
`;

const SeparatorSectionContainer = styled.div`
  margin: 5px 0;
`;
