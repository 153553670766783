import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { PageWrapper } from "../../components/Layout/PageWrapper/PageWrapper";
import CustomList from "../../components/List/CustomList/CustomList";
import {
  CardStyle2,
  LinearGradientText,
} from "../../theme/components/ThemeComponents";
import { loader } from "graphql.macro";
import { useLazyQuery } from "@apollo/client";
import ApplicationContext from "../../utils/context";
import { ReactComponent as IconOpenWindow } from "../../theme/icons/openWindow.svg";
import { Modal, ModalBody } from "reactstrap";
import PdfViewer from "../../components/PdfViewer";
import { ORGANIZATION_FILE_TYPE } from "../../constants/db_types";

interface OrganizationGuidelinesListProps {}

const columns = [
  {
    value: "Nom",
    ratio: "40%",
    type: "string",
    sortable: true,
    sortId: "name",
  },
  {
    value: "Pathologie",
    ratio: "40%",
    type: "string",
  },
  {
    value: "Lien",
    ratio: "20%",
    type: "component",
  },
];

const limit = 10;

const getOrganizationGuidelinesCountQuery = loader(
  "../../graphql/getOrganizationGuidelinesCountByOrganizationId.graphql"
);

const getOrganizationGuidelinesWithLimitOffsetQuery = loader(
  "../../graphql/getOrganizationGuidelinesWithLimitOffset.graphql"
);

const OrganizationGuidelinesList: React.FC<OrganizationGuidelinesListProps> =
  () => {
    const history = useHistory();

    const { currentOrganizationCxt, organizationGuidelineCxt } =
      useContext<any>(ApplicationContext);

    const [pagesNumber, setPagesNumber] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [search, setSearch] = useState("%%");
    const [searchBarValue, setSearchBarValue] = useState("");
    const [orderBy, setOrderBy] = useState<any>({
      name: "desc",
    });

    const [currentGuideline, setCurrentGuideline] = useState({
      show: false,
      documentName: "",
    });

    const [
      getOrganizationGuidelinesCount,
      { data: dataOrganizationGuidelinesCount },
    ] = useLazyQuery(getOrganizationGuidelinesCountQuery, {
      fetchPolicy: "network-only",
    });

    const [organizationGuidelines, setOrganizationGuidelines] = useState<Array<{
      customData: any;
      rowValues: Array<string>;
    }> | null>(null);

    const [
      getOrganizationGuidelinesWithLimitOffset,
      { data: dataOrganizationGuidelines },
    ] = useLazyQuery(getOrganizationGuidelinesWithLimitOffsetQuery, {
      fetchPolicy: "network-only",
    });

    useEffect(() => {
      if (currentOrganizationCxt?.currentOrganizationToEdit?.id) {
        getOrganizationGuidelinesCount({
          variables: {
            organizationId: currentOrganizationCxt.currentOrganizationToEdit.id,
            search,
          },
        });
      }
    }, [
      currentOrganizationCxt?.currentOrganizationToEdit,
      getOrganizationGuidelinesCount,
      search,
    ]);

    /* Calculate number of pages */
    useEffect(() => {
      if (dataOrganizationGuidelinesCount) {
        const organizationGuidelinesCount =
          dataOrganizationGuidelinesCount.organization_guideline.length;
        let pagesNumberTmp = Math.ceil(organizationGuidelinesCount / limit);
        setPagesNumber(pagesNumberTmp);
      }
    }, [dataOrganizationGuidelinesCount]);

    useEffect(() => {
      if (currentOrganizationCxt?.currentOrganizationToEdit?.id) {
        getOrganizationGuidelinesWithLimitOffset({
          variables: {
            limit,
            offset: currentPage * limit - limit,
            orderBy,
            search,
            organizationId:
              currentOrganizationCxt?.currentOrganizationToEdit?.id,
          },
        });
      }
    }, [
      currentPage,
      currentOrganizationCxt?.currentOrganizationToEdit,
      getOrganizationGuidelinesWithLimitOffset,
      search,
      orderBy,
    ]);

    useEffect(() => {
      if (dataOrganizationGuidelines) {
        const organizationGuidelinesTmp =
          dataOrganizationGuidelines.organization_guideline.map((e: any) => {
            return {
              customData: {
                ...e,
              },
              rowValues: [
                e.name,
                e.surgery_type.value,
                <ButtonLinkOpenDocument>
                  <div
                    onClick={(event) => {
                      event.stopPropagation();
                      setCurrentGuideline({
                        documentName: e.document_name,
                        show: true,
                      });
                    }}
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <span>Ouvrir le document</span>
                    <IconOpenWindow />
                  </div>
                </ButtonLinkOpenDocument>,
              ],
            };
          });
        setOrganizationGuidelines(organizationGuidelinesTmp);
      }
    }, [dataOrganizationGuidelines]);

    const handleChangeCurrentPage = (value: number) => {
      if (value > 0 && value <= pagesNumber && value !== currentPage) {
        setCurrentPage(value);
      }
    };

    const handleFilter = (value: string) => {
      setSearch("%" + value + "%");
      setCurrentPage(1);
    };

    const handleClickOrganizationGuideline = (index: number) => {
      localStorage.setItem(
        "currentOrganizationGuideline",
        JSON.stringify(organizationGuidelines?.[index].customData)
      );
      organizationGuidelineCxt.setOrganizationGuidelineToEdit(
        organizationGuidelines?.[index].customData
      );
      history.push("/organization-guidelines/update");
    };

    const handleClickSort = (sortId: string) => {
      let direction;
      if (sortId === "name") {
        direction = orderBy.name === "asc" ? "desc" : "asc";
        setOrderBy({ name: direction });
      }
    };

    return (
      <PageWrapper>
        <Container>
          <CustomList
            title="Liste des consignes médicales"
            columns={columns}
            data={organizationGuidelines}
            pagesNumber={pagesNumber}
            currentPage={currentPage}
            handleChangeCurrentPage={handleChangeCurrentPage}
            displaySearchBar={true}
            searchBarValue={searchBarValue}
            setSearchBarValue={(v: string) => setSearchBarValue(v)}
            handleSearch={(v: string) => handleFilter(v)}
            handleClick={(i: number) => handleClickOrganizationGuideline(i)}
            handleClickSort={(sortId: string) => handleClickSort(sortId)}
          />
        </Container>
        <ModalContainer
          isOpen={currentGuideline.show}
          toggle={() =>
            setCurrentGuideline({ ...currentGuideline, show: false })
          }
          className="reactstrap-modal-full-size"
        >
          <ModalBody>
            <PdfViewer
              toggle={() =>
                setCurrentGuideline({ ...currentGuideline, show: false })
              }
              documentName={currentGuideline.documentName}
              fileCategory={ORGANIZATION_FILE_TYPE.GUIDELINE}
            />
          </ModalBody>
        </ModalContainer>
      </PageWrapper>
    );
  };

export default OrganizationGuidelinesList;

const Container = styled.div`
  height: 100%;
  ${CardStyle2}
  max-width: 1030px;
  min-width: 566px;
  margin: 0 auto;
`;

const ButtonLinkOpenDocument = styled.div`
  width: auto;
  span {
    ${LinearGradientText}
    margin-right: 10px;
  }
`;

const ModalContainer = styled(Modal)``;
