import { getFirebaseToken } from "./auth";

export const uploadPractitionerSignature = async (
  data: FormData
): Promise<{
  error?: string;
  fileName?: string;
}> => {
  try {
    const token = await getFirebaseToken();
    const res = await fetch(
      `${process.env.REACT_APP_AUTH_SERVER_URL}/upload-practitioner-signature`,
      // `http://localhost:3000/upload-practitioner-signature`,
      {
        headers: {
          "Authorization": `Bearer ${token}`,
        },
        method: "POST",
        body: data,
      }
    );
    const resJson = await res.json();
    if (resJson.error) {
      return { error: resJson.error };
    } else {
      return { fileName: resJson.fileName };
    }
  } catch (err) {
    console.log(err);
    return { error: "Une erreur est survenue" };
  }
};

export const downloadPractitionerSignature = async (): Promise<{
  error?: string;
  data?: string;
}> => {
  try {
    const token = await getFirebaseToken();
    const res = await fetch(
      `${process.env.REACT_APP_AUTH_SERVER_URL}/download-practitioner-signature`,
      // `http://localhost:3000/download-practitioner-signature`,
      {
        headers: {
          "Authorization": `Bearer ${token}`,
        },
        method: "POST",
      }
    );
    const resJson = await res.json();
    if (resJson.error) {
      return { error: resJson.error };
    } else {
      return { data: resJson.data };
    }
  } catch (err) {
    console.log(err);
    return { error: "Une erreur est survenue" };
  }
};

export const uploadPrescription = async (
  data: FormData
): Promise<{
  error?: string;
  fileName?: string;
}> => {
  try {
    const token = await getFirebaseToken();
    const res = await fetch(
      `${process.env.REACT_APP_AUTH_SERVER_URL}/upload-prescription`,
      // `http://localhost:3000/upload-prescription`,
      {
        headers: {
          "Authorization": `Bearer ${token}`,
        },
        method: "POST",
        body: data,
      }
    );
    const resJson = await res.json();
    if (resJson.error) {
      return { error: resJson.error };
    } else {
      return { fileName: resJson.fileName };
    }
  } catch (err) {
    console.log(err);
    return { error: "Une erreur est survenue" };
  }
};

export const sendPrescriptionEmail = async (
  data: FormData
): Promise<{
  error?: string;
}> => {
  try {
    const token = await getFirebaseToken();
    const res = await fetch(
      `${process.env.REACT_APP_AUTH_SERVER_URL}/send-prescription-email`,
      // `http://localhost:3000/send-prescription-email`,
      {
        headers: {
          "Authorization": `Bearer ${token}`,
        },
        method: "POST",
        body: data,
      }
    );
    const resJson = await res.json();
    if (resJson.error) {
      return { error: resJson.error };
    } else {
      return { error: undefined };
    }
  } catch (err) {
    console.log(err);
    return { error: "Une erreur est survenue" };
  }
};
