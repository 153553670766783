import { useMutation, useQuery } from "@apollo/client";
import React, { useState } from "react";
import styled from "styled-components";
import FormEstablishment from "../../components/FormTemplates/Establishment/FormEstablishment";
import { PageWrapper } from "../../components/Layout/PageWrapper/PageWrapper";
import { loader } from "graphql.macro";
import { displayToastNotification } from "../../utils/toastNotification";
import { useEffect } from "react";

const addOrganizationQuery = loader("../../graphql/addOrganization.graphql");
const addHospitalServiceQuery = loader(
  "../../graphql/addHospitalService.graphql"
);
const addLiberalPracticeQuery = loader(
  "../../graphql/addLiberalPractice.graphql"
);
const addOrganizationSurgeryTypeQuery = loader(
  "../../graphql/addOrganizationSurgeryType.graphql"
);

const getAllOrganizationsQuery = loader(
  "../../graphql/getAllOrganizationsWithLimitOffset.graphql"
);

const getSurgeriesTypeQuery = loader("../../graphql/getSurgeriesType.graphql");
const addAddressQuery = loader("../../graphql/addAddress.graphql");

interface Values {
  name: string;
  phone: string;
  access: string;
  am?: string;
  organization_type: string;
  hospital_id: string;
  surgeriesType?: Array<{ label: string; value: string; checked: boolean }>;
  surveysType?: Array<{ label: string; value: string; checked: boolean }>;
  addressNumber?: string;
  addressStreet?: string;
  addressCity?: string;
  addressPostalCode?: string;
}

interface EstablishmentsCreateProps {}

const EstablishmentsCreate: React.FC<EstablishmentsCreateProps> = () => {
  const [initialValues, setInitialValues] = useState({
    name: "",
    phone: "",
    access: "",
    organization_type: "",
    hospital_id: "",
    surgeriesType: undefined,
    surveysType: undefined,
    am: "",
    addressNumber: "",
    addressStreet: "",
    addressCity: "",
    addressPostalCode: "",
  });

  const [addOrganization] = useMutation(addOrganizationQuery);
  const [addHospitalService] = useMutation(addHospitalServiceQuery);
  const [addLiberalPractice] = useMutation(addLiberalPracticeQuery);
  const [addOrganizationSurgeryType] = useMutation(
    addOrganizationSurgeryTypeQuery
  );

  const [addAddress] = useMutation(addAddressQuery);

  const { data: dataSurgeriesType } = useQuery(getSurgeriesTypeQuery);

  useEffect(() => {
    if (dataSurgeriesType) {
      const surgeriesType = dataSurgeriesType.surgery_type.map((e: any) => {
        return { label: e.value, value: e.id, checked: false };
      });
      setInitialValues({ ...initialValues, surgeriesType });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataSurgeriesType]);

  const createOrganization = async (values: Values) => {
    if (
      values.organization_type === "hospital_service" &&
      (!values.hospital_id || values.hospital_id === "")
    ) {
      console.error("hospital_id is not set");
      displayToastNotification("error", "Organisation non reconnue");
      return;
    }
    const variables = Object.assign(
      {},
      {
        name: values.name,
        organization_type: values.organization_type,
      }
    );
    addOrganization({
      variables,
    })
      .then(async (...args) => {
        const [
          {
            data: {
              insert_organization: { returning: organization },
            },
          },
        ] = args;
        console.info("Organization created", organization);
        //Add organization surgeries type
        const organizationSurgeryType = values.surgeriesType
          ?.filter((e) => e.checked)
          .map((e) => {
            return {
              organization_id: organization[0].id,
              surgery_type_id: e.value,
            };
          });
        await addOrganizationSurgeryType({
          variables: { objects: organizationSurgeryType },
        });
        if (organization[0].organization_type === "hospital_service") {
          addHospitalService({
            variables: {
              id: organization[0].id,
              name: values.name,
              access: values.access,
              phone: values.phone,
              hospital_id: values.hospital_id,
            },
            update: (cache, { data: { insert_hospital_service } }) => {
              let data = cache.readQuery({ query: getAllOrganizationsQuery });
              if (data) {
                //@ts-ignore
                const dataTmp = { ...data };
                dataTmp.organization = [
                  ...dataTmp.organization,
                  {
                    ...organization[0],
                    hospital_service: {
                      ...insert_hospital_service,
                    },
                  },
                ];
                cache.writeQuery({
                  query: getAllOrganizationsQuery,
                  data: dataTmp,
                });
              }
            },
          }).then(() => {
            console.log("Hospital Service created");
            displayToastNotification(
              "success",
              "L'établissement est bien enregistré"
            );
          });
        } else if (organization[0].organization_type === "liberal_practice") {
          const address = await addAddress({
            variables: {
              number: values.addressNumber,
              street: values.addressStreet,
              city: values.addressCity,
              postalCode: values.addressPostalCode,
            },
          });
          addLiberalPractice({
            variables: {
              id: organization[0].id,
              name: values.name,
              access: values.access,
              phone: values.phone,
              am: values.am,
              addressId: address.data.insert_address.returning[0].id,
            },
            update: (cache, { data: { insert_liberal_practice } }) => {
              let data = cache.readQuery({ query: getAllOrganizationsQuery });
              if (data) {
                //@ts-ignore
                const dataTmp = { ...data };
                dataTmp.organization = [
                  ...dataTmp.organization,
                  {
                    ...organization[0],
                    liberal_practice: {
                      ...insert_liberal_practice,
                    },
                  },
                ];
                cache.writeQuery({
                  query: getAllOrganizationsQuery,
                  data: dataTmp,
                });
              }
            },
          }).then(() => {
            console.log("Liberal Practice created");
            displayToastNotification(
              "success",
              "L'établissement est bien enregistré"
            );
          });
        }
      })
      .catch((e) => {
        console.error("Invalid input:", values);
        console.error(e);
      });
  };

  const handleSubmit = async (values: Values) => {
    await createOrganization(values);
  };

  return (
    <PageWrapper>
      <Container>
        {initialValues.surgeriesType && (
          <FormEstablishment
            title="Créer un établissement"
            initialValues={initialValues}
            mode="creation"
            handleSubmit={(values) => handleSubmit(values)}
          />
        )}
      </Container>
    </PageWrapper>
  );
};

export default EstablishmentsCreate;

const Container = styled.div`
  height: 100%;
  margin: 0 auto;
  max-width: 1030px;
`;
