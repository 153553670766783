import React from "react";
import styled from "styled-components";
import PageWrapperNoAuth from "../../components/Layout/PageWrapperNoAuth/PageWrapperNoAuth";
import ResetPasswordForm from "../../components/FormTemplates/ResetPasswordForm/ResetPasswordForm";
import { CardStyle1 } from "../../theme/components/ThemeComponents";

const ResetPassword: React.FunctionComponent<{}> = () => {
  return (
    <PageWrapperNoAuth title="Réinitialiser le mot de passe">
      <LoginFormContainer>
        <ResetPasswordForm />
      </LoginFormContainer>
    </PageWrapperNoAuth>
  );
};

const LoginFormContainer = styled.div`
  ${CardStyle1}
  width: 724px;
  height: 393px;
  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.laptop13Max}) {
    width: 540px;
    height: 323px;
  }

  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.mobilePortraitMax}) {
    width: 100%;
    height: auto;
  }
`;

export default ResetPassword;
