import React, { useEffect } from "react";
import styled from "styled-components";
import PageWrapperNoAuth from "../../components/Layout/PageWrapperNoAuth/PageWrapperNoAuth";
import { CardStyle1 } from "../../theme/components/ThemeComponents";
import { Heading3, Heading4 } from "../../theme/fonts";
import { ReactComponent as RedirectionImage } from "../../theme/images/redirection.svg";

const Unauthorized = () => {
  useEffect(() => {
    setTimeout(() => {
      window.location.href = `${process.env.REACT_APP_PATIENT_APP_URL}`;
    }, 4000);
  }, []);

  return (
    <PageWrapperNoAuth title="Redirection" displayLogo={true}>
      <Container>
        <TitleContainer>
          <Title>Vous n'avez pas les autorisations pour vous connecter.</Title>
          <Title>Vous allez être redirigé vers la bonne page.</Title>
        </TitleContainer>
        <RedirectionImageContainer>
          <RedirectionImage style={{ width: "100%", height: "100%" }} />
        </RedirectionImageContainer>
      </Container>
    </PageWrapperNoAuth>
  );
};

const Container = styled.div`
  ${CardStyle1}
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 724px;
  padding: 20px;

  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.laptop13Max}) {
    width: 540px;
  }

  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.mobilePortraitMax}) {
    width: 100%;
    height: auto;
  }
`;

const TitleContainer = styled.div`
  margin-top: 20px;
`;

const Title = styled.div`
  margin-bottom: 20px;
  text-align: center;
  ${Heading3}

  @media screen and (max-width: ${(props) =>
    props.theme.breakpoints.laptop13Max}) {
    ${Heading4}
  }

  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.mobilePortraitMax}) {
    ${Heading3}
  }
`;

const RedirectionImageContainer = styled.div`
  width: 300px;
  height: 300px;
  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.mobileLandscapeMax}) {
    display: none;
  }
`;

export default Unauthorized;
