import { useLazyQuery, useQuery } from "@apollo/client";
import { Field, Form, Formik } from "formik";
import { loader } from "graphql.macro";
import React, { useEffect, useState } from "react";
import Select from "react-select";
import styled from "styled-components";
import * as Yup from "yup";
import { Heading4 } from "../../../theme/fonts";
import CustomButton from "../../CustomButton/CustomButton";
import { DropdownForm } from "../../Form/DropdownForm/DropdownForm";
import TitleSectionForm from "../../Form/TitleSectionForm/TitleSectionForm";
import CustomContour from "../../Layout/CustomContour/CustomContour";

const getSurgeriesTypeQuery = loader(
  "../../../graphql/getSurgeriesType.graphql"
);

const getSurveysTypeQuery = loader("../../../graphql/getSurveysType.graphql");

const getSurveysTypeBySurgeryTypeQuery = loader(
  "../../../graphql/getSurveysTypeBySurgeryType.graphql"
);

interface FormValues {
  surgeryType: string;
}

interface FormAdminProps {
  title: string;
  handleSubmit: (
    values: FormValues,
    surveysType: { label: string; value: string; selected: boolean }[]
  ) => void;
  initialValues: FormValues;
}

const validationSchema = () =>
  Yup.object().shape({
    surgeryType: Yup.string().required("Veuillez choisir un type d'opération"),
  });

const FormAdmin: React.FunctionComponent<FormAdminProps> = ({
  title,
  handleSubmit,
  initialValues,
}) => {
  const { data: dataSurgeriesType } = useQuery(getSurgeriesTypeQuery, {
    fetchPolicy: "network-only",
  });
  const { data: dataSurveysType } = useQuery(getSurveysTypeQuery, {
    fetchPolicy: "network-only",
  });
  const [
    getSurveyTypeSurgeryType,
    { data: dataSurveyTypeSurgeryType, loading },
  ] = useLazyQuery(getSurveysTypeBySurgeryTypeQuery, {
    fetchPolicy: "network-only",
  });

  const [surgeriesType, setSurgeriesType] = useState<Array<{
    label: string;
    value: string;
  }> | null>(null);

  const [surveysType, setSurveysType] = useState<Array<{
    label: string;
    value: string;
    selected: boolean;
  }> | null>(null);

  useEffect(() => {
    if (dataSurgeriesType) {
      const surgeriesTypeTmp = dataSurgeriesType.surgery_type.map(
        (e: { id: string; value: string }) => {
          return { label: e.value, value: e.id };
        }
      );
      setSurgeriesType(surgeriesTypeTmp);
    }
  }, [dataSurgeriesType]);

  useEffect(() => {
    if (dataSurveysType) {
      const surveysTypeTmp = dataSurveysType.survey_type.map(
        (e: { id: string; label: string }) => {
          return { label: e.label, value: e.id, selected: false };
        }
      );
      setSurveysType([...surveysTypeTmp]);
    }
  }, [dataSurveysType]);

  useEffect(() => {
    if (!loading && dataSurveyTypeSurgeryType?.survey_type_surgery_type) {
      const surveysTypeTmp = surveysType?.map((e) => {
        const s = dataSurveyTypeSurgeryType?.survey_type_surgery_type?.filter(
          (f: any) => f.survey_type.id === e.value
        );
        if (s?.length > 0) {
          return { ...e, selected: true };
        } else {
          return { ...e, selected: false };
        }
      });
      setSurveysType(surveysTypeTmp || []);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  const handleChangeSurgeryType = (surgeryTypeId: string) => {
    if (surgeriesType) {
      getSurveyTypeSurgeryType({ variables: { surgeryTypeId } });
    }
  };

  const handleSelectSurgeryType = (value: any) => {
    const surveysTypeTmp = surveysType!.map((e) => {
      const s = value.filter((f: any) => f.value === e.value);
      if (s.length > 0) {
        return { ...e, selected: true };
      } else {
        return { ...e, selected: false };
      }
    });
    setSurveysType(surveysTypeTmp);
  };

  return (
    <Formik
      initialValues={{ ...initialValues }}
      onSubmit={async (values: FormValues) => {
        await handleSubmit(values, surveysType!);
      }}
      validationSchema={() => validationSchema()}
      validateOnChange={false}
    >
      {(props) => {
        return (
          <FormContainer>
            <CustomContour title={title}>
              <Section>
                <TitleSectionForm
                  title="Types d'opérations <-> Questionnaires"
                  subtitle={
                    "Permet de binder un type d'opération à des questionnaires (Surveys)"
                  }
                />
                <FieldSection>
                  {surgeriesType && (
                    <FieldContainer>
                      <Field
                        name={"surgeryType"}
                        label={"Opération"}
                        placeholder={"Choisir un type d'opération"}
                        component={DropdownForm}
                        data={surgeriesType}
                        onChange={(surgeryTypeId: string) =>
                          handleChangeSurgeryType(surgeryTypeId)
                        }
                      />
                    </FieldContainer>
                  )}
                  {props.values.surgeryType && surveysType && (
                    <FieldContainer>
                      <TagsContainer>
                        <TagsTitle>Questionnaires</TagsTitle>
                        <Select
                          closeMenuOnSelect={false}
                          isMulti
                          options={surveysType!}
                          isClearable={false}
                          value={surveysType.filter((e) => e.selected)}
                          onChange={(value: any) =>
                            handleSelectSurgeryType(value)
                          }
                        />
                      </TagsContainer>
                    </FieldContainer>
                  )}
                </FieldSection>
              </Section>
            </CustomContour>
            <ButtonContainer>
              <CustomButton
                type="submit"
                content="Sauvegarder les modifications"
                disabled={props.isSubmitting}
              />
            </ButtonContainer>
          </FormContainer>
        );
      }}
    </Formik>
  );
};

export default FormAdmin;

const FormContainer = styled(Form)``;

const Section = styled.div`
  width: 100%;
`;

const FieldSection = styled.div`
  width: 100%;
  display: flex;
  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.tabletMax}) {
    flex-direction: column;
  }
`;

const FieldContainer = styled.div<{ fullWidth?: boolean }>`
  width: ${({ fullWidth }) => (fullWidth ? "100%" : `calc(100% / 2)`)};
  margin: 0 8px;

  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.tabletMax}) {
    width: 100%;
    margin: 8px 0;
  }
`;

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 30px;
`;

const TagsContainer = styled.div`
  width: 100%;
`;

const TagsTitle = styled.div`
  margin-bottom: 12px;
  color: ${({ theme }) => theme.colors.darkMain};
  ${Heading4};
`;
