import { ApolloClient, createHttpLink, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { getFirebaseToken, logout, checkIfCookiesAreSet } from "../utils/auth";

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_GRAPHQL_URL,
});

const authLink = setContext(async (_, { headers }) => {
  // get the authentication token from local storage if it exists
  // const token = localStorage.getItem("token");

  // On every query we check if koalou cookies are set. If not we delog the user
  if (!checkIfCookiesAreSet()) {
    logout();
  }
  let token = await getFirebaseToken();
  // console.log('APOLLO TOKEN: ', token);
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});

export const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});
