import React, { useContext } from "react";
import styled from "styled-components";
import { PageWrapper } from "../../components/Layout/PageWrapper/PageWrapper";
import FormOrganizationDocument from "../../components/FormTemplates/OrganizationDocument/FormOrganizationDocument";
import ApplicationContext from "../../utils/context";
import { uploadOrganizationFile } from "../../utils/gcp-file";
import { ORGANIZATION_FILE_TYPE } from "../../constants/db_types";
import { useMutation } from "@apollo/client";
import { loader } from "graphql.macro";
import * as Sentry from "@sentry/react";
import { displayToastNotification } from "../../utils/toastNotification";

const addOrganizationDocumentQuery = loader(
  "../../graphql/addOrganizationDocument.graphql"
);

interface Values {
  id?: string;
  name: string;
  active: boolean;
  fileToUpload: File | null;
  fileToUploadIsSet: boolean;
  currentFileName: string | null;
}

interface OrganizationDocumentsCreateProps {}

const OrganizationDocumentsCreate: React.FC<OrganizationDocumentsCreateProps> =
  () => {
    const { currentOrganizationCxt } = useContext<any>(ApplicationContext);

    const [addOrganizationDocument] = useMutation(addOrganizationDocumentQuery);

    const addOrganizationDocumentAsync = async (
      organizationDocumentVariables: any
    ) => {
      return await addOrganizationDocument({
        variables: organizationDocumentVariables,
      }).then((...response) => {
        const [
          {
            data: {
              insert_organization_document: {
                returning: organization_document,
              },
            },
          },
        ] = response;
        console.info("Organization document created", organization_document[0]);
        return organization_document[0];
      });
    };

    const initialValues: Values = {
      name: "",
      fileToUpload: null,
      fileToUploadIsSet: false,
      active: false,
      currentFileName: null,
    };

    const createOrganizationDocument = async (values: Values) => {
      const organizationId =
        currentOrganizationCxt.currentOrganizationToEdit.id;
      if (!organizationId) {
        console.error("organizatonId is not set");
        displayToastNotification("error", "Organisation non reconnue");
        return;
      }
      try {
        const { fileName, error } = await uploadOrganizationFile(
          values.fileToUpload!,
          currentOrganizationCxt.currentOrganizationToEdit?.id,
          ORGANIZATION_FILE_TYPE.DOCUMENT
        );
        if (error) {
          console.error(error);
          return;
        } else {
          const organizationDocumentVariables = {
            name: values.name,
            organizationId,
            documentName: fileName,
            active: values.active,
          };
          try {
            await addOrganizationDocumentAsync(organizationDocumentVariables);
            // Display a toast notification if operation succeded
            displayToastNotification(
              "success",
              "Le nouveau document est bien enregistré"
            );
          } catch (err) {
            console.error("Invalid input:", organizationDocumentVariables);
            console.error(err);
            Sentry.captureException(err);
          }
        }
      } catch (err) {
        console.error(err);
      }
    };

    const handleSubmit = async (values: Values) => {
      await createOrganizationDocument(values);
    };

    return (
      <PageWrapper>
        <Container>
          <FormOrganizationDocument
            title="Créer un document"
            initialValues={initialValues}
            mode="creation"
            handleSubmit={(values) => handleSubmit(values)}
          />
        </Container>
      </PageWrapper>
    );
  };

export default OrganizationDocumentsCreate;

const Container = styled.div`
  height: 100%;
  margin: 0 auto;
  max-width: 1030px;
`;
