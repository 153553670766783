export const base64ToBlob = (
  base64: string,
  toType: "pdf"
): Blob | undefined => {
  let type = null;
  switch (toType) {
    case "pdf":
      type = "application/pdf";
      break;
    default:
      break;
  }

  if (toType === "pdf") {
    type = "application/pdf";
    if (!base64.startsWith("data:application/pdf;base64,")) {
      base64 = "data:application/pdf;base64," + base64;
    }
  }

  if (!type) {
    console.error("Type not found");
    return undefined;
  }
  const blobBin = atob(base64.split(",")[1]);
  const array = [];
  for (var i = 0; i < blobBin.length; i++) {
    array.push(blobBin.charCodeAt(i));
  }
  const file = new Blob([new Uint8Array(array)], {
    type,
  });
  return file;
};
