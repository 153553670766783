const def = (kg, ageMonth, type) => {
  let amount_scale = 0;
  let comment = "";
  if (kg < 1) {
    comment = "Pas d'AMM en dessous de 1kg";
  } else if (kg < 5) {
    amount_scale = 1.5 * kg;
  } else if (kg <= 20) {
    amount_scale = 2 * kg;
  } else {
    amount_scale = 1 * kg;
  }
  return {
    amount_scale,
    unit: "mg",
    comment,
  };
};

export default def;
