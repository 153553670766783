import { useLazyQuery } from "@apollo/client";
import { loader } from "graphql.macro";
import cookie from "js-cookie";
import moment from "moment";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { Modal, ModalBody } from "reactstrap";
import styled from "styled-components";
import { PageWrapper } from "../../components/Layout/PageWrapper/PageWrapper";
import CustomList from "../../components/List/CustomList/CustomList";
import PdfViewer from "../../components/PdfViewer";
import { COOKIES } from "../../constants/cookies_types";
import { PRESCRIPTION_FILE_TYPE } from "../../constants/db_types";
import { CardStyle2 } from "../../theme/components/ThemeComponents";
import ApplicationContext from "../../utils/context";
import { displayToastNotification } from "../../utils/toastNotification";
import * as Sentry from "@sentry/react";
import { base64ToBlob } from "../../utils/global";
import { sendPrescriptionEmail } from "../../utils/prescription";

const getPrescriptionsCountQuery = loader(
  "../../graphql/getPrescriptionsCount.graphql"
);

const getPrescriptionsWithLimitOffsetQuery = loader(
  "../../graphql/getPrescriptionsWithLimitOffset.graphql"
);

interface PrescriptionListProps {}

const columns = [
  { value: "Nom", ratio: "15%", type: "string" },
  { value: "Prénom", ratio: "15%", type: "string" },
  { value: "Intervention", ratio: "20%", type: "string" },
  { value: "Date de l'intervention", ratio: "25%", type: "string" },
  { value: "Date de la prescription", ratio: "25%", type: "string" },
];

const limit = 10;

const PrescriptionList: React.FC<PrescriptionListProps> = () => {
  const { currentOrganizationCxt } = useContext<any>(ApplicationContext);

  const [pagesNumber, setPagesNumber] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("%%");
  const [searchBarValue, setSearchBarValue] = useState("");
  const [documentBase64, setDocumentBase64] = useState("");
  const [prescriptions, setPrescriptions] = useState<Array<{
    customData: {
      id: string;
      filePath: string;
      patientId: string;
    };
    rowValues: Array<string>;
  }> | null>(null);

  const [modalDocument, setModalDocument] = useState({
    show: false,
    documentName: "",
    patientId: "",
  });

  const [getPrescriptionsCount, { data: dataPrescriptionsCount }] =
    useLazyQuery(getPrescriptionsCountQuery, {
      fetchPolicy: "network-only",
    });

  const [getPrescriptionsWithLimitOffset, { data: dataPrescriptions }] =
    useLazyQuery(getPrescriptionsWithLimitOffsetQuery, {
      fetchPolicy: "network-only",
    });

  const fetchPrescritpions = useCallback(() => {
    if (currentOrganizationCxt?.currentOrganizationToEdit.id) {
      getPrescriptionsWithLimitOffset({
        variables: {
          limit,
          offset: currentPage * limit - limit,
          search,
          practitionerId: cookie.get(COOKIES.ID),
          organizationId: currentOrganizationCxt?.currentOrganizationToEdit.id,
        },
      });
    }
  }, [
    currentPage,
    getPrescriptionsWithLimitOffset,
    search,
    currentOrganizationCxt?.currentOrganizationToEdit,
  ]);

  const fetchPrescrptionsCount = useCallback(() => {
    if (currentOrganizationCxt?.currentOrganizationToEdit?.id) {
      getPrescriptionsCount({
        variables: {
          practitionerId: cookie.get(COOKIES.ID),
          organizationId: currentOrganizationCxt?.currentOrganizationToEdit.id,
          search,
        },
      });
    }
  }, [
    currentOrganizationCxt?.currentOrganizationToEdit,
    getPrescriptionsCount,
    search,
  ]);

  useEffect(() => {
    fetchPrescrptionsCount();
  }, [fetchPrescrptionsCount]);

  /* Calculate number of pages */
  useEffect(() => {
    if (dataPrescriptionsCount) {
      const prescriptionsCount = dataPrescriptionsCount.prescription.length;
      let pagesNumberTmp = Math.ceil(prescriptionsCount / limit);
      setPagesNumber(pagesNumberTmp);
    }
  }, [dataPrescriptionsCount]);

  useEffect(() => {
    fetchPrescritpions();
  }, [fetchPrescritpions]);

  useEffect(() => {
    if (dataPrescriptions) {
      const prescriptionsTmp = dataPrescriptions.prescription.map((e: any) => {
        return {
          customData: {
            id: e.id,
            filePath: e.file_path,
            patientId: e.surgery.patient.id,
          },
          rowValues: [
            e.surgery.patient.surname,
            e.surgery.patient.name,
            e.surgery.surgery_type.value,
            moment(e.surgery.date).format("DD/MM/YYYY"),
            moment(e.created_at).format("DD/MM/YYYY"),
          ],
        };
      });
      setPrescriptions(prescriptionsTmp);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataPrescriptions]);

  const handleChangeCurrentPage = (value: number) => {
    if (value > 0 && value <= pagesNumber && value !== currentPage) {
      setCurrentPage(value);
    }
  };

  const handleFilter = (value: string) => {
    setSearch("%" + value + "%");
    setCurrentPage(1);
  };

  const showDocumentPDF = (i: number) => {
    setModalDocument({
      show: true,
      documentName: prescriptions![i].customData.filePath,
      patientId: prescriptions![i].customData.patientId,
    });
  };

  const sendPrescription = async () => {
    if (documentBase64) {
      const file = base64ToBlob(documentBase64, "pdf");
      if (!file) {
        displayToastNotification("error", "Une erreur est survenue");
        return;
      }
      const data = new FormData();
      data.append("file", file);
      data.append("patientId", modalDocument.patientId);
      const { error } = await sendPrescriptionEmail(data);
      if (error) {
        Sentry.captureException(error);
        displayToastNotification("error", "Une erreur est survenue");
        return;
      }
      displayToastNotification("success", "La prescription a bien été envoyée");
    }
  };

  return (
    <PageWrapper>
      <Container>
        <ListContainer>
          <CustomList
            title={"Liste des prescriptions"}
            columns={columns}
            data={prescriptions}
            pagesNumber={pagesNumber}
            currentPage={currentPage}
            handleChangeCurrentPage={handleChangeCurrentPage}
            displaySearchBar={true}
            searchBarValue={searchBarValue}
            setSearchBarValue={(v: string) => setSearchBarValue(v)}
            handleSearch={(v: string) => handleFilter(v)}
            handleClick={(i: number) => showDocumentPDF(i)}
          />
        </ListContainer>

        <ModalContainer
          isOpen={modalDocument.show}
          className="reactstrap-modal-full-size"
        >
          <ModalBody>
            <PdfViewer
              toggle={() =>
                setModalDocument({
                  show: false,
                  documentName: "",
                  patientId: "",
                })
              }
              documentName={modalDocument.documentName}
              fileCategory={PRESCRIPTION_FILE_TYPE.PRESCRIPTION}
              onLoadDocumentBase64={(data) => setDocumentBase64(data)}
              secondCustomButtonText={"Envoyer"}
              handleClickSecondCustomButton={() => {
                sendPrescription();
              }}
            />
          </ModalBody>
        </ModalContainer>
      </Container>
    </PageWrapper>
  );
};

export default PrescriptionList;

const Container = styled.div`
  height: 100%;
  max-width: 1030px;
  min-width: 566px;
  margin: 0 auto;
`;

const ListContainer = styled.div`
  ${CardStyle2}
`;

const ModalContainer = styled(Modal)``;
