import React, { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { Heading4, Heading5 } from "../../theme/fonts";
import { ReactComponent as IconBell } from "../../theme/icons/bell.svg";
import ApplicationContext from "../../utils/context";

type NotificationType = {
  notificationUserId: string;
  patientSurname?: string;
  patientName?: string;
  value: string;
  read: boolean;
};
interface NotificationProps {
  data?: Array<NotificationType>;
  handleClickNotification?: (notificationUserId: string) => void;
  handleClickMakedAllAsRead?: (notificationUserIds: Array<string>) => void;
}

const Notification: React.FC<NotificationProps> = ({
  data,
  handleClickNotification,
  handleClickMakedAllAsRead,
}) => {
  const history = useHistory();

  const { currentOrganizationCxt } = useContext<any>(ApplicationContext);

  const [showNotifications, setShowNotifications] = useState(false);
  const [dataNotification, setDataNotification] = useState<
    Array<NotificationType> | []
  >([]);

  useEffect(() => {
    if (data) {
      setDataNotification(data);
    }
  }, [data]);

  useEffect(() => {
    if (dataNotification) {
      setUnreadNotificationNbr(countUnreadNotification(dataNotification));
    }
  }, [dataNotification]);

  /* When the organization changes, we close the notification container */
  useEffect(() => {
    if (currentOrganizationCxt.currentOrganizationToEdit.id) {
      setShowNotifications(false);
    }
  }, [currentOrganizationCxt.currentOrganizationToEdit.id]);

  const [unreadNotificationNbr, setUnreadNotificationNbr] = useState(0);

  const handleItemClickNotification = async (
    index: number,
    notificationUserId: string
  ) => {
    if (dataNotification?.[index].read === false) {
      const dataNotificationTmp = [...dataNotification];
      dataNotificationTmp[index].read = true;
      setDataNotification(dataNotificationTmp);
      if (handleClickNotification) {
        await handleClickNotification(notificationUserId);
        history.push("/patients/profile");
      }
    }
  };

  const handleMarkedAllAsRead = () => {
    if (dataNotification.filter((e) => !e.read).length > 0) {
      const dataNotificationTmp = [...dataNotification].map((e) => {
        return { ...e, read: true };
      });
      setDataNotification([...dataNotificationTmp]);
      if (handleClickMakedAllAsRead) {
        const notificationUserId = dataNotificationTmp.map(
          (e) => e.notificationUserId
        );
        handleClickMakedAllAsRead(notificationUserId);
      }
    }
  };

  const renderNotificationContainer = () => {
    if (dataNotification?.length > 0) {
      return (
        <NotificationContainer>
          <NotificatonReadAllContainer
            onClick={() => {
              handleMarkedAllAsRead();
            }}
          >
            Tout marquer comme lu
          </NotificatonReadAllContainer>
          <NotificationCoreContainer>
            {dataNotification.map((e: any, i: number) => {
              return (
                <div key={i}>
                  <NotificationItem
                    onClick={() =>
                      handleItemClickNotification(i, e.notificationUserId)
                    }
                  >
                    <NotificationUnreadDot read={e.read} />
                    <NotificationText>
                      {e.patientName} {e.patientSurname} {e.value}
                    </NotificationText>
                  </NotificationItem>
                  {i !== dataNotification.length - 1 && (
                    <NotificationSeparator />
                  )}
                </div>
              );
            })}
          </NotificationCoreContainer>
        </NotificationContainer>
      );
    }
  };

  const countUnreadNotification = (arr: Array<NotificationType>) => {
    return arr?.filter((e) => !e.read).length;
  };

  return (
    <Container>
      <IconBellContainer
        onClick={() =>
          (unreadNotificationNbr > 0 || dataNotification?.length > 0) &&
          setShowNotifications(!showNotifications)
        }
      >
        <IconBell />
        {unreadNotificationNbr > 0 && (
          <NotificationBadge>
            {unreadNotificationNbr > 99 ? "+99" : unreadNotificationNbr}
          </NotificationBadge>
        )}
      </IconBellContainer>

      {showNotifications && renderNotificationContainer()}
    </Container>
  );
};

export default Notification;

const Container = styled.div`
  position: relative;
  width: 100%;
  user-select: none;
`;

const IconBellContainer = styled.div`
  cursor: pointer;
`;

const NotificationBadge = styled.div`
  min-width: 20px;
  height: 20px;
  border-radius: 50%;
  position: absolute;
  top: -3px;
  right: -10px;
  background-color: ${({ theme }) => theme.colors.redMain};
  ${Heading5}
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.colors._white};
`;

const NotificationContainer = styled.div`
  position: absolute;
  right: 0;
  top: 38px; /* Height of the bell icon */
  width: 287px;
  background: linear-gradient(135.02deg, #095c66 0%, #209aa2 100%);
  box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 12px;
  z-index: 10;

  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.mobilePortraitMax}) {
    width: 240px;
  }
`;

const NotificationCoreContainer = styled.div`
  max-height: 300px;
  overflow-y: auto;
  padding: 0 12px;
`;

const NotificationItem = styled.div`
  width: calc(100%);
  padding: 12px 0;
  display: flex;
  cursor: pointer;
`;

const NotificationUnreadDot = styled.div<{ read: boolean }>`
  width: 9px;
  height: 9px;
  margin-top: 3px;
  border-radius: 50px;
  background-color: ${({ theme, read }) => (read ? "" : theme.colors.redL2)};
`;

const NotificationText = styled.div`
  width: calc(100% - 9px);
  margin-left: 7px;
  ${Heading4}
  color: ${({ theme }) => theme.colors._white};
`;

const NotificationSeparator = styled.div`
  border: 1px solid #ffffff;
`;

const NotificatonReadAllContainer = styled.div`
  border: 2px solid ${(props) => props.theme.colors._white};
  color: ${(props) => props.theme.colors._white};
  border-radius: 6px;
  padding: 3px 9px;
  text-align: center;
  margin: 10px;
  cursor: pointer;
  position: relative;
  ${Heading4}
`;
