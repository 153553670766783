import mixpanel from "mixpanel-browser";
import { isProductionEnv } from "../utils/env";

if (isProductionEnv()) {
  mixpanel.init(process.env.REACT_APP_MIXPANEL_KOALOU_PRO_TOKEN);
}

export const login = (id, email, role, allowedRoles) => {
  mixpanel.identify(id);
  mixpanel.people.set({
    "$email": email,
    "Role": role,
    "Allowed Roles": allowedRoles,
    "Last Connection": new Date().toISOString(),
  });
  mixpanel.people.increment("Number of connections");
  mixpanel.people.set_once({
    "First Connection": new Date().toISOString(),
  });
  mixpanel.track("Login");
};

export const logout = () => {
  mixpanel.track("Logout");
  mixpanel.reset();
};

export const createPatient = (
  practitionerId,
  practitionerName,
  practitionerSurname,
  organizationId,
  organizationName,
  surgeryTypeId,
  surgeryTypeName
) => {
  mixpanel.track("Create patient", {
    "Practitioner id": practitionerId,
    "Practitioner name": practitionerName,
    "Practitioner surname": practitionerSurname,
    "Organization id": organizationId,
    "Organization name": organizationName,
    "Surgery type id": surgeryTypeId,
    "Surgery type name": surgeryTypeName,
  });
};

export const viewPatientProfile = (
  practitionerId,
  practitionerName,
  practitionerSurname,
  organizationId,
  organizationName,
  surgeryTypeId,
  surgeryTypeName
) => {
  mixpanel.track("View patient profile", {
    "Practitioner id": practitionerId,
    "Practitioner name": practitionerName,
    "Practitioner surname": practitionerSurname,
    "Organization id": organizationId,
    "Organization name": organizationName,
    "Surgery type id": surgeryTypeId,
    "Surgery type name": surgeryTypeName,
  });
};

export const openFeedbackSurvey = (
  practitionerId,
  practitionerName,
  practitionerSurname
) => {
  mixpanel.track("Open feedback survey", {
    "Practitioner id": practitionerId,
    "Practitioner name": practitionerName,
    "Practitioner surname": practitionerSurname,
  });
};

export let Mixpanel = isProductionEnv()
  ? { login, logout, createPatient, viewPatientProfile, openFeedbackSurvey }
  : null;
