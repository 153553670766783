import React from "react";
import styled, { css } from "styled-components";
import { Heading1, Heading2 } from "../../../theme/fonts";
import { ReactComponent as KoalouLogo } from "../../../theme/images/koalouLogoColLight.svg";
import { ReactComponent as IconFacebook } from "../../../theme/icons/facebook.svg";
import { ReactComponent as IconInstagram } from "../../../theme/icons/instagram.svg";
import { ReactComponent as IconTwitter } from "../../../theme/icons/twitter.svg";

interface PageWrapperNoAuthProps {
  title?: string;
  displayLogo?: boolean;
}

const PageWrapperNoAuth: React.FC<PageWrapperNoAuthProps> = ({
  children,
  title,
  displayLogo,
}) => {
  const openFacebook = () => {
    window.open("https://www.facebook.com/KoalouApp", "_blank");
  };
  const openInstagram = () => {
    window.open("https://www.instagram.com/KoalouApp", "_blank");
  };

  const openTwitter = () => {
    window.open("https://twitter.com/KoalouApp", "_blank");
  };

  return (
    <Page>
      {displayLogo && (
        <LogoContainer>
          <KoalouLogo />
        </LogoContainer>
      )}
      <SocialNetworkContainer>
        <IconFacebookContainer onClick={() => openFacebook()} />
        <IconInstagramContainer onClick={() => openInstagram()} />
        <IconTwitterContainer onClick={() => openTwitter()} />
      </SocialNetworkContainer>
      <Container>
        <Title>{title}</Title>
        {children}
      </Container>
    </Page>
  );
};

const Page = styled.div`
  min-height: 100%;
  width: 100%;
  background: linear-gradient(135.02deg, #095c66 0%, #209aa2 100%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;

  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.mobileLandscapeMax}) {
    padding: 10px 20px;
  }

  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.mobilePortraitMax}) {
    display: block;
    padding-top: 60px;
  }
`;

const LogoContainer = styled.div`
  position: absolute;
  top: 30px;
  left: 30px;
  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.mobileLandscapeMax}) {
    display: none;
  }
`;

const SocialNetworkContainer = styled.div`
  position: absolute;
  top: 30px;
  right: 30px;
  & > svg {
    margin: 0 5px;
    cursor: pointer;
  }

  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.mobilePortraitMax}) {
    top: 15px;
  }
`;

const iconDimension = css`
  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.laptop13Max}) {
    width: 20px;
    height: 20px;
  }

  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.mobilePortraitMax}) {
    width: 16px;
    height: 16px;
  }
`;

const IconFacebookContainer = styled(IconFacebook)`
  ${iconDimension}
`;

const IconInstagramContainer = styled(IconInstagram)`
  ${iconDimension}
`;

const IconTwitterContainer = styled(IconTwitter)`
  ${iconDimension}
`;

const Container = styled.div``;

const Title = styled.div`
  text-align: center;
  margin-bottom: 36px;
  color: ${(props) => props.theme.colors._white};
  ${Heading1}

  @media screen and (max-width: ${(props) =>
    props.theme.breakpoints.laptop13Max}) {
    ${Heading2}
  }
`;

export default PageWrapperNoAuth;
