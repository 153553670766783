const def = (kg, ageMonth, type) => {
  return [
    {
      name: "Dose initiale",
      amount_scale: [kg * 0.025, kg * 0.1],
      unit: "Toutes les 5 à 10 minutes",
      comment: "",
    },
    {
      name: "Bolus",
      amount_scale: kg * 0.025,
      unit: "mg",
      comment: "",
    },
    {
      name: "Perfusion intra-veineuse",
      amount_scale: [kg * 0.01, kg * 0.02],
      unit: "mg/h",
      comment: "",
    },
    {
      name: "Analgésie auto-contrôlé: bolus",
      amount_scale: ageMonth >= 72 ? [kg * 0.015, kg * 0.02] : 0,
      unit: "mg",
      comment: ageMonth >= 72 ? "" : "Pas d'AMM en dessous de 6 ans",
    },
    {
      name: "Analgésie auto-contrôlé: période réfractaire",
      amount_scale: "",
      unit: "",
      comment:
        ageMonth >= 72 ? "10 à 15 minutes" : "Pas d'AMM en dessous de 6 ans",
    },
    {
      name: "Analgésie auto-contrôlé: dose continue",
      amount_scale: ageMonth >= 72 ? [kg * 0.005, kg * 0.02] : 0,
      unit: "mg/h",
      comment: ageMonth >= 72 ? "" : "Pas d'AMM en dessous de 6 ans",
    },
  ];
};

export default def;
