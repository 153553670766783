import { isValidPhoneNumber } from "react-phone-number-input";

export function validatePhoneNumber(value) {
  let error = "";

  if (value && !isValidPhoneNumber(value))
    error = "Le numéro de téléphone n'est pas valide";

  return error;
}
