import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import "moment/locale/fr";
import Modal from "react-modal";
import { isProductionEnv } from "./utils/env";
import "./i18n";

Sentry.init({
  enabled: process.env.REACT_APP_SENTRY_ENV !== "local",
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [new Integrations.BrowserTracing()],
  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: isProductionEnv() ? 0.2 : 1.0,
  environment: process.env.REACT_APP_SENTRY_ENV,
  release: "koalou-pro@" + process.env.REACT_APP_SENTRY_RELEASE,
});

Modal.setAppElement("#root");

ReactDOM.render(<App />, document.getElementById("root"));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
