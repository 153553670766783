import React from "react";
import styled from "styled-components";

interface BurgerProps {
  color?: string;
}

const Burger: React.FC<BurgerProps> = ({ color }) => {
  return (
    <StyledBurger color={color}>
      <div />
      <div />
      <div />
    </StyledBurger>
  );
};
const StyledBurger = styled.button<{ color?: string }>`
  position: absolute;
  top: 5%;
  left: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 2rem;
  height: 2rem;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 10;

  &:focus {
    outline: none;
  }

  div {
    width: 2rem;
    height: 0.25rem;
    background: ${({ color }) =>
      color ? color : "linear-gradient(135.02deg, #095C66 0%, #209AA2 100%)"};
    border-radius: 10px;
    transition: all 0.3s linear;
    position: relative;
    transform-origin: 1px;
  }
`;

export default Burger;
