import React from "react";
import styled from "styled-components";
import { Heading2, Heading3 } from "../../../theme/fonts";
import { ReactComponent as IconArrowBack } from "../../../theme/icons/arrowBack.svg";
import Notification from "../../Notification/Notification";

type NotificationType = {
  notificationUserId: string;
  patientSurname?: string;
  patientName?: string;
  value: string;
  read: boolean;
};

interface PageWrapperProps {
  previousPageIconOn?: boolean;
  previousPageText?: string;
  handleOnClickPreviousPage?: () => void;
  notificationIcon?: boolean;
  notificationData?: Array<NotificationType>;
  handleClickNotification?: (notificationUserId: string) => void;
  handleClickMakedAllAsRead?: (notificationUserIds: Array<string>) => void;

}

export const PageWrapper: React.FC<PageWrapperProps> = ({
  children,
  previousPageIconOn,
  previousPageText,
  handleOnClickPreviousPage,
  notificationIcon,
  notificationData,
  handleClickNotification,
  handleClickMakedAllAsRead
}) => {
  return (
    <Page>
      <Container>
        <Subcontainer>
          {(previousPageIconOn || notificationIcon) && (
            <OptionsContainer>
              {previousPageIconOn && (
                <PreviousPageContainer
                  onClick={() =>
                    handleOnClickPreviousPage
                      ? handleOnClickPreviousPage()
                      : null
                  }
                >
                  <IconArrowBackContainer />
                  <TextArrowBack>{previousPageText}</TextArrowBack>
                </PreviousPageContainer>
              )}

              {notificationIcon && (
                <NotificationContainer>
                  <Notification
                    data={notificationData}
                    handleClickNotification={handleClickNotification}
                    handleClickMakedAllAsRead={handleClickMakedAllAsRead}
                  />
                </NotificationContainer>
              )}
            </OptionsContainer>
          )}
          {children}
        </Subcontainer>
      </Container>
    </Page>
  );
};

const Page = styled.div`
  width: 100%;
  position: relative;
  /* background-color: pink; */
`;

const Container = styled.div`
  height: 100vh;
  overflow-y: auto;
  overflow-y: auto;
  /* background-color: yellow; */
`;

const Subcontainer = styled.div`
  padding: 30px;
  /* background-color: violet; */
  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.mobileLandscapeMax}) {
    padding: 50px 10px;
  }
`;

const OptionsContainer = styled.div`
  width: 100%;
  padding: 10px 0;
  display: flex;
  position: relative;
`;

const PreviousPageContainer = styled.div`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.darkGreenMain};
  cursor: pointer;
`;

const IconArrowBackContainer = styled(IconArrowBack)`
  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.laptop13Max}) {
    width: 12px;
    height: 12px;
  }
`;

const TextArrowBack = styled.div`
  ${Heading2}
  margin-left: 10px;

  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.laptop13Max}) {
    ${Heading3}
  }
`;

const NotificationContainer = styled.div`
  position: absolute;
  top: 0px;
  right: 0px;

  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.mobileLandscapeMax}) {
    right: 20px;
    top: -35px;
  }
`;
