import { useLazyQuery, useMutation } from "@apollo/client";
import * as Sentry from "@sentry/react";
import { Field, Form, Formik } from "formik";
import { loader } from "graphql.macro";
import cookie from "js-cookie";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import * as Yup from "yup";
import BasicCard from "../../../components/Card/BasicCard/BasicCard";
import CustomButton from "../../../components/CustomButton/CustomButton";
import Dropdown from "../../../components/Dropdown/Dropdown";
import { DropdownForm } from "../../../components/Form/DropdownForm/DropdownForm";
import { PageWrapper } from "../../../components/Layout/PageWrapper/PageWrapper";
import { Loader } from "../../../components/Loader/Loader";
import { COOKIES } from "../../../constants/cookies_types";
import { APPLICATION_ROLE } from "../../../constants/db_types";
import { theme } from "../../../theme";
import { LinearGradientText } from "../../../theme/components/ThemeComponents";
import { Heading1, Heading2, Heading3 } from "../../../theme/fonts";
import { ReactComponent as IconAdd } from "../../../theme/icons/add.svg";
import { ReactComponent as IconCopy } from "../../../theme/icons/copy.svg";
import { ReactComponent as IconSub } from "../../../theme/icons/sub.svg";
import ApplicationContext from "../../../utils/context";
import { displayToastNotification } from "../../../utils/toastNotification";

//TODO refacto + finish Thibaut s work - Too complex logic - we have to use moment package !!

const addReservationQuery = loader("../../../graphql/addReservation.graphql");

const deleteReservationsSlotsQuery = loader(
  "../../../graphql/deleteReservationsSlotsByOrganization.graphql"
);

const deleteReservationsQuery = loader(
  "../../../graphql/deleteReservationsByOrganization.graphql"
);

const addReservationSlotsQuery = loader(
  "../../../graphql/addReservationSlots.graphql"
);

const getReservationSlotsQuery = loader(
  "../../../graphql/getReservationSlots.graphql"
);

const validationSchema = () =>
  Yup.object().shape({
    duration: Yup.string().required("Veuillez choisir une durée d'appel"),
  });

const labels = [
  "Lundi",
  "Mardi",
  "Mercredi",
  "Jeudi",
  "Vendredi",
  "Samedi",
  "Dimanche",
];

const generateHours = (start: number, end: number, step: number) => {
  let acc = [start];
  for (let i = 1; acc[i - 1] < end; ++i) acc.push(acc[i - 1] + step);
  return acc;
};

const minutesToString = (m: number) =>
  m === 0 ? "" : `${Math.trunc(m / 60)}h${("0" + (m % 60)).slice(-2)}`;

const nextDay = (d: Date, dowIndex: number) => {
  const nextDow = moment(d).add(7, "days");
  const nextDowIndex = nextDow.isoWeekday();
  if (nextDowIndex > dowIndex)
    return nextDow.subtract(nextDowIndex - dowIndex, "days");
  else if (nextDowIndex === dowIndex) return nextDow;
  else return nextDow.add(dowIndex - nextDowIndex, "days");
};

//TODO change function name because not relevant anymore
const computeHoursISOString = (d: any, n: number) => {
  const dayNextWeek = moment(d).format("YYYY-MM-DD");
  return moment(dayNextWeek).add(n, "minutes").toISOString();
};

const cutSlot = (duration: number, date: any, slots: any[]) => {
  let acc = [];
  for (const s of slots) {
    for (let i = s.start; i < s.end; i += duration) {
      acc.push({
        start_time: computeHoursISOString(date, i),
        end_time: computeHoursISOString(date, i + duration),
      });
    }
  }
  return acc;
};

export const Configure: any = () => {
  const { currentOrganizationCxt } = useContext<any>(ApplicationContext);

  const [getReservationSlots, { data: reservationSlots }] = useLazyQuery(
    getReservationSlotsQuery,
    { fetchPolicy: "network-only" }
  );

  const [warning, setWarning] = useState(false);
  const [addReservation] = useMutation(addReservationQuery);
  const [addReservationSlots] = useMutation(addReservationSlotsQuery);
  const [deleteReservationsSlots] = useMutation(deleteReservationsSlotsQuery);
  const [deleteReservations] = useMutation(deleteReservationsQuery);
  const [days, setDays] = useState<boolean[]>(Array(7).fill(false));
  const [slots, setSlots] = useState<any[]>(Array(7).fill(null));
  const [isDisabledSubmitBtn, setIsDisabledSubmitBtn] = useState(true);
  const [isDisabledDeleteSlotsBtn, setIsDisabledDeleteSlotsBtn] =
    useState(false);

  const [originalDuration, setOriginalDuration] = useState<Number>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (currentOrganizationCxt?.currentOrganizationToEdit?.id) {
      getReservationSlots({
        variables: {
          organization_id:
            currentOrganizationCxt?.currentOrganizationToEdit?.id,
        },
      });
    }
  }, [currentOrganizationCxt?.currentOrganizationToEdit, getReservationSlots]);

  useEffect(() => {
    if (reservationSlots?.reservation_slots[0]?.slots) {
      setWarning(true);
      setDays(
        reservationSlots.reservation_slots[0].slots.map((x: any) =>
          x ? true : false
        )
      );
      setSlots(reservationSlots.reservation_slots[0].slots);
      setOriginalDuration(reservationSlots.reservation_slots[0].duration);
    }
    setLoading(false);
  }, [reservationSlots]);

  useEffect(() => {
    if (days.includes(true)) {
      setIsDisabledSubmitBtn(false);
    } else {
      setIsDisabledSubmitBtn(true);
    }
  }, [days]);

  const createReservations = async (
    reservations: any[],
    addReservation: Function
  ) => {
    if (reservations.length === 0) return;
    for (const r of reservations)
      await addReservation({
        variables: {
          organization_id:
            currentOrganizationCxt?.currentOrganizationToEdit?.id,
          ...r,
        },
      });
  };

  const isFirstDay = (dayIdx: number) => {
    const idx = days.indexOf(true);
    return idx === dayIdx;
  };

  const handleSubmit = async (duration: string) => {
    if (cookie.get(COOKIES.ROLE) === APPLICATION_ROLE.ADMIN) {
      displayToastNotification(
        "error",
        "Koalou Admin ne peut pas configurer de slots"
      );
      return;
    }
    setIsDisabledSubmitBtn(true);
    const now = new Date();
    // console.log("SLOTS", slots);
    let slotsFormatVerify = true;
    if (slots.filter((e) => e !== null).length === 0) {
      slotsFormatVerify = false;
    } else {
      for (let i = 0; i < slots.length; i++) {
        // Check because the old logic set undefined when day is enabled then disabled
        if (slots[i]) {
          for (let j = 0; j < slots[i].length; j++) {
            if (slots[i][j].start === 0 || slots[i][j].end === 0) {
              slotsFormatVerify = false;
              break;
            }
          }
        }
      }
    }
    if (!slotsFormatVerify) {
      displayToastNotification(
        "error",
        "Slots invalides. Veuillez compléter ou supprimer les slots incomplets"
      );
      setIsDisabledSubmitBtn(false);
      return;
    }
    await deleteSlots();
    await addReservationSlots({
      variables: {
        organization_id: currentOrganizationCxt?.currentOrganizationToEdit?.id,
        slots,
        duration: parseInt(duration),
      },
    });

    for (let i = 0; i < slots.length; ++i) {
      if (!slots[i]) continue;
      const whyDontYouCreateAVariable = [
        ...new Set(
          cutSlot(parseInt(duration), nextDay(now, i + 1), slots[i]).map((x) =>
            JSON.stringify(x)
          )
        ),
      ].map((x) => JSON.parse(x));
      await createReservations(whyDontYouCreateAVariable, addReservation);
    }
    setIsDisabledSubmitBtn(false);
    setWarning(true);
    displayToastNotification(
      "success",
      "Vos disponibilités ont été enregistrées"
    );
  };

  const deleteSlots = async () => {
    try {
      await deleteReservationsSlots({
        variables: {
          organization_id:
            currentOrganizationCxt?.currentOrganizationToEdit?.id,
        },
      });
      await deleteReservations({
        variables: {
          organization_id:
            currentOrganizationCxt?.currentOrganizationToEdit?.id,
        },
      });
      getReservationSlots({
        variables: {
          organization_id:
            currentOrganizationCxt?.currentOrganizationToEdit?.id,
        },
      });
      setWarning(false);
    } catch (err) {
      console.log(err);
      Sentry.captureException(err);
    }
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <PageWrapper>
      <Container>
        <Formik
          initialValues={{ duration: originalDuration?.toString() ?? "" }}
          validationSchema={() => validationSchema()}
          onSubmit={async (values) => {
            handleSubmit(values.duration);
          }}
        >
          {(props) => (
            <Form style={{ width: "100%" }}>
              <Title>Création de modèle</Title>
              <Subtitle>
                Cette page vous aide à configurer vos créneaux de disponibilités
                pour l’appel de la veille ainsi que leur durée.
              </Subtitle>
              <br />
              <Subtitle>
                Les patients pourront ainsi indiquer dans votre agenda leur
                préférence pour être rappelés.
              </Subtitle>
              {warning && (
                <SubtitleWarningMessage>
                  Attention, vous avez déjà rentré vos disponibilités pour cette
                  semaine.
                  <DeleteSlotsButton>
                    <CustomButton
                      type="button"
                      content="Me rendre indisponible"
                      size="sm"
                      backgroundColor={theme.colors.redL4}
                      disabled={isDisabledDeleteSlotsBtn}
                      handleOnClick={async () => {
                        setIsDisabledSubmitBtn(true);
                        setIsDisabledDeleteSlotsBtn(true);
                        await deleteSlots();
                        setSlots(Array(7).fill(null));
                        setDays(Array(7).fill(false));
                        displayToastNotification(
                          "success",
                          "Vos disponibilités ont été supprimées"
                        );
                        setIsDisabledSubmitBtn(false);
                        setIsDisabledDeleteSlotsBtn(false);
                      }}
                    />
                  </DeleteSlotsButton>
                </SubtitleWarningMessage>
              )}
              <MainContainer>
                <BoxContainer>
                  <BasicCard title="Disponibilités" titleSeparator={true}>
                    <CardSubtitle>
                      Sélectionnez vos disponibilités sur les prochains jours.
                    </CardSubtitle>
                    <ReminderCircleContainer>
                      {["L", "M", "M", "J", "V", "S", "D"].map((x, i) => (
                        <ReminderCircle
                          onClick={() => {
                            if (days[i] === true) {
                              const newSlots = [...slots];
                              if (newSlots[i]) {
                                newSlots[i] = null;
                              }
                              setSlots(newSlots);
                            }
                            setDays(days.map((x, n) => (n === i ? !x : x)));
                          }}
                          key={i}
                          isActive={days[i]}
                        >
                          {x}
                        </ReminderCircle>
                      ))}
                    </ReminderCircleContainer>
                    <div style={{ marginTop: "28px" }}>
                      {days.map(
                        (x, i) =>
                          x && (
                            <DayDrop
                              key={i}
                              weekDay={i}
                              label={labels[i]}
                              slots={slots}
                              handleChangeSlots={(v: any) => {
                                // console.log("NOUVELLE VALUE: ", v);
                                let newSlots = [...slots];
                                newSlots[i] = v;
                                setSlots(newSlots);
                              }}
                              displayCopyOption={isFirstDay(i)}
                              handleCopySlots={() => {
                                if (isFirstDay(i)) {
                                  let newSlots = slots.map((e, j) => {
                                    if (days[j]) return slots[i];
                                    else return null;
                                  });
                                  setSlots(newSlots);
                                }
                              }}
                            />
                          )
                      )}
                    </div>
                  </BasicCard>
                </BoxContainer>
                <BoxContainer id="reminder-configure__call-duration">
                  <BasicCard title="Durée de l’appel" titleSeparator={true}>
                    <CardSubtitle>
                      Sélectionnez ici la durée de l'appel.
                    </CardSubtitle>
                    <Field
                      name={"duration"}
                      label={""}
                      placeholder={"--"}
                      component={DropdownForm}
                      data={[5, 10, 15, 30].map((x) => ({
                        label: `${x} min`,
                        value: x,
                      }))}
                    />
                  </BasicCard>
                </BoxContainer>
              </MainContainer>
              <ButtonContainer>
                <CustomButton
                  type="submit"
                  content="Enregistrer"
                  disabled={isDisabledSubmitBtn}
                />
              </ButtonContainer>
            </Form>
          )}
        </Formik>
      </Container>
    </PageWrapper>
  );
};

export default Configure;

const DayDrop = ({
  label,
  slots,
  handleChangeSlots,
  weekDay,
  displayCopyOption,
  handleCopySlots,
}: {
  label: string;
  slots: any;
  handleChangeSlots: any;
  weekDay: number;
  displayCopyOption: boolean;
  handleCopySlots: any;
}) => {
  const start = 8 * 60;
  const end = 20 * 60 + 30;
  const [rows, setRows] = useState<{ start: number; end: number }[]>([
    { start: 0, end: 0 },
  ]);
  const baseHours = generateHours(start, end, 30);

  useEffect(() => {
    if (!slots[weekDay]) return;
    setRows(JSON.parse(JSON.stringify(slots[weekDay])));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slots]);

  return (
    <DayDropContainer>
      <DayDropLabel>{label}</DayDropLabel>
      <DayDropSlotsContainter>
        {rows.map((x, i) => {
          return (
            <DayDropSlotRowContainer key={i}>
              <DayDropSlotRow>
                <DayDropSlotTimeDropdownContainer>
                  <DayDropSlotTimeDropdown>
                    <Dropdown
                      dropdownIndicatorHidden
                      value={
                        x.start
                          ? {
                              value: x.start.toString(),
                              label: minutesToString(x.start),
                            }
                          : undefined
                      }
                      singleValuePosition="center"
                      options={(i > 0
                        ? baseHours.filter((f) => f >= rows[i - 1].end)
                        : x.start === 0 || x.end === 0
                        ? baseHours
                        : baseHours.filter((f) => f < x.end)
                      ).map((v) => {
                        return {
                          value: v.toString(),
                          label: minutesToString(v),
                        };
                      })}
                      handleOnChange={(v) => {
                        let tmp = [...rows];
                        tmp[i].start = parseInt(v.value);
                        // console.log(tmp);
                        handleChangeSlots(tmp);
                      }}
                    />
                  </DayDropSlotTimeDropdown>
                </DayDropSlotTimeDropdownContainer>
                <DayDropSlotSeparator>
                  <span>à</span>
                </DayDropSlotSeparator>
                <DayDropSlotTimeDropdownContainer>
                  <DayDropSlotTimeDropdown>
                    <Dropdown
                      dropdownIndicatorHidden
                      value={
                        x.end
                          ? {
                              value: x.end.toString(),
                              label: minutesToString(x.end),
                            }
                          : undefined
                      }
                      singleValuePosition="center"
                      options={baseHours
                        .filter((f) => f > x.start)
                        .map((v) => {
                          return {
                            value: v.toString(),
                            label: minutesToString(v),
                          };
                        })}
                      handleOnChange={(v) => {
                        let tmp = [...rows];
                        tmp[i].end = parseInt(v.value);
                        // console.log(tmp);
                        handleChangeSlots(tmp);
                      }}
                      isDisabled={x.start === 0}
                    />
                  </DayDropSlotTimeDropdown>
                </DayDropSlotTimeDropdownContainer>
              </DayDropSlotRow>
              <DayDropSlotRowOptions>
                {displayCopyOption && i === 0 && (
                  <DayDropSlotOptionItem
                    onClick={() => {
                      handleCopySlots();
                    }}
                  >
                    <IconCopy />
                  </DayDropSlotOptionItem>
                )}
                {i !== 0 && (
                  <DayDropSlotOptionItem
                    onClick={() => {
                      const newRows = rows.filter((_x, j) => i !== j);
                      handleChangeSlots(newRows);
                    }}
                  >
                    <IconSub />
                  </DayDropSlotOptionItem>
                )}
                {/* Display Add Row btn only if
                 * we are in the last row
                 * the last row has a start and end
                 * the row's end is lte 20H00 -> 1200
                 * */}
                {i === rows.length - 1 &&
                  rows[i].start !== 0 &&
                  rows[i].end !== 0 &&
                  rows[i].end <= 1200 && (
                    <DayDropSlotOptionItem
                      onClick={() => setRows([...rows, { start: 0, end: 0 }])}
                    >
                      <IconAdd style={{ marginLeft: "10px" }} />
                    </DayDropSlotOptionItem>
                  )}
              </DayDropSlotRowOptions>
            </DayDropSlotRowContainer>
          );
        })}
      </DayDropSlotsContainter>
    </DayDropContainer>
  );
};

const Container = styled.div`
  height: 100%;
  max-width: 1050px;
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

const Title = styled.div`
  margin-bottom: 36px;
  ${Heading1};

  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.laptop13Max}) {
    ${Heading2}
  }
`;

const Subtitle = styled.div`
  ${Heading3}
  ${LinearGradientText}
`;

const SubtitleWarningMessage = styled.div`
  margin-top: 20px;
  ${Heading3};
  color: ${({ theme }) => theme.colors.redMain};
  display: flex;
  flex-direction: column;
`;

const MainContainer = styled.div`
  width: 100%;
  margin-top: 50px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.laptop13Max}) {
    flex-direction: column;
  }

  & #reminder-configure__call-duration {
    z-index: 0;
    height: 222px;
    @media screen and (max-width: ${(props) =>
        props.theme.breakpoints.laptop13Max}) {
      margin-top: 30px;
    }
  }
`;

const BoxContainer = styled.div`
  width: 490px;
  position: relative;
  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.tabletMax}) {
    width: 100%;
  }
`;

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 30px;
`;

const CardSubtitle = styled.div`
  ${Heading3}
  margin-top: 28px;
  margin-bottom: 22px;
`;

const ReminderCircleContainer = styled.div`
  display: flex;
`;

const ReminderCircle = styled.div<{ isActive: boolean }>`
  margin-right: 15px;
  ${Heading3}
  cursor: pointer;
  height: 42px;
  width: 42px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ isActive }) => (isActive ? "white" : "#82A3A7")};
  background: ${({ isActive, theme }) =>
    isActive
      ? `linear-gradient(135.02deg, #095c66 0%, #209aa2 100%);`
      : theme.colors.darkGreenL1};

  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.mobilePortraitMax}) {
    margin-right: 4px;
    height: 33px;
    width: 33px;
  }
`;

const DayDropContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: 12px 0;

  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.mobilePortraitMax}) {
    flex-direction: column;
  }
`;

const DayDropLabel = styled.div`
  ${Heading3}
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 48px;

  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.mobilePortraitMax}) {
    width: auto;
    justify-content: flex-start;
  }
`;

const DayDropSlotsContainter = styled.div`
  width: calc(100% - 80px);
  display: flex;
  flex-direction: column;

  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.mobilePortraitMax}) {
    width: 100%;
  }
`;

const DayDropSlotRowContainer = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  height: 48px;
`;

const DayDropSlotRow = styled.div`
  width: calc(100% - 100px);
  display: flex;
  align-items: center;
  background: ${({ theme }) => theme.colors._white};
  box-shadow: -3px -3px 12px 0.5px rgba(197, 197, 197, 0.21),
    3px 3px 12px 0.5px rgba(197, 197, 197, 0.21);
  border-radius: 4px;
`;

const DayDropSlotTimeDropdownContent = styled.div`
  overflow-y: scroll;
  max-height: 200px;
  display: none;
  position: absolute;
  top: 0px;
  width: 100%;
  min-width: 110px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 10;
`;

const DayDropSlotTimeDropdownContainer = styled.div`
  ${Heading3}

  cursor: pointer;
  position: relative;
  width: 40%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  &:hover
    ${DayDropSlotTimeDropdownContent},
    &:active
    ${DayDropSlotTimeDropdownContent} {
    display: block !important;
  }
`;

const DayDropSlotTimeDropdown = styled.div`
  width: 100%;
  min-width: 100px;
`;

const DayDropSlotSeparator = styled.div`
  width: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
  & > span {
    ${Heading3}
    ${LinearGradientText}
  }
`;

const DayDropSlotOptionItem = styled.div`
  cursor: pointer;
  display: flex;
`;

const DayDropSlotRowOptions = styled.div`
  width: 120px;
  padding: 0 5px;
  display: flex;
  align-items: center;
`;

const DeleteSlotsButton = styled.div`
  width: 200px;
  margin-top: 20px;
`;
