import { Field, Form, Formik } from "formik";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import * as Yup from "yup";
import { Heading3, Heading4 } from "../../../theme/fonts";
import { ReactComponent as LoginImage } from "../../../theme/images/login.svg";
import {
  loginWithEmailAndPassword,
  sendResetPasswordLink,
} from "../../../utils/auth";
import AuthCardForm from "../../AuthCard/AutchCardForm/AuthCardForm";
import AuthCardButton from "../../AuthCard/AuthCardButton/AuthCardButton";
import { AuthCardImage } from "../../AuthCard/AuthCardImage/AuthCardImage";
import AuthCardLogo from "../../AuthCard/AuthCardLogo/AuthCardLogo";
import AuthCardTitle from "../../AuthCard/AuthCardTitle/AuthCardTitle";
import { TextInputForm } from "../../Form/TextInputForm/TextInputForm";

interface FormValues {
  email: string;
  password: string;
  resetPasswordView: boolean;
}

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .required("Veuillez entrer votre email")
    .email("Email invalide"),
  password: Yup.string().when("resetPasswordView", {
    is: (val: boolean) => !val,
    then: Yup.string().required("Veuillez entrer votre mot de passe"),
  }),
});

const LogInForm: React.FunctionComponent<{}> = () => {
  const history = useHistory();
  const [status, setStatus] = useState({
    successMessage: "",
  });

  const handleEmailPasswordSubmit = async (
    values: FormValues,
    setFieldError: (field: string, errorMsg: string) => void
  ) => {
    if (values.resetPasswordView) {
      try {
        const { successMessage } = await sendResetPasswordLink(values);
        if (successMessage) {
          setStatus({ successMessage });
        }
      } catch (err: any) {
        setFieldError("email", err.error);
      }
    } else {
      try {
        const { redirect } = await loginWithEmailAndPassword(values);
        if (redirect) {
          if (redirect === "SUCCESS") {
            // history.push("/index?type=patient");
            history.push("/home");
          } else if (redirect === "UNAUTHORIZED") {
            history.push("/unauthorized");
          }
        }
      } catch (err: any) {
        setFieldError("email", err.error);
      }
    }
  };

  return (
    <Container>
      <AuthCardForm>
        <Formik
          initialValues={{
            email: "",
            password: "",
            resetPasswordView: false,
          }}
          onSubmit={async (values: FormValues, { setFieldError }) => {
            await handleEmailPasswordSubmit(values, setFieldError);
          }}
          validationSchema={validationSchema}
        >
          {(props: any) => (
            <FormStyled>
              <AuthCardLogo />
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "100%",
                  width: "100%",
                }}
              >
                <AuthCardTitle
                  title={"Content de vous revoir !"}
                  subtitle={
                    props.values.resetPasswordView
                      ? "Réinitialiser le mot de passe"
                      : "Connectez-vous à votre compte"
                  }
                />

                {status.successMessage ? (
                  <Title>{status.successMessage}</Title>
                ) : (
                  <div style={{ width: "100%" }}>
                    <FieldContainer>
                      <Field
                        name="email"
                        label="Email"
                        placeholder="Email"
                        component={TextInputForm}
                        iconType={"email"}
                      />
                    </FieldContainer>
                    {!props.values.resetPasswordView && (
                      <FieldContainer>
                        <Field
                          name="password"
                          label="Mot de passe"
                          placeholder="Mot de passe"
                          type="password"
                          component={TextInputForm}
                          iconType={"password"}
                        />
                      </FieldContainer>
                    )}
                  </div>
                )}
                <AuthCardButton
                  content={
                    props.values.resetPasswordView ? "Envoyer" : "Se connecter"
                  }
                  disabled={!!status.successMessage}
                  subtitleContent={
                    props.values.resetPasswordView
                      ? "Se connecter"
                      : "Mot de passe oublié"
                  }
                  handleOnClick={() => props.handleSubmit()}
                  handleOnClickSubtitle={() => {
                    props.resetForm();
                    props.setFieldValue(
                      "resetPasswordView",
                      !props.values.resetPasswordView
                    );
                    setStatus({ successMessage: "" });
                  }}
                />
              </div>
            </FormStyled>
          )}
        </Formik>
      </AuthCardForm>
      <AuthCardImage imageComponent={<LoginImage />} />
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const FormStyled = styled(Form)`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
`;

const Title = styled.div`
  text-align: center;
  margin-bottom: 5px;
  ${Heading3};

  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.laptop13Max}) {
    ${Heading4}
  }

  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.mobilePortraitMax}) {
    ${Heading3}
  }
`;

const FieldContainer = styled.div`
  width: 100%;
  margin: 12px 0;
`;

export default LogInForm;
