import React from "react";
import styled from "styled-components";
import { PageWrapper } from "../../components/Layout/PageWrapper/PageWrapper";
import PatientListTemplates from "../../components/ListTemplates/Patient/PatientListTemplates";
import { CardStyle2 } from "../../theme/components/ThemeComponents";

interface PatientsListProps {}

export const PatientsList: React.FC<PatientsListProps> = () => {
  return (
    <PageWrapper>
      <Container>
        <PatientListTemplates />
      </Container>
    </PageWrapper>
  );
};

export default PatientsList;

const Container = styled.div`
  height: 100%;
  ${CardStyle2}
  max-width: 1030px;
  min-width: 566px;
  margin: 0 auto;
`;
