const def = (kg, ageMonth, type) => {
  if (type === "arrêt cardiaque IV") {
    return {
      amount_scale: kg * 10,
      unit: "μg",
      comment: "",
    };
  }
  else if (type === "Choc anaphylactique IM") {
    let amount_scale = 0;
    if (kg < 5) {
      amount_scale = 0.01 * kg;
    } else if (kg <= 20) {
      amount_scale = 0.15 * kg;
    } else {
      amount_scale = 0.3 * kg;
    }
    return {
      amount_scale,
      unit: "mg",
      comment: "",
    };
  }
};

export default def;
