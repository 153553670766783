import * as Sentry from "@sentry/react";
import { getFirebaseToken } from "./auth";
interface NotifyUserCreationParams {
  email?: string;
  patientName?: string;
  organizationName?: string;
  organizationPhone?: string;
  type?: string;
  parentId?: string;
  surgeryId?: string;
}

export const notifyUserCreation = async ({
  email,
  patientName,
  organizationName,
  organizationPhone,
  type,
  parentId,
  surgeryId,
}: NotifyUserCreationParams) => {
  try {
    const token = await getFirebaseToken();
    await fetch(
      `${process.env.REACT_APP_AUTH_SERVER_URL}/notify-user-creation`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email,
          patientName,
          organizationName,
          organizationPhone,
          type,
          parentId,
          surgeryId,
        }),
      }
    );
  } catch (e) {
    console.log(e);
    Sentry.captureException(e);
  }
};
