import React from "react";
import styled from "styled-components";
import { Heading1, Heading2, Heading3, Heading4 } from "../../../theme/fonts";

interface CustomContourProps {
  title?: string;
  subtitle?: string;
}

const CustomContour: React.FC<CustomContourProps> = ({
  children,
  title,
  subtitle,
}) => {
  return (
    <Container>
      {title && <Title>{title}</Title>}
      {subtitle && <Subtitle>{subtitle}</Subtitle>}
      <Contour position="top">
        <ContourBox />
      </Contour>
      <MainContainer>{children}</MainContainer>
      <Contour position="bottom">
        <ContourBox />
      </Contour>
    </Container>
  );
};

export default CustomContour;

const Container = styled.div``;

const Title = styled.div`
  margin-bottom: 36px;
  ${Heading1};

  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.laptop13Max}) {
    ${Heading2}
  } ;
`;

const Subtitle = styled.div`
  margin-bottom: 36px;
  color: ${({ theme }) => theme.colors.darkGreenMain};
  ${Heading3};

  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.laptop13Max}) {
    ${Heading4}
  } ;
`;

const Contour = styled.div<{ position: "top" | "bottom" }>`
  height: 50px;
  width: 100%;
  border-radius: 8px 8px 0 0;
  background: linear-gradient(135.02deg, #095c66 0%, #209aa2 100%);
  display: flex;
  transform: ${({ position }) =>
    position === "bottom" ? `rotate(180deg)` : ``};
`;

const ContourBox = styled.div`
  width: calc(100% - 6px);
  height: calc(100% - 6px);
  border-radius: 5px 5px 0 0;
  margin: 6px;
  background: ${({ theme }) => theme.colors._white};
`;

const MainContainer = styled.div`
  padding: 0 30px;
`;
