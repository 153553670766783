import { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import {
  Heading3,
  Heading4,
  BodySmall,
  BodyMain,
  Heading2,
} from "../../theme/fonts";
import BasicCard from "../../components/Card/BasicCard/BasicCard";
import CustomModal from "../../components/CustomModal/CustomModal";
import Dropdown from "../../components/Dropdown/Dropdown";
import { CheckBoxForm } from "../../components/Form/CheckBoxForm/CheckBoxForm";
import { medicationList, compute } from "../../utils/dosesCompute";
import medicationInfo from "./medication.json";
import moment from "moment";
import CustomButton from "../../components/CustomButton/CustomButton";
import { LinearGradientText } from "../../theme/components/ThemeComponents";

const convertDateToAge = (date: Date) => {
  const years = moment().diff(date, "years");
  const months = moment().diff(date, "month") % 12;
  if (years > 1) {
    return years + " ans " + months + " mois";
  } else if (years === 1) {
    return "1 an " + months + " mois";
  } else {
    return months + " mois";
  }
};

const capitalize = (s: String) => {
  return s.charAt(0).toUpperCase() + s.slice(1);
};

const displayNumber = (n: any) => {
  if (typeof n === "number" && n !== 0) {
    const nFixed = parseFloat(n.toFixed(2));
    if ((nFixed * 100) % 100 === 0) {
      return Math.trunc(nFixed).toString();
    } else {
      return nFixed.toString();
    }
  } else {
    return n;
  }
};

interface Options {
  value: string;
  label: string;
}

const DosesCompute: React.FC<any> = ({ userData }) => {
  const [doseInfo, setDoseInfo] = useState<any>([]);
  const [drugChoice, setDrugChoice] = useState(0);
  const [drug, setDrug] = useState<Options>({
    value: medicationList?.[0]?.value.toString() ?? "0",
    label: medicationList?.[0]?.label ?? "Rechercher",
  });
  const [weight, setWeight] = useState<number | undefined>(undefined);
  const [age, setAge] = useState<number | undefined>(undefined);
  const [infoModal, setInfoModal] = useState(false);

  useEffect(() => {
    if (weight) {
      handleComputePosology();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [drugChoice]);

  const handleComputePosology = () => {
    // console.log(userData);
    const d = userData ? moment().diff(userData!.birthDate, "month") : age;
    const type = medicationInfo[parseInt(drug.value)].choice || [];
    if (!d || !weight) return;
    // console.log(d);
    // console.log(weight);
    // console.log(drug.value);
    // console.log(type);
    // console.log(drugChoice);
    const r = compute(drug.value)(weight, d, type[drugChoice]);
    // console.log(r);
    let computed = (Array.isArray(r) ? r : [r]).map((x: any) => {
      let value;
      if (x.amount_scale[1])
        value = `Entre ${displayNumber(x.amount_scale[0])} et ${displayNumber(
          x.amount_scale[1]
        )} ${x.unit}.`;
      else value = `${displayNumber(x.amount_scale)} ${x.unit}.`;
      return {
        name: x.name || "Dose",
        value: `${value} ${x.comment}`,
      };
    });
    setDoseInfo(computed);
  };

  return (
    <div>
      <BasicCard title="Calculer les doses" titleSeparator>
        <CardContent>
          <RowItem>
            <RowTitle children="Médicament:" />
            <RowValue style={{ width: "100%", maxWidth: "250px" }}>
              <DropdownContainer style={{ width: "100%" }}>
                <Dropdown
                  isSearchable
                  options={medicationList as unknown as Options[]}
                  defaultValue={drug}
                  handleOnChange={(x) => {
                    setDrug(x);
                    setDoseInfo([{}]);
                  }}
                />
              </DropdownContainer>
            </RowValue>
          </RowItem>
          <RowItem>
            <RowTitle children="Age:" />
            <RowValueBold>
              {userData ? (
                convertDateToAge(userData!.birthDate)
              ) : (
                <Input
                  onChange={(e) => {
                    const n = parseInt(e.target.value);
                    if (Number.isNaN(n) || Math.sign(n) === -1)
                      setAge(undefined);
                    else setAge(parseInt(e.target.value));
                  }}
                  value={age ?? ""}
                  style={{ border: "none" }}
                  name={"age"}
                  type="number"
                  placeholder={"Âge du patient"}
                />
              )}
            </RowValueBold>
          </RowItem>
          <RowItem>
            <RowTitle children="Poids (kg)" />
            <RowValue>
              <Input
                onChange={(e) => {
                  const n = parseFloat(e.target.value);
                  if (Number.isNaN(n) || Math.sign(n) === -1)
                    setWeight(undefined);
                  else setWeight(parseFloat(e.target.value));
                }}
                value={weight ?? ""}
                style={{ border: "none" }}
                name={"weight"}
                type="number"
                step="0.1"
                placeholder={"Poids du patient"}
              />
            </RowValue>
          </RowItem>
          {!medicationInfo[parseInt(drug.value)].choice ? (
            <div />
          ) : (
            <RowItem>
              {medicationInfo[parseInt(drug.value)].choice?.map((x, i) => (
                <CheckBoxContainer onClick={() => setDrugChoice(i)} key={i}>
                  <CheckBoxForm
                    labelComponent={
                      <MedicationTypeOption>
                        {capitalize(x)}
                      </MedicationTypeOption>
                    }
                    checked={drugChoice === i}
                    onChange={() => {}}
                  />
                </CheckBoxContainer>
              ))}
            </RowItem>
          )}
          <DoseInfoContainer>
            {doseInfo?.[0]?.name && (
              <>
                <DoseInfoSubcontainer>
                  {doseInfo?.map((x: any, i: number) => (
                    <DoseInfoRow key={i}>
                      <DoseInfoTitle>
                        {x.name ? x.name + ":" : ""}
                      </DoseInfoTitle>
                      <DoseInfoValue>
                        {x.value && (
                          <span className="dose-info__value">{x.value}</span>
                        )}
                        {x.value && (
                          <span className="dose-info__asterisk">*</span>
                        )}
                      </DoseInfoValue>
                    </DoseInfoRow>
                  ))}
                </DoseInfoSubcontainer>
                <DoseInfoAsterisk>
                  <span>*</span> Calculation based on :
                  https://base-donnees-publique.medicaments.gouv.fr/
                </DoseInfoAsterisk>
              </>
            )}
          </DoseInfoContainer>
          <ButtonContainer>
            <CustomButton
              content="Calculer Posologie"
              handleOnClick={() => {
                handleComputePosology();
              }}
            />
          </ButtonContainer>
          <MedicationDetails>
            <div
              onClick={() => setInfoModal(true)}
              style={{ cursor: "pointer" }}
            >
              <MedicationDetailsTitle>
                Voir les détails du médicament
              </MedicationDetailsTitle>
              <span>
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M18 9C18 8.44772 17.5523 8 17 8C16.4477 8 16 8.44772 16 9H18ZM9 2C9.55228 2 10 1.55228 10 1C10 0.447715 9.55228 0 9 0V2ZM8.29289 8.29289C7.90237 8.68342 7.90237 9.31658 8.29289 9.70711C8.68342 10.0976 9.31658 10.0976 9.70711 9.70711L8.29289 8.29289ZM17 1H18C18 0.447715 17.5523 0 17 0V1ZM13 0C12.4477 0 12 0.447715 12 1C12 1.55228 12.4477 2 13 2V0ZM16 5C16 5.55228 16.4477 6 17 6C17.5523 6 18 5.55228 18 5H16ZM15 16H3V18H15V16ZM2 15V3H0V15H2ZM16 9V15H18V9H16ZM3 2H9V0H3V2ZM3 16C2.44772 16 2 15.5523 2 15H0C0 16.6569 1.34315 18 3 18V16ZM15 18C16.6569 18 18 16.6569 18 15H16C16 15.5523 15.5523 16 15 16V18ZM2 3C2 2.44772 2.44772 2 3 2V0C1.34315 0 0 1.34315 0 3H2ZM9.70711 9.70711L17.7071 1.70711L16.2929 0.292893L8.29289 8.29289L9.70711 9.70711ZM17 0H13V2H17V0ZM16 1V5H18V1H16Z"
                    fill="url(#paint0_linear)"
                  />
                  <defs>
                    <linearGradient
                      id="paint0_linear"
                      x1="1"
                      y1="1"
                      x2="16.995"
                      y2="17.005"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#095C66" />
                      <stop offset="1" stopColor="#209AA2" />
                    </linearGradient>
                  </defs>
                </svg>
              </span>
            </div>
          </MedicationDetails>
        </CardContent>
      </BasicCard>
      <CustomModal
        showModal={infoModal}
        handleCloseModal={() => setInfoModal(false)}
        closeIcon
      >
        <ModalContainer>
          <ModalTitle>
            Détails du médicament | {medicationInfo[parseInt(drug.value)].name}
          </ModalTitle>
          <Line />
          <ModalSectionContainer>
            <ModalSection>
              <ModalSectionTitle>Nom médicament (avec DCI) :</ModalSectionTitle>
              <ModalSectionContent>
                {medicationInfo[parseInt(drug.value)].dci}
              </ModalSectionContent>
            </ModalSection>
            <ModalSection>
              <ModalSectionTitle>Nom de spécialité :</ModalSectionTitle>
              <ModalSectionContent>
                {medicationInfo[parseInt(drug.value)].speciality}
              </ModalSectionContent>
            </ModalSection>
            <ModalSection>
              <ModalSectionTitle>Posologie en pédiatrie:</ModalSectionTitle>
              <ModalSectionContentInnerHtml>
                <div
                  dangerouslySetInnerHTML={{
                    __html: `${medicationInfo[parseInt(drug.value)].posology}`,
                  }}
                />
              </ModalSectionContentInnerHtml>
            </ModalSection>
            <ModalSection>
              <ModalSectionTitle>Dilution :</ModalSectionTitle>
              <ModalSectionContentInnerHtml>
                <div
                  dangerouslySetInnerHTML={{
                    __html: `${medicationInfo[parseInt(drug.value)].dilution}`,
                  }}
                />
              </ModalSectionContentInnerHtml>
            </ModalSection>
          </ModalSectionContainer>
        </ModalContainer>
      </CustomModal>
    </div>
  );
};

export default DosesCompute;

const CardContent = styled.div``;

const Line = styled.div`
  width: 100%;
  height: 3px;
  background: linear-gradient(135.02deg, #095c66 0%, #209aa2 100%);
  border-radius: 6px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 48px;
`;

const Input = styled.input`
  padding: 10px 10px 10px 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  background: ${({ theme }) => theme.colors._white};
  box-shadow: -6px -6px 12px 0.5px rgba(197, 197, 197, 0.21),
    6px 6px 12px 0.5px rgba(197, 197, 197, 0.21);
  border: none;
  border-radius: 36px;
  :focus {
    outline: none;
  }
  ::placeholder {
    color: ${({ theme }) => theme.colors.blackMain};
  }
  ${Heading3}

  @media screen and (max-width: ${(props) =>
    props.theme.breakpoints.laptop13Max}) {
    ${Heading4}
  }
`;

const DropdownContainer = styled.div`
  width: 250px;
  background: ${({ theme }) => theme.colors._white};
  box-shadow: -6px -6px 12px 0.5px rgba(197, 197, 197, 0.21),
    6px 6px 12px 0.5px rgba(197, 197, 197, 0.21);
  border: none;
  border-radius: 36px;
  :focus {
    outline: none;
  }
  ${Heading3}

  @media screen and (max-width: ${(props) =>
    props.theme.breakpoints.laptop13Max}) {
    ${Heading4}
  }
`;

const CheckBoxContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const RowItem = styled.div`
  display: flex;
  align-items: center;
  margin: 20px 0;
`;

const RowTitle = styled.div`
  margin-right: 10px;
  ${Heading3};
  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.laptop13Max}) {
    ${Heading4}
  }
`;

const RowValue = styled.div`
  ${BodyMain}
  @media screen and (max-width: ${(props) =>
    props.theme.breakpoints.laptop13Max}) {
    ${BodySmall}
  }
`;

const RowValueBold = styled.div`
  ${Heading3}
  @media screen and (max-width: ${(props) =>
    props.theme.breakpoints.laptop13Max}) {
    ${Heading4}
  }
`;

const ModalTitle = styled.div`
  ${Heading2}
  margin-bottom: 9px;
  padding-right: 25px;
  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.laptop13Max}) {
    ${Heading3}
  }
`;

const ModalSectionTitle = styled.div`
  margin-bottom: 5px;
  ${LinearGradientText};
  ${Heading3};
  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.laptop13Max}) {
    ${Heading4}
  }
`;

const ModalSectionContent = styled.div`
  ${Heading4}
`;

const ModalSectionContentInnerHtml = styled.div`
  ${BodyMain}

  & table, th, td {
    border: 1px solid black;
    border-collapse: collapse;
    padding: 10px;
  }
`;

const ModalSectionContainer = styled.div`
  max-height: 631px;
  overflow-y: auto;
  padding-right: 30px;
`;

const ModalSection = styled.div`
  margin: 24px 0;
`;

const ModalContainer = styled.div`
  width: 731px;

  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.laptop13Max}) {
    width: 600px;
  }
  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.mobileLandscapeMax}) {
    width: 450px;
  }

  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.mobilePortraitMax}) {
    width: 100%;
  }
`;

const MedicationDetails = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 14px;
`;

const MedicationDetailsTitle = styled.span`
  border-bottom: ${({ theme }) => `2px solid ${theme.colors.darkGreenL4}`};
  ${LinearGradientText};
  ${Heading4};
  margin-right: 5px;
  user-select: none;
`;

const MedicationTypeOption = styled.div`
  margin-right: 15px;
  color: ${({ theme }) => theme.colors.darkGreenL3};
  ${Heading4}
  padding-top: 1px;
`;

const DoseInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const asterisk = css`
  color: red !important;
  font-size: 1.3em;
`;

const DoseInfoRow = styled.div`
  display: flex;
  flex-direction: column;
  margin: 10px 0;
`;

const DoseInfoTitle = styled.div`
  ${Heading3}
  ${LinearGradientText}
  margin-right: 5px;
`;

const DoseInfoValue = styled.div`
  ${BodyMain}

  & > .dose-info__value {
    ${LinearGradientText}
  }

  & > .dose-info__asterisk {
    color: red;
  }
`;

const DoseInfoAsterisk = styled.div`
  margin-top: 16px;
  ${BodySmall}
  & span {
    ${asterisk}
  }
`;

const DoseInfoSubcontainer = styled.div`
  max-height: 100px;
  overflow-y: auto;
  border: 1px solid ${({ theme }) => theme.colors.darkGreenL3};
  border-radius: 6px;
  padding: 0px 17px;
`;
