import React from "react";
import { theme } from "../../theme";
import Select, { StylesConfig } from "react-select";
import styled from "styled-components";
interface Options {
  value: string;
  label: string;
}

type IsMulti = false;

const dropdownStyle: StylesConfig<Options, IsMulti> = {
  control: (styles, { selectProps }) => ({
    ...styles,
    border: "none",
    focus: "none",
    boxShadow: "none",
    cursor: "pointer",
    backgroundColor: "unset",
    borderRadius: selectProps.customBorderRadius || "0px",
    // "&:hover": {
    //   backgroundColor: theme.colors.darkGreenL1,
    // },
    // width: selectProps.customWidth,
  }),
  valueContainer: (styles, { selectProps }) => ({
    ...styles,
    display: "flex",
    justifyContent: selectProps.customSingleValuePosition ?? "flex-end",
    width: "auto",
    paddingRight: 0,
  }),
  singleValue: (styles, { selectProps }) => ({
    ...styles,
    color: selectProps.customSingleValueColor ?? theme.colors.blackMain,
  }),
  option: (styles, state) => ({
    ...styles,
    backgroundColor: state.isSelected ? theme.colors.darkGreenL1 : "",
    color: theme.colors.blackMain,
    "&:active": {
      backgroundColor: theme.colors.darkGreenL1,
    },
    cursor: "pointer",
  }),
  indicatorSeparator: () => ({ display: "none" }),
  dropdownIndicator: (_, { selectProps }) => ({
    display: selectProps.customDropdownIndicatorHidden ? "none" : "block",
    color:
      selectProps.customDropdownIndicatorColor ?? theme.colors.darkGreenMain,
  }),
};

interface DropdownProps {
  options: Array<Options>;
  value?: { value: string; label: string } | undefined;
  defaultValue?: Options;
  handleOnChange: (data: Options) => void;
  borderRadius?: string;
  dropdownIndicatorColor?: string;
  dropdownIndicatorHidden?: boolean;
  singleValueColor?: string;
  singleValuePosition?: "flex-start" | "flex-end" | "center";
  isSearchable?: boolean;
  isDisabled?: boolean;
}

const Dropdown: React.FC<DropdownProps> = ({
  options,
  value,
  defaultValue,
  handleOnChange,
  borderRadius,
  dropdownIndicatorColor,
  dropdownIndicatorHidden,
  singleValueColor,
  singleValuePosition,
  isSearchable,
  isDisabled,
}) => {
  return (
    <DropdownContainer
      value={value}
      placeholder={""}
      defaultValue={defaultValue ? defaultValue : null}
      onChange={handleOnChange}
      options={options ? options : []}
      styles={dropdownStyle}
      isSearchable={isSearchable || false}
      customBorderRadius={borderRadius}
      customDropdownIndicatorColor={dropdownIndicatorColor}
      customDropdownIndicatorHidden={dropdownIndicatorHidden}
      customSingleValueColor={singleValueColor}
      customSingleValuePosition={singleValuePosition}
      isDisabled={isDisabled}
    />
  );
};

export default Dropdown;

const DropdownContainer = styled(Select)``;
