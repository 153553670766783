import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import common_fr from "./locales/fr/common.json";
import common_en from "./locales/en/common.json";
import { setMomentFr } from "./lib/momentLocale";
import { LANGUAGES } from "./constants/languages_types";
import LanguageDetector from "i18next-browser-languagedetector";
import moment from "moment";

setMomentFr();

const resources = {
  fr: {
    common: common_fr,
  },
  en: {
    common: common_en,
  },
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    fallbackLng: LANGUAGES.EN_UK,
    // keySeparator: false, // we do not use keys in form messages.welcome
    interpolation: {
      escapeValue: false, // react already safes from xss
      format: function (value, format, lng) {
        if (value instanceof Date) {
          return moment(value).format(format);
        }
        return value;
      },
    },
  });

i18n.on("languageChanged", function (lng) {
  moment.locale(lng);
});

export default i18n;
