const def = (kg, ageMonth, type) => {
  let amount_scale = 0;
  let comment = "";
  if (type === "induction") {
    if (ageMonth <= 1) {
      comment = "Pas d'AMM en dessous de 1 mois";
    } else if (ageMonth < 36) {
      amount_scale = [kg * 2.5, kg * 4];
    } else {
      amount_scale = kg * 2.5;
    }
    return {
      amount_scale,
      unit: "mg",
      comment,
    };
  } else if (type === "entretiens") {
    if (ageMonth <= 1) {
      comment = "Pas d'AMM en dessous de 1 mois";
    } else {
      amount_scale = [kg * 9, kg * 15];
    }
    return {
      amount_scale,
      unit: "mg/h",
      comment,
    };
  }
};

export default def;
