import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import PageWrapperNoAuth from "../../components/Layout/PageWrapperNoAuth/PageWrapperNoAuth";
import SignUpForm from "../../components/FormTemplates/SignUpForm/SignUpForm";
import { CardStyle1 } from "../../theme/components/ThemeComponents";

interface SignUpProps {}

const SignUp: React.FC<SignUpProps> = () => {
  const [values, setValues] = useState<null | {
    email: string;
    type: string;
  }>(null);
  const queryParams = useLocation().search;

  useEffect(() => {
    const email = new URLSearchParams(queryParams).get("email");
    const type = new URLSearchParams(queryParams).get("type");
    setValues({ email: email ?? "", type: type ?? "" });
  }, [queryParams]);

  return (
    <>
      {values && (
        <PageWrapperNoAuth title="Création du compte">
          <LoginFormContainer>
            <SignUpForm email={values.email} type={values.type} />
          </LoginFormContainer>
        </PageWrapperNoAuth>
      )}
    </>
  );
};

const LoginFormContainer = styled.div`
  ${CardStyle1}
  width: 724px;
  height: 419px;
  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.laptop13Max}) {
    width: 620px;
    height: 353px;
  }

  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.mobilePortraitMax}) {
    width: 100%;
    height: auto;
  }
`;

export default SignUp;
