import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { PageWrapper } from "../../components/Layout/PageWrapper/PageWrapper";
import CustomList from "../../components/List/CustomList/CustomList";
import {
  CardStyle2,
  LinearGradientText,
} from "../../theme/components/ThemeComponents";
import { loader } from "graphql.macro";
import { useLazyQuery } from "@apollo/client";
import ApplicationContext from "../../utils/context";
import { ReactComponent as IconOpenWindow } from "../../theme/icons/openWindow.svg";
import { Modal, ModalBody } from "reactstrap";
import PdfViewer from "../../components/PdfViewer";
import { ORGANIZATION_FILE_TYPE } from "../../constants/db_types";

interface OrganizationDocumentsListProps {}

const columns = [
  {
    value: "Nom",
    ratio: "80%",
    type: "string",
    sortable: true,
    sortId: "name",
  },
  {
    value: "Lien",
    ratio: "20%",
    type: "component",
    component: <button>ouvrir</button>,
  },
];

const limit = 10;

const getOrganizationDocumentsCountQuery = loader(
  "../../graphql/getOrganizationDocumentsCountByOrganizationId.graphql"
);

const getOrganizationDocumentsWithLimitOffsetQuery = loader(
  "../../graphql/getOrganizationDocumentsWithLimitOffset.graphql"
);

const OrganizationDocumentsList: React.FC<OrganizationDocumentsListProps> =
  () => {
    const history = useHistory();

    const { currentOrganizationCxt, organizationDocumentCxt } =
      useContext<any>(ApplicationContext);

    const [pagesNumber, setPagesNumber] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [search, setSearch] = useState("%%");
    const [searchBarValue, setSearchBarValue] = useState("");
    const [orderBy, setOrderBy] = useState<any>({
      name: "desc",
    });

    const [currentDocument, setCurrentDocument] = useState({
      show: false,
      documentName: "",
    });

    const [
      getOrganizationDocumentsCount,
      { data: dataOrganizationDocumentsCount },
    ] = useLazyQuery(getOrganizationDocumentsCountQuery, {
      fetchPolicy: "network-only",
    });

    const [organizationDocuments, setOrganizationDocuments] = useState<Array<{
      customData: any;
      rowValues: Array<string>;
    }> | null>(null);

    const [
      getOrganizationDocumentsWithLimitOffset,
      { data: dataOrganizationDocuments },
    ] = useLazyQuery(getOrganizationDocumentsWithLimitOffsetQuery, {
      fetchPolicy: "network-only",
    });

    useEffect(() => {
      if (currentOrganizationCxt?.currentOrganizationToEdit?.id) {
        getOrganizationDocumentsCount({
          variables: {
            organizationId: currentOrganizationCxt.currentOrganizationToEdit.id,
            search,
          },
        });
      }
    }, [
      currentOrganizationCxt?.currentOrganizationToEdit,
      getOrganizationDocumentsCount,
      search,
    ]);

    /* Calculate number of pages */
    useEffect(() => {
      if (dataOrganizationDocumentsCount) {
        const organizationDocumentsCount =
          dataOrganizationDocumentsCount.organization_document.length;
        let pagesNumberTmp = Math.ceil(organizationDocumentsCount / limit);
        setPagesNumber(pagesNumberTmp);
      }
    }, [dataOrganizationDocumentsCount]);

    useEffect(() => {
      if (currentOrganizationCxt?.currentOrganizationToEdit?.id) {
        getOrganizationDocumentsWithLimitOffset({
          variables: {
            limit,
            offset: currentPage * limit - limit,
            orderBy,
            search,
            organizationId:
              currentOrganizationCxt?.currentOrganizationToEdit?.id,
          },
        });
      }
    }, [
      currentPage,
      currentOrganizationCxt?.currentOrganizationToEdit,
      getOrganizationDocumentsWithLimitOffset,
      search,
      orderBy,
    ]);

    useEffect(() => {
      if (dataOrganizationDocuments) {
        const organizationDocumentsTmp =
          dataOrganizationDocuments.organization_document.map((e: any) => {
            return {
              customData: {
                ...e,
              },
              rowValues: [
                e.name,
                <ButtonLinkOpenDocument>
                  <div
                    onClick={(event) => {
                      event.stopPropagation();
                      setCurrentDocument({
                        documentName: e.document_name,
                        show: true,
                      });
                    }}
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <span>Ouvrir le document</span>
                    <IconOpenWindow />
                  </div>
                </ButtonLinkOpenDocument>,
              ],
            };
          });
        setOrganizationDocuments(organizationDocumentsTmp);
      }
    }, [dataOrganizationDocuments]);

    const handleChangeCurrentPage = (value: number) => {
      if (value > 0 && value <= pagesNumber && value !== currentPage) {
        setCurrentPage(value);
      }
    };

    const handleFilter = (value: string) => {
      setSearch("%" + value + "%");
      setCurrentPage(1);
    };

    const handleClickOrganizationDocument = (index: number) => {
      localStorage.setItem(
        "currentOrganizationDocument",
        JSON.stringify(organizationDocuments?.[index].customData)
      );
      organizationDocumentCxt.setOrganizationDocumentToEdit(
        organizationDocuments?.[index].customData
      );
      history.push("/organization-documents/update");
    };

    const handleClickSort = (sortId: string) => {
      let direction;
      if (sortId === "name") {
        direction = orderBy.name === "asc" ? "desc" : "asc";
        setOrderBy({ name: direction });
      }
    };

    return (
      <PageWrapper>
        <Container>
          <CustomList
            title="Liste des documents"
            columns={columns}
            data={organizationDocuments}
            pagesNumber={pagesNumber}
            currentPage={currentPage}
            handleChangeCurrentPage={handleChangeCurrentPage}
            displaySearchBar={true}
            searchBarValue={searchBarValue}
            setSearchBarValue={(v: string) => setSearchBarValue(v)}
            handleSearch={(v: string) => handleFilter(v)}
            handleClick={(i: number) => handleClickOrganizationDocument(i)}
            handleClickSort={(sortId: string) => handleClickSort(sortId)}
          />
        </Container>
        <ModalContainer
          isOpen={currentDocument.show}
          toggle={() => setCurrentDocument({ ...currentDocument, show: false })}
          className="reactstrap-modal-full-size"
        >
          <ModalBody>
            <PdfViewer
              toggle={() =>
                setCurrentDocument({ ...currentDocument, show: false })
              }
              documentName={currentDocument.documentName}
              fileCategory={ORGANIZATION_FILE_TYPE.DOCUMENT}
            />
          </ModalBody>
        </ModalContainer>
      </PageWrapper>
    );
  };

export default OrganizationDocumentsList;

const Container = styled.div`
  height: 100%;
  ${CardStyle2}
  max-width: 1030px;
  min-width: 566px;
  margin: 0 auto;
`;

const ButtonLinkOpenDocument = styled.div`
  width: auto;
  span {
    ${LinearGradientText}
    margin-right: 10px;
  }
`;

const ModalContainer = styled(Modal)``;
