import React, { Component } from "react";
import { Col, Container, Row } from "react-bootstrap";
import styled from "styled-components";
import { ThemeComponents } from "../../../theme/components";
import { ReactComponent as EmailIcon } from "../../../theme/icons/email.svg";
import { ReactComponent as PasswordIcon } from "../../../theme/icons/password.svg";

export type Props = {
  label?: string;
  sublabel?: string;
  placeholder?: string;
  required?: boolean;
  direction?: "column" | "auto";
  disabled?: boolean;
  form: any;
  field: any;
  type?: string;
  autoComplete?: string;
  iconType?: "email" | "password";
};

export class TextInputForm extends Component<Props> {
  public render() {
    const {
      form,
      label,
      sublabel,
      placeholder,
      field,
      required,
      direction,
      type,
      disabled,
      autoComplete,
      iconType,
    } = this.props;
    const error = form.errors[field.name];
    const touched = form.touched[field.name];

    if (direction === "column") {
      return (
        <TextInputColumnContainer>
          <TextInputLabelContainer>
            <TextInputLabel>{label}</TextInputLabel>
            <TextInputSubLabel>{sublabel}</TextInputSubLabel>
          </TextInputLabelContainer>
          <TextInput
            {...field}
            type={type}
            autoComplete={autoComplete}
            placeholder={
              placeholder && required ? `${placeholder + " *"}` : placeholder
            }
            error={error}
            touched={touched}
            disabled={disabled}
            displayIcon={!!iconType}
          />
          {iconType && (
            <IconContainer>
              {iconType === "email" && <EmailIcon />}
              {iconType === "password" && <PasswordIcon />}
            </IconContainer>
          )}
          {touched && error && <TextInputError>{error}</TextInputError>}
        </TextInputColumnContainer>
      );
    } else {
      return (
        <TextInputAutoContainer fluid>
          <TextInputRow noGutters>
            {(label || sublabel) && (
              <Col xs={12}>
                <TextInputLabelContainer>
                  <TextInputLabel>{label}</TextInputLabel>
                  <TextInputSubLabel>{sublabel}</TextInputSubLabel>
                </TextInputLabelContainer>
              </Col>
            )}
            {/* <Col xs={12} sm={label || sublabel ? 8 : 12}> */}
            <Col xs={12}>
              <TextInput
                {...field}
                type={type}
                autoComplete={autoComplete}
                placeholder={
                  placeholder && required
                    ? `${placeholder + " *"}`
                    : placeholder
                }
                error={error}
                touched={touched}
                disabled={disabled}
                displayIcon={!!iconType}
              />
              {iconType && (
                <IconContainer>
                  {iconType === "email" && <EmailInputIcon />}
                  {iconType === "password" && <PasswordInputIcon />}
                </IconContainer>
              )}
              {touched && error && <TextInputError>{error}</TextInputError>}
            </Col>
          </TextInputRow>
        </TextInputAutoContainer>
      );
    }
  }
}

const TextInputAutoContainer = styled(Container)`
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: 0;
  position: relative;
`;

const TextInputRow = styled(Row)`
  width: 100%;
`;

const TextInput = styled(ThemeComponents.FormInput)<{
  error: string;
  touched: boolean;
  disabled: boolean;
  displayIcon: boolean;
}>`
  border-color: ${(props) => {
    const colorType =
      !!props.error && props.touched
        ? props.theme.colors.red.dark
        : props.theme.colors.white.darker;
    return colorType;
  }};
  background: ${(props) =>
    props.disabled
      ? props.theme.colors.darkGreenL1
      : props.theme.colors.darkGreenL1};
  color: ${(props) =>
    props.disabled
      ? props.theme.colors.darkGreenL4
      : props.theme.colors.blackMain};
  padding-left: ${({ displayIcon }) => (displayIcon ? "45px" : "")};
`;

const TextInputLabel = styled(ThemeComponents.FormLabel)``;

const TextInputSubLabel = styled(ThemeComponents.FormSubLabel)``;

const TextInputError = styled(ThemeComponents.FormErrorLabel)``;

const TextInputLabelContainer = styled.div`
  margin: 5px 15px 5px 0;
`;

const TextInputColumnContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
`;

const IconContainer = styled.div`
  position: absolute;
  top: 10px;
  left: 20px;
  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.laptop13Max}) {
    top: 3px;
  }
  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.mobilePortraitMax}) {
    top: 4px;
  }
`;

const EmailInputIcon = styled(EmailIcon)`
  width: 14px;
  height: 14px;
`;

const PasswordInputIcon = styled(PasswordIcon)`
  width: 14px;
  height: 14px;
`;
