// Posologie en pédiatrie
// Induction : réduire les doses avant 3 mois (1 à 2 mg/kg).
// Voie intrarectale possible, 5 à 10 mg/kg (agit en 5 à 10 minutes).

const def =  (kg, ageMonth, type) => {
  if (type === 'induction')
    return {
      amount_scale: [kg * 1, kg * 2],
      unit: 'mg',
      comment: ageMonth < 3 ? 'Réduire les doses avant 3 mois' : ''
    }
  else if (type === 'intrarectale')
    return {
      amount_scale: [kg * 5, kg * 10],
      unit: 'mg',
      comment: 'Agit en 5 à 10 minutes'
    }
  else {
    return {
      amount_scale: 0,
      unit: 'mg',
      comment: 'erreur'
    }
  }
}

export default def
