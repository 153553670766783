import { useLazyQuery } from "@apollo/client";
import { loader } from "graphql.macro";
import cookie from "js-cookie";
import React, { useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";
import CustomModal from "../../components/CustomModal/CustomModal";
import FormPrescription from "../../components/FormTemplates/Prescription/FormPrescription";
import { PageWrapper } from "../../components/Layout/PageWrapper/PageWrapper";
import { COOKIES } from "../../constants/cookies_types";
import { APPLICATION_ROLE } from "../../constants/db_types";
import ApplicationContext from "../../utils/context";
import { downloadPractitionerSignature } from "../../utils/prescription";

const getSurgeryByIdQuery = loader("../../graphql/getSurgeryById.graphql");

interface PrescriptionCreateProps {}

type Medications = { name: string; text: string }[];
type Practitioner = {
  name: string;
  surname: string;
  phone: string;
  rpps: string;
  signature: string;
  specialization: string;
};
type Organization = {
  id: string;
  address: { city: string; postalCode: string; street: string; number: string };
  am: string | null;
  finess: string | null;
};
type Patient = {
  id: string;
  name: string;
  surname: string;
  birthDate: string;
  surgeryId: string;
};

interface FormValues {
  medications: Medications;
  practitioner: Practitioner;
  organization: Organization;
  patient: Patient;
}

const PrescriptionCreate: React.FC<PrescriptionCreateProps> = () => {
  const queryParams = useLocation().search;
  const history = useHistory();

  const [getSurgeryById, { data: dataSurgery }] =
    useLazyQuery(getSurgeryByIdQuery);
  const { meCxt, currentOrganizationCxt } = useContext<any>(ApplicationContext);

  const [showModalConfigMsg, setShowModalConfigMsg] = useState(false);

  const [practitioner, setPractitioner] = useState<Practitioner>();
  const [organization, setOrganization] = useState<Organization>();
  const [currentPatient, setCurrentPatient] = useState<Patient>();

  useEffect(() => {
    const surgeryId = new URLSearchParams(queryParams).get("surgeryId");
    if (surgeryId) {
      const getSurgeryByIdAsync = async () => {
        await getSurgeryById({ variables: { surgeryId } });
      };
      getSurgeryByIdAsync();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (dataSurgery) {
      setCurrentPatient({
        id: dataSurgery.surgery[0].patient.id,
        name: dataSurgery.surgery[0].patient.name,
        surname: dataSurgery.surgery[0].patient.surname,
        birthDate: dataSurgery.surgery[0].patient.birth_date,
        surgeryId: dataSurgery.surgery[0].id,
      });
    }
  }, [dataSurgery]);

  useEffect(() => {
    const role = cookie.get(COOKIES.ROLE);
    if (role !== APPLICATION_ROLE.ADMIN) {
      if (meCxt.meToEdit.id) {
        if (!meCxt.meToEdit.rpps || !meCxt.meToEdit.signatureFilePath) {
          setShowModalConfigMsg(true);
        } else {
          const downloadPractitionerSignatureAsync = async () => {
            const sigB64 = await downloadPractitionerSignature();
            setPractitioner({
              name: meCxt.meToEdit.name,
              surname: meCxt.meToEdit.surname,
              phone: meCxt.meToEdit.phone,
              rpps: meCxt.meToEdit.rpps,
              signature: `data:${sigB64.data};base64,${sigB64.data}`,
              specialization: meCxt.meToEdit.specialization,
            });
          };
          downloadPractitionerSignatureAsync();
        }
      }
    }
  }, [meCxt.meToEdit]);

  useEffect(() => {
    if (currentOrganizationCxt.currentOrganizationToEdit.id) {
      const organizationTmp = currentOrganizationCxt.currentOrganizationToEdit;
      let am = null;
      let finess = null;
      let address = {
        city: "",
        postalCode: "",
        number: "",
        street: "",
      };
      if (organizationTmp.liberal_practice) {
        am = organizationTmp.liberal_practice.am;
        address = {
          city: organizationTmp.liberal_practice.address?.city,
          postalCode: organizationTmp.liberal_practice.address?.postal_code,
          number: organizationTmp.liberal_practice.address?.number,
          street: organizationTmp.liberal_practice.address?.street,
        };
      } else if (organizationTmp.hospital_service) {
        finess = organizationTmp.hospital_service.hospital.finess;
        address = {
          city: organizationTmp.hospital_service.hospital.address?.city,
          postalCode:
            organizationTmp.hospital_service.hospital.address?.postal_code,
          number: organizationTmp.hospital_service.hospital.address?.number,
          street: organizationTmp.hospital_service.hospital.address?.street,
        };
      }
      setOrganization({
        id: organizationTmp.id,
        am,
        finess,
        address,
      });
    }
  }, [currentOrganizationCxt.currentOrganizationToEdit]);

  const handleSubmit = (values: any) => {
    // console.log(values);
  };

  return (
    <PageWrapper>
      <Container>
        <FormPrescription
          title="Créer une prescription"
          handleSubmit={(values: FormValues) => handleSubmit(values)}
          organization={organization}
          practitioner={practitioner}
          patient={currentPatient}
        />
        {/* {generatePdf && (
          <PDFDownloadLink
            document={<PdfComponent medications={medicationsSelected} />}
            fileName="ordonnance.pdf"
          >
            {({ blob, url, loading, error }) =>
              loading ? "Loading document..." : "Download now!"
            }
          </PDFDownloadLink>
        )}
          */}
      </Container>
      <CustomModal
        showModal={showModalConfigMsg}
        textContent="Veuillez ajouter votre numéro RPPS et votre signature dans le menu Configuration. Une fois que vous aurez donné ces informations, elles seront définies par défaut et ne vous seront plus demandées. Vous pouvez toujours modifier ces informations sur la page de configuration."
        buttonRightContent="Ajouter des informations"
        maxWidth="400px"
        handleClickButtonRight={() =>
          history.push("/prescriptions/configuration")
        }
      ></CustomModal>
    </PageWrapper>
  );
};

export default PrescriptionCreate;

const Container = styled.div`
  height: 100%;
  margin: 0 auto;
  max-width: 1030px;
`;
