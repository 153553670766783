import { Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import CustomButton from "../../CustomButton/CustomButton";
import { TextInputForm } from "../../Form/TextInputForm/TextInputForm";
import TitleSectionForm from "../../Form/TitleSectionForm/TitleSectionForm";
import CustomContour from "../../Layout/CustomContour/CustomContour";
import * as Yup from "yup";
import { SimpleCheckForm } from "../../Form/SimpleCheckForm/SimpleCheckForm";
import Dropzone from "../../Dropzone";
import { DropdownForm } from "../../Form/DropdownForm/DropdownForm";
import { useLazyQuery } from "@apollo/client";
import { loader } from "graphql.macro";

const getOrganizationSurgeryTypeQuery = loader(
  "../../../graphql/getOrganizationSurgeryType.graphql"
);

type FormMode = "creation" | "update";

interface FormValues {
  id?: string;
  name: string;
  type: string;
  active: boolean;
  fileToUpload: File | null;
  fileToUploadIsSet: boolean;
  currentFileName: string | null;
}

interface FormOrganizationGuidelineProps {
  title: string;
  mode: FormMode;
  handleSubmit: (values: FormValues) => void;
  initialValues: FormValues;
  organizationId: string;
}

const FILE_SIZE = 5 * 1024 * 1024; //5mb

const validationSchema = (mode: FormMode) =>
  Yup.object().shape({
    name: Yup.string()
      .required("Veuillez entrer le nom de la consigne")
      .min(3, "3 lettres minimum"),
    type: Yup.string().required("Veuillez entrer une pathologie"),
    fileToUpload:
      mode === "creation"
        ? Yup.mixed()
            .required("Veuillez uploader un fichier PDF")
            .test(
              "fileSize",
              "Le poids du fichier doit être au maximum 5mb",
              (value) => value && value.size <= FILE_SIZE
            )
            .test(
              "fileFormat",
              "Le fichier doit être un PDF",
              (value) => value && ["application/pdf"].includes(value.type)
            )
        : Yup.mixed().when("fileToUploadIsSet", {
            is: (val: boolean) => val,
            then: Yup.mixed()
              .test(
                "fileSize",
                "Le poids du fichier doit être au maximum 5mb",
                (value) => value && value.size <= FILE_SIZE
              )
              .test(
                "fileFormat",
                "Le fichier doit être un PDF",
                (value) => value && ["application/pdf"].includes(value.type)
              ),
          }),
  });

const FormOrganizationGuideline: React.FC<FormOrganizationGuidelineProps> = ({
  title,
  mode,
  handleSubmit,
  initialValues,
  organizationId,
}) => {
  const [organizationSurgeriesTypes, setOrganizationSurgeriesTypes] = useState(
    []
  );

  const [getOrganizationSurgeryType, { data: organizationSurgeriesTypesData }] =
    useLazyQuery(getOrganizationSurgeryTypeQuery);

  useEffect(() => {
    if (organizationSurgeriesTypesData) {
      const organizationSurgeriesTypesTmp =
        organizationSurgeriesTypesData?.organization_surgery_type?.map(
          (e: { surgery_type: { value: string; id: string } }) => {
            return { label: e.surgery_type?.value, value: e.surgery_type?.id };
          }
        );
      setOrganizationSurgeriesTypes(organizationSurgeriesTypesTmp);
    }
  }, [organizationSurgeriesTypesData]);

  useEffect(() => {
    if (organizationId) {
      getOrganizationSurgeryType({
        variables: { organizationId: organizationId },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationId]);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={async (values: FormValues, { resetForm }) => {
        await handleSubmit(values);
        if (mode === "creation") {
          resetForm();
        }
      }}
      validationSchema={() => validationSchema(mode)}
      validateOnChange={false}
    >
      {(props) => {
        return (
          <FormContainer>
            <CustomContour title={title}>
              <Section>
                <TitleSectionForm title="Consignes médicales à transmettre à vos patients" />
                <FieldSection>
                  <FieldContainer>
                    <Field
                      name={"name"}
                      label={"Nom de la consigne"}
                      placeholder={"Nom de la consigne"}
                      component={TextInputForm}
                    />
                  </FieldContainer>
                  <FieldContainer>
                    <Field
                      name={"type"}
                      label={"Type d'intervention"}
                      placeholder={"--"}
                      component={DropdownForm}
                      data={organizationSurgeriesTypes}
                    />
                  </FieldContainer>
                  <FieldContainer>
                    <Field
                      name={"active"}
                      label={"Activé"}
                      component={SimpleCheckForm}
                    />
                  </FieldContainer>
                </FieldSection>
                <SeparatorContainer />
                <Section>
                  <FieldSection>
                    <Field
                      name={"fileToUpload"}
                      label={"Fichier PDF"}
                      placeholder={"Cliquez pour sélectionner un fichier"}
                      component={Dropzone}
                      currentFileName={initialValues.currentFileName}
                    />
                  </FieldSection>
                </Section>
              </Section>
            </CustomContour>
            <ButtonContainer>
              <CustomButton
                type="submit"
                content={
                  mode === "creation"
                    ? "Enregistrer la consigne"
                    : "Sauvegarder les modifications"
                }
                disabled={props.isSubmitting}
              />
            </ButtonContainer>
          </FormContainer>
        );
      }}
    </Formik>
  );
};

export default FormOrganizationGuideline;

const FormContainer = styled(Form)``;

const Section = styled.div`
  width: 100%;
`;

const FieldSection = styled.div`
  width: 100%;
  display: flex;
  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.tabletMax}) {
    flex-direction: column;
  }
`;

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 30px;
`;

const FieldContainer = styled.div`
  width: calc(100% / 3);
  margin: 0 8px;

  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.tabletMax}) {
    width: 100%;
    margin: 8px 0;
  }
`;

const SeparatorContainer = styled.div`
  margin: 40px 0;
`;
