import { useLazyQuery } from "@apollo/client";
import { loader } from "graphql.macro";
import cookie from "js-cookie";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { PageWrapper } from "../../components/Layout/PageWrapper/PageWrapper";
import CustomList from "../../components/List/CustomList/CustomList";
import { COOKIES } from "../../constants/cookies_types";
import { APPLICATION_ROLE } from "../../constants/db_types";
import { CardStyle2 } from "../../theme/components/ThemeComponents";

const getAllOrganizationsCountQuery = loader(
  "../../graphql/getAllOrganizationsCount.graphql"
);

const getAllOrganizationsWithLimitOffsetQuery = loader(
  "../../graphql/getAllOrganizationsWithLimitOffset.graphql"
);

interface EstablishmentsListProps {}

const limit = 10;

const columns = [
  {
    value: "Nom",
    ratio: "40%",
    type: "string",
  },
  {
    value: "Téléphone",
    ratio: "15%",
    type: "string",
  },
  { value: "Accès à l'établissement", ratio: "30%", type: "string" },
  {
    value: "Type",
    ratio: "15%",
    type: "string",
    sortable: true,
    sortId: "organization_type",
  },
];

const EstablishmentsList: React.FC<EstablishmentsListProps> = () => {
  const isAdmin = cookie.get(COOKIES.ROLE) === APPLICATION_ROLE.ADMIN;
  const history = useHistory();
  const [pagesNumber, setPagesNumber] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("%%");
  const [searchBarValue, setSearchBarValue] = useState("");
  const [orderBy, setOrderBy] = useState<any>({
    organization_type: "desc",
  });

  const [organizations, setOrganizations] = useState<Array<{
    customData: any;
    rowValues: Array<string>;
  }> | null>(null);

  const [getAllOrganizationsCount, { data: dataOrganizationsCount }] =
    useLazyQuery(getAllOrganizationsCountQuery, {
      fetchPolicy: "network-only",
    });

  const [getAllOrganizationsWithLimitOffset, { data: dataOrganizations }] =
    useLazyQuery(getAllOrganizationsWithLimitOffsetQuery, {
      fetchPolicy: "network-only",
    });

  useEffect(() => {
    if (!isAdmin) {
      history.push("/home");
    }
  }, [history, isAdmin]);

  useEffect(() => {
    getAllOrganizationsCount({
      variables: {
        search,
      },
    });
  }, [getAllOrganizationsCount, search]);

  /* Calculate number of pages */
  useEffect(() => {
    if (dataOrganizationsCount) {
      const organizationsCount = dataOrganizationsCount.organization.length;
      let pagesNumberTmp = Math.ceil(organizationsCount / limit);
      setPagesNumber(pagesNumberTmp);
    }
  }, [dataOrganizationsCount]);

  useEffect(() => {
    getAllOrganizationsWithLimitOffset({
      variables: {
        limit,
        offset: currentPage * limit - limit,
        orderBy,
        search,
      },
    });
  }, [currentPage, getAllOrganizationsWithLimitOffset, search, orderBy]);

  useEffect(() => {
    if (dataOrganizations) {
      const organizationsTmp = dataOrganizations.organization.map((e: any) => {
        let rowValues;
        if (e.liberal_practice) {
          rowValues = [
            e.name,
            e.liberal_practice.phone,
            e.liberal_practice.access,
            e.organization_type,
          ];
        } else if (e.hospital_service) {
          rowValues = [
            e.name + "-" + e.hospital_service.hospital.name,
            e.hospital_service.phone,
            e.hospital_service.access,
            e.organization_type,
          ];
        }
        return {
          customData: {
            ...e,
          },
          rowValues: rowValues,
        };
      });
      setOrganizations(organizationsTmp);
    }
  }, [dataOrganizations]);

  const handleChangeCurrentPage = (value: number) => {
    if (value > 0 && value <= pagesNumber && value !== currentPage) {
      setCurrentPage(value);
    }
  };

  const handleFilter = (value: string) => {
    setSearch("%" + value + "%");
    setCurrentPage(1);
  };

  const handleClickOrganization = (index: number) => {
    localStorage.setItem(
      "currentEstablishment",
      JSON.stringify(organizations?.[index].customData)
    );
    history.push("/establishments/update");
  };

  const handleClickSort = (sortId: string) => {
    let direction;
    if (sortId === "organization_type") {
      direction = orderBy.organization_type === "asc" ? "desc" : "asc";
      setOrderBy({ organization_type: direction });
    }
  };

  return (
    <PageWrapper>
      <Container>
        <CustomList
          title="Liste des établissements"
          columns={columns}
          data={organizations}
          pagesNumber={pagesNumber}
          currentPage={currentPage}
          handleChangeCurrentPage={handleChangeCurrentPage}
          displaySearchBar={true}
          searchBarValue={searchBarValue}
          setSearchBarValue={(v: string) => setSearchBarValue(v)}
          handleSearch={(v: string) => handleFilter(v)}
          handleClick={(i: number) => handleClickOrganization(i)}
          handleClickSort={(sortId: string) => handleClickSort(sortId)}
        />
      </Container>
    </PageWrapper>
  );
};

export default EstablishmentsList;

const Container = styled.div`
  height: 100%;
  ${CardStyle2}
  max-width: 1030px;
  min-width: 566px;
  margin: 0 auto;
`;
